import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ModbusGatewaysSubmenu from "../../components/menus/sub-menu/subtypes/ModbusGatewaysSubmenu";
import DeviceListCard from "../../components/device-cards/DeviceListCard";
import { getListViewData, clearListViewData } from "../../actions/list-view";
import LoaderRoller from "../../components/loader/LoaderRoller";
import TopMenu from "../../components/menus/top-menu/TopMenu";
import { Link } from "react-router-dom";
const initialState = {
  formInput: {
    gatewayModbus: false,
    projectId: "",
  },
};
class ModbusDashboard extends Component {
  state = initialState;
  componentDidMount = () => {
    const formInput = { ...this.state.formInput };
    formInput.projectId = this.props.match.params?.projectId;
    this.setState({ formInput });
    const { currentPageNr } = this.props.match.params;
  };
  handleGateway = () => {
    const formInput = { ...this.state.formInput };
    formInput.gatewayModbus = true;
    this.setState({ formInput });
  };
  render() {
    const formInput = this.state.formInput;
    return (
      <div>
        <div>
          <ModbusGatewaysSubmenu />{" "}
          <div className="row">
            <div className="card modbusTag p-4 intro-bg-color">
              <div className="h6 mb-4 modbusTagSize">
                Getting Started with Modbus
              </div>
              <div>
                <p className="submenu-text">
                  Here are the steps you need to follow for connecting your
                  Modbus device with BACE so that you can remotely control your
                  asset immediaetly.
                </p>
              </div>

              <div className="mb-2">
                <h6 className="mb-2 modbusTagSize">
                  Step 1. Select your BACE gateway
                </h6>
                <p className="submenu-text">
                  First you need to select which BACE gateway you want to
                  connect with your modbus device.BACE Cloud will handle
                  necessary BACE Gateway configurations in the background so
                  that your gateway is setup to communicate on Modbus protocol.
                </p>
              </div>
              <div className="mb-4">
                <h6 className="mb-2 modbusTagSize">
                  Step 2. Enter Gateway settings for Modbus communication
                </h6>
                <p className="submenu-text">
                  There are some Modbus protocol level settings parameters such
                  as baudrate,IP,etc that will be applicable to all of your
                  modbus devices and you need to enter these in this step.
                </p>
              </div>
              <div>
                {" "}
                <h6 className="mb-2 modbusTagSize">
                  Step 3. Set Modbus Device settings
                </h6>
                <p className="submenu-text">
                  You will enter details for the specific modbus devices such as
                  Slave ID or IP address and name them properly so that you can
                  recogize them when they start sending messages.
                </p>
              </div>
              <div>
                <h6 className="mb-2 modbusTagSize">
                  Step 4.Add registers for each Modbus Device
                </h6>
                <p className="submenu-text">
                  You will add registers,labels,units and other parameters as
                  the last step of Modbus set up.
                </p>
              </div>
              <br></br>

              <div className="d-flex justify-content-end">
                <div className="d-flex">
                  <div className="mb-3 form-check p-2 m-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label className="form-check-label" for="exampleCheck1">
                      Don't Show Again
                    </label>
                  </div>
                  {/* <button
                    className="btn btn-secondary buttonView btn-lg p-2 m-2"
                    onClick={() => this.handleGateway()}
                  >
                    {" "}
                    Let's Start
                  </button> */}
                  <Link
                    to={`/modbus-gateways/select-Gateway/1/${formInput.projectId}`}
                    className="btn btn-primary buttonView btn-lg p-2 m-2"
                  >
                    Let's Start
                  </Link>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps, {})(ModbusDashboard));
