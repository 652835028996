const initialState = {
  listViewData: null,
};

export default function listViewReducer(state = initialState, action) {
  switch (action.type) {
    case "ALL_SUBTYPES_RECEIVED": {
      return {
        ...state,
        subtypes: action.data,
      };
    }
    case "ALL_GROUPS_RECEIVED": {
      return {
        ...state,
        allGroups: action.data,
      };
    }

    case "CLEAR_LOADER": {
      return { ...state, loader: false };
    }
    case "START_LOADER": {
      return { ...state, loader: true };
    }

    case "GATEWAY_DATA_WITH_ACCESSIBLE_PROTOCOL": {
      return {
        ...state,
        allGatewayDataWithAccessibleProtocol: action.data,
      };
    }

    case "ALL_NOTIFICATION_LIST": {
      return {
        ...state,
        allNotificationList: action.data,
      };
    }

    case "UPDATE_NOTIFICATION_LIST": {
      return {
        ...state,
        updateNotificationList: action.data,
      };
    }
    case "ALL_ACKNOWLEDGED_NOTIFICATION_LIST": {
      return {
        ...state,
        acknowledgedNotificationList: action.data,
      };
    }

    case "CLEAR_ACKNOWLEDGE_NOTIFICATION": {
      return {
        ...state,
        updateNotificationList: null,
        acknowledgedNotificationList: null,
        allNotificationList: null,
      };
    }

    case "ALL_DEVICETYPES_RECEIVED": {
      return {
        ...state,
        allDeviceTypes: action.data,
      };
    }
    case "LIST_VIEW_DATA_RECEIVED": {
      return {
        ...state,
        listViewData: action.data,
      };
    }
    case "PROJECT_DELLA_DATA_ALL": {
      return {
        ...state,
        projectDellaData: action.data,
      };
    }

    case "DELLA_CHART_DATA_ALL": {
      return {
        ...state,
        dellaChartData: action.data,
      };
    }

    case "DELA_DEVICE_DATA": {
      return {
        ...state,
        dellaChartData: null,
        projectDellaData: null,
      };
    }

    case "PROJECT_DATA_ALL": {
      return {
        ...state,
        allProjectData: action.data,
      };
    }

    case "PROJECT_DATA_WITHOUT_CURRENT_PROJECT": {
      return {
        ...state,
        allProjectDataWithoutCurrentProject: action.data,
      };
    }

    case "GATEWAY_DATA_WITH_CURRENT_PROJECT": {
      return {
        ...state,
        allGatewayDataWithCurrentProject: action.data,
      };
    }

    case "MOVED_GATEWAY_TO_PROJECT": {
      return {
        ...state,
        movedGatewayToProject: action.data,
      };
    }

    case "FAIL_MOVING_GATEWAY_TO_PROJECT": {
      return {
        ...state,
        failMovingGatewayToProject: action.data,
      };
    }

    case "CLEAR_GATEWAY_TO_MOVED_PROJECT": {
      return {
        ...state,
        movedGatewayToProject: null,
        failMovingGatewayToProject: null,
      };
    }

    case "CLEAR_CURRENT_GATEWAY_TARGET_PROJECT": {
      return {
        ...state,
        allGatewayDataWithCurrentProject: null,
        allProjectDataWithoutCurrentProject: null,
      };
    }

    case "PROJECT_DATA_DETAILS_ALL": {
      return {
        ...state,
        allProjectDetailsData: action.data,
      };
    }

    case "PROJECT_DATA_IS_CONNECTED_ALL": {
      return {
        ...state,
        allProjectIsConnectedData: action.data,
      };
    }

    case "PROJECT_DATA_IS_DISCONNECTED_ALL": {
      return {
        ...state,
        allProjectIsDisconnectedData: action.data,
      };
    }

    case "PROJECT_NAME_CHANGED": {
      return {
        ...state,
        projectNameChanged: action.data,
      };
    }

    case "PROJECT_NAME_DELETE_NOT_POSSIBLE": {
      return {
        ...state,
        projectNameDeleteNotPossible: action.data,
      };
    }

    case "PROJECT_NAME_DELETE_POSSIBLE": {
      return {
        ...state,
        projectNameDeletePossible: action.data,
      };
    }

    case "PROJECT_NAME_CREATED": {
      return {
        ...state,
        projectNameCreated: action.data,
      };
    }

    case "ADD_MEMBER_CREATED": {
      return {
        ...state,
        addMemberCreated: action.data,
      };
    }
    case "CHANGE_ACCESS_TYPE_MEMBER": {
      return {
        ...state,
        changeAccessMemberUpdated: action.data,
      };
    }

    case "ADD_MEMBER_FAILED": {
      return {
        ...state,
        addMemberFailed: action.data,
      };
    }

    case "CLEAR_ADD_MEMBER_DATA": {
      return {
        ...state,
        addMemberCreated: null,
        addMemberFailed: null,
        deleteProjectMemberStatus: null,
        changeAccessMemberUpdated: null,
      };
    }

    case "CLEAR_MEMBER_VIEW_DATA": {
      return {
        //  projectMemberDetails: null,
      };
    }

    case "DELETE_PROJECT_MEMBER": {
      return {
        ...state,
        deleteProjectMemberStatus: action.data,
      };
    }

    case "CONFIG_TEMPLATE_CREATED": {
      return {
        ...state,
        listViewData: action.data,
      };
    }

    case "LIST_DEVICE_INFO_DATA_RECEIVED": {
      return {
        ...state,
        deviceInfoViewData: action.data,
      };
    }

    case "LIST_DEVICE_EVENT_TYPE_DATA_RECEIVED": {
      return {
        ...state,
        deviceInfoEventTypeViewData: action.data,
      };
    }

    case "LIST_SETTINGS_CONNECTION_DATA_RECEIVED": {
      return {
        ...state,
        settingsConnectionGateway: action.data,
      };
    }
    case "LIST_CONFIRM_WIFI_SET_DATA_RECEIVED": {
      return {
        ...state,
        WifiListSetting: action.data,
      };
    }
    case "CONFIRM_CELLULAR_VALUE": {
      return {
        ...state,
        confirmCellularData: action.data,
      };
    }

    case "LIST_TEMPLATE_DATA_RECEIVED": {
      return {
        ...state,
        settingslistTemplateOption: action.data,
      };
    }

    case "LIST_CONFIRM_TEMPLATE_DATA_RECEIVED": {
      return {
        ...state,
        settingslistConfirmTemplateOption: action.data,
      };
    }

    case "CONFIRM_WIFI_OPTION": {
      return {
        ...state,
        setWifiOption: action.data,
      };
    }

    case "LIST_FAIL_WIFI_SET_DATA_RECEIVED": {
      return {
        ...state,
        failWifiOption: action.data,
      };
    }

    case "LIST_FAIL_TEMPLATE_SET_DATA_RECEIVED": {
      return {
        ...state,
        failTemplateOption: action.data,
      };
    }
    case "LIST_FAIL_CONNECTION_SET_DATA_RECEIVED": {
      return {
        ...state,
        failConnectionOption: action.data,
      };
    }
    case "LIST_FAIL_SELFTEST_SET_DATA_RECEIVED": {
      return {
        ...state,
        failSelfTestOption: action.data,
      };
    }

    case "LIST_FAIL_REBOOT_SET_DATA_RECEIVED": {
      return {
        ...state,
        failRebootOption: action.data,
      };
    }

    case "LIST_DELETE_CONFIG_BY_ID": {
      return {
        ...state,
        deletedConfigById: action.data,
      };
    }

    case "LIST_CREATE_CONFIG_BY_ID": {
      return {
        ...state,
        createdConfigById: action.data,
      };
    }

    case "LIST_UPDATE_CONFIG_BY_ID": {
      return {
        ...state,
        updatedConfigById: action.data,
      };
    }
    case "LIST_SETTINGS_SELFTEST_DATA_RECEIVED": {
      return {
        ...state,
        settingsSelfTestGateway: action.data,
      };
    }

    case "LIST_SETTINGS_REBOOT_DATA_RECEIVED": {
      return {
        ...state,
        settingsRebootGateway: action.data,
      };
    }
    case "LIST_DEVICE_EVENT_DATA_RECEIVED": {
      return {
        ...state,
        deviceEventData: action.data,
      };
    }

    case "LIST_MOD_VIEW_DATA_RECEIVED": {
      return {
        ...state,
        listModViewData: action.data,
      };
    }

    case "LIST_BLUETOOTH_VIEW_DATA_RECEIVED": {
      return {
        ...state,
        listBluetoothViewData: action.data,
      };
    }
    case "CLEAR_LIST_VIEW_DATA": {
      return {
        ...state,
        listViewData: null,
        deviceInfoViewData: null,
      };
    }
    case "CLEAR_LIST_CONFIG_DATA": {
      return {
        ...state,
        listConfigViewData: null,
      };
    }
    case "LIST_DEVICE_DATA_RECEIVED": {
      return {
        ...state,
        deviceViewData: action.data,
      };
    }

    case "PROJECT_MEMBERS_DETAILS_VIEW": {
      return {
        ...state,
        projectMemberDetails: action.data,
      };
    }
    case "LIST_CONFIG_VIEW_DATA_RECEIVED": {
      return {
        ...state,
        listConfigViewData: action.data,
      };
    }

    case "CLEAR_LIST_DEVICE_DATA": {
      return {
        ...state,
        ...initialState,
      };
    }
    case "CLEAR_PROJECT_DELETE_DEVICE_DATA": {
      return {
        ...state,
        projectNameDeleteNotPossible: null,
      };
    }

    case "CLEAR_PROJECT_DELETED": {
      return {
        ...state,
        projectNameDeletePossible: null,
      };
    }

    case "CLEAR_DELETE_CONFIG_VALUE": {
      return {
        ...state,
        deletedConfigById: null,
      };
    }
    case "CLEAR_CREATE_CONFIG_VALUE": {
      return {
        ...state,
        createdConfigById: null,
      };
    }

    case "CLEAR_UPDATE_CONFIG_VALUE": {
      return {
        ...state,
        updatedConfigById: null,
      };
    }
    case "CLEAR_SETTINGS_LIST_DEVICE_DATA": {
      return {
        ...state,
        settingsConnectionGateway: null,
        settingsSelfTestGateway: null,
        settingsRebootGateway: null,
        WifiListSetting: null,
        failWifiOption: null,
        failTemplateOption: null,
        failConnectionOption: null,
        failRebootOption: null,
        failSelfTestOption: null,
        confirmCellularData: null,
        settingslistConfirmTemplateOption: null,
      };
    }

    default: {
      return state;
    }
  }
}
