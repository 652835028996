import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import LoaderRoller from "../../components/loader/LoaderRoller";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNetworkWired,
  faChartLine,
  faPlusCircle,
  faSliders,
} from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import { settingsEpViewChange } from "../../actions/view-history";
import SettingsSubmenu from "../../components/menus/sub-menu/subtypes/SettingsSubmenu";

class SettingsView extends Component {
  state = {
    formInput: {
      projectId: "",
    },
  };

  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;

    if (this.props.lastSettingsEpViewSelected === "LIST_VIEW") {
      return this.props.history.push(
        `/settings/members/1/${this.props.match.params?.projectId}`
      );
    }
    if (!currentPageNr) {
      //  return this.props.history.push(`/settings/info-view/1/${projectId}`);
    }
  };

  componentWillUnmount = () => {};

  render() {
    const formInput = this.state.formInput;
    formInput.projectId = this.props.match.params?.projectId;
    return (
      <div>
        <SettingsSubmenu />
        <div>
          <div></div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lastSettingsEpViewSelected: state.viewHistoryReducer.lastBaceEpViewSelected,
  };
}

export default withRouter(
  connect(mapStateToProps, { settingsEpViewChange })(SettingsView)
);
