let serverUrl = "";
let CONFIG = {};

const location = new URL(window.location.href);
const localDev = location.port && +location.port === 3000;

const fourHInMs = 14400000;
// TODO: Should be changed LiveDev & LiveProd
const liveDevURL = "dev-bacedashboard.azurewebsites.net";
const liveAccURL = "acc-bacedashboard.azurewebsites.net";
const liveProdURL = "panel.bace-iot.com";

const liveDev = location.host === liveDevURL;
const liveAcc = location.host === liveAccURL;
const liveProd = location.host === liveProdURL;

const devLocalServerUrl =
  "https://evalan-dev-bace-phpfrontend.azurewebsites.net";
// Development Slot
const devServerUrl = "https://evalan-dev-bace-phpfrontend.azurewebsites.net";
// Acceptence Slot
const accServerUrl = "https://evalan-acc-bace-phpfrontend.azurewebsites.net";
// Production Slot
const prodServerUrl = "https://dashboard.bace-iot.com";

const datatypesGuardian = {
  SIGNAL_STRENGTH: {
    datatype: 2001,
    label: "Signal strength",
    unit: "dBm",
    decimals: 0,
  },
  TEMPERATURE: {
    datatype: 2003,
    label: "Temperature",
    unit: "°C",
    decimals: 1,
  },
  HUMIDITY: {
    datatype: 2004,
    label: "Humidity",
    unit: "%",
    decimals: 0,
  },
  CONDUCTIVITY_IN_VOLTS: {
    datatype: 2006,
    label: "Conductivity in Volts",
    unit: "V",
    decimals: 4,
  },
  PRESSURE_IN_VOLTS: {
    datatype: 2007,
    label: "Pressure in Volts",
    unit: "V",
    decimals: 2,
  },
  ENERGY_STORAGE_IN_VOLTS: {
    datatype: 2005,
    label: "Energy storage Voltage in Volts",
    unit: "V",
    decimals: 2,
  },
};

const datatypesActivityMonitor = {
  TOTAL_STEP_COUNT: {
    datatype: 5300,
    label: "Total number of steps in session",
    unit: "steps",
    decimals: 0,
  },
  PERIODIC_STEP_COUNT: {
    datatype: 5301,
    label: "Steps in last minute",
    unit: "steps",
    decimals: 0,
  },
  GPS_LOCATION: {
    datatype: 2004,
    label: "GPS location",
    unit: "Longitude/latitude",
    decimals: 6,
  },
  AVG_HEART_RATE: {
    datatype: 6000,
    label: "Heart rate",
    unit: "BPM",
    decimals: 0,
  },
  BATTERY_VOLTAGE: {
    datatype: 5205,
    label: "Battery voltage",
    unit: "V",
    decimals: 2,
  },
};

const datatypesHumanMovementScience = {
  ACCELERATION_X: {
    datatype: 95011,
    label: "Acceleration X axis",
    unit: "m/s²",
    decimals: 0,
  },
  ACCELERATION_Y: {
    datatype: 95022,
    label: "Acceleration Y axis",
    unit: "m/s²",
    decimals: 0,
  },
  ACCELERATION_Z: {
    datatype: 95033,
    label: "Acceleration Z axis",
    unit: "m/s²",
    decimals: 0,
  },
  GYROSCOPE_X: {
    datatype: 95001,
    label: "Gyroscope X axis",
    unit: "mdeg/s",
    decimals: 1,
  },
  GYROSCOPE_Y: {
    datatype: 95002,
    label: "Gyroscope Y axis",
    unit: "mdeg/s",
    decimals: 1,
  },
  GYROSCOPE_Z: {
    datatype: 95003,
    label: "Gyroscope Z axis",
    unit: "mdeg/s",
    decimals: 1,
  },
  ACCELERATION_XYZ: {
    datatype: 95034,
    label: "Acceleration",
    unit: "in m/s²",
    decimals: 0,
  },
  GYROSCOPE_XYZ: {
    datatype: 95004,
    label: "Gyroscope",
    unit: "mdeg/s",
    decimals: 1,
  },
  BATTERY_VOLTAGE: {
    datatype: 5205,
    label: "Battery voltage",
    unit: "V",
    decimals: 2,
  },
};

const defaultViews = {
  defaultBaceEpView: "CARD_VIEW",
  defaultActivityMonitorsView: "CARD_VIEW",
  defaultModbusEpView: "CARD_VIEW",
  defaultSettingsEpView: "CARD_VIEW",
  defaultWebhookEpView: "INFO_VIEW",
};

if (localDev) {
  serverUrl = devLocalServerUrl;
} else if (liveDev) {
  serverUrl = devServerUrl;
} else if (liveProd) {
  serverUrl = prodServerUrl;
} else if (liveAcc) {
  serverUrl = accServerUrl;
}

const API_v1_URL = `${serverUrl}/api/v1`;

// Development local configuration
const devConfig = {
  serverUrl,
  API_v1_URL,
  datatypesGuardian,
  datatypesActivityMonitor,
  datatypesHumanMovementScience,
  defaultViews,
  clientId: "DQ2OrZ_Mvo37i_Q2SodExOaBlymtFzAY",
  clientSecret: "iCkGKgRIk4KSWu6YHNUzXSATrtqDYVtc",
  baseUrl: `${serverUrl}/api/v2`,
  loginUrl: `${serverUrl}/site/oauth2`,
  passResetUrl: `${serverUrl}/site/request-password-reset`,
  projectBaseUrl: `https://${liveDevURL}`,
  defaultNotificationTimeout: 30000,
  userLogoutTimeout: fourHInMs,
};

// Development deployed configuration
const devLiveConfig = {
  serverUrl,
  API_v1_URL,
  datatypesGuardian,
  datatypesActivityMonitor,
  datatypesHumanMovementScience,
  defaultViews,
  clientId: "UWJNscr1Wk2xlPGu5mHfxTd5du_-L2oq",
  clientSecret: "mrp6-7QhtMKA2P5O5A1c8Kbi8NJgS0bs",
  baseUrl: `${serverUrl}/api/v2`,
  loginUrl: `${serverUrl}/site/oauth2`,
  passResetUrl: `${serverUrl}/site/request-password-reset`,
  projectBaseUrl: `https://${liveDevURL}`,
  defaultNotificationTimeout: 30000,
  userLogoutTimeout: fourHInMs,
};

// Acceptance configuration
const accConfig = {
  serverUrl,
  API_v1_URL,
  datatypesGuardian,
  datatypesActivityMonitor,
  datatypesHumanMovementScience,
  defaultViews,
  clientId: "hfdU5mTWryIWBdd0E1RwlTcDHO-qBnWA",
  clientSecret: "d5JpLS3R9DFspX8CM_9_T9HDzgUF7vBR",
  baseUrl: `${serverUrl}/api/v2`,
  loginUrl: `${serverUrl}/site/oauth2`,
  passResetUrl: `${serverUrl}/site/request-password-reset`,
  projectBaseUrl: `https://${liveAccURL}`,
  defaultNotificationTimeout: 30000,
  userLogoutTimeout: fourHInMs,
};

// Production configuration
const prodConfig = {
  serverUrl,
  API_v1_URL,
  datatypesGuardian,
  datatypesActivityMonitor,
  datatypesHumanMovementScience,
  defaultViews,
  clientId: "_IqlpUuqsBeTNSs3_kzo1WRArcswNNE6",
  clientSecret: "67RZdi7cwrNXbHtUfYqcObdl0GGezUqh",
  baseUrl: `${serverUrl}/api/v2`,
  loginUrl: `${serverUrl}/site/oauth2`,
  passResetUrl: `${serverUrl}/site/request-password-reset`,
  projectBaseUrl: `https://${liveProdURL}`,
  defaultNotificationTimeout: 30000,
  userLogoutTimeout: fourHInMs,
};

if (localDev) {
  CONFIG = devConfig;
} else if (liveDev) {
  CONFIG = devLiveConfig;
} else if (liveAcc) {
  CONFIG = accConfig;
} else if (liveProd) {
  CONFIG = prodConfig;
} else {
  CONFIG = devLiveConfig;
}

export default CONFIG;
