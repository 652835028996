import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import BaceEpSubmenu from "../../components/menus/sub-menu/subtypes/BaceEpSubmenu";
import {
  getConfigListViewData,
  getListViewData,
  clearConfigData,
  clearCreateConfigData,
} from "../../actions/list-view";
import BaceEpConfigCard from "../../components/device-cards/BaceEpConfigCard";
import LoaderRoller from "../../components/loader/LoaderRoller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faCircleXmark,
} from "@fortawesome/pro-regular-svg-icons";
import CreateTemplatePopup from "../../components/popups/CreateTemplatePopup";
import StatusPopup from "../../components/popups/StatusPopup";
import { faCircleCheck, faXmark } from "@fortawesome/pro-light-svg-icons";
import CreatePopUpSucessFul from "../../components/popups/CreatePopUpSucessFul";

const initialState = {
  formInput: {
    open: false,
    popup: false,
    popupScreen: 0,
    projectId: "",
  },
};
class DeviceConfigView extends Component {
  state = initialState;

  componentDidMount = () => {
    if (this.props.baceEpView === "LIST_VIEW") {
      return this.props.history.push("/bace-ep/list-view");
    }

    const { currentPageNr, projectId } = this.props.match.params;
    const formInput = { ...this.state.formInput };
    formInput.projectId = this.props.match.params?.projectId;
    this.setState({ formInput });
    if (!currentPageNr) {
      return this.props.history.push(`/bace-ep/config-view/1/${projectId}`);
    }
    const filterParams = `filter[id_project]=${projectId}`;
    return this.props.getConfigListViewData(
      "",
      currentPageNr,
      "",
      "",
      filterParams
    );
  };
  componentWillUnmount = () => {
    this.props.clearConfigData();
  };

  componentDidUpdate = (prevProps) => {
    const { currentPageNr, projectId } = this.props.match.params;

    if (this.props.location !== prevProps.location) {
      const sortColumn = new URLSearchParams(this.props.location.search).get(
        "sortColumn"
      );
      const sortDirection = new URLSearchParams(this.props.location.search).get(
        "sortDirection"
      );
      const filterParams = `filter[id_project]=${projectId}`;
      this.props.getConfigListViewData(
        "bace-ep",
        currentPageNr,
        sortColumn,
        sortDirection,
        filterParams
      );
    }
  };

  handleToCreate = () => {
    const formInput = { ...this.state.formInput };
    formInput.open = true;
    formInput.popup = true;
    this.setState({ formInput });
  };

  handleToClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.open = false;
    formInput.popup = false;
    formInput.popupScreen = 0;
    this.setState({ formInput });
    this.props.clearCreateConfigData();
  };
  popupScreen = () => {
    const formInput = { ...this.state.formInput };
    formInput.popupScreen = 1;
    this.setState({ formInput });
  };
  render() {
    const { formInput } = this.state;
    if (this.props.listConfigViewData) {
      return (
        <Fragment>
          <div
            className={`${formInput.popup ? "popup d-block" : "popup d-none"}`}
          >
            {formInput.popupScreen === 0 && (
              <div>
                <CreateTemplatePopup
                  popupScreen={this.popupScreen}
                  handleToClose={this.handleToClose}
                />
              </div>
              
            )}
            {formInput.popupScreen === 1 &&
            this.props.createdConfigById === "pass" ? (
              <CreatePopUpSucessFul
                closePopup={this.handleToClose}
                message="Creating Complete!"
                className="online"
                icon={faCircleCheck}
              />
            ) : (
              this.props.createdConfigById === "failed" && (
                <CreatePopUpSucessFul
                  closePopup={this.handleToClose}
                  message="Creating failed, Please try again!"
                  className="offline"
                  icon={faCircleXmark}
                />
              )
            )}
          </div>

          <BaceEpSubmenu
            nrResults={this.props.listConfigViewData?._meta?.totalCount}
            projectId={this.props.match.params?.projectId}
          />
          <div>
            <BaceEpConfigCard
              subtype={"bace-ep"}
              subpage={"measurement-data"}
              projectId={formInput.projectId}
            />
            <div>
              <button
                type="button"
                className="btn bottomleft border-primary fixedButtonNew " style={{color:"white",backgroundColor:"var(--Ocean, #006)",fontWeight:"600",borderRadius:"8px",fontSize:"16px"}}
                // className="btn shadow  btn-light border-primary"
                onClick={() => this.handleToCreate()}
              >
                <FontAwesomeIcon  style={{fontWeight:"100",fontSize:"16px",marginRight:"9px"}} icon={faCirclePlus} /> Create a new template
              </button>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <BaceEpSubmenu nrResults={0} />
          <div className="bacecontainer pl-2">
            <LoaderRoller />
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    createdConfigById: state.listViewReducer?.createdConfigById,
    listConfigViewData: state.listViewReducer.listConfigViewData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getListViewData,
    clearConfigData,
    getConfigListViewData,
    clearCreateConfigData,
  })(DeviceConfigView)
);
