import React from "react";
import { Link } from "react-router-dom";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  uniqueId: () => {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substr(2);
    return dateString + randomness;
  },

  uniqueKey: () => {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return Math.random().toString(36).substr(2, 9);
  },

  round: (value, precision) => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  },

  Td: ({ children, to }) => {
    // Conditionally wrapping content into a link
    const ContentTag = to ? Link : "div";

    return (
      <td>
        <ContentTag
          to={to}
          style={{
            textDecoration: "none",
            fontSize: "16px",
            color: "black",
            textAlign:"center",
            marginTop:"15px"
          }}
        >
          {children}
        </ContentTag>
      </td>
    );
  },

  TdLink: ({ children, to }) => {
    // Conditionally wrapping content into a link
    const ContentTag = to ? Link : "div";

    return (
      <td>
        <ContentTag
          to={to}
          style={{
            fontSize: "16px",
            color: "black",
          }}
        >
          {children}
        </ContentTag>
      </td>
    );
  },
};
