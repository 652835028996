/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
//import CardBody from "./CardBody";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faServer,
  faPlusCircle,
  faCopy,
  faTrashCan,
  faTimes,
  faCircleQuestion,
} from "@fortawesome/pro-regular-svg-icons";
import Result from "./Result";
import { getApplyModbus, getSaveModbus } from "../../actions/modbus-view";
import SelectModbusDevices from "./SelectModbusDevices";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

class AddRegistersModbus extends Component {
  state = {
    formInput: {
      previousSet: false,
      regisiterList: [],
      loadModbusRegister: [],
      registerErrorView: false,
      register: "",
      unit: "",
      regStart: "",
      regtype: "",
      datatype: "",
      accessMode: "",
      deviceNumber: "0",
      showResult: false,
      redisterData: "",
      previousModbusTab: "",
      previousTab: "",
      previousSetTab: "",
      previousRegisterTab: "",
      nextSetTab: "",
      nextRegisterDevicesTab: "",
      nextModbusDevicesTab: "",
      nextResult: "",
      id_iot_device_Value: "",
      registerError: false,
      regTypeError: false,
      regstartError: false,
      registerLimitNumber: false,
      regStartError: false,
      registerAndRegStartError: false,
      scalingError: false,
      ipList: [],
      saveTemplate: false,
      sameRegisterName: false,
      sameRegStartName: false,
      projectId: "",
    },
  };

  handlerRegisterChange = (e, p, index) => {
    const formInput = { ...this.state.formInput };
    // const labelNameView = e.target.value;
    this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber].registers[
      index
    ].label = e.target.value;
    // formInput.modbusErrorLabelMsg = false;
    this.setState({ formInput });
  };
  handlerUnitChange = (e, p, index) => {
    const formInput = { ...this.state.formInput };
    this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber].registers[
      index
    ].unit = e.target.value;
    this.setState({ formInput });
  };

  handlerRegStartChange = (e, p, index) => {
    const formInput = { ...this.state.formInput };
    this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber].registers[
      index
    ].reg_start = e.target.value;
    this.setState({ formInput });
  };

  handlerScalingChange = (e, p, index) => {
    const formInput = { ...this.state.formInput };
    this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber].registers[
      index
    ].scale = e.target.value;
    this.setState({ formInput });
  };

  handleToShowRegister = (event, lo, index) => {
    const formInput = { ...this.state.formInput };

    formInput.regisiterList = lo.registers;
    formInput.deviceNumber = index;
    formInput.registerErrorView = false;
    formInput.registerLimitNumber = false;
    this.setState({ formInput });
  };

  handleRegtypeChange = (e, index) => {
    const formInput = { ...this.state.formInput };
    this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber].registers[
      index
    ].reg_type = e.target.value;
    this.setState({ formInput });
  };

  handleDatatypeChange = (e, index) => {
    const formInput = { ...this.state.formInput };
    this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber].registers[
      index
    ].data_type = e.target.value;
    this.setState({ formInput });
  };
  handleAccessModeChange = (e, index) => {
    const formInput = { ...this.state.formInput };
    this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber].registers[
      index
    ].access_mode = e.target.value;
    this.setState({ formInput });
  };
  handleToAddNewRegister = (e) => {
    const formInput = { ...this.state.formInput };

    if (
      this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber].registers
        .length === 99
    ) {
      formInput.registerLimitNumber = true;
      this.setState({ formInput });
    } else {
      const byteOrderValue =
        this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber].byteOrder;
      const wordOrderValue =
        this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber].wordOrder;
      const slaveOrIpAddressIDValue =
        this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber]
          .slaveOrIpAddressID;

      this.props.modbusDetails.ModbusNewRow[
        formInput.deviceNumber
      ].registers.push({
        label: "",
        unit: "",
        addr:
          this.props.modbusDetails.modeName === "modbus-tcp"
            ? formInput.deviceNumber + 1
            : slaveOrIpAddressIDValue,
        reg_start: "",
        reg_type: "0",
        data_type: "0",
        access_mode: "2",
        reg_Id:
          this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber]
            .registers.length,
        value_id: "",
        is_single_bit_binary: 0,
        byte_order: byteOrderValue,
        word_order: wordOrderValue,

        // regtype: "Holding",
        // datatype: "(unit16) Unsigned 16 bit Integer",
        // accessMode: "(r)Readable",
      });

      formInput.registerErrorView = false;
      this.setState({ formInput });
    }
  };

  handleToCopyNewrow = (e) => {
    const formInput = { ...this.state.formInput };
    if (
      this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber].registers
        .length === 99
    ) {
      formInput.registerLimitNumber = true;
      this.setState({ formInput });
    } else {
      const registerLength =
        this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber].registers
          .length;
      const registerLastData =
        this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber].registers[
          registerLength - 1
        ];

      this.props.modbusDetails.ModbusNewRow[
        formInput.deviceNumber
      ].registers.push({
        access_mode: registerLastData.access_mode,
        addr:
          this.props.modbusDetails.modeName === "modbus-tcp"
            ? formInput.deviceNumber + 1
            : registerLastData.addr,
        byte_order: registerLastData.byte_order,
        data_type: registerLastData.data_type,
        is_single_bit_binary: 0,
        label: registerLastData.label,
        reg_Id: registerLastData.reg_Id,
        reg_start: registerLastData.reg_start,
        reg_type: registerLastData.reg_type,
        unit: registerLastData.unit,
        word_order: registerLastData.word_order,
        value_id: registerLastData.value_id,
        scale: registerLastData.scale,
      });
      formInput.registerErrorView = false;
      this.setState({ formInput });
    }
  };
  componentDidMount = () => {
    const formInput = { ...this.state.formInput };
    formInput.projectId = this.props.match.params?.projectId;
    this.setState({ formInput });
  };

  handleToNextApply = (savingType) => {
    const formInput = { ...this.state.formInput };
    formInput.projectId = this.props.projectId;
    formInput.previousSet = false;
    const modbusDetailsValue = this.props.modbusDetails;
    const ipsData = modbusDetailsValue.ModbusNewRow.map(
      (m) => m.slaveOrIpAddressID
    );

    formInput.ipList = ipsData;
    const modbusRegistersData = modbusDetailsValue.ModbusNewRow.map(
      (m) => m.registers
    ).flatMap((m) => m);

    const regstartValue = modbusRegistersData.map(
      (l) =>
        l.reg_start === "" || l.reg_start === null || l.reg_start === undefined
      // ||
      //  l.reg_start.match(/^ *$/) !== null
    );
    for (let j = 0; j < modbusRegistersData.length; j++) {
      modbusRegistersData[j].value_id = 2100 + j;
      modbusRegistersData[j].is_single_bit_binary = 0;
    }
    const modeData =
      modbusDetailsValue.modeName === "modbus-rtu"
        ? modbusDetailsValue.modeValue
        : 0;
    const ModbusData = {
      label: modbusDetailsValue.modbusTemplateName,
      mode: modbusDetailsValue.modeName === "modbus-rtu" ? "0" : "1",
      baud_rate:
        modbusDetailsValue.modeName === "modbus-rtu"
          ? modbusDetailsValue.modeValue
          : null,
      interval: modbusDetailsValue.interval,
      id_physical_device: this.props.modbusDetails.physicalDeviceIdGateway,
      id_group: this.props.modbusDetails.groupId,
      as_sub_devices: 1,
      // ips: this.props.modbusDetails.modeName === "modbus-tcp" ? ipsData : "",
    };

    // if (this.props.modbusDetails?.modeName === "modbus-tcp") {
    //   ModbusData = {
    //     ips: ipsData,
    //   };
    // }

    formInput.showResult = true;
    const registerList = modbusRegistersData.map(
      (l) =>
        l.label === "" ||
        l.label === null ||
        l.label === undefined ||
        l.label.match(/^ *$/) !== null
    );
    const uniqueConnection = [];
    const uniqueRegStartConnection = [];

    modbusRegistersData.map((img) => {
      if (uniqueConnection.indexOf(img.label) === -1 && img.label !== null) {
        uniqueConnection.push(img.label);
      } else {
        uniqueConnection.push(false);
      }
    });
    modbusRegistersData.map((img) => {
      if (
        uniqueRegStartConnection.indexOf(img.reg_start) === -1 &&
        img.reg_start !== null
      ) {
        uniqueRegStartConnection.push(img.reg_start);
      } else {
        uniqueRegStartConnection.push(false);
      }
    });

    const scalingValue = modbusRegistersData.map(
      (l) =>
        l.scale === "" ||
        l.scale === null ||
        l.scale === undefined ||
        l.scale === 0 ||
        l.scale >= 1000000
    );
    if (registerList.includes(true) && regstartValue.includes(true)) {
      formInput.registerAndRegStartError = true;
      formInput.regStartError = false;
      formInput.registerError = false;
      formInput.registerErrorView = false;
      formInput.scalingError = false;
      this.setState({ formInput });
    } else if (registerList.includes(true)) {
      formInput.registerError = true;
      formInput.registerAndRegStartError = false;
      formInput.regStartError = false;
      formInput.registerErrorView = false;
      formInput.scalingError = false;
      this.setState({ formInput });
    } else if (regstartValue.includes(true)) {
      formInput.regStartError = true;
      formInput.registerAndRegStartError = false;
      formInput.registerError = false;
      formInput.registerErrorView = false;
      formInput.scalingError = false;
      this.setState({ formInput });
    } else if (scalingValue.includes(true)) {
      formInput.scalingError = true;
      formInput.registerAndRegStartError = false;
      formInput.registerError = false;
      formInput.registerErrorView = false;
      this.setState({ formInput });
    } else if (uniqueConnection.includes(false)) {
      formInput.sameRegisterName = true;
      formInput.registerAndRegStartError = false;
      formInput.regStartError = false;
      formInput.registerError = false;
      formInput.registerErrorView = false;
      formInput.scalingError = false;
      formInput.sameRegStartName = false;
      this.setState({ formInput });
    } else if (uniqueRegStartConnection.includes(false)) {
      formInput.sameRegStartName = true;
      formInput.sameRegisterName = false;
      formInput.registerAndRegStartError = false;
      formInput.regStartError = false;
      formInput.registerError = false;
      formInput.registerErrorView = false;
      formInput.scalingError = false;
      this.setState({ formInput });
    } else {
      if (savingType === "true") {
        this.setState({ formInput });
        this.props.getApplyModbus(
          ModbusData,
          modbusRegistersData,
          modeData,
          ipsData,
          modbusDetailsValue.modeName,
          formInput.projectId
        );
      } else {
        formInput.saveTemplate = true;
        this.setState({ formInput });
        this.props.getSaveModbus(
          ModbusData,
          modbusRegistersData,
          modeData,
          ipsData,
          modbusDetailsValue.modeName,
          formInput.projectId
        );
      }
    }
  };

  handleToDeleteRow = (pValue, index) => {
    const formInput = this.state.formInput;

    if (
      this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber].registers
        .length !== 1
    ) {
      this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber].registers =
        this.props.modbusDetails.ModbusNewRow[
          formInput.deviceNumber
        ].registers.filter((_, i) => i !== index);
    } else {
      formInput.registerErrorView = true;
      formInput.regStartError = false;
      formInput.registerAndRegStartError = false;
      formInput.registerError = false;
      formInput.scalingError = false;
    }

    this.setState({ formInput });
  };

  handleToPrevious = (event) => {
    //  this.props.previousTab();
    const formInput = { ...this.state.formInput };
    formInput.previousSet = true;
    this.setState({ formInput });
    this.props.previousModbusTab();
  };

  componentDidMount = () => {
    this.defaultResultModbusGateway();
    const key = "addr";
    const uniqueModbusList = [
      ...new Map(
        this.props.loadDataTemplate[0]?.registers.map((item) => [
          item[key],
          item,
        ])
      ).values(),
    ];
    uniqueModbusList.map((pp) => {
      this.props.loadDataTemplate[0]?.registers.map((item) => {
        if (item.addr === pp.addr) {
          const itemNumber = this.props.modbusDetails?.ModbusNewRow.findIndex(
            (x) => x.slaveOrIpAddressID === pp.addr
          );

          this.props.modbusDetails.ModbusNewRow[itemNumber]?.registers?.push({
            label: item.label,
            unit: item.unit,
            reg_start: item.reg_start,
            scale: item.scale,
            reg_type: item.reg_type,
            data_type: item.data_type,
            access_mode: item.access_mode,
            byte_order: item.byte_order,
            word_order: item.word_order,
            addr: item.addr,
            value_id: item.value_id,
            reg_Id: item.reg_Id,
            is_single_bit_binary: item.is_single_bit_binary,
          });
        }
      });
    });
  };

  defaultResultModbusGateway = (formId) => {
    const formInput = { ...this.state.formInput };
    formInput.previousTab = this.props.previousTab;
    formInput.previousSetTab = this.props.previousSetTab;
    formInput.previousModbusTab = this.props.previousModbusTab;
    formInput.previousRegisterTab = this.props.previousRegisterTab;
    formInput.nextSetTab = this.props.nextSetTab;
    formInput.nextRegisterDevicesTab = this.props.nextRegisterDevicesTab;
    formInput.nextModbusDevicesTab = this.props.nextModbusDevicesTab;
    formInput.nextResult = this.props.nextResult;
    this.setState({ formInput });
  };

  handleToConnectionClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.saveTemplate = false;
    this.setState({ formInput });
  };

  render() {
    const formInput = this.state.formInput;
    const setGatewayInfo = this.props.setGatewayInfo;
    const modbusDetails = this.props.modbusDetails;
    formInput.previousTab = this.props.previousTab;
    formInput.previousSetTab = this.props.previousSetTab;
    formInput.previousModbusTab = this.props.previousModbusTab;
    formInput.previousRegisterTab = this.props.previousRegisterTab;
    formInput.nextSetTab = this.props.nextSetTab;
    formInput.nextRegisterDevicesTab = this.props.nextRegisterDevicesTab;
    formInput.nextModbusDevicesTab = this.props.nextModbusDevicesTab;
    formInput.nextResult = this.props.nextResult;
    formInput.id_iot_device_Value = this.props.id_iot_device_Value;
    formInput.loadModbusRegister = this.props.loadDataTemplate;

    // formInput.regisiterList = modbusDetails.ModbusNewRow[0].registers;
    const nextModbusDevicesTab = this.props.nextModbusDevicesTab;
    if (this.props.allSelectModbus && this.props.allSelectModbusPreLoad) {
      this.props.nextResult();
    }
    // if (this.props.saveModbusDevices) {
    //   formInput.saveTemplate = formInput.saveTemplate === true ? false : true;
    // }
    return (
      <div>
        {this.props.saveModbusDevices?.status === 200 &&
          formInput.saveTemplate && (
            <Dialog
              open={formInput.saveTemplate === true ? true : false}
              onClose={this.handleToConnectionClose}
            >
              <div onClick={this.handleToConnectionClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToConnectionClose}
                />
              </div>
              <DialogTitle>
                <p className="wififailedMessage">Modbus Template is saved</p>
              </DialogTitle>
            </Dialog>
          )}

        {this.props.allSelectModbus && this.props.allSelectModbusPreLoad ? (
          <Result
            modbusResultInfo={this.props.allSelectModbus}
            redisterData={this.props.allSelectModbusPreLoad}
            modbusDetails={this.props.modbusDetails}
            //   nextResult={this.props.nextResult}
            previousTab={formInput.previousTab}
            previousSetTab={formInput.previousSetTab}
            previousModbusTab={formInput.previousModbusTab}
            previousRegisterTab={formInput.previousRegisterTab}
            nextSetTab={formInput.nextSetTab}
            nextRegisterDevicesTab={formInput.nextRegisterDevicesTab}
            nextModbusDevicesTab={formInput.nextModbusDevicesTab}
            nextResult={formInput.nextResult}
            id_iot_device_Value={this.props.id_iot_device_Value}
          />
        ) : formInput.previousSet === true ? (
          <SelectModbusDevices
            setGatewayInfo={this.props.setGatewayInfo}
            // nextRegisterDevicesTab={this.props.nextRegisterDevicesTab}
            ModbusFirstData={this.props.modbusDetails.ModbusFirstData}
            ModbusDataArray={this.props.modbusDetails.ModbusDataArray}
            modbusTemplateName={this.props.modbusTemplateName}
            modeName={this.props.modeName}
            modeValue={this.props.modeValue}
            intervalValue={this.props.intervalValue}
            modNameList={this.props.modNameList}
            modNameValue={this.props.modNameValue}
            groupId={this.props.groupId}
            loadDataTemplate={this.props.loadDataTemplate}
            previousLoadTemplate={this.props.previousLoadTemplate}
            //  nextResult={this.props.nextResult}
            defaultValueGateway={this.props.defaultValueGateway}
            formId={this.props.formId}
            physicalDeviceIdGateway={this.props.physicalDeviceIdGateway}
            previousTab={formInput.previousTab}
            previousSetTab={formInput.previousSetTab}
            previousModbusTab={formInput.previousModbusTab}
            previousRegisterTab={formInput.previousRegisterTab}
            //  nextSetTab={this.props.nextSetTab}
            nextSetTab={formInput.nextSetTab}
            nextRegisterDevicesTab={formInput.nextRegisterDevicesTab}
            nextModbusDevicesTab={formInput.nextModbusDevicesTab}
            nextResult={formInput.nextResult}
            id_iot_device_Value={this.props.id_iot_device_Value}
          />
        ) : (
          <div>
            <div>
              <FontAwesomeIcon
                style={{ marginRight: "8px" }}
                className="fa-lg driveModbus"
                icon={faServer}
              />
              <span className="modbusTitle">
                {" "}
                Add register for each Modbus Device
              </span>
              <button
                type="button"
                className="btn shadow previousbutton border-primary"
                onClick={() => this.handleToNextApply("false")}
              >
                Save Modbus template
              </button>
            </div>
            <div style={{ display: "flex", padding: "27px" }}>
              <div style={{ display: "flex" }} className="container slaveView">
                <ul
                  style={{ display: "block", width: "15%" }}
                  className="nav nav-pills nav-stacked"
                >
                  <div style={{ fontWeight: "700" }}>
                    {this.props.modbusDetails.modeName === "modbus-rtu"
                      ? "Slave Id"
                      : "IP Address"}
                  </div>
                  {this.props.modbusDetails.ModbusNewRow.map((m, index) => (
                    <li>
                      <button
                        onClick={(p) => this.handleToShowRegister(p, m, index)}
                        className={
                          formInput.deviceNumber === index ||
                          (formInput.deviceNumber === "0" && index === 0)
                            ? "deviceList activeSetting"
                            : "deviceList"
                        }
                      >
                        {m.slaveOrIpAddressID}
                      </button>
                    </li>
                  ))}
                </ul>
                <div style={{ width: "95%" }}>
                  <table></table>{" "}
                  <div className="registerView">
                    <table>
                      <tr>
                        <th>Label</th>
                        <th>Unit</th>
                        <th>Reg start</th>
                        <th>
                          {" "}
                          <span>Scale</span>
                          <div className="tooltipSetting">
                            <FontAwesomeIcon
                              className="fa-1x questionIcon"
                              icon={faCircleQuestion}
                            />
                            <span className="tooltipsSettingText tooltipsTemplate">
                              <div>
                                The reg start value is divided by this value
                                before sending to the cloud. A value of reg star
                                2145 with a scale of 100 will be sent as a float
                                of 21.45
                              </div>
                            </span>
                          </div>
                        </th>
                        <th>Reg type</th>
                        <th>Data type</th>
                        <th>Access mode</th>
                        <th></th>
                      </tr>

                      {this.props.modbusDetails.ModbusNewRow[
                        formInput.deviceNumber
                      ]?.registers.map((p, index) => (
                        <tr key={index}>
                          {" "}
                          <td>
                            <input
                              className="form-control-search formInputModbusSearch registerInput"
                              value={p.label}
                              name="name"
                              type="text"
                              onChange={(e) =>
                                this.handlerRegisterChange(e, p, index)
                              }
                              maxLength={200}
                              placeholder={""}
                            />
                          </td>{" "}
                          <td>
                            <input
                              className="form-control-search formInputModbusSearch registerInput"
                              value={p.unit}
                              name="name"
                              type="text"
                              onChange={(e) =>
                                this.handlerUnitChange(e, p, index)
                              }
                              placeholder={""}
                              maxLength={15}
                            />
                          </td>
                          <td>
                            <input
                              className="form-control-search formInputModbusSearch registerInput"
                              value={p.reg_start}
                              name="name"
                              type="text"
                              onChange={(e) =>
                                this.handlerRegStartChange(e, p, index)
                              }
                              maxlength={11}
                              placeholder={""}
                            />
                          </td>{" "}
                          <td>
                            <input
                              className="form-control-search formInputModbusSearch registerInput"
                              value={p.scale}
                              name="name"
                              type="text"
                              onChange={(e) =>
                                this.handlerScalingChange(e, p, index)
                              }
                              minLength={0}
                              maxlength={11}
                              placeholder={""}
                            />
                          </td>
                          <td>
                            {" "}
                            <select
                              className="form-select dropdownView"
                              aria-label="Default select example"
                              value={p.reg_type}
                              label="status"
                              onChange={(e) =>
                                this.handleRegtypeChange(e, index)
                              }
                            >
                              <option value={0}>Holding</option>
                              <option value={1}>Input</option>
                            </select>
                          </td>
                          <td>
                            <select
                              className="form-select dropdownView"
                              aria-label="Default select example"
                              value={p.data_type}
                              label="status"
                              onChange={(e) =>
                                this.handleDatatypeChange(e, index)
                              }
                            >
                              <option value={0}>
                                (unit8) Unsigned 8 bit Integer
                              </option>
                              <option value={1}>
                                (unit16) Unsigned 16 bit Integer
                              </option>
                              <option value={2}>
                                (unit32) Unsigned 32 bit Integer
                              </option>
                              <option value={9}>
                                (unit64) Unsigned 64 bit Integer
                              </option>
                              <option value={3}>(float) Float</option>
                              <option value={5}>
                                (int8) Signed 8 bit Integer
                              </option>
                              <option value={6}>
                                (int16) Signed 16 bit Integer
                              </option>
                              <option value={7}>
                                (int32) Signed 32 bit Integer
                              </option>
                              <option value={10}>
                                (int64) Signed 64 bit Integer
                              </option>
                            </select>
                          </td>
                          <td>
                            <select
                              className="form-select dropdownView"
                              aria-label="Default select example"
                              value={p.access_mode}
                              label="status"
                              onChange={(e) =>
                                this.handleAccessModeChange(e, index)
                              }
                            >
                              <option value={2}>(r)Readable</option>
                              <option value={4}>(w)Writeable</option>
                              <option value={16}>(x)Triggerable</option>
                              <option value={6}>
                                (rw)Readable and Writeable
                              </option>
                              <option value={18}>
                                (rx)Readable and Triggerable
                              </option>
                              <option value={20}>
                                (wx)Writeable and Triggerable
                              </option>
                              <option value={22}>
                                (rwx)Readable , Writeable and Triggerable
                              </option>
                            </select>
                          </td>{" "}
                          <td>
                            <button
                              className="btn-border-none"
                              onClick={() => this.handleToDeleteRow(p, index)}
                            >
                              <FontAwesomeIcon
                                className="svg-icons-sm"
                                icon={faTrashCan}
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="buttonTag" style={{ marginLeft: "200px" }}>
              {" "}
              <button
                className="btn-border-none"
                onClick={this.handleToAddNewRegister.bind(this)}
              >
                <FontAwesomeIcon
                  className="svg-icon-sm fa-lg plusRegister"
                  icon={faPlusCircle}
                />
              </button>
              <button
                className="btn-border-none"
                onClick={this.handleToCopyNewrow.bind(this)}
              >
                <FontAwesomeIcon
                  className="svg-icon-sm fa-lg plusRegister"
                  icon={faCopy}
                />
              </button>
              <div
                className={
                  formInput.registerErrorView
                    ? "showErrorRegisterMode"
                    : "hideMode"
                }
              >
                Atleast 1 device is required
              </div>
              <div>&nbsp;&nbsp; </div>
              <div
                className={
                  formInput.sameRegisterName
                    ? "showErrorRegisterMode"
                    : "hideMode"
                }
              >
                Label should be unique.
              </div>
              <div>&nbsp;&nbsp; </div>
              <div
                className={
                  formInput.sameRegStartName
                    ? "showErrorRegisterMode"
                    : "hideMode"
                }
              >
                Reg start should be unique.
              </div>
              <div>&nbsp;&nbsp; </div>
              <div
                className={
                  formInput.registerAndRegStartError
                    ? "showErrorRegisterMode"
                    : "hideMode"
                }
              >
                Register and Reg start are mandatory.
              </div>
              <div
                className={
                  formInput.registerError ? "showErrorRegisterMode" : "hideMode"
                }
              >
                Register is mandatory.
              </div>
              <div
                className={
                  formInput.regTypeError ? "showErrorRegisterMode" : "hideMode"
                }
              >
                Reg start is mandatory.
              </div>
              <div
                className={
                  formInput.registerLimitNumber
                    ? "showErrorRegisterMode"
                    : "hideMode"
                }
              >
                Reached the max number of registers.
              </div>
              <div
                className={
                  formInput.regStartError ? "showErrorRegisterMode" : "hideMode"
                }
              >
                Reg start is mandatory.
              </div>
              <div
                className={
                  formInput.scalingError ? "showErrorRegisterMode" : "hideMode"
                }
              >
                Scale value should be more than 0 and less than 1 million.
              </div>
            </div>
            <div className="belowModbusDeviceButton">
              <button
                onClick={() => this.handleToNextApply("true")}
                className="btn btn-primary buttonView modbusButton"
              >
                Apply
              </button>{" "}
              <button
                onClick={this.handleToPrevious}
                className="btn btn-secondary previousbutton"
              >
                Previous
              </button>{" "}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allSelectModbus: state.modbusViewReducer.allSelectModbus,
    allSelectModbusPreLoad: state.modbusViewReducer.allSelectModbusPreLoad,
    saveModbusDevices: state.modbusViewReducer.saveModbusDevices,
  };
}

export default connect(mapStateToProps, { getApplyModbus, getSaveModbus })(
  AddRegistersModbus
);
