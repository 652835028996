import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import { logoutUser } from "../../../actions/user";

import {
  openUserPopup,
  closeUserPopup,
} from "../../../actions/screen-activities";

import "../style.css";

class UserPopUp extends Component {
  state = {
    formInput: {
      buildName: "",
      buildVersion: "",
    },
  };
  componentDidMount = async () => {
    const formInput = { ...this.state.formInput };
    const abc = await fetch("/tags.json");
    const buildVersion = await abc.json();
    formInput.buildName = buildVersion.tags[0];
    formInput.buildVersion = buildVersion.tags[1];
    this.setState({ formInput });
  };

  render() {
    const formInput = { ...this.state.formInput };
    return (
      <Fragment>
        <div
          className={`${
            this.props.topUserPopup ? "d-flex" : "d-none"
          } justify-content-end`}
        >
          <div className="user-dialog">
            <div className="col">
              <div className="user-name">{this.props.userEmail}</div>
              <div className="buildName">
                Build:
                <div className="versionName">{formInput.buildName}</div> -
                <div className="versionName">{formInput.buildVersion}</div>
              </div>
              <button className="logout-btn" onClick={this.props.logoutUser}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                &nbsp; Log out
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userEmail: state.userReducer?.user?.email,
    topUserPopup: state.screenActivitiesReducer?.topUserPopup,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    openUserPopup,
    closeUserPopup,
    logoutUser,
  })(UserPopUp)
);
