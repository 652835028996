import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import globalFunctions from "../../helpers/functions";
import ActivityMonitorsSubmenu from "../../components/menus/sub-menu/subtypes/ActivityMonitorsSubmenu";
import DeviceCard from "../../components/device-cards/DeviceCard";
import { getListViewData, clearListViewData } from "../../actions/list-view";
import LoaderRoller from "../../components/loader/LoaderRoller";

const { uniqueKey } = globalFunctions;

class DeviceCardView extends Component {
  componentDidMount = () => {
    if (this.props.activityMonitorsView === "LIST_VIEW") {
      this.props.history.push("/activity-monitors/list-view");
    }
    this.props.getListViewData("activity-monitors");
  };

  componentWillUnmount = () => {
    this.props.clearListViewData();
  };

  render() {
    if (
      this.props.listViewData?.items &&
      this.props.listViewData.items.length > 0
    ) {
      const activityMonitor = this.props.listViewData.items.find(
        (item) => item.subtype === "activity-monitors"
      );
      return (
        <Fragment>
          <ActivityMonitorsSubmenu
            nrResults={this.props.listViewData?._meta?.totalCount}
          />
          <div className="bacecontainer-lg">
            <div className="row">
              {activityMonitor
                ? this.props.listViewData.items.map((data) => (
                    <Link
                      to={`/activity-monitors/${data.id_group}/measurement-data`}
                      className="col-md-auto mt-4"
                      key={uniqueKey()}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="bacecard-width ">
                        <DeviceCard
                          type={"activity-monitors"}
                          subpage={"measurement-data"}
                          deviceInfo={data.physicalDevices[0]}
                        />
                      </div>
                    </Link>
                  ))
                : //   )
                  ""}
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <ActivityMonitorsSubmenu nrResults={0} />
          <div className="bacecontainer-lg">
            <LoaderRoller />
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    listViewData: state.listViewReducer?.listViewData,
    activityMonitorsView:
      state.viewHistoryReducer.lastActivityMonitorsViewSelected,
  };
}

export default withRouter(
  connect(mapStateToProps, { getListViewData, clearListViewData })(
    DeviceCardView
  )
);
