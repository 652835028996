import axios from "axios";
import { showWarning } from "./notification";
import CONFIG from "../config";

const { baseUrl } = CONFIG;

const ALL_GROUP_DATA_RECEIVED = "ALL_GROUP_DATA_RECEIVED";
const ALL_SESSION_DATA_RECEIVED = "ALL_SESSION_DATA_RECEIVED";
const GROUP_DATA_PER_DATATYPE_RECEIVED = "GROUP_DATA_PER_DATATYPE_RECEIVED";
const SESSION_DATA_PER_DATATYPE_RECEIVED = "SESSION_DATA_PER_DATATYPE_RECEIVED";
const GROUP_DATATYPES_RECEIVED = "GROUP_DATATYPES_RECEIVED";
const CLEAR_GROUP_DATA = "CLEAR_GROUP_DATA";
const CLEAR_SESSION_DATA = "CLEAR_SESSION_DATA";
const CHART_DATA_RECEIVED = "CHART_DATA_RECEIVED";
const CLEAR_CHART_DATA_RECEIVED = "CLEAR_CHART_DATA_RECEIVED";
const MULTIPLE_CHART_DATA_RECEIVED = "MULTIPLE_CHART_DATA_RECEIVED";

const clearChartDataSuccess = (data) => ({
  type: CLEAR_CHART_DATA_RECEIVED,
  data,
});

const getGroupDataSuccess = (data) => ({
  type: ALL_GROUP_DATA_RECEIVED,
  data,
});

const getSessionDataSuccess = (data) => ({
  type: ALL_SESSION_DATA_RECEIVED,
  data,
});

const getDataPerDatatypeSuccess = (data) => ({
  type: GROUP_DATA_PER_DATATYPE_RECEIVED,
  data,
});

const getSessionDataPerDatatypeSuccess = (data) => ({
  type: SESSION_DATA_PER_DATATYPE_RECEIVED,
  data,
});

const getGroupDatatypesSuccess = (data) => ({
  type: GROUP_DATATYPES_RECEIVED,
  data,
});

const getChartDataSuccess = (data) => ({
  type: CHART_DATA_RECEIVED,
  data,
});

const getMultipleChartDataSuccess = (data) => ({
  type: MULTIPLE_CHART_DATA_RECEIVED,
  data,
});

const clearGroupDataSuccess = () => ({
  type: CLEAR_GROUP_DATA,
});

const clearSessionDataSuccess = () => ({
  type: CLEAR_SESSION_DATA,
});

export const getGroupData =
  (idGroup, datatype, timestampFrom, timestampTo) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios
      .get(
        `${baseUrl}/data-downsampled?per-page=1500&sort=timestamp_seconds${
          idGroup ? `&filter[id_group]=${idGroup}` : ""
        }${datatype ? `&filter[datatype]=${datatype}` : ""}${
          timestampFrom ? `&filter[timestamp_seconds][gt]=${timestampFrom}` : ""
        }${timestampTo ? `&filter[timestamp_seconds][lt]=${timestampTo}` : ""}`
      )
      .then((res) => {
        if (!datatype) {
          const datapointTimestampArr = res.data.items.map(
            (datapoint) => +datapoint.timestamp_seconds
          );
          const firstTimestamp = Math.min(...datapointTimestampArr);
          const lastTimestamp = Math.max(...datapointTimestampArr);

          const response = {
            ...res.data,
            firstTimestamp: firstTimestamp ? firstTimestamp * 1000 : 0,
            lastTimestamp: lastTimestamp ? lastTimestamp * 1000 : Date.now(),
          };
          dispatch(getGroupDataSuccess(response));
        } else {
          if (res.data.items.length < 1) {
            const { dataPerDatatype } = getState().chartViewReducer;
            const oldData = dataPerDatatype.find(
              (data) => data.datatype === datatype
            );
            if (!oldData) {
              dispatch(
                getDataPerDatatypeSuccess({
                  datatype,
                  data: res.data,
                  moreDataAvailable: false,
                })
              );
            }

            dispatch(getDataPerDatatypeSuccess({ ...oldData }));
            return;
          }
          const moreDataAvailable = res.data.items.find(
            (datapoint) => datapoint.count_val !== 1
          )
            ? true
            : false;
          dispatch(
            getDataPerDatatypeSuccess({
              datatype,
              data: res.data,
              moreDataAvailable,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const getSessionData =
  (idSession, datatype, timestampFrom, timestampTo) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios
      .get(
        `${baseUrl}/data-downsampled?per-page=100&sort=timestamp_seconds${
          idSession ? `&filter[id_data_session]=${idSession}` : ""
        }${datatype ? `&filter[datatype]=${datatype}` : ""}${
          timestampFrom ? `&filter[timestamp_seconds][gt]=${timestampFrom}` : ""
        }${timestampTo ? `&filter[timestamp_seconds][lt]=${timestampTo}` : ""}`
      )
      .then((res) => {
        if (!datatype) {
          const datapointTimestampArr = res.data.items.map(
            (datapoint) => +datapoint.timestamp_seconds
          );
          const firstTimestamp = Math.min(...datapointTimestampArr);
          const lastTimestamp = Math.max(...datapointTimestampArr);

          const response = {
            ...res.data,
            firstTimestamp: firstTimestamp ? firstTimestamp * 1000 : 0,
            lastTimestamp: lastTimestamp ? lastTimestamp * 1000 : Date.now(),
          };
          dispatch(getSessionDataSuccess(response));
        } else {
          if (res.data.items.length < 1) {
            const { dataPerDatatype } = getState().chartViewReducer;
            const oldData = dataPerDatatype.find(
              (data) => data.datatype === datatype
            );
            if (!oldData) {
              dispatch(
                getSessionDataPerDatatypeSuccess({
                  datatype,
                  data: res.data,
                  moreDataAvailable: false,
                })
              );
            }

            dispatch(getSessionDataPerDatatypeSuccess({ ...oldData }));
            return;
          }
          const moreDataAvailable = res.data.items.find(
            (datapoint) => datapoint.count_val !== 1
          )
            ? true
            : false;
          dispatch(
            getSessionDataPerDatatypeSuccess({
              datatype,
              data: res.data,
              moreDataAvailable,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const getGroupDatatypes = (idGroup) => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

  axios
    .get(
      `${baseUrl}/group/${idGroup}?expand=dataTypes,latestData,latestSession`
    )
    .then((res) => {
      const latestData = res.data.latestData;
      const latestSession = res.data.latestSession;
      const dataTypes = res.data.dataTypes.map((datatype) => {
        const relation = latestData.find(
          (data) => data.datatype === datatype.datatype
        );
        return {
          ...datatype,
          ...relation.relations,
        };
      });
      dispatch(
        getGroupDatatypesSuccess({
          ...res.data,
          latestSession,
          dataTypes,
        })
      );
    })
    .catch((err) => {
      dispatch(
        showWarning({ body: err?.response?.data?.message || err.message })
      );
    });
};

export const getMultipleChartDataSampled =
  (idGroup, viewData, filterParams) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios
      .get(
        `${baseUrl}/data-downsampled?filter[source_device]=${idGroup}&${filterParams}&sort=timestamp_seconds`
      )
      .then((res) => {
        dispatch(
          getMultipleChartDataSuccess({
            ...res.data,
            //  latestSession,
            //  dataTypes,
          })
        );
      })
      .catch((err) => {
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const getChartDataSampled =
  (idGroup, viewData, filterParams) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .get(
        `${baseUrl}/data-downsampled?filter[source_device]=${idGroup}&${filterParams}&sort=timestamp_seconds`
      )
      .then((res) => {
        // const latestData = res.data.latestData;
        // const latestSession = res.data.latestSession;
        // const dataTypes = res.data.dataTypes.map((datatype) => {
        //   const relation = latestData.find(
        //     (data) => data.datatype === datatype.datatype
        //   );
        //   return {
        //     ...datatype,
        //     ...relation.relations,
        //   };
        // });
        dispatch(
          getChartDataSuccess({
            ...res.data,
            //  latestSession,
            //  dataTypes,
          })
        );
      })
      .catch((err) => {
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const clearSessionData = () => (dispatch) => {
  dispatch(clearSessionDataSuccess());
};

export const clearChartData = () => (dispatch) => {
  dispatch(clearChartDataSuccess());
};

export const clearGroupData = () => (dispatch) => {
  dispatch(clearGroupDataSuccess());
};
