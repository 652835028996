import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import OneBleGatewaySubmenu from "../../components/menus/sub-menu/subtypes/OneBleGatewaySubmenu";
import DeviceInfoCard from "../../components/device-cards/DeviceInfoCard";

import { getDeviceData, clearDeviceData } from "../../actions/list-view";
import LoaderRoller from "../../components/loader/LoaderRoller";

class DeviceInfoView extends Component {
  componentDidMount = () => {
    const { id } = this.props.match.params;
    if (!id) {
      return this.props.history.push("/");
    }
    this.props.getDeviceData(id);
  };

  componentWillUnmount = () => {
    if (!window.location.pathname.startsWith("/ble-gateways")) {
      this.props.clearDeviceData();
    }
  };

  getData = () => {};

  render() {
    if (this.props.deviceViewData) {
      return (
        <Fragment>
          <OneBleGatewaySubmenu />
          <div className="bacecontainer">
            <div className="row mt-3">
              {/* <div className="col-lg-auto"> */}
              {/* <div className="col-12 col-xl-6"> */}
              <DeviceInfoCard />
              {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <OneBleGatewaySubmenu />
          <div className="bacecontainer">
            <div className="row mt-3">
              <LoaderRoller />
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    deviceViewData: state.listViewReducer.deviceViewData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getDeviceData,
    clearDeviceData,
  })(DeviceInfoView)
);
