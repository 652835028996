import CONFIG from "../config";

const { defaultWebhookEpView } = CONFIG.defaultViews;
const initialState = {
  sessionListViewData: null,
  groupSessionListViewData: null,
  sessionDeviceViewData: null,
  lastWebhookEpViewSelected: defaultWebhookEpView,
};

export default function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case "ALL_SESSIONS_RECEIVED": {
      return {
        ...state,
        allSessions: action.data,
        //subtypes: action.data.items.map((group) => group.subtype),
      };
    }
    case "SESSION_LIST_VIEW_DATA_RECEIVED": {
      return {
        ...state,
        sessionListViewData: action.data,
      };
    }
    case "CLEAR_SESSION_LIST_VIEW_DATA": {
      return {
        ...state,
        sessionListViewData: null,
      };
    }
    case "GROUP_SESSION_LIST_VIEW_DATA_RECEIVED": {
      return {
        ...state,
        groupSessionListViewData: action.data,
      };
    }
    case "CLEAR_GROUP_SESSION_LIST_VIEW_DATA": {
      return {
        ...state,
        groupSessionListViewData: null,
      };
    }
    case "SESSION_DEVICE_DATA_RECEIVED": {
      return {
        ...state,
        sessionDeviceViewData: action.data,
      };
    }
    case "CLEAR_SESSION_DEVICE_DATA": {
      return {
        ...state,
        ...initialState,
      };
    }

    case "ALL_WEBHOOK_OVERVIEW_DATA_RECEIVED": {
      return {
        ...state,
        webhooksOverviewData: action.data,
      };
    }

    case "CREATED_WEBHOOK_STATUS": {
      return {
        ...state,
        createdwebhookStatus: action.data,
      };
    }
    case "WEBHOOK_INFO_DATA_RECEIVED": {
      return {
        ...state,
        webhookInfoData: action.data,
      };
    }

    case "WEBHOOK_ATTEMPTS_DATA_RECEIVED": {
      return {
        ...state,
        webhookAttemptsData: action.data,
      };
    }

    case "CREATED_FAILED_WEBHOOK_STATUS": {
      return {
        ...state,
        createdFailedWebhookStatus: action.data,
      };
    }

    case "UPDATED_FAILED_WEBHOOK_STATUS": {
      return {
        ...state,
        updatedFailedWebhookStatus: action.data,
      };
    }

    case "UPDATE_WEBHOOK_STATUS": {
      return {
        ...state,
        updatedwebhookStatus: action.data,
      };
    }

    case "WEBHOOK_EP_VIEW_CHANGED": {
      return {
        ...state,
        lastWebhookEpViewSelected: action.viewOption,
      };
    }

    case "CLEAR_EDIT_WEBHOOK_STATUS": {
      return {
        ...state,
        updatedwebhookStatus: null,
        webhookInfoData: null,
        webhookAttemptsData: null,
        lastWebhookEpViewSelected: null,
      };
    }

    case "ENABLE_DISABLE_WEBHOOK_RECEIVED": {
      return {
        ...state,
        enableDisableUpdated: action.data,
      };
    }

    case "DELETE_WEBHOOK_RECEIVED": {
      return {
        ...state,
        deletedWebhook: action.data,
      };
    }

    case "CLEAR_DELETE_WEBHOOK_RECEIVED": {
      return {
        ...state,
        deletedWebhook: null,
      };
    }

    case "CLEAR_ENABLE_DISABLE_WEBHOOK_STATUS": {
      return {
        ...state,
        enableDisableUpdated: null,
      };
    }

    case "CLEAR_WEBHOOK_STATUS": {
      return {
        ...state,
        createdwebhookStatus: null,
      };
    }

    default: {
      return state;
    }
  }
}
