import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import OneModbusGatewaySubmenu from "../../components/menus/sub-menu/subtypes/OneModbusGatewaySubmenu";
import DeviceInfoCard from "../../components/device-cards/DeviceInfoCard";

// import { getDeviceData, clearDeviceData } from "../../actions/list-view";
import LoaderRoller from "../../components/loader/LoaderRoller";
import {
  getModbusDeviceInfo,
  clearModbusDeviceData,
  getAllModbusDevices,
} from "../../actions/modbus-view";
import ModbusDeviceCardInfo from "../../components/modbus/cardInfo/ModbusDeviceCardInfo";
class DeviceInfoView extends Component {
  componentDidMount = () => {
    const { id } = this.props.match.params;
    if (!id) {
      return this.props.history.push("/");
    }
    const physicalDeviceId = this.props.match.params;
    this.props.getModbusDeviceInfo(physicalDeviceId);
    this.props.clearModbusDeviceData();
    const { currentPageNr } = this.props.match.params;
    const projectId = this.props.match.params.id;
    this.props.getAllModbusDevices(this.props.match.params.projectId);
    // this.props.getDeviceData(id);
  };

  componentWillUnmount = () => {
    this.props.clearModbusDeviceData();
  };

  render() {
    if (this.props.allModbusDeviceInfo) {
      return (
        <Fragment>
          <OneModbusGatewaySubmenu
            allModbusDeviceInfo={this.props.allModbusDeviceInfo}
          />
          <div className="bacecontainer">
            {/* <DeviceInfoCard /> */}
            <ModbusDeviceCardInfo
              allModbusDeviceInfo={this.props.allModbusDeviceInfo}
              allModbusDevices={this.props.allModbusDevices}
            />
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <OneModbusGatewaySubmenu />
          <div className="bacecontainer">
            <div className="row mt-3">
              <LoaderRoller />
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    // deviceViewData: state.listViewReducer.deviceViewData,
    allModbusDeviceInfo: state.modbusViewReducer.allModbusDeviceInfo,
    allModbusDevices: state.modbusViewReducer.allModbusDevices,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    // getDeviceData,
    // clearDeviceData,
    getModbusDeviceInfo,
    getAllModbusDevices,
    clearModbusDeviceData,
  })(DeviceInfoView)
);
