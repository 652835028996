const initialState = {
  allSelectGateway: [],
};

export default function modbusViewReducer(state = initialState, action) {
  switch (action.type) {
    case "ALL_SELTECT_GATEWAY_RECEIVED": {
      return {
        ...state,
        allSelectGateway: action.data,
      };
    }

    case "ALL_MODBUS_DEVICES_RECEIVED": {
      return {
        ...state,
        allModbusDevices: action.data,
      };
    }

    case "ALL_MODBUS_DEVICE_FOR_GATEWAY": {
      return {
        ...state,
        allModbusDevicesForGateway: action.data,
      };
    }

    case "ALL_SAVE_MODBUS_RECEIVED": {
      return {
        ...state,
        saveModbusDevices: action.data,
      };
    }

    case "ALL_LOAD_MODBUS_RECEIVED": {
      return {
        ...state,
        getLoadTemplateData: action.data,
      };
    }

    case "ALL_SELTECT_MODBUS_RECEIVED": {
      return {
        ...state,
        allSelectModbus: action.data,
        allSelectModbusPreLoad: action.dataPreload,
      };
    }
    case "ALL_MODBUS_DEVICES_INFO_RECEIVED": {
      return {
        ...state,
        allModbusDeviceInfo: action.data,
      };
    }
    case "CLEAR_SELECT_GATEWAY_DATA": {
      return {
        ...state,
        clearSelectGatewayModbus: action.data,
      };
    }

    case "CLEAR_FAIL_SELECT_GATEWAY_DATA": {
      return {
        ...state,
        failSelectGatewayModbus: action.data,
      };
    }

    case "APPLY_MODBUS_DATA": {
      return {
        ...state,
        applyModbusData: action.data,
      };
    }

    case "CLEAR_MODBUS_APPLY_DEVICE_DATA": {
      return {
        ...state,
        allSelectModbus: null,
        allSelectModbusPreLoad: null,
        allModbusDevices: null,
        failSelectGatewayModbus: null,
        clearSelectGatewayModbus: null,
      };
    }

    case "CLEAR_MODBUS_DEVICE_DATA": {
      return {
        ...state,
        allModbusDeviceInfo: null,
      };
    }

    case "CLEAR_GATEWAY_DATA": {
      return {
        ...state,
        allSelectGateway: null,
      };
    }

    default: {
      return state;
    }
  }
}
