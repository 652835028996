import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import line from "./../../assets/Icons/Line.png";
import LoaderRoller from "../../components/loader/LoaderRoller";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNetworkWired,
  faChartLine,
  faPlusCircle,
  faSliders,
  faHardDrive,
  faRectangleHistory,
  faTimes,
  faCircleCheck,
  faTimesCircle,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import SettingsMovingSumenu from "../../components/menus/sub-menu/subtypes/SettingsMovingSumenu";
import {
  getAllProjectWithoutCurrentProject,
  getAllGatewaysForCurrentProject,
  confirmMovingGatewatToProject,
  clearGatewayMovedToProject,
  getAllProject,
  clearCurrentGatewayTargetProject,
} from "../../actions/list-view";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

class SettingsMoveGateways extends Component {
  state = {
    formInput: {
      projectId: "",
      currentProjectId: "",
      currentGroupId: "",
      targetProjectId: "",
      targetGroupId: "",
      movingGateway: false,
    },
  };

  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;
    const projectId = this.props.match.params?.projectId;

    if (!currentPageNr) {
      return this.props.history.push(`/settings/moving/1/${projectId}`);
    }
    //
    this.props.clearCurrentGatewayTargetProject();
    this.props.getAllProjectWithoutCurrentProject(projectId);
    this.props.getAllGatewaysForCurrentProject(projectId);
    this.props.clearGatewayMovedToProject();
  };

  componentWillUnmount = () => {};

  handleSelectedGateway = (groupId, projectId) => {
    const formInput = { ...this.state.formInput };
    formInput.currentGroupId = groupId;
    formInput.currentProjectId = projectId;
    this.setState({ formInput });
  };
  handleSelectedProject = (groupId, projectId) => {
    const formInput = { ...this.state.formInput };
    formInput.targetProjectId = groupId;
    formInput.targetGroupId = projectId;
    this.setState({ formInput });
  };
  handleToCancel = () => {
    const { currentPageNr } = this.props.match.params;
    const projectId = this.props.match.params?.projectId;
    return this.props.history.push(`/settings/info-view/1/${projectId}`);
  };

  handleToConfirmMoving = () => {
    const formInput = { ...this.state.formInput };
    formInput.movingGateway = true;
    this.setState({ formInput });
    this.props.clearGatewayMovedToProject();
    this.props.confirmMovingGatewatToProject(
      formInput.currentGroupId,
      formInput.currentProjectId,
      formInput.targetGroupId,
      formInput.targetProjectId
    );
  };

  handleToConnectionClose = () => {
    if (this.props.movedGatewayToProject?.status === 200) {
      const projectId = this.props.match.params?.projectId;
      this.props.getAllGatewaysForCurrentProject(projectId);
      this.props.getAllProject();
    }
    const formInput = { ...this.state.formInput };
    formInput.movingGateway = false;
    this.setState({ formInput });
  };

  render() {
    const formInput = this.state.formInput;
    formInput.projectId = this.props.match.params?.projectId;

    return (
      <div>
        {formInput.movingGateway && (
          <Dialog
            open={formInput.movingGateway === true ? true : false}
            onClose={this.handleToConnectionClose}
          >
            {(this.props.movedGatewayToProject?.status === 200 ||
              this.props.failMovingGatewayToProject === "failed") && (
              <div onClick={this.handleToConnectionClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToConnectionClose}
                />
              </div>
            )}
            <DialogTitle>
              {this.props.movedGatewayToProject?.status === 200 ? (
                <div>
                  {" "}
                  <FontAwesomeIcon
                    className="settingsHeaderIcon"
                    icon={faCircleCheck}
                  />
                  <h4
                    style={{ textAlign: "center" }}
                    className="settingsHeaderTextIcon"
                  >
                    Gateway has moved.
                  </h4>
                  <p style={{ textAlign: "center" }}>
                    Gateway has successfully moved to the target project.
                  </p>
                </div>
              ) : this.props.failMovingGatewayToProject === "failed" ? (
                <div>
                  {" "}
                  <FontAwesomeIcon
                    className="mb-4 fa-3x errorWifi"
                    icon={faTimesCircle}
                  />
                  <h4
                    style={{ textAlign: "center" }}
                    className="settingsHeaderTextIcon"
                  >
                    Gateway move unsuccessful.
                  </h4>
                  <p style={{ textAlign: "center" }}>
                    Gateway move unsuccessfully,please try again.
                  </p>
                </div>
              ) : (
                <div>
                  <LoaderRoller />
                  <p style={{ textAlign: "center" }}>Moving</p>
                </div>
              )}
            </DialogTitle>
          </Dialog>
        )}

        <div>
          <Fragment>
            <SettingsMovingSumenu
              projectId={this.props.match.params?.projectId}
            />

            <div class="row" style={{ marginTop: "60px" }}>
              {" "}
              {/* <div class="col-sm" style={{ maxWidth: "12%"}}></div> */}
              <div className="col-md-3"></div>
              <div className="col-md-1" style={{ paddingLeft: "0px" }}>
                {" "}
                <FontAwesomeIcon
                  className="infoProjectReferIcon"
                  icon={faHardDrive}
                />
              </div>
              <div className="col-md-3" style={{ textAlign: "center" }}>
                {" "}
                <div style={{ marginTop: "15px" }}>
                  <img src={line} height="18px" width="350px"></img>
                </div>
              </div>
              <div
                className="col-md-1"
                style={{
                  height: "25px",
                  textAlign: "center",
                  paddingLeft: "7%",
                }}
              >
                <FontAwesomeIcon
                  className="infoProjectReferIcon"
                  icon={faRectangleHistory}
                />
              </div>
              <div className="col-md-4"></div>
            </div>

            <div className="viewIconProject"> </div>
            <div>
              <div class="row">
                {" "}
                <div class="col-sm" style={{ maxWidth: "3%" }}></div>
                <div class="col-sm">
                  <div className="card-wrap listMain">
                    <div className={"moving-device modbusGrid"}>
                      <div>
                        <p className="moving-header">
                          Gateways in Current Project
                        </p>
                        {this.props.allGatewayDataWithCurrentProject?.items
                          .length > 0 ? (
                          this.props.allGatewayDataWithCurrentProject?.items.map(
                            (j, index) => (
                              <div style={{ textAlign: "left" }}>
                                <div style={{ display: "flex" }}>
                                  <input
                                    type="radio"
                                    id={j.label}
                                    name="headerProjectDevice"
                                    defaultValue="0"
                                    className="radioOption"
                                    onChange={(e) =>
                                      this.handleSelectedGateway(
                                        j.id_group,
                                        j.id_project
                                      )
                                    }
                                    //  defaultChecked={index === 0 ?? true}
                                    style={{
                                      marginLeft: "15px",
                                      accentColor: "#000000",
                                    }}
                                  />
                                  <p className="projectLabel"> {j.label}</p>
                                  <FontAwesomeIcon
                                    className="infoProjectIcon"
                                    icon={faInfoCircle}
                                  />
                                </div>
                              </div>
                            )
                          )
                        ) : this.props.allGatewayDataWithCurrentProject?.items
                            .length === 0 ? (
                          <div style={{ textAlign: "center" }}>
                            Sorry, No Gateways are found for this Project !
                          </div>
                        ) : (
                          <LoaderRoller />
                        )}
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div class="col-sm" style={{ maxWidth: "0%" }}></div>{" "}
                <div class="col-sm">
                  <div className="card-wrap-div listMain">
                    <div className={"moving-device modbusGrid"}>
                      <div>
                        <p className="moving-header">Target Project</p>
                        {this.props.allProjectDataWithoutCurrentProject?.items
                          .length > 0 ? (
                          this.props.allProjectDataWithoutCurrentProject?.items.map(
                            (j, index) => (
                              <div>
                                <div style={{ display: "flex" }}>
                                  <input
                                    type="radio"
                                    id={j.id_group}
                                    name="headerNameDevice"
                                    defaultValue="0"
                                    className="radioOption"
                                    onChange={(e) =>
                                      this.handleSelectedProject(
                                        j.id_group,
                                        j.id_project
                                      )
                                    }
                                    // defaultChecked={index === 0 ?? true}
                                    style={{
                                      marginLeft: "15px",
                                      accentColor: "#000000",
                                    }}
                                  />
                                  <p className="projectLabel"> {j.label}</p>
                                </div>
                              </div>
                            )
                          )
                        ) : this.props.allProjectDataWithoutCurrentProject
                            ?.items.length === 0 ? (
                          <div style={{ textAlign: "center" }}>
                            Sorry,No Project is found !
                          </div>
                        ) : (
                          <LoaderRoller />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>{" "}
          <div className="col pb-3"></div>
          <div className="col pb-3" style={{ width: "90%" }}>
            <div className="row" style={{ marginTop: "20px" }}>
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <button
                  className={
                    formInput.setApplyConfirm
                      ? "btn-cancel mx-1 cancelTemplate"
                      : "btn-cancel mx-1"
                  }
                  onClick={this.handleToCancel}
                  disabled={formInput.setApplyConfirm ? true : false}
                >
                  Cancel
                </button>{" "}
              </div>
              <div className="col-md-5"></div>
              <div className="col-md-3">
                <button
                  style={{ marginLeft: "20%" }}
                  className={
                    formInput.setApplyConfirm
                      ? "btn-cancel submitDisableButton"
                      : "btn-cancel submitButton"
                  }
                  onClick={() => this.handleToConfirmMoving()}
                  disabled={formInput.setApplyConfirm ? true : false}
                >
                  <Fragment>Move Gateway</Fragment>
                  {/* )} */}
                </button>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allProjectDataWithoutCurrentProject:
      state.listViewReducer?.allProjectDataWithoutCurrentProject,
    allGatewayDataWithCurrentProject:
      state.listViewReducer?.allGatewayDataWithCurrentProject,
    movedGatewayToProject: state.listViewReducer?.movedGatewayToProject,
    failMovingGatewayToProject:
      state.listViewReducer?.failMovingGatewayToProject,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAllProjectWithoutCurrentProject,
    getAllGatewaysForCurrentProject,
    confirmMovingGatewatToProject,
    clearGatewayMovedToProject,
    getAllProject,
    clearCurrentGatewayTargetProject,
  })(SettingsMoveGateways)
);
