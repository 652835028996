import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModbusIconBlue from "./../../assets/Icons/modbussetting.png";
import {
  faHardDrive,
  faCircleInfo,
  faTrashCan,
  faServer,
  faPlusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import AddRegistersModbus from "./AddRegistersModbus";
import SetGatewayModbus from "./SetGatewayModbus";
import { logDOM } from "@testing-library/react";
import "./style.css";
import { getAllModbusDeviceForGateway } from "../../actions/modbus-view";
import SelectModbusDevices from "./SelectModbusDevices";
import LoaderRoller from "../loader/LoaderRoller";
import modbusPngimage from "../../assets/Icons/modbus.png";
import { getApplyModbus, getSaveModbus } from "../../actions/modbus-view";
import Result from "./Result";
import SelectModbusDeviceType from "./SelectModbusDeviceType";

class SelectModbusDeviceTypeAddRegister extends Component {
  state = {
    formInput: {
      deviceNumber: "0",
      registers: [],
      checkInput: "",
      selectedDeviceId: "",
      projectId: "",
      isCheckAll: [],
      isCheck: [],
      modbusDetailsView: [],
      previousSet: false,
      modbusDeviceTypeArray: [],
      previousModbusTab: "",
      previousTab: "",
      previousSetTab: "",
      previousRegisterTab: "",
      nextSetTab: "",
      nextRegisterDevicesTab: "",
      nextModbusDevicesTab: "",
      nextResult: "",
      nextRegisterValid: false,
    },
  };

  componentDidMount = () => {
    const formInput = { ...this.state.formInput };
    formInput.previousTab = this.props.previousTab;
    formInput.previousSetTab = this.props.previousSetTab;
    formInput.previousModbusTab = this.props.previousModbusTab;
    formInput.previousRegisterTab = this.props.previousRegisterTab;
    formInput.nextSetTab = this.props.nextSetTab;
    formInput.nextRegisterDevicesTab = this.props.nextRegisterDevicesTab;
    formInput.nextModbusDevicesTab = this.props.nextModbusDevicesTab;
    formInput.nextResult = this.props.nextResult;
    formInput.nextRegisterValid = this.props.nextRegisterValid;
    formInput.modbusDeviceTypeArray = this.props.reisterDetailsWithModbusDevice;
    this.setState({ formInput });
  };
  handleToShowRegister = (event, lo, index) => {
    const formInput = { ...this.state.formInput };

    formInput.regisiterList = lo.registers;
    formInput.deviceNumber = index;
    //  formInput.registerErrorView = false;
    //  formInput.registerLimitNumber = false;
    this.setState({ formInput });
  };

  handleToNextApply = () => {
    const formInput = { ...this.state.formInput };
    const modbusDetailsData = this.props.formInputModbusDevice;
    const modeData =
      modbusDetailsData.modeName === "modbus-rtu"
        ? modbusDetailsData.modeValue
        : 0;
    const ModbusData = {
      label: modbusDetailsData.modbusTemplateName,
      mode: modbusDetailsData.modeName === "modbus-rtu" ? "0" : "1",
      interval: modbusDetailsData.intervalValue,
      id_physical_device: modbusDetailsData.physicalDeviceIdGateway,
      as_sub_devices: 1,
      id_group: modbusDetailsData.groupId,
    };
    formInput.projectId = this.props.projectId;
    const modbusRegistersData = formInput.registers;
    const modeName = modbusDetailsData.modeName;

    const ipsData = this.props.reisterDetailsWithModbusDevice.map(
      (m) => m.IdNumber
    );
    formInput.modbusDetailsView = {
      arrayipAddress: modeName === "modbus-tcp" ? ipsData : [],
      modeName: modeName,
    };
    this.setState({ formInput });

    this.props.getApplyModbus(
      ModbusData,
      modbusRegistersData,
      modeData,
      ipsData,
      modeName,
      formInput.projectId
    );
  };

  handleAllConfirmRegister = (o, index) => {
    const formInput = { ...this.state.formInput };
    formInput.isCheckAll[formInput.deviceNumber] =
      formInput.isCheckAll[formInput.deviceNumber] === true ? false : true;

    formInput.isCheck = this.props.reisterDetailsWithModbusDevice[
      formInput.deviceNumber
    ].modbusTemplateRegister.map((m) => m.id);

    if (formInput.isCheckAll[formInput.deviceNumber]) {
      formInput.isCheck = formInput.isCheck;
    } else {
      formInput.isCheck = [];
    }
    this.setState({ formInput });
    const registersDetails =
      this.props.reisterDetailsWithModbusDevice[formInput.deviceNumber]
        .modbusTemplateRegister[index];
    this.props.reisterDetailsWithModbusDevice[
      formInput.deviceNumber
    ].modbusTemplateRegister.map((m) => {
      formInput.registers.push({
        access_mode: m.access_mode,
        label: m.label,
        data_type: m.data_type,
        addr:
          this.props.modeName === "modbus-tcp"
            ? formInput.deviceNumber + 1
            : this.props.reisterDetailsWithModbusDevice[formInput.deviceNumber]
                .IdNumber,
        byte_order: m.byte_order,
        is_single_bit_binary: m.is_single_bit_binary,
        reg_Id: m.reg_Id,
        reg_start: m.reg_start,
        reg_type: m.reg_type,
        scale: m.scale,
        value_id: m.value_id,
        unit: m.unit,
        word_order: m.word_order,
      });
    });
  };

  handleConfirmRegister = (o, p, index) => {
    const formInput = { ...this.state.formInput };
    const { id, checked } = o.target;
    formInput.isCheck = [...formInput.isCheck, id];
    if (!checked) {
      formInput.isCheck = formInput.isCheck.filter((item) => item !== id);
    }

    formInput.checkInput = index;
    formInput.selectedDeviceId =
      this.props.reisterDetailsWithModbusDevice[
        formInput.deviceNumber
      ].IdNumber;
    const registersDetails =
      this.props.reisterDetailsWithModbusDevice[formInput.deviceNumber]
        .modbusTemplateRegister[index];
    if (o.target.checked === true) {
      formInput.registers.push({
        access_mode: registersDetails.access_mode,
        label: registersDetails.label,
        data_type: registersDetails.data_type,
        addr:
          this.props.modeName === "modbus-tcp"
            ? formInput.deviceNumber + 1
            : this.props.reisterDetailsWithModbusDevice[formInput.deviceNumber]
                .IdNumber,
        byte_order: registersDetails.byte_order,
        is_single_bit_binary: registersDetails.is_single_bit_binary,
        reg_Id: registersDetails.reg_Id,
        reg_start: registersDetails.reg_start,
        reg_type: registersDetails.reg_type,
        scale: registersDetails.scale,
        value_id: registersDetails.value_id,
        unit: registersDetails.unit,
        word_order: registersDetails.word_order,
      });
    } else if (o.target.checked === false) {
      formInput.registers.splice(index, 1);
    }

    this.setState({ formInput });
  };

  handleToPrevious = (event) => {
    //  this.props.previousTab();
    const formInput = { ...this.state.formInput };
    formInput.previousSet = true;
    this.setState({ formInput });
    this.props.previousModbusTab();
  };

  render() {
    const formInput = this.state.formInput;
    formInput.modbusDeviceTypeArray = this.props.reisterDetailsWithModbusDevice;
    formInput.previousTab = this.props.previousTab;
    formInput.previousSetTab = this.props.previousSetTab;
    formInput.previousModbusTab = this.props.previousModbusTab;
    formInput.previousRegisterTab = this.props.previousRegisterTab;
    formInput.nextSetTab = this.props.nextSetTab;
    formInput.nextRegisterDevicesTab = this.props.nextRegisterDevicesTab;
    formInput.nextModbusDevicesTab = this.props.nextModbusDevicesTab;
    formInput.nextResult = this.props.nextResult;
    formInput.nextRegisterValid = this.props.nextRegisterValid;
    return (
      <div>
        {this.props.allSelectModbus && this.props.allSelectModbusPreLoad ? (
          <Result
            modbusResultInfo={this.props.allSelectModbus}
            redisterData={this.props.allSelectModbusPreLoad}
            modbusDetails={formInput.modbusDetailsView}
            //   nextResult={this.props.nextResult}
            previousTab={formInput.previousTab}
            previousSetTab={formInput.previousSetTab}
            previousModbusTab={formInput.previousModbusTab}
            previousRegisterTab={formInput.previousRegisterTab}
            nextSetTab={formInput.nextSetTab}
            nextRegisterDevicesTab={formInput.nextRegisterDevicesTab}
            nextModbusDevicesTab={formInput.nextModbusDevicesTab}
            nextResult={formInput.nextResult}
            id_iot_device_Value={this.props.id_iot_device_Value}
            modbusTemplateName={this.props.modbusTemplateName}
            modeValue={this.props.modeValue}
            intervalValue={this.props.intervalValue}
            modNameList={this.props.modNameList}
            modNameValue={this.props.modNameValue}
            groupId={this.props.groupId}
            defaultValueGateway={this.props.defaultValueGateway}
            formId={this.props.formId}
            physicalDeviceIdGateway={this.props.physicalDeviceIdGateway}
          />
        ) : formInput.previousSet === true ? (
          <SelectModbusDeviceType
            modbusDeviceTypeArray={formInput.modbusDeviceTypeArray}
            previousTab={formInput.previousTab}
            previousSetTab={formInput.previousSetTab}
            previousModbusTab={formInput.previousModbusTab}
            previousRegisterTab={formInput.previousRegisterTab}
            previousLoadTemplate={formInput.previousLoadTemplate}
            nextSetTab={formInput.nextSetTab}
            nextRegisterDevicesTab={formInput.nextRegisterDevicesTab}
            nextModbusDevicesTab={formInput.nextModbusDevicesTab}
            nextResult={formInput.nextResult}
            modbusTemplateName={this.props.modbusTemplateName}
            modeValue={this.props.modeValue}
            intervalValue={this.props.intervalValue}
            modNameList={this.props.modNameList}
            modNameValue={this.props.modNameValue}
            groupId={this.props.groupId}
            defaultValueGateway={this.props.defaultValueGateway}
            formId={this.props.formId}
            physicalDeviceIdGateway={this.props.physicalDeviceIdGateway}
            nextRegisterValid={formInput.nextRegisterValid}
          />
        ) : (
          <div>
            <div>
              <div>
                <img src={modbusPngimage} title="modBus device" />
                <span className="modbusTitle">
                  {" "}
                  Select register for Modbus Device
                </span>
              </div>
              <div style={{ display: "flex", padding: "27px" }}>
                <div
                  style={{ display: "flex" }}
                  className="container slaveDivView"
                >
                  <div>
                    {" "}
                    <ul
                      style={{ display: "block" }}
                      className="nav nav-pills nav-stacked"
                    >
                      <li>
                        <table>
                          <tr>
                            <button className="deviceTypeList">
                              {" "}
                              <th className="deviceView">Device</th>{" "}
                              <th className="slaveIdView">
                                {this.props.modeName === "modbus-rtu"
                                  ? "Slave ID"
                                  : "IP Address"}
                              </th>
                            </button>
                          </tr>

                          {this.props.reisterDetailsWithModbusDevice.map(
                            (m, index) => (
                              <tr>
                                {" "}
                                <button
                                  onClick={(p) =>
                                    this.handleToShowRegister(p, m, index)
                                  }
                                  className={
                                    formInput.deviceNumber === index ||
                                    (formInput.deviceNumber === "0" &&
                                      index === 0)
                                      ? "activeDeviceSetting deviceRow"
                                      : "deviceTypeList"
                                  }
                                >
                                  {" "}
                                  <td className="deviceView">
                                    {m.deviceName}{" "}
                                  </td>
                                  <td className="slaveIdView"> {m.IdNumber}</td>
                                </button>{" "}
                              </tr>
                            )
                          )}
                        </table>
                      </li>
                      <div style={{ display: "inline" }}></div>
                    </ul>
                  </div>

                  <div className="registerDeviceTypeView">
                    <table style={{ textAlign: "center" }}>
                      <tr>
                        <th>
                          {" "}
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={(o) => this.handleAllConfirmRegister(o)}
                            checked={
                              formInput.isCheckAll[formInput.deviceNumber]
                            }
                            id={formInput.deviceNumber}
                            value={formInput.deviceNumber}
                            key={formInput.deviceNumber}
                          />
                        </th>
                        <th>Register</th>
                        <th>Manufacturer</th>
                        <th>Unit</th>
                        <th>Access mode</th>
                      </tr>
                      {this.props.reisterDetailsWithModbusDevice[
                        formInput.deviceNumber
                      ].modbusTemplateRegister.map((p, index) => (
                        <tr key={index}>
                          <td>
                            {" "}
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={(o) =>
                                this.handleConfirmRegister(o, p, index)
                              }
                              id={p.id}
                              value={p.id}
                              key={p.id}
                              name={p.label}
                              checked={formInput.isCheck.includes(p.id)}
                            />
                          </td>
                          <td>{p.label}</td>
                          <td>
                            {
                              this.props.reisterDetailsWithModbusDevice[
                                formInput.deviceNumber
                              ].manufacturer
                            }
                          </td>
                          <td>{p.unit}</td>
                          <td>
                            {p.access_mode === 2
                              ? "(r)Readable"
                              : p.access_mode === 4
                              ? "(w)Writeable"
                              : p.access_mode === 16
                              ? "(x)Triggerable"
                              : p.access_mode === 6
                              ? " (rw)Readable and Writeable"
                              : p.access_mode === 18
                              ? " (rx)Readable and Triggerable"
                              : p.access_mode === 20
                              ? "(wx)Writeable and Triggerable"
                              : "(rwx)Readable , Writeable and Triggerable"}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
              <div className="buttonTag" style={{ marginLeft: "200px" }}>
                {" "}
                <div>&nbsp;&nbsp; </div>
                <div>&nbsp;&nbsp; </div>
              </div>
              <div className="belowModbusDeviceButton">
                <button
                  onClick={() => this.handleToNextApply()}
                  className="btn btn-primary buttonView modbusButton"
                >
                  Apply
                </button>{" "}
                <button
                  onClick={this.handleToPrevious}
                  className="btn btn-secondary previousbutton"
                >
                  Previous
                </button>{" "}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allSelectModbus: state.modbusViewReducer.allSelectModbus,
    allSelectModbusPreLoad: state.modbusViewReducer.allSelectModbusPreLoad,
  };
}

export default connect(mapStateToProps, { getApplyModbus })(
  SelectModbusDeviceTypeAddRegister
);
