import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faCircleInfo,
  faChartLine,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCircleInfo as faCircleInfoSolid,
  faGear as faGearSolid,
  faChartLine as faChartLineSolid,
} from "@fortawesome/pro-solid-svg-icons";
import LINE from "../../../../assets/line.svg";
import { openExport } from "../../../../actions/screen-activities";
import TopMenu from "../../../../components/menus/top-menu/TopMenu";
import "../../style.css";

// import DownloadIcon from "../../../icons/DownloadIcon";
import ExportPopup from "../../../popups/ExportPopup";
import { faMemoCircleInfo } from "@fortawesome/pro-solid-svg-icons";

const initialState = {
  id_group: null,
  projectId: "",
  deviceLabel: "",
};

class OneBaceEpSubmenu extends Component {
  state = initialState;
  componentDidMount = () => {
    const { id: id_group, projectId: projectId } = this.props.match.params;
    if (!id_group) {
      this.props.history.push("/");
    }

    this.setState({
      id_group,
      projectId,
    });
  };

  render() {
    // const routeArr = window.location.pathname.split("/");
    // state = initialState;

    return (
      <div className="row">
        {this.props.exportPopup ? (
          <ExportPopup
            fromTime={this.state.fromTime}
            toTime={this.state.toTime}
          />
        ) : (
          ""
        )}
        <div className="col pl-4">
          <h1 className=" pageView">
            <div className="row">
              <div className="col-md-7">
                <h6 className="gatewayColor">
                  <Link
                    to="/bace-ep/card-view/1/a49dbc8a-e3d2-4e8a-9ef4-8543b2d903bc"
                    className="link-unstyled"
                  >
                    {" "}
                    Gateways{" "}
                  </Link>{" "}
                  {">"}
                </h6>{" "}
                <h5 className=" basepannelheading color-ocean-blue">
                  {" "}
                  {this.props.deviceHeaderInfo?.label}{" "}
                </h5>
              </div>
              <div className="col-md-5">
                <TopMenu />
              </div>
            </div>
          </h1>{" "}
          <div class="mb-3 switch-btn-line"></div>
          <div className="divModal">
            <Link
              className={
                this.props.location.pathname.endsWith("/device-info")
                  ? "bacelink-active"
                  : " bacelink-inactive"
              }
              to={`/bace-ep/${this.state.projectId}/${this.props.deviceHeaderInfo?.id_physical_device}/device-info`}
            >
              <FontAwesomeIcon
                style={{ cursor: "pointer", marginRight: "4px" }}
                className="fa-lg"
                icon={
                  this.props.location.pathname.includes("device-info")
                    ? faCircleInfoSolid
                    : faCircleInfo
                }
              />
              Device info
            </Link>

            <Link
              className={
                this.props.deviceHeaderInfo?.id_physical_device === undefined
                  ? "isDisabledSettings"
                  : this.props.location.pathname.endsWith("/settings")
                  ? "bacelink-active"
                  : "bacelink-inactive"
              }
              to={
                this.props.deviceHeaderInfo?.id_physical_device !== undefined &&
                `/bace-ep/${this.state.projectId}/${this.props.deviceHeaderInfo?.id_physical_device}/settings`
              }
            >
              <FontAwesomeIcon
                style={{ cursor: "pointer", marginRight: "4px" }}
                className="fa-lg"
                icon={
                  this.props.location.pathname.includes("settings")
                    ? faGearSolid
                    : faGear
                }
              />
              Settings
            </Link>

            <Link
              className={
                this.props.deviceHeaderInfo?.id_physical_device === undefined
                  ? "isDisabledMeasurement"
                  : this.props.location.pathname.endsWith("/measurement-data")
                  ? "bacelink-active"
                  : " bacelink-inactive"
              }
              to={
                this.props.deviceHeaderInfo?.id_physical_device !== undefined &&
                `/bace-ep/${this.state.projectId}/${this.props.deviceHeaderInfo?.id_physical_device}/measurement-data`
              }
            >
              <FontAwesomeIcon
                style={{ cursor: "pointer", marginRight: "4px" }}
                icon={
                  this.props.location.pathname.includes("measurement-data")
                    ? faChartLineSolid
                    : faChartLine
                }
              />
              Measurement-data
            </Link>
            {/* <div className="mt-4">
              <div
                className={`form-group ${
                  routeArr[routeArr.length - 1] === "measurement-data"
                    ? "row"
                    : "d-none"
                }`}
              >
                <div className="col-auto">
                  <input
                    className="form-control"
                    type="date"
                    value={moment(this.state.fromTime).format("YYYY-MM-DD")}
                    onChange={(e) =>
                      this.changeDate({
                        date: "dateFrom",
                        value: e.target.valueAsDate,
                      })
                    }
                  />
                </div>
                <div className="col-auto">to</div>
                <div className="col-auto">
                  <input
                    className="form-control"
                    type="date"
                    value={moment(this.state.toTime).format("YYYY-MM-DD")}
                    onChange={(e) =>
                      this.changeDate({
                        date: "dateTo",
                        value: e.target.valueAsDate,
                      })
                    }
                  />
                </div>

                <div className="col-auto ms-auto">
                  <button
                    className={true ? "d-block bacelink" : "d-none"}
                    onClick={this.props.openExport}
                  >
                    <DownloadIcon /> Export
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    deviceViewData: state.listViewReducer.deviceViewData,
    exportPopup: state.screenActivitiesReducer?.exportPopup,
  };
}

export default withRouter(
  connect(mapStateToProps, { openExport })(OneBaceEpSubmenu)
);
