import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Moment from "react-moment";
import PaginationNew from "../../components/global/paginate/PaginationNew";

import { getListViewData } from "../../actions/list-view";

import globalFunctions from "../../helpers/functions";

import DownIcon from "@material-ui/icons/ArrowDropDown";
import UpIcon from "@material-ui/icons/ArrowDropUp";

import "./sensor.css";

const { uniqueKey, Td } = globalFunctions;

class DeviceListCard extends Component {
  render() {
    const { subtype, subpage } = this.props;
    if (
      this.props.listViewData &&
      this.props.listViewData?._meta?.totalCount > 0
    ) {
      const { pathname } = this.props.location;
      const { currentPageNr } = this.props.match.params;
      const baseRoute = currentPageNr
        ? pathname.substring(0, pathname.lastIndexOf("/"))
        : pathname;

      const sortColumn = new URLSearchParams(this.props.location.search).get(
        "sortColumn"
      );
      const sortDirection = new URLSearchParams(this.props.location.search).get(
        "sortDirection"
      );
      const newSortDirection = sortDirection
        ? sortDirection === "ASC"
          ? "DESC"
          : "ASC"
        : "DESC";
      return (
        <div className="bacecard p-4 mt-4">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>
                  <Link
                    className="specs-key clickable"
                    scope="col"
                    to={`${baseRoute}/1?sortColumn=label&sortDirection=${newSortDirection}`}
                  >
                    Device name
                  </Link>
                  {sortColumn === "label" ? (
                    sortDirection === "ASC" ? (
                      <DownIcon />
                    ) : (
                      <UpIcon />
                    )
                  ) : (
                    ""
                  )}
                </th>
                <th className="specs-key" scope="col">
                  Device id
                </th>
                <th className="specs-key " scope="col">
                  Country
                </th>
                <th className="specs-key" scope="col">
                  Last seen
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.listViewData.items.length > 0 ? (
                this.props.listViewData.items.map((data) => (
                  <tr key={uniqueKey()}>
                    <Td
                      to={`/${subtype}/${data.id}/${subpage}`}
                      className="specs-value"
                    >
                      {data.label}
                    </Td>
                    <Td
                      to={`/${subtype}/${data.id}/${subpage}`}
                      className="specs-value"
                    >
                      {data.physicalDevices.length > 0
                        ? data.physicalDevices[0].label
                        : "no device assigned"}
                    </Td>
                    <Td
                      to={`/${subtype}/${data.id}/${subpage}`}
                      className="specs-value"
                    >
                      {data?.country?.name}
                    </Td>
                    <Td
                      to={`/${subtype}/${data.id}/${subpage}`}
                      className="specs-value"
                    >
                      {data.physicalDevices.length > 0 ? (
                        <Moment format="DD MMM YYYY hh:mm">
                          {data.physicalDevices[0].last_data_at * 1000}
                        </Moment>
                      ) : (
                        "n/a"
                      )}
                    </Td>
                  </tr>
                ))
              ) : (
                <div></div>
              )}
            </tbody>
          </table>
          <PaginationNew />
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

function mapStateToProps(state) {
  return {
    listViewData: state.listViewReducer.listViewData,
  };
}

export default withRouter(
  connect(mapStateToProps, { getListViewData })(DeviceListCard)
);
