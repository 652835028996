import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListUl, faCircleInfo } from "@fortawesome/pro-regular-svg-icons";

import { webhookEpViewChange } from "../../actions/session-view";
class WebhookInfoSubtab extends Component {
  state = {
    formInput: {
      projectId: "",
    },
  };
  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;
    // const projectId = window.location.pathname.slice(29);
    // if (this.props.lastSettingsEpViewSelected === "LIST_VIEW") {
    //   return this.props.history.push(
    //     `/webhook/info/1/${this.props.match.params?.projectId}/${this.props.match.params?.webhookId}`
    //   );
    // }

    if (!currentPageNr) {
      return this.props.history.push(
        `/webhook/info/1/${this.props.match.params?.projectId}/${this.props.match.params?.webhookId}`
      );
    }
    if (this.props.lastWebhookEpViewSelected === "LIST_VIEW") {
      return this.props.history.push(
        `/webhook/attempts/1/${this.props.match.params?.projectId}/${this.props.match.params?.webhookId}`
      );
    }
  };

  render() {
    const formInput = this.state.formInput;
    formInput.projectId = this.props.projectId;
    return (
      <div className="row">
        {" "}
        <div className="divModal" style={{ marginTop: "12px" }}>
          <Link
            onClick={() => {
              this.props.webhookEpViewChange("INFO_VIEW");
            }}
            to={`/webhook/info/1/${this.props.match.params?.projectId}/${this.props.match.params?.webhookId}`}
            className={` ${
              this.props.location.pathname.includes("info")
                ? "bacelink-active"
                : "bacelink-inactive"
            }`}
          >
            <FontAwesomeIcon
              className="svg-icons-sm fa-lg"
              data-toggle="tooltip"
              data-placement="top"
              title="Info"
              icon={faCircleInfo}
            />{" "}
            Webhook Info
          </Link>

          <Link
            onClick={() => {
              this.props.webhookEpViewChange("LIST_VIEW");
            }}
            to={`/webhook/attempts/1/${this.props.match.params?.projectId}/${this.props.match.params?.webhookId}`}
            className={` ${
              this.props.location.pathname.includes("attempts")
                ? "bacelink-active"
                : "bacelink-inactive"
            }`}
          >
            <FontAwesomeIcon
              className="svg-icons-sm fa-lg"
              data-toggle="tooltip"
              data-placement="top"
              title="Attempts"
              icon={faListUl}
              style={{ marginRight: "8px" }}
            />
            Webhook Attempts
            {/* <FontAwesomeIcon className="fa-lg" icon={faListUl} /> */}
          </Link>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lastWebhookEpViewSelected: state.sessionReducer.lastWebhookEpViewSelected,
  };
}

export default withRouter(
  connect(mapStateToProps, { webhookEpViewChange })(WebhookInfoSubtab)
);
