import React, { Component } from "react";
//import CardBody from "./CardBody";
import { connect } from "react-redux";
import "./sensor.css";

class NoDataCard extends Component {
  render() {
    return (
      <div
        className="bacecard-no-data"
        style={{
          borderTop: "solid #F26D00 10px",
          backgroundColor: "#FFFFFF",
          borderRadius: "5px",
        }}
      >
        <h2>No data available</h2>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    // allEvents: state.thresholdReducer?.allEvents,
    // allPhysicalDevices: state.groupReducer?.allPhysicalDevices,
  };
}

export default connect(mapStateToProps, {})(NoDataCard);
