import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Switch from "@material-ui/core/Switch";
import LoaderRoller from "../../components/loader/LoaderRoller";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNetworkWired,
  faChartLine,
  faPlusCircle,
  faSliders,
  faTimesCircle,
  faTimes,
  faCheckCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import { settingsEpViewChange } from "../../actions/view-history";
import "./style.css";
import {
  getAllWebhooksOverview,
  clearNewWebhook,
  updateWebhook,
  clearEditWebhook,
  webhookEpViewChange,
  getWebhooksInfo,
  getWebhooksAttempts,
} from "../../actions/session-view";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import WebhookInfoSubmenu from "../../components/menus/sub-menu/subtypes/WebhookInfoSubmenu";
import WebhookInfoSubtab from "./WebhookInfoSubtab";

class WebhookAttempts extends Component {
  state = {
    formInput: {},
  };

  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;
    if (!currentPageNr) {
      return this.props.history.push(
        `/webhook/attempts/1/${this.props.match.params?.projectId}/${this.props.match.params?.webhookId}`
      );
    }

    this.props.clearEditWebhook();
    this.props.getAllWebhooksOverview(this.props.match.params?.projectId);
    this.props.getWebhooksInfo(this.props.match.params?.webhookId);
    this.props.getWebhooksAttempts(this.props.match.params?.webhookId);
    const updatedWebhookView = this.props.webhooksOverviewData.items.filter(
      (o) => o.id_webhook === this.props.match.params?.webhookId
    )[0];
  };

  componentWillUnmount = () => {};

  render() {
    const formInput = this.state.formInput;
    formInput.projectId = this.props.match.params?.projectId;
    const updatedWebhookDetails = this.props.webhooksOverviewData.items.filter(
      (o) => o.id_webhook === this.props.match.params?.webhookId
    )[0];

    return (
      <div>
        <WebhookInfoSubmenu webhookName={this.props.webhookInfoData?.label} />
        <WebhookInfoSubtab />
        {this.props.webhookAttemptsData !== undefined &&
        this.props.webhookAttemptsData !== null ? (
          <div>
            <div className="webhookFlow">
              {" "}
              <table
                class="table attemptDiv"
                style={{
                  //  marginLeft: "40px",
                  textAlign: "center",
                  // width: "94%",
                }}
              >
                <tr>
                  <th>Queued at</th>
                  <th>Attempt at</th>
                  <th>Attempt count</th>
                  <th>Attempt next</th>
                  <th>Response code</th>
                  <th>Size mega byte</th>
                  <th>Devices</th>
                  <th>Mesurement</th>
                  <th>Data types</th>
                </tr>

                {this.props.webhookAttemptsData.items.map((m, index) => (
                  <tr key={index}>
                    <td>
                      {" "}
                      {m?.queued_at === null ? (
                        "N/A"
                      ) : (
                        <Moment format="DD MMM YYYY HH:mm A">
                          {m?.queued_at * 1000}
                        </Moment>
                      )}
                    </td>
                    <td>
                      {m?.attempt_at === null ? (
                        "N/A"
                      ) : (
                        <Moment format="DD MMM YYYY HH:mm A">
                          {m?.attempt_at * 1000}
                        </Moment>
                      )}
                    </td>
                    <td>{m.attempt_count}</td>
                    <td>
                      {m?.attempt_next === null ? (
                        "N/A"
                      ) : (
                        <Moment format="DD MMM YYYY HH:mm A">
                          {m?.attempt_next * 1000}
                        </Moment>
                      )}
                    </td>
                    <td>{m.attempt_result}</td>
                    <td>{m.payload_bytes}</td>
                    <td>{m.device_count}</td>
                    <td>{m.measurement_count}</td>
                    <td>{m.data_types_count}</td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        ) : (
          <Fragment>
            <div className="bacecontainer-lg">
              <LoaderRoller />
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    webhooksOverviewData: state.sessionReducer.webhooksOverviewData,
    lastWebhookEpViewSelected: state.sessionReducer.lastWebhookEpViewSelected,
    webhookInfoData: state.sessionReducer.webhookInfoData,
    webhookAttemptsData: state.sessionReducer.webhookAttemptsData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    clearEditWebhook,
    getAllWebhooksOverview,
    webhookEpViewChange,
    getWebhooksInfo,
    getWebhooksAttempts,
  })(WebhookAttempts)
);
