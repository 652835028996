import React, { Component } from 'react';

export default class NotificationPin extends Component {
  render() {
    return (
      <svg 
        className="bd-placeholder-img rounded me-2" 
        width="20" 
        height="20" 
        xmlns="http://www.w3.org/2000/svg" 
        aria-hidden="true" 
        preserveAspectRatio="xMidYMid slice" 
        focusable="false"
      >
        <rect width="100%" height="100%" fill={this.props.fillColor}></rect>
      </svg>
    )
  }
}
