import React from 'react';

class TimeConverter extends React.Component {
  convertSecondsToTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  };

  render() {
    const { seconds } = this.props;

    return (
      <div>
        {/*<p>Input seconds: {seconds}</p>*/}
        <p>{/* Converted time:*/} {this.convertSecondsToTime(seconds)}</p>
      </div>
    );
  }
}

export default TimeConverter;
