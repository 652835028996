import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Pagination from "@material-ui/lab/Pagination";
import LoaderRoller from "../../components/loader/LoaderRoller";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { faSquareCheck as faSquareCheckDisable } from "@fortawesome/pro-duotone-svg-icons";
import {
  getAllNotification,
  getAcknowledgedNotification,
  updateAcknowledgeNotification,
  clearAcknowledgeNotification,
} from "../../actions/list-view";
import "./style.css";
import NotificationSubmenu from "../../components/menus/sub-menu/subtypes/NotificationSubmenu";

class notificationModule extends Component {
  state = {
    formInput: {
      projectId: "",
      changeAcknowledged: false,
      notificationId: "",
      acknowledgedAt: "",
      userId: "",
      notoficationConfirm: false,
      notificationSubject: "",
      id: "",
      recipientSearch: "",
      subjectSearch: "",
      createdAtSearch: "",
      createdAtEndTimeSearch: "",
      createdAt: "",
    },
  };

  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;
    this.props.clearAcknowledgeNotification();
    if (!currentPageNr) {
      return this.props.history.push(
        `/notification/1/${this.props.match.params?.projectId}`
      );
    }
    const userId = this.props.user;
    this.props.getAllNotification(
      1,
      userId?.id_user,
      this.props.match.params?.projectId
    );
    this.props.getAcknowledgedNotification(
      1,
      userId?.id_user,
      this.props.match.params?.projectId
    );
  };

  componentWillUnmount = () => {};

  handlePageChange = (event, value) => {
    const userId = this.props.user;
    this.props.getAllNotification(
      value,
      userId?.id_user,
      this.props.match.params?.projectId
    );
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  handleAcknowledgedChange = (notificationId, subject) => {
    this.props.clearAcknowledgeNotification();
    const formInput = { ...this.state.formInput };
    formInput.changeAcknowledged = true;
    formInput.notificationId = notificationId;
    formInput.notificationSubject = subject;
    this.setState({ formInput });
  };

  handleToConnectionClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.changeAcknowledged = false;
    this.setState({ formInput });
  };

  handleToAcknowledgeConfirm = () => {
    this.props.clearAcknowledgeNotification();
    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    const userId = this.props.user;
    const formInput = { ...this.state.formInput };
    formInput.acknowledgedAt = currentTimestampInSeconds;
    formInput.userId = userId?.id_user;
    formInput.notoficationConfirm = true;
    formInput.changeAcknowledged = false;
    this.setState({ formInput });
    this.props.updateAcknowledgeNotification(
      formInput,
      this.props.match.params?.projectId
    );
  };

  handleToNotificationSubject = () => {
    const formInput = { ...this.state.formInput };
    formInput.notoficationConfirm = false;
    this.setState({ formInput });
  };

  handleIdSearchChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.id = event.target.value;
    this.setState({ formInput }, () => {
      this.handleFilterChange();
    });
  };

  handleChangeSearch = (event) => {
    const formInput = { ...this.state.formInput };
    const dateValue = event.target.value;
    formInput.createdAt = dateValue;
    const dateMille = new Date(dateValue).getTime();
    formInput.createdAtSearch = dateMille / 1000;
    const endDate = new Date(dateValue);
    endDate.setHours(23, 59, 59, 999); // Set to 11:59:59 PM

    // Convert back to timestamps
    // const startTimestamp = filterTimestamp; // > 1726185600
    const endTimestamp = Math.floor(endDate.getTime() / 1000);
    formInput.createdAtEndTimeSearch = endTimestamp;

    this.setState({ formInput }, () => {
      this.handleFilterChange();
    });
  };
  handleRecipientSearch = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.recipientSearch = event.target.value.toLowerCase();
    this.setState({ formInput }, () => {
      this.handleFilterChange();
    });
  };

  handleSubjectSearch = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.subjectSearch = event.target.value.toLowerCase();
    this.setState({ formInput }, () => {
      this.handleFilterChange();
    });
  };
  handleFilterChange = () => {
    const { currentPageNr } = this.props.match.params;
    const formInput = { ...this.state.formInput };
    const userId = this.props.user;
    const filters = this.getFilters();
    setTimeout(() => {
      this.props.getAllNotification(
        currentPageNr,
        userId?.id_user,
        this.props.match.params?.projectId,
        filters
      );
    }, 1000);
  };

  getFilters = () => {
    const { formInput } = this.state;
    let filters = {
      "filter[id_notification_message][like]": formInput.id
        ? formInput.id
        : undefined,
      "filter[recipient][like]": formInput.recipientSearch
        ? formInput.recipientSearch
        : undefined,
      "filter[subject][like]": formInput.subjectSearch
        ? formInput.subjectSearch
        : undefined,
      "filter[created_at][gt]": formInput.createdAtSearch
        ? formInput.createdAtSearch
        : undefined,
      "filter[created_at][lt]": formInput.createdAtEndTimeSearch
        ? formInput.createdAtEndTimeSearch
        : undefined,
    };
    let params = Object.entries(filters)
      .filter((e) => e[1] !== undefined)
      .map((e) => {
        return e.join("=");
      })
      .join("&");
    return params;
  };

  render() {
    const formInput = this.state.formInput;
    formInput.projectId = this.props.match.params?.projectId;
    const currentTimestamp = Date.now();
    const totalAcknowledgedNotification =
      this.props.allNotificationList?._meta?.totalCount -
      this.props.acknowledgedNotificationList?._meta?.totalCount;
    return (
      <div>
        <div>
          <div>
            <NotificationSubmenu />
          </div>
          {formInput.notoficationConfirm && (
            <Dialog
              open={formInput.notoficationConfirm === true ? true : false}
              onClose={this.handleToNotificationSubject}
            >
              <div onClick={this.handleToNotificationSubject}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToNotificationSubject}
                />
              </div>
              <DialogTitle>
                {this.props.updateNotificationList !== undefined &&
                this.props.updateNotificationList !== null ? (
                  <p className="notificationNameMessage">
                    <p className="notificationName">
                      {formInput.notificationSubject}{" "}
                    </p>{" "}
                    is acknowledged sucessfully !
                  </p>
                ) : (
                  <LoaderRoller />
                )}
              </DialogTitle>
            </Dialog>
          )}

          {formInput.changeAcknowledged && (
            <Dialog
              open={formInput.changeAcknowledged === true ? true : false}
              onClose={this.handleToConnectionClose}
            >
              <div onClick={this.handleToConnectionClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToConnectionClose}
                />
              </div>
              <DialogTitle>
                <p className="wififailedMessage">
                  Do you really want to acknowledge this Notification?
                </p>
                <div className="col pb-3">
                  <div className="d-flex justify-content-end">
                    <button
                      className={
                        formInput.setApplyConfirm
                          ? "btn-cancel mx-1 cancelTemplate"
                          : "btn-cancel mx-1"
                      }
                      onClick={this.handleToConnectionClose}
                      disabled={formInput.setApplyConfirm ? true : false}
                    >
                      Cancel
                    </button>{" "}
                    <button
                      className={"btn-cancel submitButton"}
                      onClick={() => this.handleToAcknowledgeConfirm()}
                    >
                      <Fragment>Confirm</Fragment>
                      {/* )} */}
                    </button>
                  </div>
                </div>
              </DialogTitle>
            </Dialog>
          )}
          <div className="acknowledgeNotionfication">
            {this.props.acknowledgedNotificationList !== undefined &&
            this.props.allNotificationList !== undefined &&
            this.props.acknowledgedNotificationList !== null &&
            this.props.allNotificationList !== null
              ? totalAcknowledgedNotification
              : ""}{" "}
            {this.props.acknowledgedNotificationList !== undefined &&
            this.props.allNotificationList !== undefined &&
            this.props.acknowledgedNotificationList !== null &&
            this.props.allNotificationList !== null
              ? "Active Notifications"
              : ""}
          </div>
          {this.props.allNotificationList !== undefined &&
          this.props.allNotificationList !== null ? (
            <div className="p-3 mt-3">
              <table className="table" style={{ textAlign: "center" }}>
                <thead>
                  <tr className="tableRow">
                    <th className="card-list-key" scope="col">
                      Id
                    </th>
                    <th className="card-list-key" scope="col">
                      Recipient
                    </th>
                    <th className="card-list-key" scope="col">
                      Subject
                    </th>
                    <th className="card-list-key" scope="col">
                      Created At
                    </th>
                    <th className="card-list-key" scope="col">
                      Data
                    </th>
                    <th className="card-list-key" scope="col">
                      Gateway
                    </th>
                    <th className="card-list-key" scope="col">
                      Acknowledged At
                    </th>
                  </tr>
                </thead>
                <tbody className="mh-50 p3 listViewStyle">
                  <tr
                    className="clickbleTr"
                    style={{
                      width: "1416px",
                    }}
                  >
                    <td>
                      {" "}
                      <div>
                        <input
                          className="dropdownView formInputView"
                          defaultValue={formInput.id}
                          name="name"
                          type="text"
                          onChange={this.handleIdSearchChange}
                          placeholder="Id"
                          // size="8"
                        />
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div>
                        <input
                          className="dropdownView formInputView"
                          defaultValue={formInput.recipientSearch}
                          name="name"
                          type="text"
                          onChange={this.handleRecipientSearch}
                          placeholder="Recipient"
                          //    size="8"
                        />
                      </div>
                    </td>{" "}
                    <td>
                      {" "}
                      <div>
                        <input
                          className="dropdownView formInputView"
                          defaultValue={formInput.subjectSearch}
                          name="name"
                          type="text"
                          onChange={this.handleSubjectSearch}
                          placeholder="Subject"
                          //  size="8"
                        />
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div>
                        <input
                          className="dropdownView formInputView"
                          value={formInput.createdAt}
                          name="startDate"
                          type="date"
                          onChange={this.handleChangeSearch}
                          placeholder="createdAt"
                        />
                      </div>
                    </td>
                  </tr>

                  {this.props.allNotificationList?.items.map((m) => (
                    <tr
                      className="clickbleTr"
                      style={{
                        width: "1416px",
                      }}
                    >
                      <td className="card-list-value">
                        {m.id_notification_message}
                      </td>
                      <td className="card-list-value">{m.recipient}</td>
                      <td className="card-list-value">{m.subject}</td>
                      <td className="card-list-value">
                        <Moment format="DD MMM YYYY HH:mm A">
                          {m.created_at * 1000}
                        </Moment>
                      </td>
                      <td
                        className="card-list-value"
                        style={{
                          maxWidth: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <div title={m.event?.value}>
                          {m.event?.value.includes(".")
                            ? parseFloat(m.event?.value).toFixed(2)
                            : m.event?.value}
                        </div>
                      </td>

                      <td className="card-list-value">
                        {m.event?.source_device_label}{" "}
                      </td>
                      <td className="card-list-value">
                        <div style={{ display: "flex" }}>
                          {" "}
                          {m?.event?.acknowledged_at === null
                            ? "Not Acknowledged"
                            : "Acknowledged"}{" "}
                          {m?.event?.acknowledged_at === null ? (
                            <button
                              style={{
                                border: "0px",
                                marginLeft: "4px",
                                background: "white",
                              }}
                              onClick={() =>
                                this.handleAcknowledgedChange(
                                  m?.event?.id_event,
                                  m?.subject
                                )
                              }
                            >
                              {" "}
                              <FontAwesomeIcon
                                style={{ fontSize: "24px" }}
                                className="fa-lg"
                                icon={faSquareCheck}
                              />
                            </button>
                          ) : (
                            <FontAwesomeIcon
                              style={{ marginLeft: "30px", fontSize: "24px" }}
                              className="fa-lg"
                              icon={faSquareCheckDisable}
                              disabled="true"
                            />
                          )}{" "}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div>
              <LoaderRoller />
            </div>
          )}
          {this.props.allNotificationList?.items.length === 0 && (
            <div style={{ marginLeft: "22px", fontWeight: "600" }}>
              No Notification is available for this project.
            </div>
          )}
          {this.props.allNotificationList?._meta?.pageCount > 1 ? (
            <div className="pageStyle m-3">
              <Pagination
                count={this.props.allNotificationList?._meta?.pageCount}
                page={this.props.allNotificationList?._meta?.currentPage}
                onChange={this.handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    acknowledgedNotificationList:
      state.listViewReducer.acknowledgedNotificationList,
    allNotificationList: state.listViewReducer.allNotificationList,
    updateNotificationList: state.listViewReducer.updateNotificationList,
    user: state.userReducer?.user,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAllNotification,
    getAcknowledgedNotification,
    updateAcknowledgeNotification,
    clearAcknowledgeNotification,
  })(notificationModule)
);
