import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faServer,
  faHardDrive,
  faFolderGear,
} from "@fortawesome/pro-regular-svg-icons";
import { getAllSelectGateway,clearGateWayData } from "../../actions/modbus-view";
import LoaderRoller from "../../components/loader/LoaderRoller";
import SelectGatewayModbus from "../../components/modbus/SelectGatewayModbus";

class SelectModbus extends Component {
  componentDidMount = () => {
    this.props.clearGateWayData();
    this.props.getAllSelectGateway(this.props.match.params.projectId);
  };
  render() {
    return (
      <div>
        <div>
          {/* <ModbusGatewaysSubmenu /> */}
          <div style={{ padding: "30px" }}>
            {this.props.allSelectGateway?.items !== undefined ? (
              <SelectGatewayModbus
                allSelectGateway={this.props.allSelectGateway}
                nextSetTab={this.props.nextTab}
                nextModbusDevicesTab={this.props.nextModbusDevicesTab}
                nextRegisterDevicesTab={this.props.nextRegisterDevicesTab}
                nextResult={this.props.nextResult}
                //  modeName={this.props.modeName}
                previousTab={this.props.previousTab}
                previousSetTab={this.props.previousSetTab}
                previousModbusTab={this.props.previousModbusTab}
                previousRegisterTab={this.props.previousRegisterTab}
                projectId={this.props.projectId}
                formId={""}
                physicalDeviceIdGateway={""}
              />
            ) : (
              <LoaderRoller />
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allSelectGateway: state.modbusViewReducer.allSelectGateway,
  };
}

export default withRouter(
  connect(mapStateToProps, { getAllSelectGateway,clearGateWayData })(SelectModbus)
);
