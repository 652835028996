import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getAllSubtypes, getAllGroups } from "../../../actions/list-view";
import BACELogoLight from "../../../assets/logo/BACE_logo_light.svg";
import BACELogoLightWhite from "../../../assets/logo/BACE_Logo_Word_white.png";
import MenuItems from "./MenuItems";
import Success from "../../../views/register/Success";
import Failed from "../../../views/register/Failed";

class MainMenu extends Component {
  componentDidMount = () => {
    // this.props.getAllSubtypes();
    //this.props.getAllGroups();
  };

  render() {
    return (
      <Fragment>
        {this.props.userDetails?.RegisterDataResult?.status === 200 ? (
          <div>
            <Success />
          </div>
        ) : this.props.userDetails?.RegisterDataResult?.status === "failed" ||
          this.props.userDetails?.RegisterFailedDataResult === "failed" ? (
          <div>
            <Failed />
          </div>
        ) : (
          <div className="fixed-top min-vh-100 main-menu">
            <div
              className="
            logo-top-frame"
            >
              <h4 className="fw-bold navbar-project-subname">BACE Panel</h4>
            </div>
            <MenuItems
              projectId={this.props.projectId}
              clearProject={this.props.clearProject}
              projectLabel={this.props.projectLabel}
              allGatewayDataWithAccessibleProtocol={
                this.props.allGatewayDataWithAccessibleProtocol
              }
            />
            +
            <div className="main-menu-logo">
              <img src={BACELogoLightWhite} alt="BACE iot logo" />
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer,
    subtypes: state.listViewReducer?.subtypes,
    allGatewayDataWithAccessibleProtocol:
      state.listViewReducer.allGatewayDataWithAccessibleProtocol,
  };
}

export default withRouter(
  connect(mapStateToProps, { getAllSubtypes, getAllGroups })(MainMenu)
);
