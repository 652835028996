import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { getListViewData } from "../../actions/list-view";
import { getSessionListViewData } from "../../actions/session-view";

const initialState = {
  modbus: [],
};
class ApiDashboard extends Component {
  state = initialState;
  componentDidMount = () => {
    const { currentPageNr, id } = this.props.match.params;
    if (!currentPageNr) {
      this.props.history.push("/api-dashboard/ApiDashboard");
    }
  };

  componentDidUpdate = (prevProps) => {
    const { currentPageNr } = this.props.match.params;

    if (this.props.location !== prevProps.location) {
      const sortColumn = new URLSearchParams(this.props.location.search).get(
        "sortColumn"
      );
      const sortDirection = new URLSearchParams(this.props.location.search).get(
        "sortDirection"
      );
    }
  };

  componentWillUnmount = () => {};

  render() {
    return (
      <Fragment>
        <div className="bacecontainer-lg pl-2">
          <div className="col pl-4">
            {" "}
            <h1 className="page-title-bold">API</h1>
            <Link
              to="/human-movement-science/list-view"
              className={` ${
                this.props.location.pathname ===
                "/human-movement-science/list-view"
                  ? "bacelink-active"
                  : "bacelink"
              }`}
            ></Link>
            <div className="align-right">
              <span className="text-alert submenu-text-bold">
                {this.props.nrResults}
              </span>
              <span className="text-alert submenu-text"></span>
            </div>
            <div className="switch-btn-line">Not Impletemented Yet</div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    sessionListViewData: state.sessionReducer.sessionListViewData,
    listViewData: state.listViewReducer.listViewData,
    listModViewData: state.listViewReducer.listModViewData,
    listBluetoothViewData: state.listViewReducer.listBluetoothViewData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getSessionListViewData,
    getListViewData,
  })(ApiDashboard)
);
