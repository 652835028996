import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import LoaderRoller from "../../components/loader/LoaderRoller";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNetworkWired,
  faChartLine,
  faPlusCircle,
  faSliders,
  faEdit,
  faTimes,
  faTrashCan,
  faUpDownLeftRight,
  faTimesCircle,
  faCheckCircle,
  faCircleExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import "./styles.css";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import SettingsSubmenu from "../../components/menus/sub-menu/subtypes/SettingsSubmenu";
import {
  renameProjectName,
  getAllProject,
  deleteWholeProject,
  clearDeleteProjectNotPossible,
  clearDeleteProjectPossible,
  getMemberDetailsForProject,
} from "../../actions/list-view";
import SettingsMoveGateways from "./SettingsMoveGateways";
class SettingsInfoView extends Component {
  state = {
    formInput: {
      projectId: "",
      editProject: false,
      projectName: "",
      projectNameConfirm: false,
      deleteProject: false,
      deleteConfirmProject: false,
      moveProject: false,
    },
  };

  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;
    const projectId = this.props.match.params?.projectId;
    if (!currentPageNr) {
      return this.props.history.push(`/settings/info-view/1/${projectId}`);
    }
    if (this.props.lastSettingsEpViewSelected === "LIST_VIEW") {
      return this.props.history.push(`/settings/members/1/${projectId}`);
    }
    this.props.clearDeleteProjectNotPossible();
    this.props.clearDeleteProjectPossible();
    this.props.getMemberDetailsForProject(this.props.match.params?.projectId);
  };

  componentWillUnmount = () => {};

  handleChange = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput[input.name] = input.value;
    formInput.projectName = input.value;
    this.setState({ formInput });
  };

  handleToEditProjectName = () => {
    const formInput = { ...this.state.formInput };
    formInput.editProject = false;
    formInput.projectNameConfirm = true;
    this.setState({ formInput });
    this.props.renameProjectName(formInput.projectName, formInput.projectId);
  };

  openEditProject = () => {
    const formInput = { ...this.state.formInput };
    formInput.editProject = true;
    this.setState({ formInput });
  };

  handleToConnectionClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.editProject = false;
    formInput.deleteProject = false;
    formInput.deleteConfirmProject = false;
    this.setState({ formInput });
  };

  handleToConnectionProjectClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.editProject = false;
    formInput.deleteProject = false;
    formInput.deleteConfirmProject = false;
    this.setState({ formInput });
    this.props.clearDeleteProjectNotPossible();
  };

  handleToProjectClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.editProject = false;
    formInput.deleteProject = false;
    formInput.deleteConfirmProject = false;
    this.setState({ formInput });
    this.props.clearDeleteProjectPossible();
    this.props.getAllProject();

    // return this.props.history.push("/");
    return window.location.replace("/");
  };

  handleToProjectNameUpdate = () => {
    if (this.props.projectNameChanged?.status === 200) {
      this.props.getAllProject();
    }
    const formInput = { ...this.state.formInput };
    formInput.editProject = false;
    formInput.projectNameConfirm = false;
    this.setState({ formInput });
  };

  handleToDelete = (rowId, addr, index) => {
    const formInput = { ...this.state.formInput };
    formInput.deleteProject = true;
    this.setState({ formInput });
  };

  handleToDeleteProject = () => {
    const formInput = { ...this.state.formInput };
    formInput.deleteConfirmProject = true;
    formInput.deleteProject = false;
    this.setState({ formInput });
    const projectId = this.props.match.params?.projectId;
    this.props.deleteWholeProject(projectId);
  };

  handleToMoveGateways = () => {
    const formInput = { ...this.state.formInput };
    formInput.moveProject = true;
    this.setState({ formInput });
  };

  render() {
    const formInput = this.state.formInput;
    formInput.projectId = this.props.match.params?.projectId;

    const projectName = this.props.allProjectData.items.filter(
      (o) => o.id_project === this.props.match.params?.projectId
    )[0].label;

    const accessTypeMapping =
      this.props.projectMemberDetails?.data?.access.filter(
        (p) => p.email === this.props.userEmail
      );
    return (
      <div>
        <div>
          {formInput.deleteConfirmProject === true &&
            (this.props.projectNameDeletePossible === null ||
              this.props.projectNameDeleteNotPossible === null) && (
              <Dialog
                open={formInput.deleteConfirmProject === true ? true : false}
              >
                <DialogTitle>
                  <LoaderRoller />
                </DialogTitle>
              </Dialog>
            )}
          {this.props.projectNameDeletePossible === 200 && (
            <Dialog
              open={formInput.deleteConfirmProject === true ? true : false}
              onClose={this.handleToProjectClose}
            >
              <div onClick={this.handleToProjectClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToProjectClose}
                />
              </div>
              <DialogTitle>
                <FontAwesomeIcon
                style={{fontSize: "3em"}}
                  className="mb-4 fa-3x passDelete"
                  icon={faCheckCircle}
                />
                <p className="DeleteProjectMessage" style={{marginLeft: "142px"}}>
                  Deleting Project succeeded.
                </p>
                <p className="failedDelete" style={{marginLeft:"81px"}}>
                  Project is deleted successfully.{" "}
                </p>
              </DialogTitle>
            </Dialog>
          )}
          {this.props.projectNameDeleteNotPossible === true && (
            <Dialog
              open={formInput.deleteConfirmProject === true ? true : false}
              onClose={this.handleToConnectionProjectClose}
            >
              <div onClick={this.handleToConnectionProjectClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToConnectionProjectClose}
                />
              </div>
              <DialogTitle>
                <FontAwesomeIcon
                  className="mb-4 fa-3x errorWifi"
                  icon={faTimesCircle}
                />
                <p className="DeleteProjectMessage">Deleting Project failed.</p>
                <p className="failedDelete">
                  Sorry,there are still gateways in this project you need to
                  move them to other projects before deleting this project.{" "}
                </p>
              </DialogTitle>
            </Dialog>
          )}
          {formInput.deleteProject && (
            <Dialog
              open={formInput.deleteProject === true ? true : false}
              onClose={this.handleToConnectionClose}
            >
              <div onClick={this.handleToConnectionClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToConnectionClose}
                />
              </div>
              <DialogTitle>
                <p className="DeleteProjectMessage">Delete Project</p>
                <p className="deleteMessage">
                  Do you want to delete project{" "}
                  <div className="boldDeleteName">{projectName}</div>?
                </p>
                <div className="col pb-3">
                  <div className="d-flex justify-content-end">
                    <button
                      className={
                        formInput.setApplyConfirm
                          ? "btn-cancel mx-1 cancelTemplate"
                          : "btn-cancel mx-1"
                      }
                      onClick={this.handleToConnectionClose}
                      disabled={formInput.setApplyConfirm ? true : false}
                    >
                      Cancel
                    </button>{" "}
                    <button
                      className={
                        formInput.setApplyConfirm
                          ? "btn-cancel submitDisableButton"
                          : "btn-cancel submitButton"
                      }
                      onClick={() => this.handleToDeleteProject()}
                    >
                      <Fragment>Confirm</Fragment>
                      {/* )} */}
                    </button>
                  </div>
                </div>
              </DialogTitle>
            </Dialog>
          )}
          {formInput.projectNameConfirm && (
            <Dialog
              open={formInput.projectNameConfirm === true ? true : false}
              onClose={this.handleToProjectNameUpdate}
            >
              <div onClick={this.handleToProjectNameUpdate}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToProjectNameUpdate}
                />
              </div>
              <DialogTitle>
                {this.props.projectNameChanged?.status === 200 ? (
                  <p className="wififailedMessage" style={{ display: "flex" }}>
                    <p className="projectName">{formInput.projectName} </p>{" "}
                    changed sucessfully !
                  </p>
                ) : (
                  <LoaderRoller />
                )}
              </DialogTitle>
            </Dialog>
          )}
          {formInput.editProject && (
            <Dialog
              open={formInput.editProject === true ? true : false}
              onClose={this.handleToConnectionClose}
            >
              <div onClick={this.handleToConnectionClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToConnectionClose}
                />
              </div>
              <DialogTitle>
                <p className="editMessage">
                  Edit <div className="boldEditName">{projectName}</div>
                </p>
                <input
                  className="form-control-start formInput inputProjectBox"
                  defaultValue={projectName}
                  name="label"
                  type="text"
                  placeholder="Project name"
                  onChange={this.handleChange}
                />
                <div className="col pb-3">
                  <div className="d-flex justify-content-end">
                    <button
                      className={
                        formInput.setApplyConfirm
                          ? "btn-cancel mx-1 cancelTemplate"
                          : "btn-cancel mx-1"
                      }
                      onClick={this.handleToConnectionClose}
                      disabled={formInput.setApplyConfirm ? true : false}
                    >
                      Cancel
                    </button>{" "}
                    <button
                      className={
                        formInput.setApplyConfirm
                          ? "btn-cancel submitDisableButton"
                          : "btn-cancel submitButton"
                      }
                      onClick={() => this.handleToEditProjectName()}
                      disabled={formInput.projectName === "" ? true : false}
                    >
                      <Fragment>Confirm</Fragment>
                      {/* )} */}
                    </button>
                  </div>
                </div>
              </DialogTitle>
            </Dialog>
          )}
          <SettingsSubmenu projectId={this.props.match.params?.projectId} />
          <div>
            <Fragment>
              <div
                className="card-wrap listMain"
                style={{ margin: "40px", width: "80%" }}
              ></div>
              <div>
                <div className="settingView">
                  {" "}
                  <p className="projectName">Project Name</p>
                  <div className="emptyDiv"></div>
                  <div>{projectName}</div>
                  <button
                    className="btn-border-none"
                    onClick={() => this.openEditProject()}
                  >
                    {" "}
                    <FontAwesomeIcon
                      className="svg-icons-sm editSettings"
                      icon={faEdit}
                    />
                  </button>
                </div>
              </div>
            </Fragment>
            {this.props.projectMemberDetails?.data?.access.length > 0 ? (
              <div className="buttonAction">
                {" "}
                <button
                  className={
                    accessTypeMapping.length !== 0 &&
                    accessTypeMapping[0].type !== 50
                      ? "disableMember disableOption"
                      : "btn-border-none deleteButton"
                  }
                  disabled={
                    accessTypeMapping.length !== 0 &&
                    accessTypeMapping[0].type !== 50
                      ? true
                      : false
                  }
                  onClick={() => this.handleToDelete()}
                >
                  <FontAwesomeIcon
                    className="svg-icons-sm buttonViewIcon"
                    icon={faTrashCan}
                  />
                  Delete project
                </button>
                {accessTypeMapping.length !== 0 &&
                accessTypeMapping[0].type !== 50 ? (
                  <button
                    className={
                      accessTypeMapping.length !== 0 &&
                      accessTypeMapping[0].type !== 50
                        ? "disableMember disableOption"
                        : "btn-border-none moveButton"
                    }
                    disabled={
                      accessTypeMapping.length !== 0 &&
                      accessTypeMapping[0].type !== 50
                        ? true
                        : false
                    }
                  >
                    <FontAwesomeIcon
                      className="svg-icons-sm buttonViewIcon"
                      icon={faUpDownLeftRight}
                    />
                    Move gateways
                  </button>
                ) : (
                  <Link
                    to={`/settings/moving/1/${this.props.match.params?.projectId}`}
                    disabled={
                      accessTypeMapping.length !== 0 &&
                      accessTypeMapping[0].type !== 50
                        ? true
                        : false
                    }
                  >
                    {" "}
                    <button
                      className={
                        accessTypeMapping.length !== 0 &&
                        accessTypeMapping[0].type !== 50
                          ? "disableMember disableOption"
                          : "btn-border-none moveButton"
                      }
                      disabled={
                        accessTypeMapping.length !== 0 &&
                        accessTypeMapping[0].type !== 50
                          ? true
                          : false
                      }
                      //  onClick={() => this.handleToMoveGateways()}
                    >
                      <FontAwesomeIcon
                        className="svg-icons-sm buttonViewIcon"
                        icon={faUpDownLeftRight}
                      />
                      Move gateways
                    </button>
                  </Link>
                )}
                {accessTypeMapping.length !== 0 &&
                  accessTypeMapping[0].type !== 50 && (
                    <div>
                      {" "}
                      <div className="mx-1">
                        <div
                          style={{
                            width: "28%",
                            textAlign: "center",
                            marginTop: "10px",
                          }}
                        >
                          <FontAwesomeIcon
                            className="infoProjectIcon notRemoveOption"
                            icon={faCircleExclamation}
                            title="Action is not possible for contributor and viewer type"
                          />{" "}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            ) : (
              <div>
                <LoaderRoller />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lastSettingsEpViewSelected: state.viewHistoryReducer.lastBaceEpViewSelected,
    allProjectData: state.listViewReducer?.allProjectData,
    projectNameChanged: state.listViewReducer?.projectNameChanged,
    projectNameDeleteNotPossible:
      state.listViewReducer?.projectNameDeleteNotPossible,
    projectNameDeletePossible: state.listViewReducer?.projectNameDeletePossible,
    projectMemberDetails: state.listViewReducer?.projectMemberDetails,
    userEmail: state.userReducer?.user?.email,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    renameProjectName,
    getAllProject,
    deleteWholeProject,
    clearDeleteProjectNotPossible,
    clearDeleteProjectPossible,
    getMemberDetailsForProject,
  })(SettingsInfoView)
);
