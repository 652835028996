import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModbusIconBlue from "./../../assets/Icons/modbussetting.png";
import {
  faHardDrive,
  faCircleInfo,
  faTrashCan,
  faServer,
  faPlusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import AddRegistersModbus from "./AddRegistersModbus";
import SetGatewayModbus from "./SetGatewayModbus";
import { logDOM } from "@testing-library/react";
import "./style.css";
import { getAllModbusDeviceForGateway } from "../../actions/modbus-view";
import SelectModbusDevices from "./SelectModbusDevices";
import LoaderRoller from "../loader/LoaderRoller";
import SelectModbusDeviceTypeAddRegister from "./SelectModbusDeviceTypeAddRegister";

class SelectModbusDeviceType extends Component {
  state = {
    formInput: {
      selectGenericView: false,
      modbusDeviceTypeSetting: false,
      modbusErrorIPAddressMsg: false,
      modbusErrorModeMsg: false,
      modeValueMissing: false,
      sameDeviceName: false,
      nextReisterPage: false,
      previousModbusTab: "",
      previousTab: "",
      previousSetTab: "",
      previousRegisterTab: "",
      nextSetTab: "",
      nextRegisterDevicesTab: "",
      nextModbusDevicesTab: "",
      nextResult: "",
      nextRegisterValid: false,
      modbusDeviceTypeArray: [
        {
          deviceName: "",
          IdNumber: "",
          modbusTemplateRegister: [],
          manufacturer: "",
        },
      ],
    },
  };

  componentDidMount = () => {
    this.props.getAllModbusDeviceForGateway(this.props.modeName);
    const formInput = { ...this.state.formInput };
    formInput.previousTab = this.props.previousTab;
    formInput.previousSetTab = this.props.previousSetTab;
    formInput.previousModbusTab = this.props.previousModbusTab;
    formInput.previousRegisterTab = this.props.previousRegisterTab;
    formInput.nextSetTab = this.props.nextSetTab;
    formInput.nextRegisterDevicesTab = this.props.nextRegisterDevicesTab;
    formInput.nextModbusDevicesTab = this.props.nextModbusDevicesTab;
    formInput.nextResult = this.props.nextResult;
    formInput.nextRegisterValid = this.props.nextRegisterValid;
    formInput.modbusDeviceTypeArray =
      this.props.modbusDeviceTypeArray === undefined
        ? formInput.modbusDeviceTypeArray
        : this.props.modbusDeviceTypeArray;
    this.setState({ formInput });
    //this.defaultSelectModbusGateway();
  };

  handleToDeleteRow = (rowId, index) => {
    const formInput = { ...this.state.formInput };
    if (formInput.modbusDeviceTypeArray.length === 1) {
      formInput.modbusDeviceTypeSetting = true;
    } else {
      formInput.modbusDeviceTypeArray = formInput.modbusDeviceTypeArray.filter(
        (_, i) => i !== index
      );
    }
    //})
    formInput.ModbuserrorView = false;
    this.setState({ formInput });
  };

  handleToAddNewDevicerow = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.modbusDeviceTypeSetting = false;
    formInput.modbusDeviceTypeArray.push({
      deviceName:
        this.props.allModbusDevicesForGateway[0].manufacturer +
        "-" +
        this.props.allModbusDevicesForGateway[0].model,
      IdNumber: "",
      modbusTemplateRegister:
        this.props.allModbusDevicesForGateway[0].modbusTemplate?.registers,
      manufacturer: this.props.allModbusDevicesForGateway[0].manufacturer,
    });
    this.setState({ formInput });
  };

  handleToAddGenericNewDevice = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.selectGenericView = true;
    this.setState({ formInput });
  };

  handleDeviceTypeChange = (e, deviceIndex) => {
    const formInput = { ...this.state.formInput };
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const manufacturerName = e.target.value.split("-");
    const ManuValue = this.props.allModbusDevicesForGateway.filter(
      (d) => d.manufacturer === manufacturerName[0]
    );

    formInput.ModbuserrorView = false;
    formInput.modbusDeviceTypeArray[deviceIndex].manufacturer =
      ManuValue[0].manufacturer;
    formInput.modbusDeviceTypeArray[deviceIndex].deviceName = e.target.value;
    formInput.modbusDeviceTypeArray[deviceIndex].modbusTemplateRegister =
      this.props.allModbusDevicesForGateway[
        e.target.selectedIndex
      ].modbusTemplate?.registers;
    this.setState({ formInput });
  };

  handleModeValueChange = (e, modeIndex) => {
    const formInput = { ...this.state.formInput };
    formInput.ModbuserrorView = false;
    const labelNameView = e.target.value;

    if (this.props.modeName === "modbus-tcp") {
      if (
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          labelNameView
        )
      ) {
        formInput.modbusDeviceTypeArray[modeIndex].IdNumber = e.target.value;
        formInput.modbusErrorIPAddressMsg = false;
        this.setState({ formInput });
      } else {
        formInput.modbusErrorIPAddressMsg = true;
        this.setState({ formInput });
      }
    } else {
      if (labelNameView > 255) {
        formInput.modbusErrorModeMsg = true;
        this.setState({ formInput });
      } else {
        formInput.modbusDeviceTypeArray[modeIndex].IdNumber = e.target.value;
        formInput.modbusErrorModeMsg = false;
        formInput.modbusDeviceSetting = false;
        this.setState({ formInput });
      }
    }
  };

  handleToNextRegister = () => {
    const formInput = { ...this.state.formInput };
    const valueMissing = formInput.modbusDeviceTypeArray.map(
      (l) => l.IdNumber === ""
    );
    const uniqueConnection = [];
    const uniqueRegStartConnection = [];

    // formInput.modbusDeviceTypeArray.map((img) => {
    //   if (
    //     uniqueConnection.indexOf(img.deviceName) === -1 &&
    //     img.deviceName !== null
    //   ) {
    //     uniqueConnection.push(img.deviceName);
    //   } else {
    //     uniqueConnection.push(false);
    //   }
    // });

    if (uniqueConnection.includes(false)) {
      formInput.sameDeviceName = true;
      this.setState({ formInput });
    } else if (valueMissing.includes(true)) {
      formInput.modeValueMissing = true;
      formInput.ModbuserrorView = false;
      formInput.sameDeviceName = false;
      this.setState({ formInput });
    } else {
      formInput.nextReisterPage = true;
      formInput.nextRegisterValid = true;
      formInput.modeValueMissing = false;
      formInput.sameDeviceName = false;
      this.setState({ formInput });
    }
  };

  handleToPrevious = (event) => {
    //  this.props.previousTab();
    const formInput = { ...this.state.formInput };
    formInput.previousSet = true;
    this.setState({ formInput });
    this.props.previousSetTab();
  };

  render() {
    const formInput = this.state.formInput;
    formInput.previousTab = this.props.previousTab;
    formInput.previousSetTab = this.props.previousSetTab;
    formInput.previousModbusTab = this.props.previousModbusTab;
    formInput.previousRegisterTab = this.props.previousRegisterTab;
    formInput.nextSetTab = this.props.nextSetTab;
    formInput.nextRegisterDevicesTab = this.props.nextRegisterDevicesTab;
    formInput.nextModbusDevicesTab = this.props.nextModbusDevicesTab;
    formInput.nextRegisterValid =
      this.props.nextRegisterValid === undefined
        ? formInput.nextRegisterValid
        : this.props.nextRegisterValid;
    formInput.nextResult = this.props.nextResult;
    //   formInput.modbusDeviceTypeArray = this.props.modbusDeviceTypeArray;
    if (
      this.props.allModbusDevicesForGateway !== undefined &&
      formInput.nextRegisterValid !== true
    ) {
      formInput.modbusDeviceTypeArray[0].deviceName =
        formInput.modbusDeviceTypeArray[0].deviceName === ""
          ? this.props.allModbusDevicesForGateway[0].manufacturer +
            "-" +
            this.props.allModbusDevicesForGateway[0].model
          : formInput.modbusDeviceTypeArray[0].deviceName;

      formInput.modbusDeviceTypeArray[0].manufacturer =
        formInput.modbusDeviceTypeArray[0].manufacturer === ""
          ? this.props.allModbusDevicesForGateway[0].manufacturer
          : formInput.modbusDeviceTypeArray[0].manufacturer;

      formInput.modbusDeviceTypeArray[0].modbusTemplateRegister =
        formInput.modbusDeviceTypeArray[0].modbusTemplateRegister.length === 0
          ? this.props.allModbusDevicesForGateway[0].modbusTemplate.registers
          : formInput.modbusDeviceTypeArray[0].modbusTemplateRegister;
    }
    return (
      <div>
        {formInput.nextReisterPage === true ? (
          <SelectModbusDeviceTypeAddRegister
            reisterDetailsWithModbusDevice={formInput.modbusDeviceTypeArray}
            //  previousRegisterTab={this.props.previousRegisterTab}
            modeName={this.props.modeName}
            formInputModbusDevice={this.props.formInputData}
            nextRegisterValid={formInput.nextRegisterValid}
            projectId={this.props.projectId}
            modbusDeviceTypeArray={formInput.modbusDeviceTypeArray}
            previousTab={formInput.previousTab}
            previousSetTab={formInput.previousSetTab}
            previousModbusTab={formInput.previousModbusTab}
            previousRegisterTab={formInput.previousRegisterTab}
            previousLoadTemplate={formInput.previousLoadTemplate}
            nextSetTab={formInput.nextSetTab}
            nextRegisterDevicesTab={formInput.nextRegisterDevicesTab}
            nextModbusDevicesTab={formInput.nextModbusDevicesTab}
            nextResult={formInput.nextResult}
            modbusTemplateName={this.props.modbusTemplateName}
            modeValue={this.props.modeValue}
            intervalValue={this.props.intervalValue}
            modNameList={this.props.modNameList}
            modNameValue={this.props.modNameValue}
            groupId={this.props.groupId}
            //  nextResult={this.props.nextResult}
            defaultValueGateway={this.props.defaultValueGateway}
            formId={this.props.formId}
            physicalDeviceIdGateway={this.props.physicalDeviceIdGateway}
          />
        ) : formInput.previousSet === true ? (
          <SetGatewayModbus
            formInput={this.props.formInput}
            nextModbusDevicesTab={this.props.nextModbusDevicesTab}
            //  nextRegisterDevicesTab={this.props.nextRegisterDevicesTab}
            modbusTemplateName={this.props.modbusTemplateName}
            modeName={this.props.modeName}
            modeValue={this.props.modeValue}
            intervalValue={this.props.intervalValue}
            modNameList={this.props.modNameList}
            modNameValue={this.props.modNameValue}
            groupId={this.props.groupId}
            //  nextResult={this.props.nextResult}
            defaultValueGateway={this.props.defaultValueGateway}
            formId={this.props.formId}
            physicalDeviceIdGateway={this.props.physicalDeviceIdGateway}
            // nextSetTab={this.props.nextSetTab}
            ModbusFirstData={formInput.ModbusFirstData}
            ModbusDataArray={formInput.ModbusDataArray}
            previousTab={formInput.previousTab}
            previousSetTab={formInput.previousSetTab}
            previousModbusTab={formInput.previousModbusTab}
            previousRegisterTab={formInput.previousRegisterTab}
            nextSetTab={formInput.nextSetTab}
            nextRegisterDevicesTab={formInput.nextRegisterDevicesTab}
            //nextModbusDevicesTab={formInput.nextModbusDevicesTab}
            nextResult={formInput.nextResult}
            id_iot_device_Value={this.props.id_iot_device_Value}
            projectId={this.props.projectId}
            //nextResult={this.props.nextResult}
          />
        ) : (
          <div>
            {this.props.allModbusDevicesForGateway !== undefined ? (
              <div>
                {formInput.selectGenericView === true ? (
                  <SelectModbusDevices
                    setGatewayInfo={this.props.setGatewayInfo}
                    formInput={this.props.formInputData}
                    modbusTemplateName={this.props.modbusTemplateName}
                    modeName={this.props.modeName}
                    modeValue={this.props.modeValue}
                    intervalValue={this.props.intervalValue}
                    modNameList={this.props.modNameList}
                    modNameValue={this.props.modNameValue}
                    groupId={this.props.groupId}
                    ModbusFirstData={this.props.ModbusFirstData}
                    ModbusDataArray={this.props.ModbusDataArray}
                    defaultValueGateway={this.props.defaultValueGateway}
                    formId={this.props.formId}
                    physicalDeviceIdGateway={this.props.physicalDeviceIdGateway}
                    id_iot_device_Value={this.props.id_iot_device_Value}
                    previousTab={this.props.previousTab}
                    previousSetTab={this.props.previousSetTab}
                    previousModbusTab={this.props.previousModbusTab}
                    previousRegisterTab={this.props.previousRegisterTab}
                    nextSetTab={this.props.nextSetTab}
                    nextRegisterDevicesTab={this.props.nextRegisterDevicesTab}
                    nextModbusDevicesTab={this.props.nextModbusDevicesTab}
                    nextResult={this.props.nextResult}
                    loadDataTemplate={this.props.loadDataTemplate}
                    projectId={this.props.projectId}
                  />
                ) : (
                  <div>
                    <div>
                      <img
                        className="settingsIcon"
                        src={ModbusIconBlue}
                        title="modBus device"
                      />
                      <span className="modbusTitle modbusNewDeviceTypeView">
                        {" "}
                        Select a Modbus device by manufacturer or model
                      </span>
                      <div>
                        <div className="modbusDeviceTypeList">
                          {formInput.modbusDeviceTypeArray.map((m, index) => (
                            <div className="gapAllModbusDevice">
                              <table>
                                <tr>
                                  <th>
                                    {" "}
                                    <div>Modbus Device {index + 1}</div>
                                  </th>
                                  <th>
                                    {" "}
                                    <div>
                                      {this.props.modeName === "modbus-tcp"
                                        ? "IP Address"
                                        : "Slave Id"}
                                    </div>
                                  </th>
                                  <th></th>
                                </tr>

                                <tr>
                                  <td style={{ width: "50%" }}>
                                    <div style={{ width: "90%" }}>
                                      <select
                                        className="form-select dropdownView modbusDropDownView"
                                        aria-label="Default select example"
                                        label="status"
                                        key={index}
                                        onChange={(e) =>
                                          this.handleDeviceTypeChange(e, index)
                                        }
                                        defaultValue={
                                          formInput.nextRegisterValid !== true
                                            ? this.props
                                                .allModbusDevicesForGateway[0]
                                                .manufacturer -
                                              this.props
                                                .allModbusDevicesForGateway[0]
                                                .model
                                            : m.deviceName
                                        }
                                      >
                                        {this.props
                                          .allModbusDevicesForGateway !==
                                          undefined &&
                                          this.props.allModbusDevicesForGateway.map(
                                            (x, y) => (
                                              <option key={y} selected id={y}>
                                                {" "}
                                                {x.manufacturer}-{x.model}
                                              </option>
                                            )
                                          )}
                                      </select>
                                    </div>
                                  </td>
                                  <td style={{ width: "25%" }}>
                                    <div>
                                      <input
                                        className="form-control-search formInputModbusSearch modbusInputView"
                                        // value={formInput.ModbusFirstData[0].slaveOrIpAddressID}
                                        defaultValue={m.IdNumber}
                                        name="name"
                                        type="text"
                                        onChange={(e) =>
                                          this.handleModeValueChange(e, index)
                                        }
                                        // onInputChange={(e) =>
                                        //   this.handleModeValueChange(e)
                                        // }
                                        //   placeholder={
                                        //     setGatewayView.modeName === "modbus-rtu"
                                        //       ? "Slave ID"
                                        //       : "IP address"
                                        //   }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    {" "}
                                    <button
                                      className="btn-border-none"
                                      onClick={() =>
                                        this.handleToDeleteRow(m, index)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        className="svg-icons-sm"
                                        icon={faTrashCan}
                                      />
                                    </button>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          ))}
                        </div>
                        <div className="deviceFunction">
                          <div
                            className="plusDevice"
                            onClick={this.handleToAddNewDevicerow.bind(this)}
                          >
                            <div>
                              <button className="btn-border-none buttonDevice">
                                <FontAwesomeIcon icon={faPlusCircle} />
                              </button>{" "}
                            </div>
                            <div className="addDevice buttonDevice">
                              Add another Modbus device
                            </div>
                          </div>
                          {formInput.modbusDeviceTypeArray?.length === 1 ? (
                            <div
                              className="genericbutton buttonGenericDevice"
                              onClick={this.handleToAddGenericNewDevice.bind(
                                this
                              )}
                            >
                              <div style={{ display: "flex" }}>
                                <div>
                                  <button className="btn-border-none buttonViewRange buttonGenericDevice">
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                  </button>{" "}
                                </div>
                                <div className="ReturnToGenericDevice buttonGenericDevice">
                                  Your modbus isn’t in the list? Add a generic
                                  one
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            className={
                              formInput.modbusDeviceTypeSetting
                                ? "showErrorMsgMode"
                                : "hideMode"
                            }
                          >
                            Atleast one modbus device is required
                          </div>{" "}
                          <div
                            className={
                              formInput.modbusErrorIPAddressMsg
                                ? "showErrorMsgMode"
                                : "hideMode"
                            }
                          >
                            IP address is not valid
                          </div>
                          <div
                            className={
                              formInput.modbusErrorModeMsg
                                ? "showErrorMsgMode"
                                : "hideMode"
                            }
                          >
                            Exceed slave Id range 1-255
                          </div>{" "}
                          <div
                            className={
                              formInput.sameDeviceName
                                ? "showErrorRegisterMode"
                                : "hideMode"
                            }
                          >
                            Modbus Device Type should be unique.
                          </div>
                          <div
                            className={
                              formInput.modeValueMissing
                                ? "showErrorMsgMode"
                                : "hideMode"
                            }
                          >
                            {this.props.modeName === "modbus-rtu"
                              ? "Slave Id can not be empty."
                              : "IP address can not be empty."}
                          </div>{" "}
                        </div>
                      </div>
                      <div className="belowModbusDeviceButton">
                        <button
                          onClick={this.handleToNextRegister}
                          className="btn btn-primary buttonView modbusButton"
                        >
                          Next {">"} Add Registers
                        </button>{" "}
                        <button
                          onClick={this.handleToPrevious}
                          className="btn btn-secondary previousbutton"
                        >
                          Previous
                        </button>{" "}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <LoaderRoller />
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allModbusDevicesForGateway:
      state.modbusViewReducer.allModbusDevicesForGateway,
  };
}

export default connect(mapStateToProps, {
  getAllModbusDeviceForGateway,
})(SelectModbusDeviceType);
