import { combineReducers } from "redux";
import userReducer from "./user";
import notificationReducer from "./notification";
import redirectReducer from "./redirect";
import screenActivitiesReducer from "./screen-activities";
import listViewReducer from "./list-view";
import modbusViewReducer from "./modbus-view";
import chartViewReducer from "./chart-view";
import viewHistoryReducer from "./view-history";
import sessionReducer from "./session-view";

export default combineReducers({
  userReducer,
  notificationReducer,
  redirectReducer,
  screenActivitiesReducer,
  listViewReducer,
  modbusViewReducer,
  chartViewReducer,
  viewHistoryReducer,
  sessionReducer,
});
