import React, { Component } from "react";
import ModbusIconBlue from "./../../assets/Icons/modbussetting.png";
//import CardBody from "./CardBody";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import {} from "@fortawesome/pro-regular-svg-icons";
import "./style.css";
import {
  faTriangleExclamation,
  faHardDrive,
} from "@fortawesome/pro-regular-svg-icons";
import SetGatewayModbus from "./SetGatewayModbus";
import Pagination from "@material-ui/lab/Pagination";
import {
  getAllSelectGateway,
  clearGateWayData,
} from "../../actions/modbus-view";
import { logDOM } from "@testing-library/react";
import ModbusCardView from "../../views/modbus-gateways/ModbusCardView";
class SelectGatewayModbus extends Component {
  state = {
    formInput: {
      previousTab: "",
      defaultValueGateway: [],
      previousSetTab: "",
      previousModbusTab: "",
      previousRegisterTab: "",
      nextSetTab: "",
      nextRegisterDevicesTab: "",
      nextModbusDevicesTab: "",
      nextResult: "",
      nextSet: false,
      selectetGateway: "",
      selectMode: "",
      nextPage: false,
      radioSelect: false,
      radiosMsg: false,
      physicalDeviceIdGateway: "",
      id_iot_device_Value: "",
      groupId: "",
      modbusHome: false,
      ModbusFirstData: [
        {
          deviceName: "",
          slaveOrIpAddressID: "",
          byteOrder: "0",
          wordOrder: "0",
          id: "first",
          registers: [
            {
              label: "",
              unit: "",
              reg_start: "",
              reg_type: "0",
              data_type: "1",
              access_mode: "2",
              byte_order: "0",
              word_order: "0",
              addr: "",
              value_id: "",
              is_single_bit_binary: 0,
              reg_Id: 0,
            },
          ],
        },
      ],
      ModbusDataArray: [],
      modNameValue: [],
      modbusTemplateName: "",
      modeName: "",
      modeValue: "",
      intervalValue: "",
    },
  };
  handleToNext = (event) => {
    const formInput = { ...this.state.formInput };
    if (formInput.radioSelect === false) {
      formInput.radiosMsg = true;
      this.setState({ formInput });
    } else {
      formInput.nextPage = true;

      formInput.selectMode = this.props.allSelectGateway?.items.find(
        (m) => m.id_iot_device === formInput.selectetGateway
      )?.protocol_enabled;

      this.setState({ formInput });
      this.props.nextSetTab();
    }
  };
  defaultValueGatewayValue = (formId) => {
    const formInput = { ...this.state.formInput };
    formInput.previousTab =
      this.props.previousTab === undefined
        ? formInput.previousTab
        : this.props.previousTab;
    formInput.previousSetTab =
      this.props.previousSetTab === undefined
        ? formInput.previousSetTab
        : this.props.previousSetTab;

    formInput.previousModbusTab =
      this.props.previousModbusTab === undefined
        ? formInput.previousModbusTab
        : this.props.previousModbusTab;
    formInput.previousRegisterTab =
      this.props.previousRegisterTab === undefined
        ? formInput.previousRegisterTab
        : this.props.previousRegisterTab;

    formInput.nextSetTab = this.props.nextSetTab;
    formInput.nextRegisterDevicesTab = this.props.nextRegisterDevicesTab;
    formInput.nextModbusDevicesTab = this.props.nextModbusDevicesTab;
    formInput.nextResult = this.props.nextResult;
    formInput.selectetGateway = this.props.formId;
    formInput.physicalDeviceIdGateway = this.props.physicalDeviceIdGateway;
    formInput.id_iot_device_Value = this.props.id_iot_device_Value;
    formInput.radioSelect =
      formInput.physicalDeviceIdGateway === "" ? false : true;
    formInput.selectMode = this.props.allSelectGateway?.items.find(
      (m) => m.id_iot_device === formInput.selectetGateway
    )?.protocol_enabled;
    formInput.modbusTemplateName = this.props.modbusTemplateName;
    formInput.modNameValue = this.props.modNameValue;
    formInput.modeName = this.props.modeName;
    formInput.modeValue = this.props.modeValue;
    formInput.intervalValue = this.props.intervalValue;
    formInput.ModbusFirstData =
      this.props.ModbusFirstData?.length === 0
        ? formInput.ModbusFirstData
        : this.props.ModbusFirstData;
    formInput.ModbusDataArray =
      this.props.ModbusDataArray?.length === 0
        ? formInput.ModbusDataArray
        : this.props.ModbusDataArray;
    this.setState({ formInput });
  };
  handleSelectGateway = (event) => {
    const formInput = { ...this.state.formInput };

    formInput.selectetGateway = event.target.id;
    formInput.radioSelect = true;
    formInput.radiosMsg = false;

    formInput.selectMode = this.props.allSelectGateway?.items.find(
      (m) => m.id_iot_device === formInput.selectetGateway
    )?.protocol_enabled;

    formInput.physicalDeviceIdGateway = this.props.allSelectGateway?.items.find(
      (m) => m.id_iot_device === formInput.selectetGateway
    ).id_physical_device;
    formInput.groupId = this.props.allSelectGateway?.items.find(
      (m) => m.id_iot_device === formInput.selectetGateway
    ).id_group;
    formInput.defaultValueGateway = this.props.allSelectGateway?.items.find(
      (m) => m.id_iot_device === formInput.selectetGateway
    );
    formInput.id_iot_device_Value = this.props.allSelectGateway?.items.find(
      (m) => m.id_iot_device === formInput.selectetGateway
    ).id_iot_device;
    // formInput.selectMode = this.props.gatewayInfo.find(
    //   (m) => m.id_iot_device === formInput.selectetGateway
    // )?.protocol_enabled;
    this.setState({ formInput });
  };

  handleToCancel = () => {
    // this.props.history.push("/modbus-gateways/card-view/1");
    // return this.props.history.push("/modbus-gateways/card-view/1");
    // const routeURL =
    //   window.location.pathname === "/modbus-gateways/list-view/1"
    //     ? "List"
    //     : "Card";
    const formInput = { ...this.state.formInput };
    formInput.modbusHome = true;
    // formInput.routeURLPath = routeURL;
    this.setState({ formInput });
  };

  handlePageChange = (event, value) => {
    this.props.getAllSelectGateway(this.props.projectId, value);
    // this.props.history.push("/bace-ep/card-view/1");
  };
  componentDidUpdate = (prevProps) => {
    const formInput = { ...this.state.formInput };
  };
  componentDidMount = () => {
    const formInput = { ...this.state.formInput };

    this.defaultValueGatewayValue();
  };

  render() {
    const formInput = this.state.formInput;
    formInput.groupId = this.props.allSelectGateway?.items.find(
      (m) => m.id_iot_device === formInput.selectetGateway
    )?.id_group;
    formInput.defaultValueGateway = this.props.allSelectGateway?.items.find(
      (m) => m.id_iot_device === formInput.selectetGateway
    );

    formInput.previousTab = this.props.previousTab;

    return (
      <div>
        {formInput.modbusHome ? (
          <div>
            <ModbusCardView />
          </div>
        ) : (
          <div>
            {formInput?.nextPage === true ? (
              <SetGatewayModbus
                gateWayInfo={this.props.allSelectGateway?.items}
                formId={formInput.selectetGateway}
                selectMode={formInput.selectMode}
                physicalDeviceIdGateway={formInput.physicalDeviceIdGateway}
                id_iot_device_Value={formInput.id_iot_device_Value}
                groupId={formInput.groupId}
                ModbusFirstData={formInput.ModbusFirstData}
                ModbusDataArray={formInput.ModbusDataArray}
                // nextModbusDevicesTab={this.props.nextModbusDevicesTab}
                // nextRegisterDevicesTab={this.props.nextRegisterDevicesTab}
                // nextResult={this.props.nextResult}
                previousTab={formInput.previousTab}
                previousSetTab={formInput.previousSetTab}
                previousModbusTab={formInput.previousModbusTab}
                previousRegisterTab={formInput.previousRegisterTab}
                modNameValue={formInput.modNameValue}
                modeName={formInput.modeName}
                modeValue={formInput.modeValue}
                nextSetTab={formInput.nextSetTab}
                nextRegisterDevicesTab={formInput.nextRegisterDevicesTab}
                nextModbusDevicesTab={formInput.nextModbusDevicesTab}
                nextResult={formInput.nextResult}
                modbusTemplateName={formInput.modbusTemplateName}
                intervalValue={formInput.intervalValue}
                projectId={this.props.projectId}
                // modNameValue={this.props.modNameValue}
              />
            ) : (
              <div>
                {this.props.allSelectGateway?.items.length > 0 ? (
                  <div>
                    {/*<FontAwesomeIcon
                      style={{ marginRight: "8px" }}
                      className="fa-lg driveModbus"
                      icon={faHardDrive}
                /> */}
                    <img
                      className="settingsIcon"
                      src={ModbusIconBlue}
                      title="modBus device"
                    />
                    <span className="modbusTitle">
                      {" "}
                      Select your BACE gateway
                    </span>
                    <div className="selectGateway">
                      <div className="row">
                        <div className="col-sm-3 col-md-6 col-lg-1"></div>
                        <div className="col-sm-3 col-md-6 col-lg-4 modbusTitle">
                          &nbsp;&nbsp; &nbsp;&nbsp;
                        </div>
                        <div className="col-sm-9 col-md-6 col-lg-7 modbusTitle">
                          &nbsp;&nbsp; &nbsp;&nbsp;
                        </div>
                      </div>{" "}
                      <div className="row">
                        <div className="col-sm-3 col-md-6 col-lg-1"></div>
                        <div className="col-sm-3 col-md-6 col-lg-4 modbusTitle">
                          Gateway label
                        </div>
                        <div className="col-sm-9 col-md-6 col-lg-7 modbusTitle">
                          Readiness
                        </div>
                      </div>
                      {this.props.allSelectGateway?.items.map((m, index) => (
                        <div class="tooltipSettingSelectGateway">
                          {!m.protocol_enabled?.includes("modbus-rtu") ||
                          !m.protocol_enabled?.includes("modbus-tcp") ||
                          m.protocol_received?.includes("modbus-rtu") ||
                          m.protocol_received?.includes("modbus-tcp") ? (
                            <div className="row" style={{ marginTop: "5px" }}>
                              <div className="col-sm-3 col-md-6 col-lg-1"></div>
                              <div className="col-sm-3 col-md-6 col-lg-4">
                                <a href="" className="link-button">
                                  <span>
                                    <FontAwesomeIcon
                                      className="fa-3x errorModbus waringMsg"
                                      icon={faTriangleExclamation}
                                    />
                                    <span className="tooltipstextSelectGateway tooltipsTemplateSelectGateway">
                                      <div>
                                        This gateway is already onboarded with
                                        an Modbus Asset, please go back and
                                        select configure
                                      </div>
                                    </span>
                                  </span>
                                </a>
                                <>
                                  <a
                                    href=""
                                    className="link-button"
                                    style={{
                                      color: "var(--Body, #313131)",
                                      textDecoration: "none",
                                    }}
                                  >
                                    &nbsp;&nbsp;
                                    <span className="waringMsg">
                                      {m?.label}
                                    </span>
                                  </a>
                                </>
                              </div>
                              <div className="col-sm-9 col-md-6 col-lg-7 ">
                                <a
                                  href=""
                                  className="link-button"
                                  style={{
                                    color: "var(--Body, #313131)",
                                    textDecoration: "none",
                                  }}
                                >
                                  {""}
                                  <span className="waringMsg">
                                    {m?.protocol_enabled !== null
                                      ? m?.protocol_enabled.length !== 0
                                        ? "Not ready for modbus"
                                        : "Not ready for modbus"
                                      : "Not ready for modbus"}
                                  </span>
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="row" style={{ marginTop: "5px" }}>
                              <div className="col-sm-3 col-md-6 col-lg-1"></div>
                              <div className="col-sm-3 col-md-6 col-lg-4">
                                <input
                                  type="radio"
                                  id={
                                    m.id_iot_device ||
                                    formInput.id_iot_device_Value
                                  }
                                  // value={formInput.id_iot_device_Value}
                                  name="headerNameDevice"
                                  checked={
                                    formInput.id_iot_device_Value ===
                                    m.id_iot_device
                                  }
                                  style={{ accentColor: "rgb(0, 0, 0)" }}
                                  // defaultChecked={
                                  //   formInput.id_iot_device_Value ||
                                  //   formInput.defaultValueGateway?.id_iot_device
                                  // }
                                  className="radioModbusOption"
                                  onChange={this.handleSelectGateway.bind(this)}
                                />
                                <>
                                  &nbsp;&nbsp;
                                  <span style={{ marginLeft: "14px" }}>
                                    {m?.label}
                                  </span>
                                </>
                              </div>
                              <div className="col-sm-9 col-md-6 col-lg-7">
                                {""}
                                {m?.protocol_enabled !== null
                                  ? m?.protocol_enabled.length !== 0
                                    ? "Ready for modbus"
                                    : "Ready for modbus"
                                  : "Ready for modbus"}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="row">
                      <div
                        className={
                          formInput.radiosMsg
                            ? "showMsgMode col-sm-9 col-md-6 col-lg-6"
                            : "col-sm-9 col-md-6 col-lg-6"
                        }
                      >
                        &nbsp;&nbsp; &nbsp;&nbsp;
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={
                          formInput.radiosMsg
                            ? "showMsgMode col-sm-9 col-md-6 col-lg-6"
                            : "hideMode col-sm-9 col-md-6 col-lg-6"
                        }
                      >
                        Please select a bace gateway
                      </div>
                    </div>{" "}
                    <div className="row">
                      <div className="col-sm-7 col-md-8 col-lg-7">
                        {" "}
                        <div className="row">
                          {this.props.allSelectGateway?._meta?.pageCount > 1 ? (
                            <div className="pageStyle">
                              <Pagination
                                count={
                                  this.props.allSelectGateway?._meta?.pageCount
                                }
                                page={
                                  this.props.allSelectGateway?._meta
                                    ?.currentPage
                                }
                                onChange={this.handlePageChange}
                              />
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-5 col-md-4 col-lg-5 belowModbusDeviceButton">
                        {" "}
                        <div className="nextButton">
                          <button
                            className="btn-cancel btn-secondary"
                            onClick={this.handleToCancel}
                          >
                            Cancel
                          </button>{" "}
                          <button
                            onClick={this.handleToNext}
                            style={{ marginTop: "0px" }}
                            className={
                              formInput.radioSelect
                                ? "btn btn-primary buttonView modbusButtonMovegeteway"
                                : "btn btn-secondary buttonView modbusButtonMovegeteway submitDisableButton"
                            }
                          >
                            Next {">"} Set gateway
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    You don’t have any gateways that haven’t been onboarded.
                    Please configure existing gateways or add a new gateway to
                    this project
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { allSelectGateway: state.modbusViewReducer.allSelectGateway };
}

export default connect(mapStateToProps, {
  getAllSelectGateway,
  clearGateWayData,
})(SelectGatewayModbus);
