import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Switch from "@material-ui/core/Switch";
import LoaderRoller from "../../components/loader/LoaderRoller";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNetworkWired,
  faChartLine,
  faPlusCircle,
  faSliders,
  faTimesCircle,
  faTimes,
  faCheckCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import { settingsEpViewChange } from "../../actions/view-history";
import "./style.css";
import {
  getAllWebhooksOverview,
  clearNewWebhook,
  updateWebhook,
  clearEditWebhook,
  webhookEpViewChange,
  getWebhooksInfo,
  enableDisableWebhook,
  clearEnableDisableWebhook,
} from "../../actions/session-view";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import WebhookInfoSubmenu from "../../components/menus/sub-menu/subtypes/WebhookInfoSubmenu";
import WebhookInfoSubtab from "./WebhookInfoSubtab";

class WebhookInfo extends Component {
  state = {
    formInput: {
      enableDisableClick: false,
      diableValue: "",
      webhookId: "",
      disableEnableValue: "",
      enableDiableUpdate: false,
      webhookName: "",
    },
  };

  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;

    if (!currentPageNr) {
      return this.props.history.push(
        `/webhook/info/1/${this.props.match.params?.projectId}/${this.props.match.params?.webhookId}`
      );
    }

    this.props.clearEditWebhook();
    this.props.getAllWebhooksOverview(this.props.match.params?.projectId);
    this.props.getWebhooksInfo(this.props.match.params?.webhookId);
    const updatedWebhookView = this.props.webhooksOverviewData.items.filter(
      (o) => o.id_webhook === this.props.match.params?.webhookId
    )[0];
  };

  toggleEnableDiableChange = (event, webhookId, webhookName) => {
    const formInput = { ...this.state.formInput };
    formInput.enableDisableClick = true;
    formInput.diableValue = event === true ? false : true;
    formInput.webhookId = webhookId;
    formInput.webhookName = webhookName;
    this.setState({ formInput });
  };

  handleToConnectionClose = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.enableDisableClick = false;
    formInput.diableValue = "";
    this.setState({ formInput });
  };

  handleToChangeEnableDisable = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.disableEnableValue =
      formInput.diableValue === true ? null : Date.now();
    formInput.enableDiableUpdate = true;
    formInput.enableDisableClick = false;
    this.setState({ formInput });
    this.props.clearEnableDisableWebhook();
    this.props.enableDisableWebhook(
      formInput.webhookId,
      formInput.disableEnableValue
    );
  };

  handleToEnableDisableUpdate = () => {
    if (this.props.enableDisableUpdated?.status === 200) {
      this.props.getWebhooksInfo(this.props.match.params?.webhookId);
    }
    const formInput = { ...this.state.formInput };
    formInput.enableDisableClick = false;
    formInput.diableValue = "";
    formInput.webhookId = "";
    formInput.disableEnableValue = "";
    formInput.enableDiableUpdate = false;

    this.setState({ formInput });
  };
  componentWillUnmount = () => {};

  render() {
    const formInput = this.state.formInput;
    formInput.projectId = this.props.match.params?.projectId;
    const updatedWebhookDetails = this.props.webhooksOverviewData.items.filter(
      (o) => o.id_webhook === this.props.match.params?.webhookId
    )[0];

    return (
      <div>
        {formInput.enableDiableUpdate && (
          <Dialog
            open={formInput.enableDiableUpdate === true ? true : false}
            onClose={this.handleToEnableDisableUpdate}
          >
            <div onClick={this.handleToEnableDisableUpdate}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.handleToEnableDisableUpdate}
              />
            </div>
            <DialogTitle>
              {this.props.enableDisableUpdated?.status === 200 ? (
                <p className="wififailedMessage" style={{ display: "flex" }}>
                  <div style={{ fontWeight: "600", marginRight: "9px" }}>
                    {" "}
                    {formInput.webhookName}
                  </div>{" "}
                  webhook is{"    "}
                  {formInput.diableValue === true ? "enabled" : "disabled"}{" "}
                  sucessfully !
                </p>
              ) : (
                <LoaderRoller />
              )}
            </DialogTitle>
          </Dialog>
        )}
        {formInput.enableDisableClick && (
          <Dialog
            open={formInput.enableDisableClick === true ? true : false}
            onClose={this.handleToConnectionClose}
          >
            <div onClick={this.handleToConnectionClose}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.handleToConnectionClose}
              />
            </div>
            <DialogTitle>
              <p className="wififailedMessage">
                Do you want to{" "}
                {formInput.diableValue === true ? "enable" : "disable"} this
                webhook?
              </p>

              <div className="col pb-3">
                <div className="d-flex justify-content-end">
                  <button
                    className={
                      formInput.setApplyConfirm
                        ? "btn-cancel mx-1 cancelTemplate"
                        : "btn-cancel mx-1"
                    }
                    onClick={this.handleToConnectionClose}
                  >
                    Cancel
                  </button>{" "}
                  <button
                    className={
                      formInput.setApplyConfirm
                        ? "btn-cancel submitDisableButton"
                        : "btn-cancel submitButton"
                    }
                    onClick={() => this.handleToChangeEnableDisable()}
                  >
                    <Fragment>Confirm</Fragment>
                    {/* )} */}
                  </button>
                </div>
              </div>
            </DialogTitle>
          </Dialog>
        )}
        <WebhookInfoSubmenu webhookName={this.props.webhookInfoData?.label} />
        <WebhookInfoSubtab />

        <div className="bacecard-width">
          {this.props.webhookInfoData !== undefined &&
          this.props.webhookInfoData !== null ? (
            <div>
              <div
                className={`${
                  this.props.webhookInfoData?.disabled_at === null
                    ? "connected-device infoPage"
                    : "disconnected-device infoPage"
                }`}
              >
                <div
                  className={`${
                    this.props.webhookInfoData?.disabled_at === null
                      ? "connected-Status"
                      : "disconnected-Status"
                  }`}
                >
                  <span
                    className="card-key text-white"
                    style={{ fontWeight: "500", marginRight: "112px" }}
                  >
                    Status
                  </span>
                  <div className="text-white" style={{ fontWeight: "600" }}>
                    {this.props.webhookInfoData.disabled_at === null ? (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={true}
                            color="primary"
                            className="switchInfo"
                            onChange={(e) =>
                              this.toggleEnableDiableChange(
                                true,
                                this.props.webhookInfoData?.id_webhook,
                                this.props.webhookInfoData?.label
                              )
                            }
                            value={true}
                          />
                        }
                        label="Enabled"
                      />
                    ) : (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={false}
                            onChange={(e) =>
                              this.toggleEnableDiableChange(
                                false,
                                this.props.webhookInfoData?.id_webhook,
                                this.props.webhookInfoData?.label
                              )
                            }
                            value={false}
                          />
                        }
                        label="Disabled"
                      />
                    )}
                  </div>
                </div>
                <div className="cardViewDetail">
                  {" "}
                  <div className="card-titles">Label</div>
                  <div className="card-keyLabel  text-capitalize text-wrap">
                    {this.props.webhookInfoData?.label ? (
                      this.props.webhookInfoData?.label
                    ) : (
                      <div className="mb-4"></div>
                    )}
                  </div>
                  <div className="card-titles"> Forwarding Type</div>
                  <div className="card-keyLabel  text-capitalize text-wrap">
                    {this.props.webhookInfoData?.forwarding_type === 1
                      ? "Measurements"
                      : "Events"}
                  </div>
                  <div className="card-titles">
                    {" "}
                    Webhook Implementation Type
                  </div>
                  <div className="card-keyLabel  text-capitalize text-wrap">
                    {this.props.webhookInfoData?.formatter === "v1"
                      ? "Default verbose format"
                      : this.props.webhookInfoData?.formatter === "v2a"
                      ? "compact individual and aggregated data"
                      : this.props.webhookInfoData?.formatter === "v2b"
                      ? "compact only aggregated data"
                      : this.props.webhookInfoData?.formatter === "v3a"
                      ? "Verbose format with group/device metadata as objects"
                      : "Verbose format with group/device metadata as arrays"}
                  </div>
                  <div className="card-titles"> Webhook URL</div>
                  <div className="card-keyLabel  text-capitalize text-wrap">
                    {this.props.webhookInfoData?.webhook_url}
                  </div>
                  <div className="card-titles"> Authorization</div>
                  <div className="card-keyLabel  text-capitalize text-wrap">
                    {this.props.webhookInfoData?.authorization}
                  </div>
                  <div className="card-titles"> Timeout</div>
                  <div className="card-keyLabel  text-capitalize text-wrap">
                    {this.props.webhookInfoData?.timeout}
                  </div>
                  <div className="card-titles"> Created at</div>
                  <div className="card-keyLabel  text-capitalize text-wrap">
                    {this.props.webhookInfoData?.created_at === null ? (
                      "N/A"
                    ) : (
                      <Moment format="DD MMM YYYY HH:mm A">
                        {this.props.webhookInfoData?.created_at * 1000}
                      </Moment>
                    )}
                  </div>
                  <div className="card-titles"> Created by</div>
                  <div className="card-keyLabel  text-capitalize text-wrap">
                    {this.props.webhookInfoData?.creator?.email}
                  </div>
                  <div className="card-titles"> Updated at</div>
                  <div className="card-keyLabel  text-capitalize text-wrap">
                    {this.props.webhookInfoData?.updated_at === null ? (
                      "N/A"
                    ) : (
                      <Moment format="DD MMM YYYY HH:mm A">
                        {this.props.webhookInfoData?.updated_at * 1000}
                      </Moment>
                    )}
                  </div>
                  <div className="card-titles"> Updated by</div>
                  <div className="card-keyLabel  text-capitalize text-wrap">
                    {this.props.webhookInfoData?.updater?.email}
                  </div>
                  <div className="card-titles"> Last start </div>
                  <div className="card-keyLabel  text-capitalize text-wrap">
                    {this.props.webhookInfoData?.last_start === null ? (
                      "N/A"
                    ) : (
                      <Moment format="DD MMM YYYY HH:mm A">
                        {this.props.webhookInfoData?.last_start * 1000}
                      </Moment>
                    )}
                  </div>
                  <div className="card-titles"> Last success</div>
                  <div className="card-keyLabel  text-capitalize text-wrap">
                    {this.props.webhookInfoData?.last_success === null ? (
                      "N/A"
                    ) : (
                      <Moment format="DD MMM YYYY HH:mm A">
                        {this.props.webhookInfoData?.last_success * 1000}
                      </Moment>
                    )}
                  </div>
                  <div className="card-titles"> Last finish</div>
                  <div className="card-keyLabel  text-capitalize text-wrap">
                    {this.props.webhookInfoData?.last_finish === null ? (
                      "N/A"
                    ) : (
                      <Moment format="DD MMM YYYY HH:mm A">
                        {this.props.webhookInfoData?.last_finish * 1000}
                      </Moment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Fragment>
              <div className="bacecontainer-lg">
                <LoaderRoller />
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    webhooksOverviewData: state.sessionReducer.webhooksOverviewData,
    lastWebhookEpViewSelected: state.sessionReducer.lastWebhookEpViewSelected,
    webhookInfoData: state.sessionReducer.webhookInfoData,
    enableDisableUpdated: state.sessionReducer.enableDisableUpdated,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    clearEditWebhook,
    getAllWebhooksOverview,
    webhookEpViewChange,
    getWebhooksInfo,
    enableDisableWebhook,
    clearEnableDisableWebhook,
  })(WebhookInfo)
);
