import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import DownloadIcon from "../icons/DownloadIcon";

import { closeExport, exportFiles } from "../../actions/screen-activities";
import { showWarning, showInfo } from "../../actions/notification";
import { getDeviceData, clearDeviceData } from "../../actions/list-view";

import moment from "moment";

import "./main.css";

import CONFIG from "../../config";
import { Fragment } from "react";
const { baseUrl } = CONFIG;

const initialState = {
  dateFrom: 0,
  dateTo: 0,
  defaultFrom: 0,
  defaultTo: 0,
  containerDataLatestIdsArr: [],
  downloadingFile: false,
};

class ExportPopup extends Component {
  state = initialState;

  componentDidMount = () => {
    const { id } = this.props.match.params;
    this.props.getDeviceData(id);
    const dateFrom = moment(+this.props.firstTimestamp).format("YYYY-MM-DD");
    const dateTo = moment(+this.props.lastTimestamp).format("YYYY-MM-DD");
    this.setState({
      ...this.state,
      dateFrom,
      dateTo,
      defaultFrom: dateFrom,
      defaultTo: dateTo,
      containerDataLatestIdsArr: this.props.datatypes.map(
        (datatype) => datatype.id_container_data_latest
      ),
    });
  };

  componentWillUnmount = () => {
    this.props.clearDeviceData();
  };

  changeDataCheckbox = (idContainerDataLatest) => {
    if (this.state.containerDataLatestIdsArr.includes(idContainerDataLatest)) {
      this.setState({
        ...this.state,
        containerDataLatestIdsArr: this.state.containerDataLatestIdsArr.filter(
          (id_container_data_latest) =>
            id_container_data_latest !== idContainerDataLatest
        ),
      });
      return;
    }
    this.setState({
      ...this.state,
      containerDataLatestIdsArr: [
        ...this.state.containerDataLatestIdsArr,
        idContainerDataLatest,
      ],
    });
  };

  dateChanged = ({ input, value }) => {
    if (input === "DATE_FROM") {
      if (value <= this.state.dateTo && value >= this.state.defaultFrom) {
        this.setState({
          ...this.state,
          dateFrom: value,
        });
        return;
      }

      if (value < this.state.defaultFrom) {
        this.props.showWarning({
          body: `First datapoint found ${this.state.defaultFrom}.`,
        });
        this.setState({
          ...this.state,
          dateFrom: this.state.defaultFrom,
        });
        return;
      }

      if (value > this.state.dateTo) {
        this.props.showWarning({
          body: "Date from can not be after date to.",
        });
        return;
      }
    }

    // "DATE_TO"
    if (value >= this.state.dateFrom && value <= this.state.defaultTo) {
      this.setState({
        ...this.state,
        dateTo: value,
      });
      return;
    }

    if (value > this.state.defaultTo) {
      this.props.showWarning({
        body: `Last datapoint found ${this.state.defaultTo}.`,
      });
      this.setState({
        ...this.state,
        dateTo: this.state.defaultTo,
      });
      return;
    }

    if (value < this.state.dateFrom) {
      this.props.showWarning({
        body: "Date from can not be after date to.",
      });
      return;
    }
  };

  readStream = (dateFrom, dateTo, containerDataLatestIdsArr) => {
    const from_date = moment(new Date(dateFrom)).format("DD-MM-YYYY"),
      to_date = moment(new Date(dateTo)).format("DD-MM-YYYY");
    this.props.showInfo({
      body: "Please wait, preparing raw data file.",
    });
    this.setState({
      ...this.state,
      downloadingFile: true,
    });
    // Get raw data from Azure Data Lake
    fetch(`${baseUrl}/data/raw`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userToken}`,
      },
      // redirect: 'follow',
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        from_date: from_date,
        to_date: to_date,
        id_container_data_arr: containerDataLatestIdsArr,
      }),
    })
      .then((resp) => {
        if (resp.status === 200) {
          let filename = "";
          const dispositionHeader = resp.headers.get("Content-Disposition");
          if (dispositionHeader) {
            const parts = dispositionHeader.split(";");
            filename = decodeURI(parts[1].trim().split("*=UTF-8''")[1]);
          } else {
            filename = "raw-data.zip";
          }
          resp
            .blob()
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
              this.setState({
                ...this.state,
                downloadingFile: false,
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                ...this.state,
                downloadingFile: false,
              });
            });
        } else {
          this.props.showWarning({
            body: "Something went wrong with raw data download. Please try again.",
          });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div className="export-popup">
        <div className="export-popup-container">
          <h4 className="mt-3 ms-3" style={{ color: "black" }}>
            Export
          </h4>
          <div className="bacecard mx-3 my-3 p-3" style={{ color: "black" }}>
            <div className="col">
              <h6>Date range</h6>
              <div className="row">
                <div className="col-6">
                  <input
                    type="date"
                    className="form-control"
                    value={this.state.dateFrom}
                    onChange={(e) =>
                      this.dateChanged({
                        input: "DATE_FROM",
                        value: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-6">
                  <input
                    type="date"
                    className="form-control"
                    max={this.state.dateTo}
                    value={this.state.dateTo}
                    onChange={(e) =>
                      this.dateChanged({
                        input: "DATE_TO",
                        value: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <h6 className="mt-3">Type of data</h6>
              {this.props.datatypes.map((datatype) => (
                <div className="form-check" key={datatype.datatype}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.containerDataLatestIdsArr.includes(
                      datatype.id_container_data_latest
                    )}
                    onChange={() =>
                      this.changeDataCheckbox(datatype.id_container_data_latest)
                    }
                  />
                  <label className="form-check-label" htmlFor="dataCheckbox">
                    {datatype.label}
                  </label>
                </div>
              ))}

              <h6 className="mt-3">Format</h6>
              <div className="form-check">CSV</div>
            </div>
          </div>
          <div className="col pb-3">
            <div className="d-flex justify-content-end">
              <button
                className="btn-cancel mx-1"
                onClick={this.props.closeExport}
              >
                Cancel
              </button>
              <button
                disabled={this.state.downloadingFile ? true : false}
                className="btn-export me-3 ms-1"
                onClick={() =>
                  this.readStream(
                    this.state.dateFrom,
                    this.state.dateTo,
                    this.state.containerDataLatestIdsArr
                  )
                }
              >
                {this.state.downloadingFile ? (
                  <Fragment>
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    Downloading
                  </Fragment>
                ) : (
                  <Fragment>
                    <DownloadIcon color="#fff" /> Export
                  </Fragment>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userToken: state.userReducer?.token?.token,
    firstTimestamp: state.chartViewReducer?.groupData?.firstTimestamp,
    lastTimestamp: state.chartViewReducer?.groupData?.lastTimestamp,
    datatypes: state.chartViewReducer?.groupDatatypes,
    groupName: state.listViewReducer?.deviceViewData?.label,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    closeExport,
    exportFiles,
    showWarning,
    showInfo,
    getDeviceData,
    clearDeviceData,
  })(ExportPopup)
);
