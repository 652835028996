import CONFIG from "../config";

const {
  defaultBaceEpView,
  defaultModbusEpView,
  defaultSettingsEpView,
  defaultActivityMonitorsView,
  defaultWebhookEpView
} = CONFIG.defaultViews;

const initialState = {
  lastBaceEpViewSelected: defaultBaceEpView,
  lastModbusEpViewSelected: defaultModbusEpView,
  lastSettingsEpViewSelected: defaultSettingsEpView,
  lastWebhookEpViewSelected: defaultWebhookEpView,
  lastActivityMonitorsViewSelected: defaultActivityMonitorsView,
};

export default function viewHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case "BACE_EP_VIEW_CHANGED": {
      return {
        ...state,
        lastBaceEpViewSelected: action.viewOption,
      };
    }

    case "MODBUS_EP_VIEW_CHANGED": {
      return {
        ...state,
        lastModbusEpViewSelected: action.viewOption,
      };
    }

    case "SETTINGS_EP_VIEW_CHANGED": {
      return {
        ...state,
        lastSettingsEpViewSelected: action.viewOption,
      };
    }

    case "ACTIVITY_MONITORS_VIEW_CHANGED": {
      return {
        ...state,
        lastActivityMonitorsViewSelected: action.viewOption,
      };
    }
    default: {
      return state;
    }
  }
}
