import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import baseline_person_outline_black_24dp from "../../../assets/Icons/baseline_person_outline_black_24dp.png";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import {
  openUserPopup,
  closeUserPopup,
} from "../../../actions/screen-activities";
import "../style.css";
import UserPopUp from "./UserPopUp";
import { faBookOpen } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import { faBookOpenAlt, faUserAlien } from "@fortawesome/pro-solid-svg-icons";

class TopMenu extends Component {
  componentDidMount = () => {};
  render() {
    const IconView = window.location.pathname;
    return (
      <Fragment>
        <div className="topDivView">
          {" "}
          <div className="top-menu">
            <div className="top-menu-container d-flex justify-content-end ">
              {/* <span> */}

              <Link
                to="/api-dashboard/ApiDashboard"
                className="specs-key-low clickable"
              >
                {/* <FontAwesomeIcon
                icon={faBookOpen}
                style={{
                  height: "25px",
                  width: "20px",
                  margin: "8px 12px",
                  //color: "#fff",
                }}
                className={IconView === "/" ? "iconWhite" : "iconBlack"}
              /> */}
                <FontAwesomeIcon
                  icon={faBookOpen}
                  style={{
                    height: "25px",
                    width: "20px",
                    margin: "8px 12px",
                    //color: "#fff",
                  }}
                  className={IconView === "/" ? "iconWhite" : "iconBlack"}
                />
              </Link>
              {/* </span> */}
              <NotificationsNoneOutlinedIcon
                // className="alretIcon"
                style={{ margin: "8px 12px" }}
                className={
                  IconView === "/"
                    ? "iconWhite alretIcon"
                    : "iconBlack alretIcon"
                }
              />
              <button
                className="btn"
                onClick={
                  this.props.topUserPopup
                    ? this.props.closeUserPopup
                    : this.props.openUserPopup
                }
              >
                <FontAwesomeIcon
                  style={{ fontSize: "18px" }}
                  icon={faUser}
                  className={IconView === "/" ? "iconWhite" : "iconBlack"}
                />
              </button>
            </div>
          </div>
          <UserPopUp />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userEmail: state.userReducer?.user?.email,
    topUserPopup: state.screenActivitiesReducer?.topUserPopup,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    openUserPopup,
    closeUserPopup,
  })(TopMenu)
);
