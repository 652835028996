import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import TopMenu from "../../../../components/menus/top-menu/TopMenu";
class ModbusGatewaysSubmenu extends Component {
  render() {
    // const connectedSensors = this.props.latestData.filter(
    //   (sensorData) => sensorData?.latest_data?.length
    // );
    return (
      <div className="row">
        <div style={{ display: "flex" }}>
          <h3 className="p-3 color-ocean-blue modbusDevice">Modbus Devices</h3>

          <TopMenu />
        </div>
        <div className="mb-3 switch-btn-line"></div>
        {/* <Link
              to="/modbus-gateways"
              className={` ${
                this.props.location.pathname === "/modbus-gateways"
                  ? "bacelink-active"
                  : "bacelink"
              }`}
            >
              <DashboardIcon
                style={{ marginRight: "4", transform: "scale(0.7)" }}
              />
              Card
            </Link> */}
        {/* <Link
              to="/modbus-gateways/list-view"
              className={` ${
                this.props.location.pathname.includes("list-view")
                  ? "bacelink-active"
                  : "bacelink"
              }`}
            >
              <FontAwesomeIcon
                style={{ marginRight: "8px" }}
                icon={faList}
                size="sm"
              />
              List
            </Link> */}
        {/* <Link
              to="/subtype-1/map-view"
              className={` ${
                this.props.location.pathname === "/subtype-1/map-view"
                  ? "bacelink-active"
                  : "bacelink"
              }`}
            >
              <FontAwesomeIcon
                style={{ marginRight: "8px" }}
                icon={faMapMarked}
                size="sm"
              />
              Map
            </Link> */}
        {/* <div className="align-right">
              <span className="text-alert submenu-text-bold">
                {this.props.nrResults}
              </span>
              <span className="text-alert submenu-text">
                result{this.props.nrResults === 1 ? "" : "s"}
              </span>
            </div> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    //latestData: state.groupReducer?.latestData,
  };
}

export default withRouter(connect(mapStateToProps)(ModbusGatewaysSubmenu));
