import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./main.css";
import { Fragment } from "react";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import { getConfirmTemplate, connectWifiOption } from "../../actions/list-view";
import { data } from "autoprefixer";
import {
  faWifiWeak,
  faWifiFair,
  faTimes,
  faWifiStrong,
  faWifiSlash,
  faEthernet as faEthernetSlash,
} from "@fortawesome/pro-duotone-svg-icons";

class SetWifiPopup extends Component {
  state = {
    formInput: {
      setTemplate: "",
      physicalDeviceId: "",
      wifiPassword: false,
      wifiId: "",
      wifiSsid: "",
      password: "",
    },
  };
  handleToConfirm = (ssid) => {
    const formInput = { ...this.state.formInput };
    formInput.physicalDeviceId = this.props.physicalDeviceId;
    formInput.wifiSsid = ssid;
    this.setState({ formInput });
    this.props.connectWifiOption(formInput);
  };

  handleChangeTemplate = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.setTemplate = event.target.id;
    this.setState({ formInput });
  };

  handleWifiOption = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.wifiPassword = true;
    formInput.wifiId = event.target.id;
    this.setState({ formInput });
  };

  handleToCancelWifiOption = () => {
    const formInput = { ...this.state.formInput };
    formInput.wifiPassword = false;
    this.setState({ formInput });
  };

  handlePassword = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput.password = input.value;
    this.setState({ formInput });
  };
  render() {
    //const { formInput } = this.state;
    return (
      <div className="template-popup">
        <div className="template-popup-wifi-container">
          <button className="cancelViewIcon cancelWifi">
            <FontAwesomeIcon
              className="svg-icons-sm wifiView"
              data-toggle="tooltip"
              data-placement="top"
              icon={faTimes}
              onClick={this.props.closePopup}
            />
          </button>
          <h4 className="mt-3 ms-3 wifiText">
            SSID
            <div className="tooltipSetting">
              <FontAwesomeIcon
                className="fa-1x questionWifiIcon"
                icon={faCircleQuestion}
              />
              <span className="tooltipsSettingText tooltipsTemplate">
                <div>Select the WiFi Access Point you want to connect.</div>
              </span>
            </div>
          </h4>
          <div className="wifiList">
            {this.props.templateListOptions.map((dataList) => (
              <div style={{ display: "flex" }}>
                <div>
                  {dataList.signal >= 0 && dataList.signal <= 33 ? (
                    <FontAwesomeIcon
                      className="svg-icons-sm wifiView"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Weak"
                      icon={faWifiWeak}
                    />
                  ) : dataList.signal >= 33 && dataList.signal <= 68 ? (
                    <FontAwesomeIcon
                      className="svg-icons-sm wifiView"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Fair"
                      icon={faWifiFair}
                    />
                  ) : dataList.signal >= 69 && dataList.signal <= 100 ? (
                    <FontAwesomeIcon
                      className="svg-icons-sm wifiView"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Strong"
                      icon={faWifiStrong}
                    />
                  ) : dataList.signal === 0 || dataList.signal < 0 ? (
                    <FontAwesomeIcon
                      className="svg-icons-sm wifiView"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="No Connection"
                      icon={faWifiSlash}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {/* <div>{dataList.signalStrength}</div> */}
                <div className="wifiNameView">
                  <button
                    className="wifiOption"
                    id={dataList.macAddress}
                    onClick={this.handleWifiOption.bind(this)}
                  >
                    {" "}
                    {dataList.ssid !== "" ? dataList.ssid : dataList.macAddress}
                  </button>
                  <div
                    id={dataList.macAddress}
                    className={
                      this.state.formInput.wifiPassword === true &&
                      this.state.formInput.wifiId === dataList?.macAddress
                        ? "wifiPasswordShow"
                        : "wifiPasswordHide"
                    }
                  >
                    <input
                      className="form-control-search formInputWifi wifiConfirmbutton"
                      defaultValue={this.state.formInput.password}
                      name="Password"
                      type="password"
                      onChange={this.handlePassword}
                      placeholder="Password"
                    />
                    <div className="col pb-3 wifiConfirmbutton">
                      <div className="d-flex ">
                        <button
                          className="btn-cancel mx-1"
                          onClick={this.handleToCancelWifiOption}
                        >
                          Cancel
                        </button>{" "}
                        <button
                          className="btn-cancel justify-content-end submitButton"
                          onClick={() => this.handleToConfirm(dataList?.ssid)}
                        >
                          <Fragment>Connect</Fragment>
                          {/* )} */}
                        </button>
                      </div>
                      <hr></hr>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    settingslistConfirmTemplateOption:
      state.listViewReducer.settingslistConfirmTemplateOption,
    setWifiOption: state.listViewReducer.setWifiOption,
  };
}

export default withRouter(
  connect(mapStateToProps, { getConfirmTemplate, connectWifiOption })(
    SetWifiPopup
  )
);
