import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import { connect } from "react-redux";
import { withRouter } from "react-router";

class PaginationNew extends Component {
  render() {
    if (this.props.listViewData?._meta?.pageCount > 1) {
      const paginationSize = this.props.paginationSize || 2;

      const { pathname } = this.props.location;
      const { currentPageNr } = this.props.match.params;
      const allPageNrArray = Array.from({length: this.props.listViewData?._meta?.pageCount}, (_, i) => i + 1);
      let pageNrArray = allPageNrArray
        .filter(page => {
          return +(+currentPageNr - +paginationSize <= +page &&  +currentPageNr + +paginationSize >= +page);
        });
      
      if (+this.props.listViewData?._meta?.pageCount > 6) {
        if (!pageNrArray.includes(1)) {
          pageNrArray = [1, ...pageNrArray];
        }
        if (!pageNrArray.includes(+this.props.listViewData?._meta?.pageCount)) {
          pageNrArray = [...pageNrArray, +this.props.listViewData?._meta?.pageCount];
        }
      }
      const baseRoute = currentPageNr ? (
        pathname.substring(0, pathname.lastIndexOf("/"))
      ) : (
        pathname
      );

      const sortColumn = new URLSearchParams(this.props.location.search).get("sortColumn");
      const sortDirection = new URLSearchParams(this.props.location.search).get("sortDirection");

      return (
        <nav>
          <ul className={`${
            (this.props.justify && this.props.justify === 'start') ? (
              'justify-content-start'
            ) : (
              'justify-content-end'
            )
          } pagination m-1 mt-3`}>
            {pageNrArray.map(pageNr => (
              <li 
                className={`page-item ${+pageNr === +currentPageNr ? "active" : ""}`}
                key={pageNr}
              >
                <Link 
                  to={`${baseRoute}/${pageNr}${(sortColumn && sortDirection) ? (
                      `?sortColumn=${sortColumn}&sortDirection=${sortDirection}`
                    ) : ("")
                  }`} 
                  className="page-link"
                >
                  {pageNr}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    listViewData: state.listViewReducer.listViewData,
  };
}

export default withRouter(connect(mapStateToProps, { })(PaginationNew));
