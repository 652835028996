import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ModbusGatewaysSubmenu from "../../components/menus/sub-menu/subtypes/ModbusGatewaysSubmenu";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBrowser,
  faListUl,
  faSliders,
  faHome,
} from "@fortawesome/pro-regular-svg-icons";
import { modbusEpViewChange } from "../../actions/view-history";
import { logDOM } from "@testing-library/react";
import ModbusCardView from "./ModbusCardView";
import EditModbusDevices from "../../components/modbus/configureModbus/EditModbusDevices";
import ModbusListView from "./ModbusListView";
import ModbusCancelPage from "./ModbusMovePage";
import {
  getAllModbusDevices,
  clearApplyModbus,
} from "../../actions/modbus-view";
import LoaderRoller from "../../components/loader/LoaderRoller";
class ModbusGatewayEdit extends Component {
  state = {
    formInput: {
      selectModbusGateway: false,
      modbusHome: false,
      registerModel: false,
      homeModel: false,
      modbusModel: true,
      routeURLPath: "",
      modbusNumber: "",
    },
  };

  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;
    const projectId = window.location.pathname.slice(31);
    const modbusNum = window.location.pathname.slice(29, 32);
    if (!currentPageNr) {
      return this.props.history.push(
        `/modbus-gateways/config-view/1/${this.props.match.params.projectId}`
      );
    }
    this.props.clearApplyModbus();
    this.props.getAllModbusDevices(projectId);
  };
  handleModbusPage = () => {
    const modbusGatewayInfo = this.props.modbusGatewayInfo;
    return this.props.history.push(
      `/modbus-gateways/card-view/1/${this.props.match.params.projectId}`
    );
    //this.setState({ formInput });
  };
  render() {
    const formInput = { ...this.state.formInput };

    return (
      <div>
        {formInput.modbusHome ? (
          <div>
            <ModbusCancelPage />
          </div>
        ) : (
          <div>
            {this.props.allModbusDevices?.items.length > 0 ? (
              <div>
                <ModbusGatewaysSubmenu nrResults={9} />
                <div>
                  <ul
                    className="nav nav-tabs tabModbus"
                    id="ex1"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className={
                          formInput.homeModel
                            ? "nav-link linkView active activeTabModbus"
                            : "nav-link linkView InactiveTabModbus"
                        }
                        id="ex1-tab-1"
                        data-mdb-toggle="tab"
                        onClick={this.handleModbusPage}
                        role="tab"
                        aria-controls="ex1-tabs-1"
                        aria-selected="false"
                      >
                        <FontAwesomeIcon
                          className="svg-icons-sm fa-sm"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="home"
                          icon={faHome}
                        />
                      </button>
                    </li>
                    <li className="nav-item arrowItem" role="presentation">
                      {">"}
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={
                          formInput.modbusModel
                            ? "nav-link linkView active activeTabModbus"
                            : "nav-link linkView InactiveTabModbus"
                        }
                        id="ex1-tab-2"
                        data-mdb-toggle="tab"
                        //  onClick={() => this.handleTab2("ex1-tab-2")}
                        role="tab"
                        aria-controls="ex1-tabs-2"
                        aria-selected="true"
                      >
                        Set Modbus devices
                      </button>
                    </li>
                    <li className="nav-item arrowItem" role="presentation">
                      {">"}
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={
                          formInput.registerModel
                            ? "nav-link linkView active activeTabModbus"
                            : "nav-link linkView InactiveTabModbus"
                        }
                        id="ex1-tab-3"
                        data-mdb-toggle="tab"
                        // onClick={() => this.handleTab3("ex1-tab-3")}
                        role="tab"
                        aria-controls="ex1-tabs-3"
                        aria-selected="false"
                      >
                        Add registers
                      </button>
                    </li>
                  </ul>
                  <div className="mb-3 switch-modbus-line"></div>
                  <div className="tab-content" id="ex1-content">
                    <div
                      className={
                        formInput.Tab1
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      //class="tab-pane fade show active"
                      id="ex1-tabs-1"
                      role="tabpanel"
                      aria-labelledby="ex1-tab-1"
                    ></div>{" "}
                    <div style={{ padding: "30px" }}>
                      <EditModbusDevices
                        modbusGatewayInfo={
                          this.props.allModbusDevices?.items[
                            this.props.match.params?.currentPageNr
                          ]?.currentModbusTemplate
                        }
                        modbusSubDevicesInfo={
                          this.props.allModbusDevices?.items[
                            this.props.match.params?.currentPageNr
                          ]?.subDevices
                        }
                        modbusGroupId={
                          this.props.allModbusDevices?.items[
                            this.props.match.params?.currentPageNr
                          ]?.id_group
                        }
                        modbusPhysicalDeviceId={
                          this.props.allModbusDevices?.items[
                            this.props.match.params?.currentPageNr
                          ]?.id_physical_device
                        }
                        handleModbusPage={this.handleModbusPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <LoaderRoller />
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { allModbusDevices: state.modbusViewReducer.allModbusDevices };
}

export default withRouter(
  connect(mapStateToProps, {
    modbusEpViewChange,
    getAllModbusDevices,
    clearApplyModbus,
  })(ModbusGatewayEdit)
);
