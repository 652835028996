import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import ReactApexChart from "react-apexcharts";
import LoaderRoller from "../../components/loader/LoaderRoller";
import Moment from "react-moment";

import { Link } from "react-router-dom";
import OneBaceEpSubmenu from "../../components/menus/sub-menu/subtypes/OneBaceEpSubmenu";
import {
  getDeviceData,
  clearDeviceData,
  getDeviceInfoData,
  getDeviceEventInfoData,
  clearListViewData,
  getAllProjectData,
  getAllChartData,
} from "../../actions/list-view";
import "./styles.css";
class DelaMeasurementChart extends Component {
  state = {
    formInput: {
      projectId: "",
      deviceLabel: "",
      combinedDatasets: [],
      filterOptions: [],
      modifyFilterOptions: [],
      checkedItems: [],
      //   startDate: Math.round(new Date().getTime() / 1000),
      //   endDate: Math.round(new Date().getTime() / 1000),
      startDate: "",
      endDate: "",
      endDatePass: "",
      options: [],
      series: [],
      checkedItemsLabel: [],
      checkedItemsUnit: [],
      labelColorMap: {},
    },
  };

  componentDidMount = () => {};

  componentWillUnmount = () => {};
  getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  render() {
    const formInput = this.state.formInput;
    const dataReceived = this.props.chartdataList;
    formInput.checkedItemsLabel = this.props.checkedItemsLabel;
    formInput.checkedItemsUnit = this.props.checkedItemsUnit;
    const labels = [
      this.props.chartdataList[0]?.map(
        (dataPoint) => dataPoint?.timestamp_seconds * 1000
      ),
    ];

    formInput.checkedItemsLabel.forEach((label) => {
      // If the label doesn't already have a color, assign it a new one
      if (!formInput.labelColorMap[label]) {
        formInput.labelColorMap[label] = this.getRandomColor();
      }
    });

    const seriesArray = formInput.checkedItemsLabel.map((label, index) => {
      const filterData = this.props.chartdataList[index];
      const seriesData = filterData.map((item) => {
        return isNaN(item.avg_val) ||
          item.avg_val === null ||
          item.avg_val === undefined
          ? 0
          : item.avg_val;
      });

      return {
        name: label,
        data: seriesData,
        type: "line",
        color: formInput.labelColorMap[label],
      };
    });

    formInput.series = seriesArray || [];

    formInput.checkedItemsUnit.map((unitValue, index) => {
      formInput.options = {
        chart: {
          height: 750,
          type: "line",
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
          zoom: {
            enabled: true,
          },
        },
        noData: {
          fontSize: "16px",
          text: "No data available",
          align: "center",
          verticalAlign: "middle",
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#FF9A00"],
        title: {
          text: "Project Data",
          align: "left",
          style: {
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "700",
            fontFamily: "Roboto Flex, sans-serif",
          },
          floating: false,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          showForNullSeries: true,
          showForZeroSeries: true,
          fontFamily: "Roboto Flex",
          fontSize: "16px",
          fontWeight: "600",
          height: 40,
          labels: {
            colors: ["#fff", "#fff", "#fff"],
          },
          formatter: (seriesName, opts) => {
            //  function ({ series, seriesIndex, dataPointIndex, w }) {
            const color = "black";
            const col = formInput.labelColorMap[seriesName];
            const unit =
              formInput.checkedItemsUnit[index] !== "" ? unitValue : "N/A";

            return `<span class="legend-item" style="display: flex; align-items: center;color: ${col};">
          <span style="width: 8px; height: 8px;border-radius: 50%; display: inline-block; margin-right: 5px;"></span>
          ${seriesName}
          </span>`;
          },
          markers: {
            width: 0,
            height: 0,
            radius: 0,
          },
        },
        xaxis: {
          tooltip: {
            enabled: true,
            //   formatter: function (val) {
            //     return new Date(val).toLocaleString();
            //   },
          },
          categories: labels[0],
          type: "datetime",
          tickPlacement: "on",
          labels: {
            datetimeUTC: false,
            showDuplicates: false,
          },
        },
        yaxis: {
          title: {
            text: "",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
          labels: {
            formatter: (value) => {
              return Number.isInteger(value)
                ? value?.toFixed(0)
                : value?.toFixed(2);
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (value, { seriesIndex }) {
              const valueUnit = value.toFixed(2);
              const unit = formInput.checkedItemsUnit[seriesIndex];
              return `${valueUnit} ${unit}`;
            },
          },
        },
      };
    });
    return (
      <Fragment>
        <div>
          {" "}
          {this.props.chartdataList.length > 0 ? (
            <div id="chart" style={{ width: "95%" }}>
              <ReactApexChart
                options={formInput.options}
                series={formInput.series}
                type="line"
                height={375}
              />
            </div>
          ) : (
            <div className="nochartData">
              {" "}
              No chart is available.Please select Register from below.
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    // deviceInfoViewData: state.listViewReducer.deviceInfoViewData,
    // deviceViewData: state.listViewReducer.deviceViewData,
    // deviceEventData: state.listViewReducer.deviceEventData,
    // projectDellaData: state.listViewReducer.projectDellaData,
    // dellaChartData: state.listViewReducer?.dellaChartData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getDeviceData,
    clearDeviceData,
    getDeviceInfoData,
    getDeviceEventInfoData,
    clearListViewData,
    getAllProjectData,
    getAllChartData,
  })(DelaMeasurementChart)
);
