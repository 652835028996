import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { baceEpViewChange } from "../../../../actions/view-history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListUl,
  faSliders,
  faHardDrive as faHardDriveRegular,
  faBrowser as faBrowserRegular,
} from "@fortawesome/pro-regular-svg-icons";
import TopMenu from "../../../../components/menus/top-menu/TopMenu";
import { faHardDrive, faBrowser } from "@fortawesome/pro-solid-svg-icons";
class IntegrationSubmenu extends Component {
  state = {
    formInput: {
      projectId: "",
    },
  };
  componentDidMount = () => {
    const { currentPageNr, projectId } = this.props.match.params;
  };

  render() {
    const formInput = this.state.formInput;

    formInput.projectId = this.props.projectId;
    return (
      <div className="col pl-4">
        <div className="d-flex">
          <h3 className="p-3 color-ocean-blue color-ocean-blue-font">
            Integration
          </h3>
          <TopMenu />
        </div>
        <div className="mb-3 switch-btn-line"></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    //latestData: state.groupReducer?.latestData,
  };
}

export default withRouter(
  connect(mapStateToProps, { baceEpViewChange })(IntegrationSubmenu)
);
