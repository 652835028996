import React, { Component } from 'react';
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import NotificationPin from './NotificationPin';

import { clearOneNotification } from "../../actions/notification";

import CONFIG from '../../config';
const {defaultNotificationTimeout} = CONFIG;

class Notification extends Component {
  componentDidMount = () => {
    setTimeout(() => {
      this.props.clearOneNotification(this.props.payload.uniqueId)
    }, defaultNotificationTimeout);
  }

  render() {
    return (
      <div className="toast show mb-1">
        <div className="toast-header">
          <NotificationPin fillColor={this.props.payload.fillColor} />
          <strong className="me-auto">{this.props.payload.header}</strong>
          <small className="text-muted">{moment(this.props.payload.timestamp).fromNow()}</small>
          <button 
            type="button" 
            className="btn-close"
            onClick={() => this.props.clearOneNotification(this.props.payload.uniqueId)}
          >
          </button>
        </div>
        <div className="toast-body">
          {this.props.payload.body}
        </div>
      </div>
    )
  }
}

export default withRouter(connect(null, { clearOneNotification })(Notification));
