import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Notification from './Notification';

class NotificationBar extends Component {
  render() {
    if (this.props.notification.length > 0) {
      return (
        <Fragment>
          <div className="position-absolute bottom-0 end-0" style={{zIndex: 100000}}>
            {this.props.notification.map((notification) => (
              <Notification
                key={notification.uniqueId} 
                payload={notification}
              />
            ))}
          </div>
        </Fragment>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}


function mapStateToProps(state) {
  return {
    notification: state.notificationReducer,
  };
}

export default withRouter(
  connect(mapStateToProps, {
  })(NotificationBar)
);
