import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import LoaderRoller from "../../components/loader/LoaderRoller";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNetworkWired,
  faChartLine,
  faPlusCircle,
  faSliders,
  faCircleXmark,
  faCirclePlus,
  faTimes,
  faCircleCheck,
  faCircleExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import SettingsSubmenu from "../../components/menus/sub-menu/subtypes/SettingsSubmenu";
import {
  getMemberDetailsForProject,
  clearMemberSuccesData,
  addEmailMember,
  DeleteProjectMember,
  clearMemberViewData,
  changeAccessTypeMember,
} from "../../actions/list-view";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

class SettingsMembersView extends Component {
  state = {
    formInput: {
      projectId: "",
      openCreatePopUp: false,
      emailValue: "",
      accessValue: "50",
      emailValid: "",
      addMember: false,
      removeMember: false,
      removeMemberName: "",
      removeMemberType: "",
      confirmRemovedMember: false,
      changeAccess: false,
      changeType: "",
      changeEmail: "",
      confirmAccessMember: false,
    },
  };

  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;
    const projectId = this.props.match.params?.projectId;
    if (!currentPageNr) {
      return this.props.history.push(
        `/settings/members/1/${this.props.match.params?.projectId}`
      );
    }
    this.props.clearMemberSuccesData();
    // this.props.clearMemberViewData();
    this.props.getMemberDetailsForProject(this.props.match.params?.projectId);
  };

  componentWillUnmount = () => {};

  handleToCreate = () => {
    const formInput = { ...this.state.formInput };
    formInput.openCreatePopUp = true;
    this.setState({ formInput });
  };

  handleChangeAccessType = (type, email) => {
    const formInput = { ...this.state.formInput };
    formInput.changeAccess = true;
    formInput.changeType = type;
    formInput.changeEmail = email;
    this.setState({ formInput });
  };

  handleChange = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput[input.name] = input.value;
    formInput.emailValue = input.value;
    this.setState({ formInput });
  };

  handleToConnectionClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.openCreatePopUp = false;
    formInput.removeMember = false;
    formInput.changeAccess = false;
    formInput.emailValue = "";
    this.setState({ formInput });
  };

  handleToAddMemberview = () => {
    const formInput = { ...this.state.formInput };
    formInput.addMember = false;
    formInput.openCreatePopUp = false;
    formInput.emailValue = "";
    this.setState({ formInput });
    this.props.getMemberDetailsForProject(this.props.match.params?.projectId);
  };

  handleToRemoveMemberview = () => {
    const formInput = { ...this.state.formInput };
    formInput.removeMemberName = "";
    formInput.removeMemberType = "";
    formInput.confirmRemovedMember = false;
    formInput.removeMember = false;
    this.setState({ formInput });
    this.props.clearMemberSuccesData();
    this.props.getMemberDetailsForProject(this.props.match.params?.projectId);
  };

  handleToChangeAccessView = () => {
    const formInput = { ...this.state.formInput };
    formInput.changeEmail = "";
    formInput.changeType = "";
    formInput.changeAccess = false;
    formInput.confirmAccessMember = false;
    this.setState({ formInput });
    this.props.clearMemberSuccesData();
    this.props.getMemberDetailsForProject(this.props.match.params?.projectId);
  };

  handleToRemoveMember = (memberData, index) => {
    const formInput = { ...this.state.formInput };
    formInput.removeMemberName = memberData.email;
    formInput.removeMemberType = memberData.type;
    formInput.removeMember = true;
    //  formInput.removeMemberName = emailName;
    this.setState({ formInput });
  };

  handleToRemoveDataMemberConfirm = () => {
    const formInput = { ...this.state.formInput };
    formInput.confirmRemovedMember = true;
    formInput.removeMember = false;
    this.setState({ formInput });
    this.props.DeleteProjectMember(
      this.props.match.params?.projectId,
      formInput.removeMemberName,
      formInput.removeMemberType
    );
  };

  handleToConfirmAccessType = () => {
    const formInput = { ...this.state.formInput };
    formInput.confirmAccessMember = true;
    formInput.changeAccess = false;
    formInput.changeType =
      formInput.changeType === "Owner"
        ? 50
        : formInput.changeType === "Contributor"
        ? 40
        : 10;
    this.setState({ formInput });
    this.props.changeAccessTypeMember(
      this.props.match.params?.projectId,
      formInput.changeEmail,
      formInput.changeType
    );
  };

  handleMemberRoleChange = (e) => {
    const formInput = { ...this.state.formInput };
    formInput.accessValue =
      e.target.value === "Owner"
        ? 50
        : e.target.value === "Contributor"
        ? 40
        : 10;
    this.setState({ formInput });
  };

  handleAddMemberRegister = () => {
    const formInput = { ...this.state.formInput };
    const emailValid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailValid.test(formInput.emailValue)) {
      formInput.emailValid = true;
    } else {
      formInput.emailValid = false;
      formInput.openCreatePopUp = false;
      formInput.addMember = true;
      this.props.clearMemberSuccesData();
      this.props.addEmailMember(
        this.props.match.params?.projectId,
        formInput.emailValue,
        formInput.accessValue
      );
    }
    this.setState({ formInput });
  };

  render() {
    const formInput = this.state.formInput;
    formInput.projectId = this.props.match.params?.projectId;

    const accessTypeMapping =
      this.props.projectMemberDetails?.data?.access.filter(
        (p) => p.email === this.props.userEmail
      );
    const accessTypeMap = this.props.projectMemberDetails?.data?.access.filter(
      (p) => p.email === this.props.userEmail
    );

    return (
      <div>
        {formInput.confirmAccessMember && (
          <Dialog
            open={formInput.confirmAccessMember === true ? true : false}
            onClose={this.handleToChangeAccessView}
          >
            <div onClick={this.handleToChangeAccessView}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.handleToChangeAccessView}
              />
            </div>
            <DialogTitle>
              {this.props.changeAccessMemberUpdated?.status === 200 ? (
                <p className="wififailedMessage">
                  <FontAwesomeIcon
                    className="addMemberStatusIcon"
                    icon={faCircleCheck}
                    style={{ display: "flex" }}
                  />
                  <p className="Addmember">Access Changed</p>
                  <p>
                    {" "}
                    Access is changed for
                    <p className="addmemberSuccess">
                      {" "}
                      {formInput.changeEmail}{" "}
                    </p>{" "}
                    from the project.
                  </p>
                </p>
              ) : (
                <LoaderRoller />
              )}
            </DialogTitle>
          </Dialog>
        )}

        {formInput.changeAccess && (
          <Dialog
            open={formInput.changeAccess === true ? true : false}
            onClose={this.handleToConnectionClose}
          >
            <div onClick={this.handleToConnectionClose}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.handleToConnectionClose}
              />
            </div>
            <DialogTitle>
              <p className="Addmember">Change Access </p>

              <div>
                <p className="emailCheck">
                  {" "}
                  <p style={{ textAlign: "center" }}>
                    {" "}
                    Do you want to change the access for
                    <p
                      className="addmemberSuccess"
                      style={{ marginRight: "20px" }}
                    >
                      {formInput.changeEmail}
                    </p>
                  </p>
                </p>
              </div>
              <div className="col pb-3">
                <div className="d-flex justify-content-end">
                  <button
                    className={"btn-cancel mx-1"}
                    onClick={this.handleToConnectionClose}
                  >
                    Cancel
                  </button>{" "}
                  <button
                    className={"btn-cancel submitButton"}
                    onClick={() => this.handleToConfirmAccessType()}
                  >
                    <Fragment>Confirm</Fragment>
                    {/* )} */}
                  </button>
                </div>
              </div>
            </DialogTitle>
          </Dialog>
        )}

        {formInput.confirmRemovedMember && (
          <Dialog
            open={formInput.confirmRemovedMember === true ? true : false}
            onClose={this.handleToRemoveMemberview}
          >
            <div onClick={this.handleToRemoveMemberview}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.handleToRemoveMemberview}
              />
            </div>
            <DialogTitle>
              {this.props.deleteProjectMemberStatus === 200 ? (
                <p className="wififailedMessage">
                  <FontAwesomeIcon
                    className="addMemberStatusIcon"
                    icon={faCircleCheck}
                    style={{ display: "flex" }}
                  />
                  <p className="Addmember">User removed</p>
                  <p className="addmemberSuccess">
                    {" "}
                    {formInput.removeMemberName}{" "}
                  </p>{" "}
                  is removed from the project.
                </p>
              ) : (
                <LoaderRoller />
              )}
            </DialogTitle>
          </Dialog>
        )}

        {formInput.removeMember && (
          <Dialog
            open={formInput.removeMember === true ? true : false}
            onClose={this.handleToConnectionClose}
          >
            <div onClick={this.handleToConnectionClose}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.handleToConnectionClose}
              />
            </div>
            <DialogTitle>
              <p className="Addmember">Remove member</p>

              <div>
                <p className="emailCheck" style={{ display: "flex" }}>
                  {" "}
                  Do you want to delete{" "}
                  <p className="addmemberSuccess" style={{ marginLeft: "6px" }}>
                    {formInput.removeMemberName} ?
                  </p>
                </p>
              </div>
              <div className="col pb-3">
                <div className="d-flex justify-content-end">
                  <button
                    className={"btn-cancel mx-1"}
                    onClick={this.handleToConnectionClose}
                  >
                    Cancel
                  </button>{" "}
                  <button
                    className={"btn-cancel submitButton"}
                    onClick={() => this.handleToRemoveDataMemberConfirm()}
                  >
                    <Fragment>Remove</Fragment>
                    {/* )} */}
                  </button>
                </div>
              </div>
            </DialogTitle>
          </Dialog>
        )}
        {formInput.addMember && (
          <Dialog
            open={formInput.addMember === true ? true : false}
            onClose={this.handleToAddMemberview}
          >
            <div onClick={this.handleToAddMemberview}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.handleToAddMemberview}
              />
            </div>
            <DialogTitle>
              {this.props.addMemberCreated?.status === 200 ? (
                <p className="wififailedMessage">
                  <FontAwesomeIcon
                    className="addMemberStatusIcon"
                    icon={faCircleCheck}
                    style={{ display: "flex" }}
                  />
                  <p className="Addmember">User Added</p>
                  <p className="addmemberSuccess">
                    {" "}
                    {formInput.emailValue}{" "}
                  </p>{" "}
                  is added to the project.
                </p>
              ) : this.props.addMemberFailed === "422" ? (
                <p className="wififailedMessage">
                  <FontAwesomeIcon
                    className="addMemberFailedStatusIcon"
                    icon={faCircleXmark}
                    style={{ display: "flex" }}
                  />
                  <p className="Addmember">User cannot be Added</p>
                  <p className="addmemberSuccess">
                    {" "}
                    {formInput.emailValue}{" "}
                  </p>{" "}
                  can not be added.you can add only add users that are already
                  registered to BACE IoT Paltform.
                </p>
              ) : (
                <LoaderRoller />
              )}
            </DialogTitle>
          </Dialog>
        )}

        {formInput.openCreatePopUp && (
          <Dialog
            open={formInput.openCreatePopUp === true ? true : false}
            onClose={this.handleToConnectionClose}
            className="dialogAdd"
          >
            <div onClick={this.handleToConnectionClose}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.handleToConnectionClose}
              />
            </div>
            <DialogTitle>
              <p className="Addmember">Add member</p>
              <p className="addmemberView">Email</p>
              <input
                className="memberinPutBox"
                defaultValue={formInput.emailValue}
                name="label"
                type="text"
                placeholder="Email"
                onChange={this.handleChange}
              />
              <p className="addmemberViewText">Form helper text</p>
              <p className="addmemberView">Access</p>
              <select
                style={{ marginRight: "10px" }}
                className="memberAccess"
                aria-label="Default select example"
                // label="status"
                //  onChange={this.handleStatusChange}
                onChange={(e) => this.handleMemberRoleChange(e)}
              >
                <option>Owner</option>
                <option>Contributor</option>
                <option>Viewer</option>
                
              </select>
              <p className="addmemberViewText">Form helper text</p>

              <div
                className={
                  formInput.emailValid ? "showErrorMsgMode" : "hideMode"
                }
              >
                <p className="emailCheck"> Email address is not valid.</p>
              </div>
              <div className="col pb-3">
                <div className="d-flex justify-content-end">
                  <button
                    className={"btn-cancel mx-1"}
                    onClick={this.handleToConnectionClose}
                  >
                    Cancel
                  </button>{" "}
                  <button
                    className={"btn-cancel submitButton"}
                    onClick={() => this.handleAddMemberRegister()}
                  >
                    <Fragment>Add</Fragment>
                    {/* )} */}
                  </button>
                </div>
              </div>
            </DialogTitle>
          </Dialog>
        )}
        <SettingsSubmenu projectId={this.props.match.params?.projectId} />
        <div>
          <Fragment>
            <div
            // className="card-wrap listMain"
            //style={{ margin: "40px" }}
            >
              <div>
                {" "}
                <div className="headerMember">
                  <div class="row">
                    <div class="col-sm" style={{ textAlign: "center" }}>
                      Email address
                    </div>
                    <div class="col-sm">Access</div>
                    <div class="col-sm">Action</div>
                  </div>
                </div>
                <div className="divModal"></div>
                <div
                  // class="container"
                  style={{ marginTop: "30px" }}
                >
                  {this.props.projectMemberDetails?.data?.access.length > 0 ? (
                    this.props.projectMemberDetails?.data?.access.map(
                      (j, index) => (
                        <div class="row" style={{ width: "95%" }}>
                          <div class="col-sm" style={{ textAlign: "center",marginTop: "15px" }}>
                            {j.email}
                          </div>{" "}
                          <div class="col-sm" style={{ textAlign: "center" }}>
                            <select
                              style={{ width: "50%", marginRight: "10px",marginTop: "8px" }}
                              className={
                                accessTypeMapping.length !== 0 &&
                                accessTypeMapping[0].type !== 50
                                  ? "select-container disabledOption"
                                  : "select-container"
                              }
                              aria-label="Default select example"
                              value={
                                j.type === 50
                                  ? "Owner"
                                  : j.type === 40
                                  ? "Contributor"
                                  : "Viewer"
                              }
                              label="status"
                              disabled={
                                accessTypeMapping.length !== 0 &&
                                accessTypeMapping[0].type !== 50
                                  ? true
                                  : false
                              }
                              onChange={(event) =>
                                this.handleChangeAccessType(
                                  event.target.value,
                                  j.email
                                )
                              }
                              //   onChange={this.handleChangeAccessType}
                            >
                              <option>Owner</option>
                              <option>Contributor</option>
                              <option>Viewer</option>
                            </select>
                            
                          </div>{" "}
                          <div class="col-sm">
                            {" "}
                            {accessTypeMapping.length !== 0 &&
                            accessTypeMapping[0].type !== 50 ? (
                              <div>
                                {" "}
                                <div className="btn-cancel mx-1">
                                  <div
                                    style={{
                                      width: "28%",
                                      textAlign: "center",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      className="infoProjectIcon notRemoveOption"
                                      icon={faCircleExclamation}
                                      title="Action is not possible for contributor and viewer type"
                                    />{" "}
                                  </div>
                                </div>
                              </div>
                            ) : (
                             <button
                                className="btn-cancel mx-1"
                                onClick={() =>
                                  this.handleToRemoveMember(j, index)
                                }
                              >
                                <FontAwesomeIcon
                                  className="infoProjectIcon"
                                  icon={faCircleXmark}
                                  style={{fontSize:"24px"}}
                                />{" "}
                                <span className="removeButtonText" style={{marginTop: "5px",fontSize: "16px"}}>remove </span>
                                
                              </button>
                             )}
                          </div>{" "}
                        </div>
                      )
                    )
                  ) : (
                    <LoaderRoller />
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        </div>
        <div>
          <div>
            {" "}
            <button
              type="button"
              className={
                accessTypeMapping.length !== 0 &&
                accessTypeMapping[0].type !== 50
                  ? "bottomleftNew disableAddMember"
                  : "bottomleftNew addmember"
              }
              disabled={
                accessTypeMapping.length !== 0 &&
                accessTypeMapping[0].type !== 50
                  ? true
                  : false
              }
              onClick={() => this.handleToCreate()}
            >
              <FontAwesomeIcon icon={faCirclePlus} />
              <p className="memberColor">Add member</p>
            </button>
            {accessTypeMapping.length !== 0 &&
            accessTypeMapping[0].type !== 50 ? (
              <div className="bottomDisableleft">
                {" "}
                <FontAwesomeIcon
                  className="infoProjectIcon notRemoveOption"
                  icon={faCircleExclamation}
                  title="Action is not possible for contributor and viewer type"
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    projectMemberDetails: state.listViewReducer?.projectMemberDetails,
    addMemberCreated: state.listViewReducer?.addMemberCreated,
    addMemberFailed: state.listViewReducer?.addMemberFailed,
    deleteProjectMemberStatus: state.listViewReducer?.deleteProjectMemberStatus,
    changeAccessMemberUpdated: state.listViewReducer?.changeAccessMemberUpdated,
    userEmail: state.userReducer?.user?.email,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getMemberDetailsForProject,
    addEmailMember,
    clearMemberSuccesData,
    DeleteProjectMember,
    clearMemberViewData,
    changeAccessTypeMember,
  })(SettingsMembersView)
);
