import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import "./styles.css";

import {
  faFolderGear,
  faHardDrive,
  faCircleExclamation,
  faServer,
} from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import TopMenu from "../../components/menus/top-menu/TopMenu";
import CONFIG from "../../config";
import axios from "axios";
import {
  getAllProjectIsConnectedDetails,
  getAllProjectIsDisConnectedDetails,
} from "../../actions/list-view";
const { baseUrl } = CONFIG;
const initialState = {
  modbus: [],
};
class ProjectView extends Component {
  state = initialState;
  componentDidMount = () => {
    if (!this.props.projectData?.id) return;

    this.props.getAllProjectIsConnectedDetails(this.props.projectData?.id);
    this.props.getAllProjectIsDisConnectedDetails(this.props.projectData?.id);
  };

  render() {
    return (
      <Fragment>
        <div>{this.props.allProjectIsConnectedData?._meta?.totalCount}</div>,
        <div>{this.props.allProjectIsDisconnectedData?._meta?.totalCount}</div>
        {/* {this.props.projectData?.id ===
          this.props.allProjectIsConnectedData?.items[0]?.id_project &&
          ((
            <div>{this.props.allProjectIsConnectedData?._meta?.totalCount}</div>
          ),
          (
            <div>
              {this.props.allProjectIsDisconnectedData?._meta?.totalCount}
            </div>
          ))} */}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    allProjectIsConnectedData: state.listViewReducer?.allProjectIsConnectedData,
    allProjectIsDisconnectedData:
      state.listViewReducer?.allProjectIsDisconnectedData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAllProjectIsConnectedDetails,
    getAllProjectIsDisConnectedDetails,
  })(ProjectView)
);
