import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHardDrive,
  faCircleInfo,
  faTrashCan,
  faCopy,
  faPlusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { getApplyModbus } from "../../../actions/modbus-view";
import { Link } from "react-router-dom";

import ModbusCardView from "../../../views/modbus-gateways/ModbusCardView";
import EditModbusDevices from "./EditModbusDevices";

class EditModbusDevicesRegister extends Component {
  state = {
    formInput: {
      deviceNumber: "0",
      addrValue: "",
      registerErrorView: false,
      previousTab: false,
      updatedDevices: false,
      registerListData: [],
      sameRegisterName: false,
      sameRegStartName: false,
      registerError: false,
      regTypeError: false,
    },
  };

  handleToShowRegister = (event, lo, index) => {
    const formInput = { ...this.state.formInput };
    formInput.addrValue = lo.addr;
    // formInput.regisiterList = lo.registers;
    formInput.deviceNumber = index;

    this.setState({ formInput });
  };

  handleToCopyNewrow = (e) => {
    const formInput = { ...this.state.formInput };
    formInput.addrValue =
      formInput.addrValue === ""
        ? this.props.inputRecordData.dataNewList[0].addr
        : formInput.addrValue;
    let registerLength = 0;
    formInput.registerListData.map((i) => {
      if (i.addr === formInput.addrValue) {
        registerLength++;
      }
    });
    const dataRegisterView = [];

    formInput.registerListData.map((l, index) => {
      if (l.addr === formInput.addrValue) {
        dataRegisterView.push(l);
      }
    });

    const registerLastData = dataRegisterView[registerLength - 1];
    formInput.registerListData.push({
      access_mode: registerLastData.access_mode,
      addr: registerLastData.addr,
      byte_order: registerLastData.byte_order,
      data_type: registerLastData.data_type,
      is_single_bit_binary: 0,
      label: registerLastData.label,
      reg_Id: registerLastData.reg_Id,
      reg_start: registerLastData.reg_start,
      reg_type: registerLastData.reg_type,
      unit: registerLastData.unit,
      word_order: registerLastData.word_order,
      value_id: registerLastData.value_id,
    });

    this.setState({ formInput });
  };

  handleToDeleteRow = (pValue, index) => {
    const formInput = this.state.formInput;
    formInput.registerListData =
      formInput.registerListData.length === 0
        ? this.props.inputRecordData?.registerList.filter((_, i) => i !== index)
        : formInput.registerListData?.filter((_, i) => i !== index);

    // this.props.inputRecordData?.registerList =
    // if (
    //   this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber].registers
    //     .length !== 1
    // ) {

    //   this.props.modbusDetails.ModbusNewRow[formInput.deviceNumber].registers =
    //     this.props.modbusDetails.ModbusNewRow[
    //       formInput.deviceNumber
    //     ].registers.filter((_, i) => i !== index);
    // } else {
    //   formInput.registerErrorView = true;
    //   formInput.regStartError = false;
    //   formInput.registerAndRegStartError = false;
    //   formInput.registerError = false;
    //   formInput.scalingError = false;
    // }

    this.setState({ formInput });
  };

  handleToAddNewRegister = (e) => {
    const formInput = { ...this.state.formInput };

    const key = "addr";
    const uniqueModbusList = [
      ...new Map(
        this.props.inputRecordData?.dataNewList.map((item) => [item[key], item])
      ).values(),
    ];

    formInput.addrValue =
      formInput.deviceNumber === "0" && formInput.addrValue === ""
        ? uniqueModbusList[0].addr
        : formInput.addrValue;
    formInput.registerListData.push({
      label: "",
      unit: "",
      addr: formInput.addrValue,
      reg_start: "",
      reg_type: "0",
      data_type: "0",
      access_mode: "2",
      reg_Id: "0",
      value_id: "",
      is_single_bit_binary: 0,
      byte_order:
        this.props.inputRecordData.dataNewList[formInput.deviceNumber]
          .byte_order,
      word_order:
        this.props.inputRecordData.dataNewList[formInput.deviceNumber]
          .word_order,
    });
    formInput.registerErrorView = false;
    this.setState({ formInput });
  };

  handleToNextApply = () => {
    const formInput = { ...this.state.formInput };
    const modbusDetails = this.props.modbusGatewayInfo;
    const ModbusData = {
      label: modbusDetails.label,
      mode: modbusDetails.mode,
      baud_rate: modbusDetails.baud_rate,
      interval: modbusDetails.interval_milliseconds,
      id_physical_device: this.props.modbusPhysicalDeviceId,
      id_group: this.props.modbusGroupId,
      as_sub_devices: 1,
      // ips: modbusDetailsValue.modeName === "modbus-tcp" ? ipsData : "",
    };
    const key = "addr";
    const uniqueModbusList = [
      ...new Map(
        this.props.inputRecordData?.dataNewList.map((item) => [item[key], item])
      ).values(),
    ];
    const IPDataView = [];
    uniqueModbusList.map((o) => {
      IPDataView.push(o.IPadress);
    });
    IPDataView.unshift("BROADCAST");
    const modbusRegistersData = [];

    const registerList = formInput.registerListData.map(
      (l) =>
        l.label === "" ||
        l.label === null ||
        l.label === undefined ||
        l.label.match(/^ *$/) !== null
    );

    const regstartValue = formInput.registerListData.map(
      (l) =>
        l.reg_start === "" || l.reg_start === null || l.reg_start === undefined
      // ||
      //  l.reg_start.match(/^ *$/) !== null
    );
    const uniqueConnection = [];
    const uniqueRegStartConnection = [];

    formInput.registerListData.map((img) => {
      if (uniqueConnection.indexOf(img.label) === -1 && img.label !== null) {
        uniqueConnection.push(img.label);
      } else {
        uniqueConnection.push(false);
      }
    });
    formInput.registerListData.map((img) => {
      if (
        uniqueRegStartConnection.indexOf(parseInt(img.reg_start)) === -1 &&
        img.reg_start !== null
      ) {
        uniqueRegStartConnection.push(img.reg_start);
      } else {
        uniqueRegStartConnection.push(false);
      }
    });
    if (registerList.includes(true) && regstartValue.includes(true)) {
      formInput.registerAndRegStartError = true;
      formInput.registerError = false;
      formInput.regTypeError = false;
      formInput.sameRegisterName = false;
      formInput.sameRegStartName = false;
      this.setState({ formInput });
    }
    if (regstartValue.includes(true)) {
      formInput.regTypeError = true;
      formInput.registerAndRegStartError = false;
      formInput.registerError = false;
      formInput.sameRegisterName = false;
      formInput.sameRegStartName = false;
      this.setState({ formInput });
    } else if (registerList.includes(true)) {
      formInput.registerError = true;
      formInput.sameRegisterName = false;
      formInput.sameRegStartName = false;
      formInput.regTypeError = false;
      formInput.registerAndRegStartError = false;
      this.setState({ formInput });
    } else if (uniqueConnection.includes(false)) {
      formInput.sameRegisterName = true;
      formInput.sameRegStartName = false;
      formInput.registerError = false;
      formInput.regTypeError = false;
      formInput.registerAndRegStartError = false;
      this.setState({ formInput });
    } else if (uniqueRegStartConnection.includes(false)) {
      formInput.sameRegStartName = true;
      formInput.sameRegisterName = false;
      formInput.registerError = false;
      formInput.regTypeError = false;
      formInput.registerAndRegStartError = false;
      this.setState({ formInput });
    } else {
      if (this.props.modbusSubDevicesInfo[0].id_device_type === "modbus-tcp") {
        formInput.registerListData.map((j, index) => {
          modbusRegistersData.push({
            access_mode: j.access_mode,
            label: j.label,
            data_type: j.data_type,
            addr: j.addr,
            byte_order: j.byte_order,
            is_single_bit_binary: j.is_single_bit_binary,
            reg_Id: j.reg_Id === undefined ? 0 : j.reg_Id,
            reg_start: j.reg_start,
            reg_type: j.reg_type,
            value_id: 2100 + index,
            unit: j.unit,
            word_order: j.word_order,
          });
        });
      } else {
        formInput.registerListData.map((j, index) => {
          modbusRegistersData.push({
            access_mode: j.access_mode,
            label: j.label,
            data_type: j.data_type,
            addr: j.addr,
            byte_order: j.byte_order,
            is_single_bit_binary: j.is_single_bit_binary,
            reg_Id: j.reg_Id === undefined ? 0 : j.reg_Id,
            reg_start: j.reg_start,
            reg_type: j.reg_type,
            value_id: 2100 + index,
            unit: j.unit,
            word_order: j.word_order,
          });
        });
      }
      this.setState({ formInput });
      const modeData = this.props.modbusGatewayInfo?.baud_rate;
      const ipsData =
        this.props.modbusSubDevicesInfo[0].id_device_type === "modbus-tcp"
          ? IPDataView
          : "";
      const modeName = this.props.modbusSubDevicesInfo[0].id_device_type;
      this.props.getApplyModbus(
        ModbusData,
        modbusRegistersData,
        modeData,
        ipsData,
        modeName
      );
    }
  };

  handlerRegisterChange = (e, p, index) => {
    const formInput = { ...this.state.formInput };
    // const labelNameView = e.target.value;
    this.props.inputRecordData.registerList[index].label = e.target.value;
    // formInput.modbusErrorLabelMsg = false;
    this.setState({ formInput });
  };

  handlerUnitChange = (e, p, index) => {
    const formInput = { ...this.state.formInput };
    this.props.inputRecordData.registerList[index].unit = e.target.value;
    this.setState({ formInput });
  };
  handleToPrevious = () => {
    const formInput = { ...this.state.formInput };
    formInput.previousTab = true;
    this.setState({ formInput });
  };

  handlerRegStartChange = (e, p, index) => {
    const formInput = { ...this.state.formInput };
    this.props.inputRecordData.registerList[index].reg_start = e.target.value;
    this.setState({ formInput });
  };
  handleRegtypeChange = (e, index) => {
    const formInput = { ...this.state.formInput };
    this.props.inputRecordData.registerList[index].reg_type = e.target.value;
    this.setState({ formInput });
  };

  handleDatatypeChange = (e, index) => {
    const formInput = { ...this.state.formInput };
    this.props.inputRecordData.registerList[index].data_type = e.target.value;
    this.setState({ formInput });
  };
  handleAccessModeChange = (e, index) => {
    const formInput = { ...this.state.formInput };
    this.props.inputRecordData.registerList[index].access_mode = e.target.value;
    this.setState({ formInput });
  };

  componentDidMount = () => {
    const formInput = { ...this.state.formInput };
    formInput.registerListData =
      formInput.registerListData.length === 0
        ? this.props.inputRecordData.registerList
        : formInput.registerListData;
    this.setState({ formInput });
  };

  render() {
    const formInput = { ...this.state.formInput };
    formInput.addrValue =
      formInput.addrValue === ""
        ? this.props.inputRecordData.dataNewList[0].addr
        : formInput.addrValue;

    const key = "addr";
    const uniqueModbusList = [
      ...new Map(
        this.props.inputRecordData?.dataNewList.map((item) => [item[key], item])
      ).values(),
    ];

    const newModbusRegister = this.props.inputRecordData.registerList.map(
      (p) => p.addr === formInput.addrValue
    );
    const newDeviceFindRegister = newModbusRegister.find((m) => m === true);

    if (newDeviceFindRegister === undefined) {
      this.props.inputRecordData.registerList.push({
        label: "",
        unit: "",
        addr: formInput.addrValue,
        reg_start: "",
        reg_type: "0",
        data_type: "0",
        access_mode: "2",
        reg_Id: "0",
        value_id: "",
        is_single_bit_binary: 0,
        byte_order:
          this.props.inputRecordData.dataNewList[formInput.deviceNumber]
            .byte_order,
        word_order:
          this.props.inputRecordData.dataNewList[formInput.deviceNumber]
            .word_order,
      });
    }
    if (this.props.allSelectModbus && this.props.allSelectModbusPreLoad) {
      formInput.updatedDevices = true;
    }
    return (
      <div>
        {formInput.updatedDevices ? (
          <div>Modbus configuration applied successfully</div>
        ) : formInput.previousTab ? (
          <EditModbusDevices
            modbusGatewayInfo={this.props.modbusGatewayInfo}
            modbusSubDevicesInfo={this.props.modbusSubDevicesInfo}
            inputRecordData={formInput}
            modbusGroupId={this.props.modbusGroupId}
            modbusPhysicalDeviceId={this.props.modbusPhysicalDeviceId}
            handleModbusPage={this.props.handleModbusPage}
          />
        ) : (
          //
          <div>
            <div
              style={{
                height: "400px",
              }}
            >
              <FontAwesomeIcon
                style={{ marginRight: "8px" }}
                className="fa-lg driveModbus"
                icon={faHardDrive}
              />
              <span className="modbusTitle">
                Add registers for each Modbus Device
              </span>

              <div style={{ width: "100%" }}>
                <div style={{ display: "flex", padding: "27px" }}>
                  <div style={{ display: "flex" }} className="container">
                    <ul
                      style={{ display: "block", width: "15%" }}
                      className="nav nav-pills nav-stacked"
                    >
                      <div style={{ fontWeight: "700" }}>
                        {this.props.modbusSubDevicesInfo[0].id_device_type ===
                        "modbus-tcp"
                          ? "IP Address"
                          : " Slave Id"}
                        {/* {this.props.modbusDetails.modeName === "modbus-rtu"
                      ? "Slave Id"
                      : "IP Address"} */}
                      </div>

                      {uniqueModbusList.map((m, index) => (
                        <li>
                          <button
                            onClick={(p) =>
                              this.handleToShowRegister(p, m, index)
                            }
                            className={
                              formInput.deviceNumber === index ||
                              (formInput.deviceNumber === "0" && index === 0)
                                ? "deviceList activeSetting"
                                : "deviceList"
                            }
                          >
                            {/* {this.props.modbusSubDevicesInfo[0]
                              .id_device_type === "modbus-tcp"
                              ? this.props.modbusGatewayInfo?.ips[m.addr]
                              : m.addr} */}
                            {this.props.modbusSubDevicesInfo[0]
                              .id_device_type === "modbus-tcp"
                              ? m.IPadress
                              : m.addr}
                            {/* {m.IPadress} */}
                          </button>
                        </li>
                      ))}
                    </ul>
                    <div style={{ width: "85%" }}>
                      <table>
                        <tr>
                          <th>Label</th>
                          <th>Unit</th>
                          <th>Reg start</th>
                          <th>Reg type</th>

                          <th>Data type</th>

                          <th>
                            <div>Access mode</div>
                          </th>
                        </tr>
                        {formInput.registerListData.map((p, index) => {
                          return p.addr === formInput.addrValue ? (
                            <tr key={index}>
                              <td>
                                <input
                                  className="form-control-search formInputModbusSearch registerInput"
                                  value={p.label}
                                  name="name"
                                  type="text"
                                  onChange={(e) =>
                                    this.handlerRegisterChange(e, p, index)
                                  }
                                  placeholder={""}
                                />
                              </td>{" "}
                              <td>
                                <input
                                  className="form-control-search formInputModbusSearch registerInput"
                                  value={p.unit}
                                  name="name"
                                  type="text"
                                  onChange={(e) =>
                                    this.handlerUnitChange(e, p, index)
                                  }
                                  placeholder={""}
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control-search formInputModbusSearch registerInput"
                                  value={p.reg_start}
                                  name="name"
                                  type="text"
                                  onChange={(e) =>
                                    this.handlerRegStartChange(e, p, index)
                                  }
                                  placeholder={""}
                                />
                              </td>{" "}
                              <td>
                                {" "}
                                <select
                                  className="form-select dropdownView"
                                  aria-label="Default select example"
                                  value={p.reg_type}
                                  label="status"
                                  onChange={(e) =>
                                    this.handleRegtypeChange(e, index)
                                  }
                                >
                                  <option value={0}>Holding</option>
                                  <option value={1}>Input</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="form-select dropdownView"
                                  aria-label="Default select example"
                                  value={p.data_type}
                                  label="status"
                                  onChange={(e) =>
                                    this.handleDatatypeChange(e, index)
                                  }
                                >
                                  <option value={0}>
                                    (unit8) Unsigned 8 bit Integer
                                  </option>
                                  <option value={1}>
                                    (unit16) Unsigned 16 bit Integer
                                  </option>
                                  <option value={2}>
                                    (unit32) Unsigned 32 bit Integer
                                  </option>
                                  <option value={9}>
                                    (unit64) Unsigned 64 bit Integer
                                  </option>
                                  <option value={3}>(float) Float</option>
                                  <option value={5}>
                                    (int8) Signed 8 bit Integer
                                  </option>
                                  <option value={6}>
                                    (int16) Signed 16 bit Integer
                                  </option>
                                  <option value={7}>
                                    (int32) Signed 32 bit Integer
                                  </option>
                                  <option value={10}>
                                    (int64) Signed 64 bit Integer
                                  </option>
                                </select>
                              </td>
                              <td>
                                <select
                                  className="form-select dropdownView"
                                  aria-label="Default select example"
                                  value={p.access_mode}
                                  label="status"
                                  onChange={(e) =>
                                    this.handleAccessModeChange(e, index)
                                  }
                                >
                                  <option value={2}>(r)Readable</option>
                                  <option value={4}>(w)Writeable</option>
                                  <option value={16}>(x)Triggerable</option>
                                  <option value={6}>
                                    (rw)Readable and Writeable
                                  </option>
                                  <option value={18}>
                                    (rx)Readable and Triggerable
                                  </option>
                                  <option value={20}>
                                    (wx)Writeable and Triggerable
                                  </option>
                                  <option value={22}>
                                    (rwx)Readable , Writeable and Triggerable
                                  </option>
                                </select>
                              </td>{" "}
                              <td>
                                <button
                                  className="btn-border-none"
                                  onClick={() =>
                                    this.handleToDeleteRow(p, index)
                                  }
                                >
                                  <FontAwesomeIcon
                                    className="svg-icons-sm"
                                    icon={faTrashCan}
                                  />
                                </button>
                              </td>
                            </tr>
                          ) : (
                            ""
                          );
                        })}{" "}
                      </table>
                      <div>
                        {" "}
                        <button
                          className="btn-border-none"
                          onClick={this.handleToAddNewRegister.bind(this)}
                        >
                          <FontAwesomeIcon
                            className="svg-icon-sm fa-lg plusRegister"
                            icon={faPlusCircle}
                          />
                        </button>
                        <button
                          className="btn-border-none"
                          onClick={this.handleToCopyNewrow.bind(this)}
                        >
                          <FontAwesomeIcon
                            className="svg-icon-sm fa-lg plusRegister"
                            icon={faCopy}
                          />
                        </button>
                      </div>
                      <div
                        className={
                          formInput.registerErrorView
                            ? "showErrorRegisterMode"
                            : "hideMode"
                        }
                      >
                        Atleast 1 device is required
                      </div>
                      <div>&nbsp;&nbsp; </div>
                      <div
                        className={
                          formInput.sameRegisterName
                            ? "showErrorRegisterMode"
                            : "hideMode"
                        }
                      >
                        Label should be unique.
                      </div>
                      <div>&nbsp;&nbsp; </div>
                      <div
                        className={
                          formInput.sameRegStartName
                            ? "showErrorRegisterMode"
                            : "hideMode"
                        }
                      >
                        Reg start should be unique.
                      </div>
                      <div>&nbsp;&nbsp; </div>
                      <div
                        className={
                          formInput.registerAndRegStartError
                            ? "showErrorRegisterMode"
                            : "hideMode"
                        }
                      >
                        Register and Reg start are mandatory.
                      </div>
                      <div
                        className={
                          formInput.registerError
                            ? "showErrorRegisterMode"
                            : "hideMode"
                        }
                      >
                        Register is mandatory.
                      </div>
                      <div
                        className={
                          formInput.regTypeError
                            ? "showErrorRegisterMode"
                            : "hideMode"
                        }
                      >
                        Reg start is mandatory.
                      </div>
                      <div
                        className={
                          formInput.registerLimitNumber
                            ? "showErrorRegisterMode"
                            : "hideMode"
                        }
                      >
                        Reached the max number of registers.
                      </div>
                      <div
                        className={
                          formInput.regStartError
                            ? "showErrorRegisterMode"
                            : "hideMode"
                        }
                      >
                        Reg start is mandatory.
                      </div>
                      <div
                        className={
                          formInput.scalingError
                            ? "showErrorRegisterMode"
                            : "hideMode"
                        }
                      >
                        Scale value should be more than 0 and less than 1
                        million.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="buttonTag">
                  {" "}
                  <div
                    className={
                      formInput.registerErrorView
                        ? "showErrorRegisterMode"
                        : "hideMode"
                    }
                  >
                    Atleast 1 device is required
                  </div>{" "}
                </div>
                <div className="">
                  <button
                    onClick={this.handleToNextApply}
                    className="btn buttonView modbusButton"
                  >
                    Apply
                    {/* {formInput.showResult ? "Applying" : "Apply"} */}
                  </button>{" "}
                  <button
                    onClick={this.handleToPrevious}
                    className="btn previousbutton"
                  >
                    Previous
                  </button>{" "}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allSelectModbus: state.modbusViewReducer.allSelectModbus,
    allSelectModbusPreLoad: state.modbusViewReducer.allSelectModbusPreLoad,
  };
}

export default withRouter(
  connect(mapStateToProps, { getApplyModbus })(EditModbusDevicesRegister)
);
