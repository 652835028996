import React, { Component } from "react";
//import CardBody from "./CardBody";
import { connect } from "react-redux";
import BaceEpCardBody from "../device-cards/BaceEpCardBody";
import ActivityMonitorCardBody from "../device-cards/ActivityMonitorCardBody";
import "./sensor.css";

class DeviceCard extends Component {
  render() {
    return (
      <div className="">
        {this.props.type === "bace-ep" ? (
          <BaceEpCardBody deviceInfo={this.props.deviceInfo} />
        ) : (
          <ActivityMonitorCardBody deviceInfo={this.props.deviceInfo} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(DeviceCard);
