import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import OneActivityMonitorSubmenu from "../../components/menus/sub-menu/subtypes/OneActivityMonitorSubmenu";
import DeviceInfoCard from "../../components/device-cards/DeviceInfoCard";

import { getDeviceData, clearDeviceData } from "../../actions/list-view";
import {
  getGroupSessionListViewData,
  clearGroupSessionListViewData,
} from "../../actions/session-view";

class DeviceInfoView extends Component {
  componentDidMount = () => {
    const { id } = this.props.match.params;
    if (!id) {
      return this.props.history.push("/");
    }
    this.props.getDeviceData(id);
    return this.props.getGroupSessionListViewData(id);
  };

  componentWillUnmount = () => {
    this.props.clearDeviceData();
    this.props.clearGroupSessionListViewData();
  };

  getData = () => {};

  render() {
    if (this.props.deviceViewData) {
      return (
        <Fragment>
          <OneActivityMonitorSubmenu />
          <div className="bacecontainer">
            <div className="row mt-3">
              <DeviceInfoCard />
            </div>
          </div>
        </Fragment>
      );
    } else {
      return <div></div>;
    }
  }
}

function mapStateToProps(state) {
  return {
    deviceViewData: state.listViewReducer.deviceViewData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getGroupSessionListViewData,
    clearGroupSessionListViewData,
    getDeviceData,
    clearDeviceData,
  })(DeviceInfoView)
);
