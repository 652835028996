import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ModbusGatewaysSubmenu from "../../components/menus/sub-menu/subtypes/ModbusGatewaysSubmenu";
import DeviceListCard from "../../components/device-cards/DeviceListCard";
import {
  getAllModbusDevices,
  clearApplyModbus,
  getClearSelectGateway,
} from "../../actions/modbus-view";
import LoaderRoller from "../../components/loader/LoaderRoller";
import ModbusEpSubMenu from "./ModbusEpSubMenu";
import ModbusEpBottomMenu from "./ModbusEpBottomMenu";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNetworkWired,
  faChartLine,
  faPlusCircle,
  faSliders,
  faTrashCan,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import ModbusGatewayEdit from "./ModbusGatewayEdit";
import { Link } from "react-router-dom";

class ModbusCardView extends Component {
  state = {
    formInput: {
      modbusIndex: 0,
      selectModel: "false",
      selectModbusGatewayData: false,
      clearModbus: false,
      clearMobusLength: "",
      clearConfirmModbus: false,
    },
  };

  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;
    const projectId = window.location.pathname.slice(29);
    if (!currentPageNr) {
      return this.props.history.push(
        `/modbus-gateways/card-view/1/${this.props.match.params}`
      );
    }
    const formInput = { ...this.state.formInput };
    formInput.selectModbusGateway = false;
    this.setState({ formInput });
    this.props.clearApplyModbus();
    return this.props.getAllModbusDevices(projectId);
  };

  changeModbusDevice = (modbusNumber) => {
    const formInput = { ...this.state.formInput };
    formInput.modbusIndex = modbusNumber;
    formInput.selectModel = true;
    this.setState({ formInput });
  };

  componentWillUnmount = () => {
    const formInput = { ...this.state.formInput };
    formInput.selectModbusGateway = false;
    this.setState({ formInput });
  };

  handleNewGateway = () => {
    this.props.history.push(
      `/modbus-gateways/dashboard-view/1/${this.props.match.params?.projectId}`
    );
    //window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  handleClearGateway = () => {
    const formInput = { ...this.state.formInput };
    formInput.clearModbus = true;
    this.setState({ formInput });
  };

  handleToConnectionClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.clearModbus = false;
    formInput.clearConfirmModbus = false;
    this.setState({ formInput });
  };

  handleConfirmClearGateway = () => {
    const formInput = { ...this.state.formInput };
    const deleteModbusGateway =
      this.props.allModbusDevices?.items[formInput.modbusIndex]
        .id_physical_device;
    this.props.getClearSelectGateway(deleteModbusGateway);
    formInput.clearConfirmModbus = true;
    formInput.clearModbus = false;
    this.setState({ formInput });
  };

  handleConfigureOption = () => {
    const formInput = { ...this.state.formInput };
    this.props.history.push(
      `/modbus-gateways/config-view/${formInput.modbusIndex}/${this.props.match.params?.projectId}`
    );
  };

  render() {
    const formInput = { ...this.state.formInput };
    const templateData =
      this.props.allModbusDevices?.items[formInput.modbusIndex]
        ?.currentModbusTemplate?.registers;

    if (this.props.allModbusDevices) {
      return (
        <div>
          {formInput.clearConfirmModbus && (
            <Dialog
              open={formInput.clearConfirmModbus === true ? true : false}
              onClose={this.handleToConnectionClose}
            >
              <div onClick={this.handleToConnectionClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToConnectionClose}
                />
              </div>
              <DialogTitle>
                {this.props.clearSelectGatewayModbus?.status === 200 ? (
                  <p className="wififailedMessage">
                    Modbus Device Configutation is cleared.
                  </p>
                ) : this.props.failSelectGatewayModbus === "failed" ? (
                  <p className="wififailedMessage">
                    Modbus Device Configutation is not cleared.Apply Modbus
                    template is not applied yet.
                  </p>
                ) : (
                  <LoaderRoller />
                )}
              </DialogTitle>
            </Dialog>
          )}
          {formInput.clearModbus && (
            <Dialog
              open={formInput.clearModbus === true ? true : false}
              onClose={this.handleToConnectionClose}
            >
              <div onClick={this.handleToConnectionClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToConnectionClose}
                />
              </div>
              <DialogTitle>
                <p className="wififailedMessage">
                  Clearing modbus configuration from gateway will cause gateway
                  to stop receiving modbus messages from connected assets and
                  delete existing modbus measurements. Do you want to confirm?
                </p>
                <div className="col pb-3">
                  <div className="d-flex justify-content-end">
                    <button
                      className={
                        formInput.setApplyConfirm
                          ? "btn-cancel mx-1 cancelTemplate"
                          : "btn-cancel mx-1"
                      }
                      onClick={this.handleToConnectionClose}
                    >
                      Cancel
                    </button>
                    <button
                      className={"btn-cancel submitButton"}
                      onClick={() => this.handleConfirmClearGateway()}
                    >
                      <Fragment>Confirm</Fragment>
                      {/* )} */}
                    </button>
                  </div>
                </div>
              </DialogTitle>
            </Dialog>
          )}
          <div>
            <div>
              <ModbusGatewaysSubmenu nrResults={9} />
              {this.props.allModbusDevices?.items.length === 0 ? (
                <div>
                  Oops, it seems like you have no Modbus devices connected to
                  your BACE gateways yet.
                  <div className="settingsViewAreaGateway">
                    <button
                      className="settingsViewAreaCheck buttonSettings"
                      onClick={this.handleNewGateway}
                    >
                      <FontAwesomeIcon
                        className="svg-icons-sm fa-lg"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Strong"
                        icon={faPlusCircle}
                      />{" "}
                      Add new gateway
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <ModbusEpSubMenu
                    projectId={this.props.match.params?.projectId}
                  />
                  <div style={{ display: "flex" }}>
                    <div className="nav-item  arrowModbus" role="presentation">
                      {"<"}
                    </div>

                    <ul
                      className="nav nav-tabs tabModbus tabModbusLayout code-block"
                      id="ex1"
                      role="tablist"
                    >
                      {this.props.allModbusDevices?.items.map((m, index) => (
                        <li className="nav-item" role="presentation">
                          <button
                            className={
                              index === formInput.modbusIndex
                                ? "nav-link viewModbus"
                                : "nav-link hideModbus"
                            }
                            data-mdb-toggle="tab"
                            onClick={() => this.changeModbusDevice(index)}
                            role="tab"
                            aria-controls="ex1-tabs-1"
                            aria-selected="true"
                          >
                            <span style={{ fontWeight: 600 }}>
                              {m.label === "" ? "No label name" : m.label}
                              <FontAwesomeIcon
                                className="svg-icons-sm fa-sm"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Strong"
                                icon={faNetworkWired}
                              />
                            </span>
                            <span>
                              <br />
                            </span>
                            <span className="idDevicetype">
                              {m.id_device_type}{" "}
                              {m?.currentModbusTemplate?.mode === 0
                                ? "RTU"
                                : "TCP"}{" "}
                              {m?.currentModbusTemplate?.baud_rate}{" "}
                            </span>
                          </button>
                        </li>
                      ))}
                    </ul>
                    <div className="nav-item  arrowModbus" role="presentation">
                      {">"}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      padding: "5px",
                      height: "440px",
                    }}
                  >
                    <div className="card-wrap" style={{ margin: "0px" }}>
                      {this.props.allModbusDevices?.items[
                        formInput.modbusIndex
                      ].subDevices.map((p, index) => (
                        <Link
                          to={`/modbus-gateways/${this.props.match.params.projectId}/${p.id_physical_device}/device-info`}
                          className="col-md-auto mt-2"
                          key={index}
                          style={{ textDecoration: "none" }}
                        >
                          <div>
                            {p.modbusDevice !== null && (
                              <div
                                className={`${
                                  p.is_connected === 1
                                    ? "connected-device modbusGrid"
                                    : "disconnected-device modbusGrid"
                                }`}
                                style={{ width: "350px", height: "401px" }}
                              >
                                <div
                                  className={`${
                                    p.is_connected === 1
                                      ? "connected-Status"
                                      : "disconnected-Status"
                                  }`}
                                >
                                  <span
                                    className="card-key text-white"
                                    style={{
                                      fontWeight: "200",
                                      fontSize: "12px",
                                      marginRight: "90px",
                                    }}
                                  >
                                    Status
                                    {p?.is_connected === 1 ? (
                                      <div className="statusView">Online</div>
                                    ) : (
                                      <div className="statusView">Offline</div>
                                    )}
                                  </span>
                                  <div
                                    className="text-white"
                                    style={{ fontWeight: "600" }}
                                  ></div>
                                </div>

                                <div className="card-content m-2 text-dark cardName">
                                  <div>
                                    <div className="card-titles">
                                      Device name
                                    </div>
                                    <div className="card-key text-capitalize text-wrap">
                                      <div className="d-flex align-items-center">
                                        <span>{p.label}</span>
                                      </div>
                                    </div>{" "}
                                  </div>
                                  <div>
                                    <div className="card-titles">Last seen</div>
                                    <div className="card-key text-capitalize text-wrap">
                                      {p.last_data_at !== null ? (
                                        <Moment format="DD MMM YYYY HH:mm">
                                          {p.last_data_at * 1000}
                                        </Moment>
                                      ) : (
                                        <div className="mb-4"></div>
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="card-titles">
                                      {this.props.allModbusDevices?.items[
                                        formInput.modbusIndex
                                      ]?.currentModbusTemplate?.mode === 1
                                        ? "IP Address"
                                        : "Slave Id"}
                                    </div>
                                    <div className="card-key text-capitalize text-wrap">
                                      <div className="d-flex align-items-center">
                                        <span>
                                          {this.props.allModbusDevices?.items[
                                            formInput.modbusIndex
                                          ]?.currentModbusTemplate?.mode === 1
                                            ? this.props.allModbusDevices
                                                ?.items[formInput.modbusIndex]
                                                ?.currentModbusTemplate.ips[
                                                p.modbusDevice?.server_addr
                                              ]
                                            : p.modbusDevice?.server_addr}
                                        </span>
                                      </div>
                                    </div>{" "}
                                  </div>

                                  <div>
                                    <div className="card-titles">
                                      Active device alerts
                                    </div>
                                    <div className="card-key text-capitalize text-wrap">
                                      <div className="d-flex align-items-center">
                                        <span> {p.inactive_at}</span>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                                <div
                                  style={
                                    p.is_connected === 1
                                      ? {
                                          borderTop: "2px solid #11cf19",
                                        }
                                      : {
                                          borderTop: "2px solid #ba1313",
                                        }
                                  }
                                ></div>
                                <div className="code-block-view">
                                  {p?.latestData?.map(
                                    (k) =>
                                      templateData?.find(
                                        (elem) =>
                                          elem.value_id === k.datatype &&
                                          elem.addr ===
                                            p.modbusDevice?.server_addr
                                      ) !== undefined && (
                                        <div className="card-register m-2 text-dark">
                                          <div className="col-md-5">
                                            {" "}
                                            <div
                                              style={{
                                                fontWeight: 500,
                                                fontSize: "16px",
                                                marginTop: "8px",
                                              }}
                                            >
                                              <Moment format="DD MMM YYYY HH:mm">
                                                {k.timestamp}
                                              </Moment>
                                            </div>
                                          </div>
                                          <div
                                            className="col-md-5"
                                            style={{
                                              fontWeight: 600,
                                              fontSize: "20px",
                                            }}
                                          >
                                            <div style={{ display: "flex" }}>
                                              <p className="unitValue">
                                                {k.value.includes(".")
                                                  ? parseFloat(k.value).toFixed(
                                                      2
                                                    )
                                                  : k.value}
                                              </p>
                                              <p className="unitValueData">
                                                {" "}
                                                {k.unit}
                                              </p>
                                            </div>

                                            <div
                                              style={{
                                                fontWeight: 500,
                                                wordBreak: "break-word",
                                                fontSize: "16px",
                                              }}
                                              title={k.label}
                                            >
                                              {" "}
                                              {k.label}
                                            </div>
                                          </div>
                                          <div
                                            className="col-md-2"
                                            style={{
                                              marginTop: "8px",
                                              fontWeight: 400,
                                              fontSize: "32px",
                                            }}
                                          >
                                            {" "}
                                            <FontAwesomeIcon
                                              className="fa-md"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title="Chart"
                                              icon={faChartLine}
                                              style={{ marginLeft: "25px" }}
                                            />
                                          </div>
                                        </div>
                                      )
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                  <div className="divModbusModal">
                    <div className="settingsViewArea">
                      <button
                        className="settingsViewAreaCheck buttonSettings"
                        onClick={this.handleConfigureOption}
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: "10px" }}
                          className="fa-lg"
                          icon={faSliders}
                        />
                        Configure
                      </button>
                    </div>
                    <div className="settingsViewAreaGateway">
                      <button
                        className="settingsViewAreaCheck buttonSettings"
                        onClick={this.handleNewGateway}
                      >
                        <FontAwesomeIcon
                          className="svg-icons-sm fa-lg"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Strong"
                          icon={faPlusCircle}
                        />{" "}
                        Add new gateway
                      </button>
                    </div>
                    <div className="settingsViewAreaGateway">
                      <button
                        className="settingsViewAreaCheck buttonSettings"
                        onClick={this.handleClearGateway}
                      >
                        <FontAwesomeIcon
                          className="svg-icons-sm fa-lg"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Strong"
                          icon={faTrashCan}
                        />{" "}
                        Clear Modbus
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* )} */}
          </div>
        </div>
      );
    } else {
      return (
        <Fragment>
          <ModbusGatewaysSubmenu nrResults={0} />
          <div className="bacecontainer pl-2">
            <LoaderRoller />
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    allModbusDevices: state.modbusViewReducer.allModbusDevices,
    modbusEpView: state.viewHistoryReducer.lastModbusEpViewSelected,
    clearSelectGatewayModbus: state.modbusViewReducer.clearSelectGatewayModbus,
    failSelectGatewayModbus: state.modbusViewReducer.failSelectGatewayModbus,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAllModbusDevices,
    clearApplyModbus,
    getClearSelectGateway,
  })(ModbusCardView)
);
