import React, { Component, Fragment } from "react";
import CONFIG from "../../config";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import BACELogo from "../../assets/logo/BACE-logo-blue.svg";

import "./style.css";
import { showInfo } from "../../actions/notification";
import { Link } from "react-router-dom";
const { loginUrl, passResetUrl, clientId, clientSecret } = CONFIG;

class Login extends Component {
  state = {
    formInput: {
      registerCheck: false,
    },
  };

  handleRegister = () => {
    const formInput = { ...this.state.formInput };
    formInput.registerCheck = true;
    this.setState({ formInput });
  };
  render() {
    const { formInput } = this.state;
    return (
      <Fragment>
        <div className="loginImgDiv"></div>
        <img className="loginImg" src={BACELogo} alt="BACE logo" />
        <div>
          <p className="welcomeBoard"> Welcome to the BACE Dashboard </p>
        </div>
        <div>
          <div className="d-flex flex-row justify-content-center">
            <div className="bg-login-container">
              <div className="col-12">
                {" "}
                <div className="justify-content-center bace-logo">
                  <h3 className="loginView">Login to your account</h3>
                </div>
                <div className="text-center mt-4">
                  <a
                    href={decodeURIComponent(
                      `${loginUrl}?ClientId=${clientId}&ClientSecret=${clientSecret}`
                    )}
                    rel="noopener noreferrer"
                    className="login-btn"
                  >
                    &nbsp; LOGIN
                  </a>
                  <Link to="/Register" className="link-register">
                    <button
                      rel="noopener noreferrer"
                      className="login-register"
                      // onClick={this.handleRegister}
                    >
                      &nbsp; Register
                    </button>
                  </Link>
                </div>
                {/* <div className="text-center mt-4">
                  <a
                    href={passResetUrl}
                    rel="noopener noreferrer"
                    className="pass-reset-btn"
                  >
                    Forgot/change password
                  </a>
                </div> */}
              </div>
            </div>

            {/* <div className="row justify-content-center">
              <div className="m-4">
                <div className="card p-3">
                  <div className="card-body m-2">
                    <div className="mb-4">
                      <strong>Login to your Account</strong>
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        id="email_address"
                        className="form-control"
                        name="email-address"
                        placeholder="Enter Email"
                        required
                        autofocus
                      />
                    </div>

                    <div className="mb-3">
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        name="password"
                        placeholder="Enter Password"
                        required
                      />
                    </div>

                    <div>
                      <button type="submit" className="btn btn-primary">
                        Login
                      </button>
                    </div>
                    <br></br>
                    <div className="d-flex justify-content-between">
                      <Link to="/" className="specs-key-low">
                        I Forgot Your Password?
                      </Link>
                      <Link to="/" className="specs-key-low">
                        Create a BACE account
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer,
  };
}

export default withRouter(connect(mapStateToProps, { showInfo })(Login));
