import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ModbusGatewaysSubmenu from "../../components/menus/sub-menu/subtypes/ModbusGatewaysSubmenu";
import SelectModbus from "./SelectModbus";
import SetModbus from "./SetModbus";
import SetGateway from "./SetGateway";
import AddRegister from "./AddRegister";
import { logDOM } from "@testing-library/react";
class ModbusGatewaySetting extends Component {
  state = {
    formInput: {
      Tab1: true,
      Tab2: false,
      Tab3: false,
      Tab4: false,
      tabModel: false,
      selectModel: true,
      modbusDevices: false,
      addRegister: false,
      addResult: false,
      selectModelName: "",
      projectId: "",
    },
  };
  componentDidMount = () => {
    const formInput = { ...this.state.formInput };
    formInput.projectId = this.props.match.params?.projectId;
    this.setState({ formInput });
  };
  handleTab4 = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.addRegister = true;
    formInput.addResult = false;
    formInput.modbusDevices = false;
    formInput.selectModel = false;
    formInput.tabModel = false;
    this.setState({ formInput });
  };
  handleTab5 = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.addResult = true;
    formInput.addRegister = false;
    formInput.tabModel = false;
    formInput.modbusDevices = false;
    formInput.selectModel = false;
    this.setState({ formInput });
  };
  handleTab3 = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.tabModel = false;
    formInput.addRegister = false;
    formInput.modbusDevices = true;
    formInput.selectModel = false;
    formInput.addResult = false;
    this.setState({ formInput });

    this.setState({ formInput });
  };
  handleTab1 = (event) => {
    const formInput = { ...this.state.formInput };

    formInput.selectModel = true;
    formInput.tabModel = false;
    formInput.modbusDevices = false;
    formInput.addResult = false;
    formInput.addRegister = false;
    this.setState({ formInput });
  };
  handleTab2 = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.tabModel = true;
    formInput.selectModel = false;
    formInput.modbusDevices = false;
    formInput.addResult = false;
    formInput.addRegister = false;
    this.setState({ formInput });
  };
  render() {
    const formInput = this.state.formInput;
    return (
      <div>
        <div>
          <ModbusGatewaysSubmenu />
          <div>
            <ul className="nav nav-tabs tabModbus" id="ex1" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={
                    formInput.selectModel
                      ? "nav-link linkView active activeTabModbus"
                      : "nav-link linkView InactiveTabModbus"
                  }
                  id="ex1-tab-1"
                  data-mdb-toggle="tab"
                  // onClick={() => this.handleTab1("ex1-tab-1")}
                  role="tab"
                  aria-controls="ex1-tabs-1"
                  aria-selected="true"
                >
                  Select Gateway
                </button>
              </li>
              <li className="nav-item arrowItem" role="presentation">
                {">"}
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    formInput.tabModel
                      ? "nav-link linkView active activeTabModbus"
                      : "nav-link linkView InactiveTabModbus"
                  }
                  id="ex1-tab-2"
                  data-mdb-toggle="tab"
                  //  onClick={() => this.handleTab2("ex1-tab-2")}
                  role="tab"
                  aria-controls="ex1-tabs-2"
                  aria-selected="false"
                >
                  Set Gateway
                </button>
              </li>
              <li className="nav-item arrowItem" role="presentation">
                {">"}
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    formInput.modbusDevices
                      ? "nav-link linkView active activeTabModbus"
                      : "nav-link linkView InactiveTabModbus"
                  }
                  id="ex1-tab-3"
                  data-mdb-toggle="tab"
                  // onClick={() => this.handleTab3("ex1-tab-3")}
                  role="tab"
                  aria-controls="ex1-tabs-3"
                  aria-selected="false"
                >
                  Set Modbus Devices
                </button>
              </li>
              <li className="nav-item arrowItem" role="presentation">
                {">"}
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    formInput.addRegister
                      ? "activeTabModbus nav-link active linkView "
                      : "InactiveTabModbus nav-link linkView"
                  }
                  id="ex1-tab-4"
                  data-mdb-toggle="tab"
                  // onClick={() => this.handleTab4("ex1-tab-4")}
                  role="tab"
                  aria-controls="ex1-tabs-4"
                  aria-selected="false"
                >
                  Add registers
                </button>
              </li>{" "}
              <li className="nav-item arrowItem" role="presentation">
                {">"}
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    formInput.addResult
                      ? "activeTabModbus nav-link linkView active "
                      : "InactiveTabModbus nav-link linkView"
                  }
                  id="ex1-tab-4"
                  data-mdb-toggle="tab"
                  // onClick={() => this.handleTab5("ex1-tab-5")}
                  role="tab"
                  aria-controls="ex1-tabs-5"
                  aria-selected="false"
                >
                  Result
                </button>
              </li>
            </ul>
            <div className="mb-3 switch-modbus-line"></div>
            <div className="tab-content" id="ex1-content">
              <div
                className={
                  formInput.Tab1 ? "tab-pane fade show active" : "tab-pane fade"
                }
                //class="tab-pane fade show active"
                id="ex1-tabs-1"
                role="tabpanel"
                aria-labelledby="ex1-tab-1"
              >
                <SelectModbus
                  nextTab={this.handleTab2}
                  nextModbusDevicesTab={this.handleTab3}
                  nextRegisterDevicesTab={this.handleTab4}
                  nextResult={this.handleTab5}
                  modeName={formInput.selectModelName}
                  previousTab={this.handleTab1}
                  previousSetTab={this.handleTab2}
                  previousModbusTab={this.handleTab3}
                  previousRegisterTab={this.handleTab4}
                  projectId={formInput.projectId}
                />
              </div>
              {/* <div
                className={
                  formInput.Tab2 ? "tab-pane fade show active" : "tab-pane fade"
                }
                // class="tab-pane fade"
                id="ex1-tabs-2"
                role="tabpanel"
                aria-labelledby="ex1-tab-2"
              >
                <SetGateway
                  previousTab={this.handleTab1}
                  nextTab={this.handleTab3}
                 // modeName={formInput.selectModelName}
                />
              </div> */}
              <div
                className={
                  formInput.Tab3 ? "tab-pane fade show active" : "tab-pane fade"
                }
                //  class="tab-pane fade"
                id="ex1-tabs-3"
                role="tabpanel"
                aria-labelledby="ex1-tab-3"
              >
                Tab 3 content
                <SetModbus />
              </div>
              <div
                className={
                  formInput.Tab4 ? "tab-pane fade show active" : "tab-pane fade"
                }
                //  class="tab-pane fade"
                id="ex1-tabs-3"
                role="tabpanel"
                aria-labelledby="ex1-tab-3"
              >
                Tab 4 content
                <AddRegister />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps, {})(ModbusGatewaySetting));
