import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Switch from "@material-ui/core/Switch";
import LoaderRoller from "../../components/loader/LoaderRoller";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNetworkWired,
  faChartLine,
  faPlusCircle,
  faSliders,
  faTimesCircle,
  faTimes,
  faCheckCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import "./style.css";
import {
  createNewWebhook,
  getAllWebhooksOverview,
  clearNewWebhook,
  updateWebhook,
  clearEditWebhook,
} from "../../actions/session-view";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import WebhookEditSubmenu from "../../components/menus/sub-menu/subtypes/WebhookEditSubmenu";

class WebhookEdit extends Component {
  state = {
    formInput: {
      projectId: "",
      status: true,
      labelName: "",
      forwardingType: "1",
      formatterType: "v1",
      urlValue: "",
      authorization: "",
      labelNameError: false,
      URLNameError: false,
      dialogBox: false,
      webhookNameUpdated: "",
      webhookId: "",
      statusValue: false,
    },
  };

  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;

    if (!currentPageNr) {
      return this.props.history.push(
        `/webhook/edit/1/${this.props.match.params?.projectId}/${this.props.match.params?.webhookId}`
      );
    }

    this.props.clearEditWebhook();
    this.props.getAllWebhooksOverview(this.props.match.params?.projectId);

    const updatedWebhookView = this.props.webhooksOverviewData.items.filter(
      (o) => o.id_webhook === this.props.match.params?.webhookId
    )[0];

    const formInput = this.state.formInput;
    formInput.projectId = this.props.match.params?.projectId;
    formInput.status = updatedWebhookView?.disabled_at;
    formInput.forwardingType = updatedWebhookView?.forwarding_type;
    formInput.formatterType = updatedWebhookView?.formatter;
    formInput.urlValue = updatedWebhookView?.webhook_url;
    formInput.authorization = updatedWebhookView?.authorization;
    formInput.webhookId = updatedWebhookView?.id_webhook;
    formInput.labelName = updatedWebhookView?.label;
    this.setState({ formInput });

    // this.props.clearNewWebhook();
  };

  componentWillUnmount = () => {};

  handleLabelChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.labelName = event.target.value;
    formInput.labelNameError = false;
    this.setState({ formInput });
  };

  handleForwardingTypeChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.forwardingType = event.target.value === "Measurements" ? 1 : 2;
    this.setState({ formInput });
  };

  handleFormatterTypeChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.formatterType = event.target.value;
    this.setState({ formInput });
  };

  handleURLChange = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput[input.name] = input.value;
    formInput.URLNameError = false;
    formInput.urlValue = input.value;
    this.setState({ formInput });
  };

  handleAuthorizationChange = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput[input.name] = input.value;
    formInput.authorization = input.value;
    this.setState({ formInput });
  };

  handleToConnectionCancel = () => {
    return this.props.history.push(
      `/webhook/overview/1/${this.props.match.params?.projectId}`
    );
  };

  toggleWebhookChecked = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.status = event === true ? Date.now() : null;
    this.setState({ formInput });
  };

  handleToUpdateWebhook = (event) => {
    const formInput = { ...this.state.formInput };

    if (formInput.labelName === "") {
      formInput.labelNameError = true;
      formInput.URLNameError = false;
      this.setState({ formInput });
    } else if (formInput.urlValue === "") {
      formInput.URLNameError = true;
      formInput.labelNameError = false;
      this.setState({ formInput });
    } else {
      this.props.updateWebhook(formInput, this.props.match.params?.projectId);
    }
  };

  handleToConnectionClose = () => {
    if (this.props.updatedwebhookStatus === "pass") {
      const projectId = this.props.match.params?.projectId;
      this.props.getAllWebhooksOverview(projectId);
      return this.props.history.push(
        `/webhook/overview/1/${this.props.match.params?.projectId}`
      );
      // this.props.getAllProject();
    }
    const formInput = { ...this.state.formInput };
    formInput.dialogBox = false;
    this.setState({ formInput });
  };

  render() {
    const formInput = this.state.formInput;
    formInput.projectId = this.props.match.params?.projectId;
    const updatedWebhookDetails = this.props.webhooksOverviewData.items.filter(
      (o) => o.id_webhook === this.props.match.params?.webhookId
    )[0];

    return (
      <div>
        {this.props.updatedFailedWebhookStatus === "failed" && (
          <div>
            <Dialog
              open={formInput.dialogBox ? true : false}
              onClose={this.handleToConnectionClose}
            >
              <div onClick={this.handleToConnectionClose}>
                <button className="cancelViewIcon cancelErrorWifi">
                  <FontAwesomeIcon
                    className="svg-icons-sm wifiView"
                    data-toggle="tooltip"
                    data-placement="top"
                    icon={faTimes}
                  />
                </button>
              </div>
              <DialogTitle>
                <div>
                  {" "}
                  <FontAwesomeIcon
                    className="fa-lg"
                    style={{
                      marginLeft: "10px",
                      width: "100%",
                      fontSize: "45px",
                      color: "#ec0000",
                    }}
                    icon={faTimesCircle}
                  />
                </div>
                <p className="infoTemplate">
                  Webhook is n't updated sucessfully.
                </p>
              </DialogTitle>
            </Dialog>
          </div>
        )}
        {this.props.updatedwebhookStatus === "pass" && (
          <div>
            <Dialog open={true} onClose={this.handleToConnectionClose}>
              <div onClick={this.handleToConnectionClose}>
                <button className="cancelViewIcon cancelErrorWifi">
                  <FontAwesomeIcon
                    className="svg-icons-sm wifiView"
                    data-toggle="tooltip"
                    data-placement="top"
                    icon={faTimes}
                  />
                </button>
              </div>
              <DialogTitle>
                <div>
                  {" "}
                  <FontAwesomeIcon
                    className="fa-lg"
                    style={{
                      marginLeft: "10px",
                      width: "100%",
                      fontSize: "45px",
                      color: "green",
                    }}
                    icon={faCheckCircle}
                  />
                </div>
                <p className="infoTemplate">Webhook is updated sucessfully.</p>
              </DialogTitle>
            </Dialog>
          </div>
        )}{" "}
        <div>
          <div style={{ height: "700px" }}>
            <WebhookEditSubmenu webhookName={updatedWebhookDetails?.label} />
            <div className="createDiv">
              <div>Status</div>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formInput.status === null ? true : false}
                      color="primary"
                      onChange={(e) =>
                        this.toggleWebhookChecked(
                          formInput.status === null ? true : false
                        )
                      }
                    />
                  }
                />
                <div>{formInput.status === null ? "Enable" : "Disable"}</div>
              </div>
              <div style={{ marginTop: "24px" }}>Webhook Label</div>
              <input
                className="form-control"
                //  value={formInput.webhookNameUpdated}
                defaultValue={updatedWebhookDetails?.label}
                name="labelName"
                type="text"
                onChange={this.handleLabelChange}
                placeholder="Enter a label for the webhook(i.e. webhook to Azure Event Hub)"
              />

              <div style={{ marginTop: "24px" }}>Forwarding Type</div>
              <select
                style={{ marginRight: "10px" }}
                className="form-select dropdownView"
                aria-label="Default select example"
                label="status"
                defaultValue={
                  updatedWebhookDetails.forwarding_type === 1
                    ? "Measurements"
                    : "Events"
                }
                onChange={this.handleForwardingTypeChange}
              >
                <option>Measurements</option>
                <option>Events</option>
              </select>

              <div style={{ marginTop: "24px" }}>Webhook Formatter Type</div>
              <select
                style={{ marginRight: "10px" }}
                className="form-select dropdownView"
                aria-label="Default select example"
                label="status"
                onChange={this.handleFormatterTypeChange}
                defaultValue={updatedWebhookDetails.formatter}
              >
                <option selected>v1</option>
                <option>v2a</option>
                <option>v2b</option>
                <option>v3a</option>
                <option> v3b</option>
              </select>

              <div style={{ marginTop: "24px" }}>Webhook URL</div>
              <input
                className="form-control"
                defaultValue={updatedWebhookDetails.webhook_url}
                name="sessionId"
                type="text"
                onChange={this.handleURLChange}
                placeholder="URL of the webhookto post it to"
              />

              <div style={{ marginTop: "24px" }}>Authorization</div>

              <textarea
                className="form-control"
                defaultValue={updatedWebhookDetails.authorization}
                name="sessionId"
                onChange={this.handleAuthorizationChange}
                placeholder="If an Authorization header needs to be sent, add it here. If it is a Bearer token, include the Bearer text."
              ></textarea>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "25px" }}>
          <div
            className={
              formInput.labelNameError ? "showErrorMsgMode" : "hideMode"
            }
          >
            Webhook Label can not be empty.
          </div>
          <div
            className={formInput.URLNameError ? "showErrorMsgMode" : "hideMode"}
          >
            Webhook Url can not be empty.
          </div>
        </div>
        <div className="col pb-3">
          <div className="d-flex divButton">
            <button
              className={"btn-cancel mx-1"}
              onClick={this.handleToConnectionCancel}
            >
              Cancel
            </button>{" "}
            <button
              className={"btn-cancel submitButton"}
              onClick={() => this.handleToUpdateWebhook()}
            >
              <Fragment>Update</Fragment>
              {/* )} */}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    webhooksOverviewData: state.sessionReducer.webhooksOverviewData,
    updatedwebhookStatus: state.sessionReducer.updatedwebhookStatus,
    updatedFailedWebhookStatus: state.sessionReducer.updatedFailedWebhookStatus,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAllWebhooksOverview,
    updateWebhook,
    clearEditWebhook,
  })(WebhookEdit)
);
