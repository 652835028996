import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faCircleInfo,
  faCircleUser,
} from "@fortawesome/pro-regular-svg-icons";
import TopMenu from "../../../../components/menus/top-menu/TopMenu";
import { deleteWholeProject } from "../../../../actions/list-view";
import { settingsEpViewChange } from "../../../../actions/view-history";
import MemberImg from "../../../../assets/Icons/Member.png";
import MemberLight from "../../../../assets/Icons/MemberLight.png";
import infoSolid from "../../../../assets/Icons/infoSolid.png";
import info from "../../../../assets/Icons/info.png";
class SettingsSubmenu extends Component {
  state = {
    formInput: {
      projectId: "",
      isMouseOver:false, 
      isMouseOverMember:false, 
      
    },
  };
  
  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;
    // const projectId = window.location.pathname.slice(29);
    if (this.props.lastSettingsEpViewSelected === "LIST_VIEW") {
      return this.props.history.push(
        `/settings/members/1/${this.props.match.params?.projectId}`
      );
    }
  };
  handleMouseOver = () => {
    const formInput = { ...this.state.formInput };
    formInput.isMouseOver = true
    this.setState({ formInput })
    
  }
  handleMouseOverMember = () => {
    const formInput = { ...this.state.formInput };
    formInput.isMouseOverMember = true
    this.setState({ formInput })
    
  }
  
   handleMouseOut = () => {
     const formInput = { ...this.state.formInput };
     formInput.isMouseOver = false
     this.setState({ formInput })
     
  }
  handleMouseOutMember = () => {
    const formInput = { ...this.state.formInput };
    formInput.isMouseOverMember = false
    this.setState({ formInput })
    
 }
  render() {
    const formInput = this.state.formInput;
    formInput.projectId = this.props.projectId;
    return (
      <div className="row">
        <div style={{ display: "flex" }}>
          <h3 className="p-3 color-ocean-blue modbusDevice">Settings</h3>

          <TopMenu />
        </div>
        <div className="mb-3 switch-btn-line">
          {" "}
          <div className="divModal" style={{ marginTop: "12px" }}>
            <Link
              onClick={() => {
                this.props.settingsEpViewChange("CARD_VIEW");
              }}
              to={`/settings/info-view/1/${this.props.projectId}`}
              className={` ${
                this.props.location.pathname.includes("info-view")
                  ? "bacelink-active"
                  : "bacelink-inactive"
              }`}
              onMouseOver={this.handleMouseOver}
              onMouseOut={this.handleMouseOut}
            >
              {/* <FontAwesomeIcon
                className="svg-icons-sm fa-lg"
                data-toggle="tooltip"
                data-placement="top"
                title="Strong"
                icon={
                ` ${
                  this.props.location.pathname.includes("members")
                  ? info
                  : info
              }`}
            />*/}
             {/*<img 
              src={
                ` ${
                  this.props.location.pathname.includes("members")
                  ? info
                  : infoSolid
              }`}  title="Member" 
              
              />*/}
              {this.props.location.pathname.includes("info-view") ? 
              <img
                src={infoSolid}
                alt="Member"
               />
              : <img
              src={ formInput.isMouseOver ? infoSolid : info }
              alt="Member"
             />
              }
              {" "}
              Project Info
            </Link>

            <Link
              onClick={() => {
                this.props.settingsEpViewChange("LIST_VIEW");
              }}
              to={`/settings/members/1/${this.props.projectId}`}
              className={` ${
                this.props.location.pathname.includes("members")
                  ? "bacelink-active"
                  : "bacelink-inactive"
              }`}
              onMouseOver={this.handleMouseOverMember}
              onMouseOut={this.handleMouseOutMember}
            >
                {/* <FontAwesomeIcon
                className="svg-icons-sm fa-lg"
                data-toggle="tooltip"
                data-placement="top"
                title="Strong"
                icon={faCircleUser}
            /> */}
           
            {
             this.props.location.pathname.includes("members") ?
             <img 
              src={MemberImg
                
              }  title="Member"
              
              />
             : <img 
             src={
              formInput.isMouseOverMember 
                 
                  ?  MemberImg
                  : MemberLight
              
              
             }  title="Member"
             
             />
            }
              
              {" "}
              Members
              {/* <FontAwesomeIcon className="fa-lg" icon={faListUl} /> */}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lastSettingsEpViewSelected: state.viewHistoryReducer.lastBaceEpViewSelected,
    allProjectData: state.listViewReducer?.allProjectData,
    projectNameDeleteNotPossible:
      state.listViewReducer?.projectNameDeleteNotPossible,
    projectNameDeletePossible: state.listViewReducer?.projectNameDeletePossible,
  };
}

export default withRouter(
  connect(mapStateToProps, { settingsEpViewChange, deleteWholeProject })(
    SettingsSubmenu
  )
);
