import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import BleGatewaysSubmenu from "../../components/menus/sub-menu/subtypes/BleGatewaysSubmenu";
import DeviceListCard from "../../components/device-cards/DeviceListCard";
import { getListViewData, clearListViewData } from "../../actions/list-view";
import LoaderRoller from "../../components/loader/LoaderRoller";

class DeviceListView extends Component {
  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;
    if (!currentPageNr) {
      return this.props.history.push("/ble-gateways/list-view/1");
    }
    return this.props.getListViewData("ble-gateways", currentPageNr);
  };

  componentDidUpdate = (prevProps) => {
    const { currentPageNr } = this.props.match.params;

    if (this.props.location !== prevProps.location) {
      const sortColumn = new URLSearchParams(this.props.location.search).get("sortColumn");
      const sortDirection = new URLSearchParams(this.props.location.search).get("sortDirection");

      return this.props.getListViewData("ble-gateways", currentPageNr, sortColumn, sortDirection);
    }
  }

  componentWillUnmount = () => {
    if (!window.location.pathname.startsWith('/ble-gateways')) {
      this.props.clearListViewData();
    }
  }

  render() {
    if (this.props.listViewData) {
      const subtypeItems = this.props.listViewData.items.filter(
        (data) => data.subtype === "ble-gateways"
      );

      return (
        <Fragment>
          <BleGatewaysSubmenu nrResults={subtypeItems.length} />
          <div className="bacecontainer pl-2">
            <DeviceListCard
              subtype={"ble-gateways"}
              subpage={"device-info"}
              deviceData={subtypeItems}
            />
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <BleGatewaysSubmenu nrResults={0} />
          <div className="bacecontainer pl-2">
            <LoaderRoller />
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return { 
    listViewData: state.listViewReducer.listViewData 
  };
}

export default withRouter(
  connect(mapStateToProps, { getListViewData, clearListViewData })(DeviceListView)
);
