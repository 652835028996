import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import BaceEpSubmenu from "../../components/menus/sub-menu/subtypes/BaceEpSubmenu";
import BaceEpListCard from "../../components/device-cards/BaceEpListCard";
import { getListViewData, clearListViewData } from "../../actions/list-view";
import LoaderRoller from "../../components/loader/LoaderRoller";

class DeviceListView extends Component {
  componentDidMount = () => {
    const projectId = window.location.pathname.slice(21);
    if (this.props.baceEpView === "CARD_VIEW") {
      return this.props.history.push(`/bace-ep/card-view/1/${projectId}`);
    }

    const { currentPageNr } = this.props.match.params;
    if (!currentPageNr) {
      this.props.history.push(`/bace-ep/list-view/1/${projectId}`);
    }
    const filterParams = `filter[id_project]=${projectId}&filter[inactive_at]=NULL`;
    return this.props.getListViewData(
      "bace-ep",
      currentPageNr,
      "",
      filterParams
    );
    // return this.props.getListViewData(currentPageNr);
  };

  componentDidUpdate = (prevProps) => {
    const { currentPageNr } = this.props.match.params;
    const projectId = window.location.pathname.slice(21);
    if (this.props.location !== prevProps.location) {
      const sortColumn = new URLSearchParams(this.props.location.search).get(
        "sortColumn"
      );
      const sortDirection = new URLSearchParams(this.props.location.search).get(
        "sortDirection"
      );

      if (this.props.location?.pathname === "/bace-ep/list-view/1") {
        const filterParams = `filter[id_project]=${projectId}&filter[inactive_at]=NULL`;
        return this.props.getListViewData(
          currentPageNr,
          sortColumn,
          sortDirection,
          filterParams
        );
      }
    }
  };

  render() {
    if (this.props.listViewData) {
      return (
        <Fragment>
          <BaceEpSubmenu
            nrResults={this.props.listViewData._meta.totalCount}
            projectId={window.location.pathname.slice(21)}
          />
          <div className="gatewaysList pl-2">
            <BaceEpListCard
              subtype={"bace-ep"}
              subpage={"measurement-data"}
              // deviceData={this.props.listViewData.items}
            />
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <BaceEpSubmenu nrResults={0} />
          <div className="bacecontainer pl-2">
            <LoaderRoller />
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    listViewData: state.listViewReducer.listViewData,
  };
}

export default withRouter(
  connect(mapStateToProps, { getListViewData, clearListViewData })(
    DeviceListView
  )
);
