import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ModbusGatewaysSubmenu from "../../components/menus/sub-menu/subtypes/ModbusGatewaysSubmenu";
import DeviceListCard from "../../components/device-cards/DeviceListCard";
import { getListViewData, clearListViewData } from "../../actions/list-view";
import LoaderRoller from "../../components/loader/LoaderRoller";

class DeviceListView extends Component {
  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;
    const projectId = window.location.pathname.slice(29);

    if (!currentPageNr) {
      return this.props.history.push(
        `/modbus-gateways/list-view/1/${projectId}`
      );
    }
    return this.props.getListViewData("modbus-gateways", currentPageNr);
  };

  componentDidUpdate = (prevProps) => {
    const { currentPageNr } = this.props.match.params;

    if (this.props.location !== prevProps.location) {
      const sortColumn = new URLSearchParams(this.props.location.search).get(
        "sortColumn"
      );
      const sortDirection = new URLSearchParams(this.props.location.search).get(
        "sortDirection"
      );

      return this.props.getListViewData(
        "modbus-gateways",
        currentPageNr,
        sortColumn,
        sortDirection
      );
    }
  };

  componentWillUnmount = () => {
    if (!window.location.pathname.startsWith("/modbus-gateways")) {
      this.props.clearListViewData();
    }
  };

  render() {
    if (this.props.listViewData) {
      return (
        <Fragment>
          <ModbusGatewaysSubmenu
            nrResults={this.props.listViewData?._meta?.totalCount}
          />
          <div className="bacecontainer pl-2">
            <DeviceListCard
              subtype={"modbus-gateways"}
              subpage={"measurement-data"}
            />
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <ModbusGatewaysSubmenu nrResults={0} />
          <div className="bacecontainer pl-2">
            <LoaderRoller />
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    listViewData: state.listViewReducer.listViewData,
  };
}

export default withRouter(
  connect(mapStateToProps, { getListViewData, clearListViewData })(
    DeviceListView
  )
);
