import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "@material-ui/lab/Pagination";
import "./sensor.css";
import {
  faLink,
  faEdit,
  faAngleDown,
  faAngleUp,
  faTrashCan,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/pro-regular-svg-icons";
import {
  getConfigListViewData,
  clearDeleteConfigData,
  clearUpdateConfigData,
} from "../../actions/list-view";
import DownIcon from "@material-ui/icons/ArrowDropDown";
import UpIcon from "@material-ui/icons/ArrowDropUp";
import StatusPopup from "../popups/StatusPopup";
import DeletePopup from "../popups/DeletePopup";
import EditTemplatePopup from "../popups/EditTemplatePopup";
import StatusPopUpDelete from "../popups/StatusPopUpDelete";

class BaceEpConfigCard extends Component {
  state = {
    expandedRows: [],
    formInput: {
      label: "",
      templateName: "",
      templateId: "",
      created_at: "",
      updated_at: "",
      labelName: "",
      location: true,
      popup: false,
      popupScreen: 0,
      editPopup: false,
      editPopupScreen: 0,
      editViewDetails: "",
    },
  };

  handleChange = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput[input.name] = input.value;
    formInput.labelName = input.value;
    this.setState({ formInput });
    this.handleFilterChange();
  };

  handleFilterChange = () => {
    setTimeout(() => {
      this.props.getConfigListViewData("bace-ep", 1, "", "", this.getFilters());
    }, 2000);
  };

  getFilters = () => {
    const formInput = { ...this.state.formInput };
    let fl = {
      "filter[label][like]": formInput.labelName
        ? formInput.labelName
        : undefined,
    };
    let params = Object.entries(fl)
      .filter((e) => e[1] !== undefined)
      .map((e) => {
        return e.join("=");
      })
      .join("&");
    return params;
  };

  handleLocationChange = () => {
    const formInput = { ...this.state.formInput };
    formInput.location = !formInput.location;
    this.setState({ formInput });
  };
  handleExpand = (template) => {
    const newExpandedRows = [...this.state.expandedRows];
    const idxFound = newExpandedRows.findIndex((id) => {
      return id === template.created_at;
    });
    if (idxFound > -1) {
      newExpandedRows.splice(idxFound, 1);
    } else {
      newExpandedRows.push(template.created_at);
    }
    this.setState({ expandedRows: [...newExpandedRows] });
  };

  popupDeleteScreen = () => {
    const formInput = { ...this.state.formInput };
    formInput.popupScreen = 1;
    this.setState({ formInput });
    this.props.clearDeleteConfigData();
  };

  editPopupScreen = (template) => {
    const formInput = { ...this.state.formInput };
    formInput.editPopupScreen = 1;
    this.setState({ formInput });
  };
  isExpanded = (template) => {
    const idx = this.state.expandedRows.find((id) => {
      return id === template.created_at;
    });
    return idx > -1;
  };
  handleToOpen = (data) => {
    const formInput = { ...this.state.formInput };
    formInput.popup = true;
    formInput.templateName = data.label;
    formInput.templateId = data.id_config_template;
    this.setState({ formInput });
  };
  openEditPopup = (data) => {
    const formInput = { ...this.state.formInput };
    formInput.editPopup = true;
    formInput.editViewDetails =
      this.props.listConfigViewData?.data?.items.filter(
        (m) => m.id_config_template === data.id_config_template
      )[0];
    formInput.templateName = data.label;
    formInput.templateId = data.id_config_template;
    this.setState({ formInput });
  };
  closeEditPopup = () => {
    const formInput = { ...this.state.formInput };
    formInput.editPopup = false;
    formInput.editPopupScreen = 0;
    this.setState({ formInput });
    this.props.clearUpdateConfigData();
  };
  handleToClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.popup = false;
    formInput.editPopup = false;
    formInput.popupScreen = 0;
    this.setState({ formInput });
    this.props.clearDeleteConfigData();
  };

  getRows = (template) => {
    const { formInput } = this.state;
    const rows = [];
    const combinedArr = [];
    const configPresets = template.deviceConfigPresets || [];

    //filter data from configPresets array
    const getValues = configPresets.filter(
      (data) =>
        data.config_key ===
          "properties.reported.settings.pushToCloudWithCellularEveryXms" ||
        data.config_key === "properties.reported.settings.sampleEveryXms" ||
        data.config_key ===
          "properties.reported.settings.pushToCloudWithWiFiEveryXms" ||
        data.config_key === "properties.reported.settings.locationConsentGiven"
    );

    //get object with combination of {config_key:desired_value}
    getValues.map((e) => {
      const configObj = { [e.config_key]: e.desired_value };
      combinedArr.push(configObj);
      return combinedArr;
    });

    //add data in one object using reduce function
    const configPresetsData = combinedArr.reduce(
      (r, c) => Object.assign(r, c),
      {}
    );
    const firstRow = (
  <>

  <tr key={template.id_config_template}>
  <div className={`${formInput.popup ? "popup d-block" : "popup d-none"}`}  >
  {formInput.popupScreen === 0 && (
    <DeletePopup
      label={formInput.templateName}
      templateId={formInput.templateId}
      handleToClose={this.handleToClose}
      popupDeleteScreen={this.popupDeleteScreen}
      projectId={this.props.projectId}
    />
  )}
  {formInput.popupScreen === 1 && this.props.deletedConfigById === 'pass' ? (
    <StatusPopUpDelete
      closePopup={this.handleToClose}
      message="Removing Complete!"
      className="online"
      icon={faCircleCheck}
    />
  ) : (
    this.props.deletedConfigById === 'failed' && (
      <StatusPopUpDelete
        closePopup={this.handleToClose}
        message="Removing failed, Please try again!"
        className="offline"
        icon={faCircleXmark}
      />
    )
  )}
</div>

          <div
          
            className={`${
              formInput.editPopup ? "EditPopup d-block" : "EditPopup d-none"
            }`}
          >
            {this.state.formInput.editPopup &&
              this.state.formInput.editPopupScreen === 0 && (
                <EditTemplatePopup
                  label={this.state.formInput.templateName}
                  templateId={this.state.formInput.templateId}
                  editViewDetails={this.state.formInput.editViewDetails}
                  closeEditPopup={this.closeEditPopup}
                  editPopupScreen={this.editPopupScreen}
                  projectId={this.props.projectId}
                />
              )}
            {formInput.editPopupScreen === 1 &&
              (this.props.updatedConfigById === "pass" ? (
                <StatusPopUpDelete
                  closePopup={this.closeEditPopup}
                  message="Editing Complete!"
                  className="online"
                  icon={faCircleCheck}
                />
              ) : (
                this.props.updatedConfigById === "failed" && (
                  <StatusPopUpDelete
                    closePopup={this.closeEditPopup}
                    message="Removing failed, Please try again!"
                    className="offline"
                    icon={faCircleXmark}
                  />
                )
              ))}
          </div>
        </tr>
        <tr>
          <td>
            <span>{template.label}</span>
          </td>
          <td>
            {template.created_at ? (
              <Moment format="DD MMM YYYY HH:mm">
                {template.created_at * 1000}
              </Moment>
            ) : (
              ""
            )}
          </td>
          <td>
            {template.updated_at ? (
              <Moment format="DD MMM YYYY HH:mm">
                {template.updated_at * 1000}
              </Moment>
            ) : (
              ""
            )}
          </td>
          <td style={{ width: "8rem" }}>
            {configPresets && (
              <div onClick={() => this.handleExpand(template)}>
                {this.isExpanded(template) ? (
                  <FontAwesomeIcon style={{fontSize:"24px"}} className="m-2" icon={faAngleUp} />
                ) : (
                  <FontAwesomeIcon style={{fontSize:"24px"}} className="m-2" icon={faAngleDown} />
                )}
              </div>
            )}
          </td>
          <td>
            <button
              className="btn-border-none"
              onClick={() => this.openEditPopup(template)}
            >
              {" "}
              <FontAwesomeIcon style={{fontSize:"24px"}} className="fa-1x" icon={faEdit} />
            </button>
          </td>
          
          <td>
            <button
              className="btn-border-none"
              onClick={() => this.handleToOpen(template)}
            >
              <FontAwesomeIcon style={{fontSize:"24px",marginLeft: "10px"}}  icon={faTrashCan} />
            </button>
          </td>
        </tr>
      </>
    );
    rows.push(firstRow);

    if (this.isExpanded(template) && configPresets.length > 0) {
      const data = (
        // <tbody style={{ background: "#EAEDF8" }}>
        //   <td>2ddd</td> <td>2ddd</td> <td>2ddd</td> <td>2ddd</td> <td></td>{" "}
        //   <td></td> <td></td>
        // </tbody>
        <tbody
          key={configPresets}
          className="mt-2"
          style={{ background: "var(--10-sea, #EAEDF8)", width: "100% !important" }}
        >
          <td>
            <span className="">{`Cloud <-> Gateway`}</span>
            <div className="template-details">
              <div className="grid-text" style={{ columnGap: "10%" }}>
                <div>Interval cellular:</div>

                <div>
                  {configPresetsData[
                    "properties.reported.settings.pushToCloudWithCellularEveryXms"
                  ] * 0.001}
                  &nbsp; sec
                </div>
              </div>
              <div className="grid-text" style={{ columnGap: "10%" }}>
                <div>Interval WIFI/Ethernet</div>
                <div>
                  {configPresetsData[
                    "properties.reported.settings.pushToCloudWithWiFiEveryXms"
                  ] * 0.001}
                  &nbsp; sec
                </div>
              </div>
            </div>
          </td>
          <td>
            <span>Consent</span>

            <div className="template-details d-flex">
              <div>Location: &nbsp; &nbsp; &nbsp; &nbsp;</div>
              <div>
                <label
                  className="form-check-label"
                  htmlFor="flexSwitchCheckChecked"
                >
                  {configPresetsData[
                    "properties.reported.settings.locationConsentGiven"
                  ] === "1"
                    ? "On"
                    : "Off"}
                </label>
              </div>
            </div>
          </td>
          <td>
            <span>{`Gateway <-> Asset`}</span>
            <div className="template-details">
              {Math.floor(
                configPresetsData["properties.reported.settings.sampleEveryXms"]
              ) * 0.001 } 
              &nbsp; sec
            </div>
          </td>

          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tbody>
      );

      rows.push(data);
    }
    return rows;
  };

  handlePageChange = (event, value) => {
    this.props.getConfigListViewData("bace-ep", value);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  render() {
    const { formInput } = this.state;
    const { pathname } = this.props.location;
    const { currentPageNr } = this.props.match.params;
    const baseRoute = currentPageNr
      ? pathname.substring(0, pathname.lastIndexOf("/"))
      : pathname;

    const sortColumn = new URLSearchParams(this.props.location.search).get(
      "sortColumn"
    );
    const sortDirection = new URLSearchParams(this.props.location.search).get(
      "sortDirection"
    );
    const newSortDirection = sortDirection
      ? sortDirection === "ASC"
        ? "DESC"
        : "ASC"
      : "DESC";

    const templateRows = this.props.listConfigViewData?.data?.items?.map(
      (template) => {
        return this.getRows(template);
      }
    );
    // ?.filter((item) => item.label.toLowerCase().includes(formInput.label)) //filter data for input

    return (
      <div>
        <div className="addListNew m-3 card-pNew">
          <table className="table borderless">
            <thead className="header">
              <tr>
                <th scope="col">
                  <Link
                    className="specs-key-low link-text clickable"
                    scope="col"
                    to={`${baseRoute}/1?sortColumn=label&sortDirection=${newSortDirection}`}
                  >
                    Template name
                  </Link>
                  {sortColumn === "label" ? (
                    sortDirection === "ASC" ? (
                      <DownIcon />
                    ) : (
                      <UpIcon />
                    )
                  ) : (
                    ""
                  )}
                </th>
                <th scope="col">
                  {" "}
                  <Link
                    className="specs-key-low link-text clickable"
                    scope="col"
                    to={`${baseRoute}/1?sortColumn=created_at&sortDirection=${newSortDirection}`}
                  >
                    Created at
                  </Link>
                  {sortColumn === "created_at" ? (
                    sortDirection === "ASC" ? (
                      <DownIcon />
                    ) : (
                      <UpIcon />
                    )
                  ) : (
                    ""
                  )}
                </th>
                <th scope="col">
                  {" "}
                  <Link
                    className="specs-key-low link-text clickable"
                    scope="col"
                    to={`${baseRoute}/1?sortColumn=updated_at&sortDirection=${newSortDirection}`}
                  >
                    Updated at
                  </Link>
                  {sortColumn === "updated_at" ? (
                    sortDirection === "ASC" ? (
                      <DownIcon />
                    ) : (
                      <UpIcon />
                    )
                  ) : (
                    ""
                  )}
                </th>

                <th scope="col"></th>
                <th scope="col" className="specs-key-low">
                  Edit
                </th>
                
                <th scope="col" className="specs-key-low">
                  Remove
                </th>
              </tr>
            </thead>
            <td >
              <input
                className=" inputSearch "
                defaultValue={formInput.label}
                name="label"
                type="text"
                placeholder="Template name"
                onChange={this.handleChange}
              />
            </td>
            {templateRows}
          </table>
        </div>
        {this.props.listConfigViewData?.data?._meta?.pageCount > 1 ? (
          <div className="pageStyle mt-2">
            <Pagination
              count={this.props.listConfigViewData?.data?._meta?.pageCount}
              page={this.props.listConfigViewData?.data?._meta?.currentPage}
              onChange={this.handlePageChange}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    deletedConfigById: state.listViewReducer?.deletedConfigById,
    updatedConfigById: state.listViewReducer?.updatedConfigById,
    listConfigViewData: state.listViewReducer.listConfigViewData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getConfigListViewData,
    clearUpdateConfigData,
    clearDeleteConfigData,
  })(BaceEpConfigCard)
);
