import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import LoaderRoller from "../../components/loader/LoaderRoller";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNetworkWired,
  faChartLine,
  faPlusCircle,
  faSliders,
} from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import OneBaceEpSubmenu from "../../components/menus/sub-menu/subtypes/OneBaceEpSubmenu";
import {
  getDeviceData,
  clearDeviceData,
  getDeviceInfoData,
  getDeviceEventInfoData,
  clearListViewData,
  getAllProjectData,
  getAllChartData,
} from "../../actions/list-view";
import "./styles.css";
import DelaMeasurementChart from "./DelaMeasurementChart";
import TinySpinner from "../../components/loader/TinySpinner";
class DellaMeasurementData extends Component {
  state = {
    formInput: {
      projectId: "",
      deviceLabel: "",
      combinedDatasets: [],
      filterOptions: [],
      modifyFilterOptions: [],
      checkedItems: [],
      checkedItemsLabel: [],
      // startDate: "Math.round(new Date().getTime() / 1000)",
      // endDate: Math.round(new Date().getTime() / 1000),
      startDate: "",
      endDate: "",
      endDatePass: "",
      startDatePass: "",
      chartdataList: [],
      checkedItemsUnit: [],
    },
  };

  componentDidMount = () => {
    const { id, projectId: projectId } = this.props.match.params;
    const groupId = this.props.deviceInfoViewData?.id_group;
    const formInput = this.state.formInput;
    let dataView = this.props.projectDellaData?.items;
    // let dataView = this.props.projectDellaData?.items.filter(
    //   (n) => n.id_group === groupId
    // );

    let dataSet = [];
    let dataName = [];
    let dataUnit = [];
    let combinedDatasets = [];
    let dataviewList = dataView[0]?.latestData;

    var date = new Date();

    let start = date.setHours(0, 0, 0, 0);
    let startdate = start / 1000;

    let endDateMLSec = date.setHours(24, 0, 0, 0) + 3600000;
    const currentTime = new Date().getTime();
    //  let enddate = endDateMLSec / 1000;
    let enddate = Math.round(currentTime / 1000);

    formInput.startDate = startdate;
    formInput.endDate = Math.round(currentTime);
    formInput.endDatePass = Math.round(currentTime / 1000);
    formInput.checkedItems.push(
      dataviewList[0]?.relations?.id_container_data_latest
    );
    formInput.checkedItemsLabel.push(dataviewList[0]?.label);
    formInput.checkedItemsUnit.push(dataviewList[0]?.unit);
    formInput.combinedDatasets = combinedDatasets;

    let id_container_data_latest = {
      "filter[timestamp_seconds][gte]": formInput.startDate,
      "filter[timestamp_seconds][lte]": formInput.endDatePass,
    };

    id_container_data_latest[`filter[id_container_data_latest][0]`] =
      dataviewList[0]?.relations?.id_container_data_latest;
    let params = Object.entries(id_container_data_latest)
      .filter((e) => e[1] !== undefined)
      .map((e) => {
        return e.join("=");
      })
      .join("&");
    formInput.filterOptions = params;
    this.setState({ formInput });
    this.props.getAllChartData(params);
  };

  componentWillUnmount = () => {};

  handleChangeDataSelection = (e, dataSet, dataSetLabel, dataSetUnit) => {
    const formInput = this.state.formInput;

    if (e.target.checked) {
      // Add the item to checkedItems if it's checked
      formInput.checkedItems.push(dataSet);
      formInput.checkedItemsLabel.push(dataSetLabel);
      formInput.checkedItemsUnit.push(dataSetUnit);
    } else {
      // Remove the item from checkedItems if it's unchecked
      formInput.checkedItems = formInput.checkedItems.filter(
        (item) => item !== dataSet
      );
      formInput.checkedItemsLabel = formInput.checkedItemsLabel.filter(
        (item) => item !== dataSetLabel
      );
      formInput.checkedItemsUnit = formInput.checkedItemsUnit.filter(
        (item) => item !== dataSetUnit
      );
    }

    let id_container_data_latest = [];

    id_container_data_latest = {
      "filter[timestamp_seconds][gte]": formInput.startDate,
      "filter[timestamp_seconds][lte]": formInput.endDatePass,
    };
    formInput.checkedItems.forEach((dataset, idx) => {
      id_container_data_latest[`filter[id_container_data_latest][${idx}]`] =
        dataset;
    });
    let params = Object.entries(id_container_data_latest)
      .filter((e) => e[1] !== undefined)
      .map((e) => {
        return e.join("=");
      })
      .join("&");
    formInput.modifyFilterOptions = params;
    this.setState({ formInput });
    this.props.getAllChartData(params);
  };

  handleChangeStartDate = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    // formInput.customValue = false;
    formInput.startDate = new Date(input.value).getTime() / 1000;

    this.setState({ formInput });
    let id_container_data_latest = {
      "filter[timestamp_seconds][gte]": formInput.startDate,
      "filter[timestamp_seconds][lte]": formInput.endDate / 1000,
    };

    let dataView = this.props.projectDellaData?.items;

    //let dataviewList = dataView[0]?.latestData;
    // id_container_data_latest[`filter[id_container_data_latest][0]`] =
    //   dataviewList[0]?.relations?.id_container_data_latest;

    formInput.checkedItems.forEach((dataset, idx) => {
      id_container_data_latest[`filter[id_container_data_latest][${idx}]`] =
        dataset;
    });

    let params = Object.entries(id_container_data_latest)
      .filter((e) => e[1] !== undefined)
      .map((e) => {
        return e.join("=");
      })
      .join("&");
    formInput.filterOptions = params;
    this.setState({ formInput });
    this.props.getAllChartData(params);
  };

  handleChangeEndDate = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    // formInput.customValue = false;
    formInput.endDate = new Date(input.value).getTime();
    formInput.endDatePass = new Date(input.value).getTime() / 1000;

    let id_container_data_latest = {
      "filter[timestamp_seconds][gte]": formInput.startDate,
      "filter[timestamp_seconds][lte]": formInput.endDatePass,
    };

    let dataView = this.props.projectDellaData?.items;

    // let dataviewList = dataView[0]?.latestData;
    // id_container_data_latest[`filter[id_container_data_latest][0]`] =
    //   dataviewList[0]?.relations?.id_container_data_latest;

    formInput.checkedItems.forEach((dataset, idx) => {
      id_container_data_latest[`filter[id_container_data_latest][${idx}]`] =
        dataset;
    });

    let params = Object.entries(id_container_data_latest)
      .filter((e) => e[1] !== undefined)
      .map((e) => {
        return e.join("=");
      })
      .join("&");
    formInput.filterOptions = params;
    this.setState({ formInput });
    this.props.getAllChartData(params);
  };

  handleTimeChange = (e) => {
    const formInput = { ...this.state.formInput };
    formInput.customValue = false;
    formInput.customValueData = e.target.value;
    const currentTime = new Date().getTime();
    var date = new Date();
    let endDateMLSec = date.setHours(24, 0, 0, 0) + 3600000;
    if (e.target.value === "24hour") {
      formInput.startDate = Math.round(
        (new Date().getTime() - 86400000) / 1000
      );
      formInput.endDate = currentTime;
      formInput.endDatePass = Math.round(currentTime / 1000);
    } else if (e.target.value === "1week") {
      formInput.startDate = Math.round(
        (new Date().getTime() - 604800000) / 1000
      );
      formInput.endDate = currentTime;
      formInput.endDatePass = Math.round(currentTime / 1000);
    } else if (e.target.value === "1month") {
      formInput.startDate = Math.round(
        (new Date().getTime() - 2629746000) / 1000
      );
      formInput.endDate = currentTime;
      formInput.endDatePass = Math.round(currentTime / 1000);
    } else if (e.target.value === "3month") {
      formInput.startDate = Math.round(
        (new Date().getTime() - 7889238000) / 1000
      );
      formInput.endDate = currentTime;
      formInput.endDatePass = Math.round(currentTime / 1000);
    } else if (e.target.value === "1Year") {
      formInput.startDate = Math.round(
        (new Date().getTime() - 31556952000) / 1000
      );
      formInput.endDate = currentTime;
      formInput.endDatePass = Math.round(currentTime / 1000);
    }
    formInput.loaderOn = true;
    this.setState({ formInput });
    let id_container_data_latest = {
      "filter[timestamp_seconds][gte]": formInput.startDate,
      "filter[timestamp_seconds][lte]": formInput.endDatePass,
    };

    let dataView = this.props.projectDellaData?.items;

    // let dataviewList = dataView[0]?.latestData;
    // id_container_data_latest[`filter[id_container_data_latest][0]`] =
    //   dataviewList[0]?.relations?.id_container_data_latest;

    formInput.checkedItems.forEach((dataset, idx) => {
      id_container_data_latest[`filter[id_container_data_latest][${idx}]`] =
        dataset;
    });

    let params = Object.entries(id_container_data_latest)
      .filter((e) => e[1] !== undefined)
      .map((e) => {
        return e.join("=");
      })
      .join("&");
    formInput.filterOptions = params;
    this.setState({ formInput });
    this.props.getAllChartData(params);
  };

  render() {
    const formInput = this.state.formInput;
    formInput.projectId = this.props.match.params?.projectId;

    const dataPresentationProjectDataWithCheckbox =
      this.props.projectDellaData?.items[0]?.latestData;
    let chartdataList = [];

    formInput.checkedItems.forEach((id, index) => {
      chartdataList[index] = this.props.dellaChartData?.items.filter(
        (m) => m.id_container_data_latest === id
      );
    });

    formInput.chartdataList = chartdataList;
    const today = new Date().toISOString().replace("Z", "");
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set time to midnight
    const startDateTimestamp = Math.round(currentDate.getTime() / 1000);
    formInput.startDate = formInput.startDate
      ? new Date(formInput.startDate).getTime()
      : startDateTimestamp;
    formInput.endDate = formInput.endDate
      ? new Date(formInput.endDate).getTime()
      : new Date().getTime();
    if (this.props.dellaChartData && this.props.dellaChartData !== undefined) {
      return (
        <Fragment>
          <table className="tableView">
            <tbody>
              <div>
                {" "}
                <tr>
                  <td style={{ display: "flex" }}>
                    <p className="toView">From</p>
                    <input
                      className="form-control-start formInput chartfilter"
                      // value={formInput.startDate}
                      value={new Date(formInput.startDate * 1000 + 7200000)
                        .toISOString()
                        .replace("Z", "")
                        .slice(0, 16)}
                      name="startDate"
                      type="datetime-local"
                      onChange={this.handleChangeStartDate}
                      placeholder="Starting time"
                      max={today}
                    />
                    <p className="toView">To</p>{" "}
                    <input
                      className="form-control-start formInput chartfilter"
                      // value={formInput.endDate}
                      value={new Date(formInput.endDate + 7200000)
                        .toISOString()
                        .slice(0, 16)}
                      name="endDate"
                      type="datetime-local"
                      onChange={this.handleChangeEndDate}
                      placeholder="Ending time"
                      max={today}
                    />
                    <select
                      className="form-control-start formInput delaFilterOption"
                      aria-label="Default select example"
                      label="status"
                      onChange={(e) => this.handleTimeChange(e)}
                      style={{ marginLeft: "15px" }}
                    >
                      <option
                        key="custom"
                        value="custom"
                        selected={formInput.customValue === true ? true : false}
                      >
                        Custom
                      </option>
                      <option key="24hour" value="24hour">
                        Last 24 hour
                      </option>
                      <option key="1week" value="1week">
                        Last 1 week
                      </option>
                      <option key="1month" value="1month">
                        Last 1 month
                      </option>
                      <option key="3month" value="3month">
                        Last 3 month
                      </option>
                      <option key="1Year" value="1Year">
                        Last 1 Year
                      </option>
                    </select>
                  </td>

                  {/* <td>
                    <div style={{ fontWeight: "600", marginLeft: "8px" }}>
                      Resolution :{" "}
                      {formInput.resoltionValueData === "day"
                        ? "Day"
                        : formInput.resoltionValueData === "hour"
                        ? "Hour"
                        : formInput.resoltionValueData === "raw"
                        ? "Raw"
                        : "Minute"}
                    </div>
                  </td> */}
                </tr>
              </div>
            </tbody>
          </table>
          {this.props.loader ? <TinySpinner /> : ""}{" "}
          <div style={{ padding: "20px" }}>
            <div>
              <DelaMeasurementChart
                chartdataList={formInput.chartdataList}
                checkedItemsLabel={formInput.checkedItemsLabel}
                checkedItemsUnit={formInput.checkedItemsUnit}
                projectDellaData={this.props.projectDellaData}
                checkedItems={formInput.checkedItems}
              />
            </div>
            <table className="customTable">
              <thead>
                <tr className="equipmentStatusTextRow">
                  <th className="headerTextRow col-md-1"> </th>
                  <th className="headerTextRow col-md-3 p-2">Register Name</th>
                  <th className="headerTextRow col-md-4 p-2">Device name</th>
                  <th className="headerTextRow col-md-2 p-2">Value</th>
                  <th className="headerTextRow col-md-2 p-2">Time</th>
                </tr>
              </thead>
            </table>

            <div className="delaGridTable">
              {" "}
              <table>
                <tbody>
                  {dataPresentationProjectDataWithCheckbox.map(
                    (projectList, index) => (
                      <tr
                        className={`equipmentRow ${
                          index ===
                          dataPresentationProjectDataWithCheckbox.length - 1
                            ? "lastRow"
                            : ""
                        }`}
                        key={index}
                      >
                        <td
                          className="col-md-1 checkboxCell"
                          style={{ textAlign: "center", border: "none" }}
                        >
                          {" "}
                          <input
                            type="checkbox"
                            className="custom-control-input checkboxOutside"
                            id={this.state.randId}
                            defaultChecked={index === 0 ? true : false}
                            onChange={(o) =>
                              this.handleChangeDataSelection(
                                o,
                                projectList?.relations
                                  ?.id_container_data_latest,
                                projectList?.label,
                                projectList?.unit
                              )
                            }
                          />
                        </td>
                        <td className="col-md-3 p-2">{projectList.label}</td>
                        <td className="col-md-4 p-2">
                          {projectList?.relations?.source_device}
                        </td>
                        <td className="col-md-2">
                          {parseFloat(projectList.value).toFixed(2)}
                        </td>
                        <td className="col-md-2">
                          <Moment format="DD MMM YYYY HH:mm">
                            {projectList.timestamp}
                          </Moment>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <div>
          <LoaderRoller />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    deviceInfoViewData: state.listViewReducer.deviceInfoViewData,
    deviceViewData: state.listViewReducer.deviceViewData,
    deviceEventData: state.listViewReducer.deviceEventData,
    projectDellaData: state.listViewReducer.projectDellaData,
    dellaChartData: state.listViewReducer?.dellaChartData,
    loader: state.listViewReducer?.loader,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getDeviceData,
    clearDeviceData,
    getDeviceInfoData,
    getDeviceEventInfoData,
    clearListViewData,
    getAllProjectData,
    getAllChartData,
  })(DellaMeasurementData)
);
