const initialState = {
  dataPerDatatype: [],
  sessionDataPerDatatype: [],
};

export default function chartViewReducer(state = initialState, action) {
  switch (action.type) {
    case "ALL_GROUP_DATA_RECEIVED": {
      return {
        ...state,
        groupData: action.data,
      };
    }
    case "ALL_SESSION_DATA_RECEIVED": {
      return {
        ...state,
        sessionData: action.data,
      };
    }
    case "GROUP_DATA_PER_DATATYPE_RECEIVED": {
      const foundDatatypeData = state.dataPerDatatype.find(
        (data) => data.datatype === action.data.datatype
      );
      if (!foundDatatypeData) {
        return {
          ...state,
          dataPerDatatype: [...state.dataPerDatatype, action.data],
        };
      }

      return {
        ...state,
        dataPerDatatype: state.dataPerDatatype.map((data) => {
          if (data.datatype === action.data.datatype) {
            return action.data;
          }
          return data;
        }),
      };
    }
    case "SESSION_DATA_PER_DATATYPE_RECEIVED": {
      const foundDatatypeData = state.dataPerDatatype.find(
        (data) => data.datatype === action.data.datatype
      );
      if (!foundDatatypeData) {
        return {
          ...state,
          sessionDataPerDatatype: [
            ...state.sessionDataPerDatatype,
            action.data,
          ],
        };
      }

      return {
        ...state,
        sessionDataPerDatatype: state.sessionDataPerDatatype.map((data) => {
          if (data.datatype === action.data.datatype) {
            return [...state.sessionDataPerDatatype, action.data];
          }
          return data;
        }),
      };
    }
    case "GROUP_DATATYPES_RECEIVED": {
      return {
        ...state,
        groupDatatypes: action.data?.dataTypes ? action.data?.dataTypes : [],
        groupLatestSession: action.data?.latestSession
          ? action.data?.latestSession
          : null,
      };
    }

    case "CHART_DATA_RECEIVED": {
      return {
        ...state,
        modbuschartData: action.data,
        //   modbuschartListData: [action.data],
      };
    }

    case "MULTIPLE_CHART_DATA_RECEIVED": {
      return {
        ...state,
        modbuschartData: null,
        modbuschartListData: [action.data],
      };
    }

    case "CLEAR_CHART_DATA_RECEIVED": {
      return {
        ...state,
        modbuschartData: null,
      };
    }

    case "CLEAR_GROUP_DATA": {
      return initialState;
    }
    case "CLEAR_SESSION_DATA": {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
