import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import OneHumanMovementScienceSubmenu from "../../components/menus/sub-menu/subtypes/OneHumanMovementScienceSubmenu";
import DeviceSessionListCard from "../../components/device-cards/DeviceSessionListCard";

import {
  getGroupSessionListViewData,
  clearGroupSessionListViewData,
} from "../../actions/session-view";
import { getDeviceData, clearDeviceData } from "../../actions/list-view";

class DeviceInfoView extends Component {
  componentDidMount = () => {
    const { id } = this.props.match.params;
    if (!id) {
      return this.props.history.push("/");
    }
    this.props.getDeviceData(id);
    this.props.getGroupSessionListViewData(id, 1, "start", "DESC");
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location !== prevProps.location) {
      const { currentPageNr } = this.props.match.params?.currentPageNr;
      const sortColumn = new URLSearchParams(this.props.location.search).get(
        "sortColumn"
      );
      const sortDirection = new URLSearchParams(this.props.location.search).get(
        "sortDirection"
      );

      this.props.getGroupSessionListViewData(
        this.props.match.params.id,
        currentPageNr,
        sortColumn,
        sortDirection
      );
    }
  };

  componentWillUnmount = () => {
    this.props.clearDeviceData();
    this.props.clearGroupSessionListViewData();
  };

  getData = () => {};

  render() {
    if (this.props.groupSessionListViewData) {
      return (
        <Fragment>
          <OneHumanMovementScienceSubmenu />
          <div className="bacecontainer-lg p-2">
            <div className="row mt-4">
              <DeviceSessionListCard subtype="human-movement-science" />
            </div>
          </div>
        </Fragment>
      );
    } else {
      return <div></div>;
    }
  }
}

function mapStateToProps(state) {
  return {
    deviceViewData: state.listViewReducer.deviceViewData,
    groupSessionListViewData: state.sessionReducer.groupSessionListViewData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getGroupSessionListViewData,
    clearGroupSessionListViewData,
    getDeviceData,
    clearDeviceData,
  })(DeviceInfoView)
);
