import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ModbusGatewaysSubmenu from "../../components/menus/sub-menu/subtypes/ModbusGatewaysSubmenu";

class AddRegister extends Component {
  render() {
    return (
      <div>
        <div>
          {/* <ModbusGatewaysSubmenu /> */}
          <div>hello AddRegister</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps, {})(AddRegister));
