import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { baceEpViewChange } from "../../../../actions/view-history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListUl,
  faSliders,
  faHardDrive as faHardDriveRegular,
  faBrowser as faBrowserRegular,
} from "@fortawesome/pro-regular-svg-icons";
import TopMenu from "../../../../components/menus/top-menu/TopMenu";
import { faHardDrive, faBrowser } from "@fortawesome/pro-solid-svg-icons";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
class WebhookInfoSubmenu extends Component {
  state = {
    formInput: {
      projectId: "",
    },
  };
  componentDidMount = () => {
    const { currentPageNr, projectId } = this.props.match.params;
  };

  render() {
    const formInput = this.state.formInput;

    formInput.projectId = this.props.projectId;
    return (
      <div className="col pl-4">
        <div className="d-flex">
          <div className="col-md-7">
            <h6 style={{ fontSize: "18px", margin: "15px", color: "#000066" }}>
              {" "}
              Integration {">"} Webhook
            </h6>{" "}
            <h5
              className="color-ocean-blue"
              style={{
                color: "#000066",
                margin: "15px",
                fontWeight: "bold",
                fontSize: "32px",
              }}
            >
              {" "}
              {this.props.webhookName}
            </h5>
          </div>
          <TopMenu />
        </div>
        <div className="mb-3 switch-btn-line"></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    //latestData: state.groupReducer?.latestData,
  };
}

export default withRouter(
  connect(mapStateToProps, { baceEpViewChange })(WebhookInfoSubmenu)
);
