import React, { Component } from "react";

import "./style.css";

export default class TinySpinner extends Component {
  render() {
    return (
      <div className="g-spin d-flex justify-content-center align-items-center">
        <div className="loader">
          {/* <div className="lds-roller"> */}
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {/* <div className="spinner"></div> */}
        {/* </div> */}
      </div>
    );
  }
}
