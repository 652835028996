import React, { Component } from "react";
import { connect } from "react-redux";
import {
  faSignalBarsFair,
  faSignalBarsGood,
  faSignalBarsStrong,
  faSignalBarsWeak,
  faWifiWeak,
  faWifiFair,
  faWifiStrong,
  faWifiSlash,
  faSignalBarsSlash,
  faBluetooth,
  faEthernet as faEthernetSlash,
} from "@fortawesome/pro-duotone-svg-icons";

import ModbusTCP from "../../assets/Icons/Modbus_TCP.png";
import ModbusRTU from "../../assets/Icons/Modbus_RTU.png";
import BluetoothIcon from "../../assets/Icons/Bluetooth.png";
import ZwaveIcon from "../../assets/Icons/zwave.svg";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthernet } from "@fortawesome/pro-solid-svg-icons";

class BaceEpCardBody extends Component {
  render() {
    if (this.props.deviceInfo) {
      return (
        <div
          className={`${
            this.props.deviceInfo.is_connected === 1
              ? "connected-device"
              : "disconnected-device"
          }`}
          style={{ width: "22rem", height: "15rem" }}
        >
          <div
           style={{padding: "5px 12px 3px 17px"}}
            className={`${
              this.props.deviceInfo.is_connected === 1
                ? "connected-Status"
                : "disconnected-Status"
            }`}
          >
            <span
              className="card-key text-white"
              style={{ fontWeight: "500", marginRight: "112px" }}
            >
              Status
            </span>
            <div className="text-white" style={{ fontWeight: "600" }}>
              {this.props.deviceInfo?.is_connected === 1 ? (
                <div>Online</div>
              ) : (
                <div>Offline</div>
              )}
            </div>
          </div>
          
          <div className="cardViewDetailGridView ">
            
            {" "}
            <div className="card-titles">Label</div>
            <div className="card-keyLabel  text-capitalize text-wrap">
              {this.props.deviceInfo.label ? (
                this.props.deviceInfo.label
              ) : (
                <div className="mb-4"></div>
              )}
            </div>
            <div className="card-titles">Device ID</div>
            <div className="card-key text-capitalize text-wrap">
              {this.props.deviceInfo.iotDevice !== null ? (
                this.props.deviceInfo.iotDevice.iot_id
              ) : (
                <div className="mb-4"></div>
              )}
            </div>
            <div className="card-titles">Connectivity</div>
            <div className="card-key text-capitalize text-wrap">
              <div className="d-flex align-items-center">
              <span> {this.props.deviceInfo.connection}</span>
                {this.props.deviceInfo.connection === "ethernet" && (
                  <FontAwesomeIcon
                    className="svg-icons-sm fa-lg"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Strong"
                    icon={faEthernet}
                  />
                  
                )}

                {this.props.deviceInfo.connection === "wifi" &&
                  (this.props.deviceInfo.signal >= 0 &&
                  this.props.deviceInfo.signal <= 33 ? (
                    <FontAwesomeIcon
                      className="svg-icons-sm"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Weak"
                      icon={faWifiWeak}
                    />
                  ) : this.props.deviceInfo.signal >= 34 &&
                    this.props.deviceInfo.signal <= 68 ? (
                    <FontAwesomeIcon
                      className="svg-icons-sm"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Fair"
                      icon={faWifiFair}
                    />
                  ) : this.props.deviceInfo.signal >= 69 &&
                    this.props.deviceInfo.signal <= 100 ? (
                    <FontAwesomeIcon
                      className="svg-icons-sm"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Strong"
                      icon={faWifiStrong}
                    />
                  ) : this.props.deviceInfo.signal === 0 ? (
                    <FontAwesomeIcon
                      className="svg-icons-sm"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="No Connection"
                      icon={faWifiSlash}
                    />
                  ) : (
                    ""
                  ))}
                {this.props.deviceInfo.connection === "blutooth" && (
                  <FontAwesomeIcon
                    className="svg-icons-sm"
                    icon={faBluetooth}
                  />
                )}
                {this.props.deviceInfo.connection === "cellular" &&
                  (this.props.deviceInfo.signal > 0 &&
                  this.props.deviceInfo.signal <= 25 ? (
                    <FontAwesomeIcon
                      className="svg-icons-sm"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Weak"
                      icon={faSignalBarsWeak}
                    />
                  ) : this.props.deviceInfo.signal >= 26 &&
                    this.props.deviceInfo.signal <= 50 ? (
                    <FontAwesomeIcon
                      className="svg-icons-sm"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Good"
                      icon={faSignalBarsGood}
                    />
                  ) : this.props.deviceInfo.signal >= 51 &&
                    this.props.deviceInfo.signal <= 75 ? (
                    <FontAwesomeIcon
                      className="svg-icons-sm"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Fair"
                      icon={faSignalBarsFair}
                    />
                  ) : this.props.deviceInfo.signal >= 76 &&
                    this.props.deviceInfo.signal <= 100 ? (
                    <FontAwesomeIcon
                      className="svg-icons-sm"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Strong"
                      icon={faSignalBarsStrong}
                    />
                  ) : this.props.deviceInfo.signal === 0 ? (
                    <FontAwesomeIcon
                      className="svg-icons-sm"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="No Connection"
                      icon={faSignalBarsSlash}
                    />
                  ) : (
                    <div className="mb-3"></div>
                  ))}
                {this.props.deviceInfo.connection === "LTE-M" && (
                  <FontAwesomeIcon
                    className="svg-icons-sm"
                    icon={faSignalBarsSlash}
                  />
                )}

                {this.props.deviceInfo.connection === null && <span>-</span>}
              </div>
            </div>
            <div className="card-titles">Protocol</div>
            <div className="card-key text-capitalize text-wrap">
              <img
                src={
                  this.props.deviceInfo?.protocol_received !== null &&
                  this.props.deviceInfo?.protocol_received.includes(
                    "modbus-rtu"
                  ) &&
                  ModbusRTU
                }
                className={
                  this.props.deviceInfo?.protocol_received !== null &&
                  this.props.deviceInfo?.protocol_received.includes(
                    "modbus-rtu"
                  )
                    ? "imageWithoutValue"
                    : "imageValue"
                }
                title={
                  this.props.deviceInfo?.protocol_received !== null &&
                  this.props.deviceInfo?.protocol_received.includes(
                    "modbus-rtu"
                  ) &&
                  "Modbus RTU"
                }
              />
              <img
                src={
                  this.props.deviceInfo?.protocol_received !== null &&
                  this.props.deviceInfo?.protocol_received.includes(
                    "modbus-tcp"
                  ) &&
                  ModbusTCP
                }
                className={
                  this.props.deviceInfo?.protocol_received !== null &&
                  this.props.deviceInfo?.protocol_received.includes(
                    "modbus-tcp"
                  )
                    ? "imageWithoutValue"
                    : "imageValue"
                }
                title={
                  this.props.deviceInfo?.protocol_received !== null &&
                  this.props.deviceInfo?.protocol_received.includes(
                    "modbus-tcp"
                  ) &&
                  "Modbus TCP"
                }
              />
              <img
                src={
                  this.props.deviceInfo?.protocol_received !== null &&
                  this.props.deviceInfo?.protocol_received.includes("ble") &&
                  BluetoothIcon
                }
                className={
                  this.props.deviceInfo?.protocol_received !== null &&
                  this.props.deviceInfo?.protocol_received.includes("ble")
                    ? "imageBluetoothValue"
                    : "imageValue"
                }
                title={
                  this.props.deviceInfo?.protocol_received !== null &&
                  this.props.deviceInfo?.protocol_received.includes("ble") &&
                  "Bluetooth"
                }
              />
              <img
                src={
                  this.props.deviceInfo?.protocol_received !== null &&
                  this.props.deviceInfo?.protocol_received.includes("zwave") &&
                  ZwaveIcon
                }
                className={
                  this.props.deviceInfo?.protocol_received !== null &&
                  this.props.deviceInfo?.protocol_received.includes("zwave")
                    ? "imageWithoutValue"
                    : "imageValue"
                }
                title={
                  this.props.deviceInfo?.protocol_received !== null &&
                  this.props.deviceInfo?.protocol_received.includes("zwave") &&
                  "Zwave"
                }
              />
              {/* {this.props.deviceInfo.id_device_type
                  ? this.props.deviceInfo.id_device_type
                  : "-"} */}
            </div>
            <div className="card-titles">Last Seen</div>
            <div className="card-key text-wrap">
              {this.props.deviceInfo?.last_data_at !== null ? (
                <Moment format="DD MMM YYYY HH:mm">
                  {this.props.deviceInfo.last_data_at * 1000}
                </Moment>
              ) : (
                <div className="mb-3"></div>
              )}
            </div>
            <div className="card-titles">Connected Devices</div>
            <div className="card-key text-capitalize text-wrap">
              {this.props.deviceInfo.subDevices.length}
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

function mapStateToProps(state) {
  return {
    // listViewData: state.listViewReducer.listViewData,
  };
}

export default connect(mapStateToProps, {})(BaceEpCardBody);
