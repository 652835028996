import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import LoaderRoller from "../../components/loader/LoaderRoller";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNetworkWired,
  faChartLine,
  faPlusCircle,
  faSliders,
} from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import OneBaceEpSubmenu from "../../components/menus/sub-menu/subtypes/OneBaceEpSubmenu";
import {
  getDeviceData,
  clearDeviceData,
  getDeviceInfoData,
  getDeviceEventInfoData,
  clearListViewData,
  getAllProjectData,
  clearDelaDeviceData,
} from "../../actions/list-view";
import DellaMeasurementData from "../della/DellaMeasurementData";

class DeviceMeasurementData extends Component {
  state = {
    formInput: {
      projectId: "",
      deviceLabel: "",
    },
  };

  componentDidMount = () => {
    const { id, projectId: projectId } = this.props.match.params;
    if (!id) {
      return this.props.history.push("/");
    }
    this.setState({
      projectId,
    });

    // this.props.getAllProjectData();
    //  this.props.clearListViewData();
    this.props.clearDelaDeviceData();
    this.props.getDeviceInfoData(id, this.props.match.params.projectId);
    this.props.getDeviceEventInfoData(id, 1);

    if (this.props.deviceInfoViewData) {
      this.props.getAllProjectData(this.props.deviceInfoViewData?.id_group);
    }
  };

  componentWillUnmount = () => {};

  render() {
    const formInput = this.state.formInput;
    formInput.projectId = this.props.match.params?.projectId;
    formInput.deviceLabel = this.props.deviceInfoViewData?.label;

    if (this.props.deviceInfoViewData && this.props.projectDellaData) {
      return (
        <Fragment>
          <OneBaceEpSubmenu
            deviceHeaderInfo={this.props.deviceInfoViewData}
            deviceLabel={formInput.deviceLabel}
          />
          <div>
            <div>
              {this.props.projectDellaData &&
                this.props.projectDellaData !== undefined && (
                  <DellaMeasurementData
                    projectDellaData={this.props.projectDellaData}
                    deviceInfoViewData={this.props.deviceInfoViewData}
                    deviceHeaderInfo={this.props.deviceInfoViewData}
                    deviceLabel={formInput.deviceLabel}
                  />
                )}
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <OneBaceEpSubmenu
            deviceHeaderInfo={this.props.deviceInfoViewData}
            deviceLabel={formInput.deviceLabel}
          />
          <LoaderRoller />
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    deviceInfoViewData: state.listViewReducer.deviceInfoViewData,
    deviceViewData: state.listViewReducer.deviceViewData,
    deviceEventData: state.listViewReducer.deviceEventData,
    projectDellaData: state.listViewReducer.projectDellaData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getDeviceData,
    clearDeviceData,
    getDeviceInfoData,
    getDeviceEventInfoData,
    clearListViewData,
    getAllProjectData,
    clearDelaDeviceData,
  })(DeviceMeasurementData)
);
