import React, { Component } from "react";
import { Provider } from "react-redux";
import store from "./store";

import Main from "./views/main/Main";
import NotificationBar from "./components/notification/NotificationBar";

import "bootstrap/dist/css/bootstrap.css";
import "./App.css";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
       
        <Main />
        
        {/* <NotificationBar /> */}
      </Provider>
      
      
    );
  }
}

export default App;
