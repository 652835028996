import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import OneSessionSubmenu from "../../components/menus/sub-menu/sessions/OneSessionSubmenu";
import MeasurementSessionDataChart from "../../components/device-cards/MeasurementSessionDataChart";

import {
  getSessionData,
  clearSessionData,
  getGroupDatatypes,
} from "../../actions/chart-view";
import {
  getSessionDeviceData,
  clearSessionDeviceData,
} from "../../actions/session-view";
import NoDataCard from "../../components/device-cards/NoDataCard";
import LoaderRoller from "../../components/loader/LoaderRoller";

import "./style.css";
class SessionChartView extends Component {
  componentDidMount = () => {
    const { sessionId } = this.props.match.params;
    if (!sessionId) {
      return this.props.history.push("/");
    }
    this.props.getSessionData(sessionId);
    this.props.getSessionDeviceData(sessionId);
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.sessionDeviceViewData !== prevProps.sessionDeviceViewData) {
      this.props.getGroupDatatypes(this.props.match.params.id);
    }
  };

  componentWillUnmount = () => {
    //if (!window.location.pathname.startsWith("/sessions")) {
    this.props.clearSessionData();
    this.props.clearSessionDeviceData();
    //}
  };

  render() {
    if (this.props.datatypes && this.props.datatypes.length === 0) {
      return (
        <Fragment>
          <OneSessionSubmenu />
          <NoDataCard />
        </Fragment>
      );
    } else if (this.props.datatypes && this.props.datatypes.length > 0) {
      return (
        <Fragment>
          <OneSessionSubmenu />
          <div className="bacecontainer">
            <div className="row mt-3">
              <div className="col-lg-auto">
                {this.props.datatypes.map((datatype) => {
                  return (
                    <Fragment key={datatype.datatype}>
                      <MeasurementSessionDataChart datatype={datatype} />
                      <br />
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <OneSessionSubmenu />
          <div className="bacecontainer">
            <div className="row mt-3">
              <LoaderRoller />
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    sessionListViewData: state.sessionReducer.sessionListViewData,
    sessionDeviceViewData: state.sessionReducer.sessionDeviceViewData,
    datatypes: state.chartViewReducer?.groupDatatypes,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getSessionData,
    clearSessionData,
    getGroupDatatypes,
    getSessionDeviceData,
    clearSessionDeviceData,
  })(SessionChartView)
);
