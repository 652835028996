import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "react-moment";

import CONFIG from "../../config";

class ActivityMonitorCardBody extends Component {
  render() {
    const { TOTAL_STEP_COUNT, AVG_HEART_RATE, BATTERY_VOLTAGE } =
      CONFIG.datatypesActivityMonitor;

    const deviceData = this.props.listViewData.items.find(
      (group) => group.id === this.props.deviceInfo?.id_group
    );

    return (
      <div className="row mx-4 py-4" style={{ color: "#000" }}>
        <div className="col-6 px-0">
          <p className="card-key">Device name</p>
          <p className="card-value">
            {this.props.deviceInfo
              ? this.props.deviceInfo?.label
              : "no device assigned"}
          </p>

          <p className="card-key">Last seen</p>
          <p className="card-value">
            {this.props.deviceInfo ? (
              <Moment format="DD MMM YYYY HH:mm A">
                {this.props.deviceInfo?.last_data_at * 1000}
              </Moment>
            ) : (
              "n/a"
            )}
          </p>
          <p className="card-key">Battery voltage</p>
          <p className="card-value">
            {deviceData?.latestData.find(
              (data) => data.datatype === BATTERY_VOLTAGE.datatype
            )?.value === undefined
              ? "N/A "
              : Math.round(
                  deviceData?.latestData.find(
                    (data) => data.datatype === BATTERY_VOLTAGE.datatype
                  )?.value * 100
                ) / 100}{" "}
            V
          </p>
        </div>
        <div className="col-6 ps-4">
          <p className="card-key">Step count current session</p>
          <p className="lg-card">
            {deviceData?.latestData.find(
              (data) => data.datatype === TOTAL_STEP_COUNT.datatype
            )?.value === undefined
              ? "N/A "
              : Math.round(
                  deviceData?.latestData.find(
                    (data) => data.datatype === TOTAL_STEP_COUNT.datatype
                  )?.value * 100
                ) / 100}{" "}
            <span className="md-card">Steps</span>
          </p>
          <p className="card-key">Heart rate</p>
          <p className="lg-card">
            {deviceData?.latestData.find(
              (data) => data.datatype === AVG_HEART_RATE.datatype
            )?.value === undefined
              ? "N/A "
              : Math.round(
                  deviceData?.latestData.find(
                    (data) => data.datatype === AVG_HEART_RATE.datatype
                  )?.value * 100
                ) / 100}{" "}
            <span className="md-card">BPM</span>
          </p>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { listViewData: state.listViewReducer.listViewData };
}

export default connect(mapStateToProps, {})(ActivityMonitorCardBody);
