import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import OneHumanMovementScienceSubmenu from "../../components/menus/sub-menu/subtypes/OneHumanMovementScienceSubmenu";
import MeasurementSessionDataChart from "../../components/device-cards/MeasurementSessionDataChart";
import MeasurementSessionDataMultiLineChart from "../../components/device-cards/MeasurementSessionDataMultiLineChart";
import ExportComponent from "../../components/global/ExportComponent";

import {
  clearGroupData,
  clearSessionData,
  getGroupDatatypes,
} from "../../actions/chart-view";
import {
  getGroupSessionListViewData,
  clearGroupSessionListViewData,
} from "../../actions/session-view";
import CONFIG from "../../config";
import NoDataCard from "../../components/device-cards/NoDataCard";
import LoaderRoller from "../../components/loader/LoaderRoller";

class DeviceChartView extends Component {
  componentDidMount = () => {
    this.fetchData();
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.match.params?.sessionId !== prevProps.match.params?.sessionId
    ) {
      this.fetchData();
    }
  };

  fetchData = () => {
    const { id } = this.props.match.params;
    if (!id) {
      return this.props.history.push("/");
    }

    this.props.getGroupDatatypes(id); // also contains latestSession data
    this.props.getGroupSessionListViewData(id);
  };

  componentWillUnmount = () => {
    this.props.clearSessionData();
    this.props.clearGroupData();
    this.props.clearGroupSessionListViewData();
  };

  render() {
    const {
      ACCELERATION_XYZ,
      ACCELERATION_X,
      ACCELERATION_Y,
      ACCELERATION_Z,
      GYROSCOPE_XYZ,
      GYROSCOPE_X,
      GYROSCOPE_Y,
      GYROSCOPE_Z,
      BATTERY_VOLTAGE,
    } = CONFIG.datatypesHumanMovementScience;

    if (
      this.props.datatypes &&
      this.props.datatypes.length === 0 &&
      this.props.latestSession &&
      this.props.groupSessionListViewData
    ) {
      return (
        <Fragment>
          <OneHumanMovementScienceSubmenu
            session_id={this.props.latestSession.id_data_session}
          />
          <NoDataCard />
        </Fragment>
      );
    } else if (
      this.props.datatypes &&
      this.props.datatypes.length > 0 &&
      this.props.latestSession &&
      this.props.groupSessionListViewData
    ) {
      return (
        <Fragment>
          <OneHumanMovementScienceSubmenu
            session_id={this.props.latestSession.id_data_session}
          />
          <div className="bacecontainer">
            <div className="d-flex justify-content-end">
              {this.props.datatypes && this.props.datatypes.length ? (
                <ExportComponent />
              ) : (
                <div></div>
              )}
            </div>
            <div className="row mt-3">
              <div className="col-lg-auto">
                <Fragment key={1}>
                  <MeasurementSessionDataMultiLineChart
                    session_id={this.props.latestSession.id_data_session}
                    data_types={[
                      ACCELERATION_X,
                      ACCELERATION_Y,
                      ACCELERATION_Z,
                    ]}
                    type={ACCELERATION_XYZ.label}
                    unit={ACCELERATION_XYZ.unit}
                    decimals={ACCELERATION_XYZ.decimals}
                  />
                  <br />

                  <MeasurementSessionDataMultiLineChart
                    session_id={this.props.latestSession.id_data_session}
                    data_types={[GYROSCOPE_X, GYROSCOPE_Y, GYROSCOPE_Z]}
                    type={GYROSCOPE_XYZ.label}
                    unit={GYROSCOPE_XYZ.unit}
                    decimals={GYROSCOPE_XYZ.decimals}
                  />
                  <br />

                  <MeasurementSessionDataChart
                    session_id={this.props.latestSession.id_data_session}
                    datatype={BATTERY_VOLTAGE}
                  />
                  <br />
                </Fragment>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <OneHumanMovementScienceSubmenu />
          <div className="bacecontainer">
            <div className="row mt-3">
              <LoaderRoller />
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    datatypes: state.chartViewReducer?.groupDatatypes,
    latestSession: state.chartViewReducer?.groupLatestSession,
    groupSessionListViewData: state.sessionReducer.groupSessionListViewData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    clearSessionData,
    clearGroupData,
    getGroupDatatypes,
    getGroupSessionListViewData,
    clearGroupSessionListViewData,
  })(DeviceChartView)
);
