const LOCATION_CHANGE = "LOCATION_CHANGE";
const OPEN_USER_POPUP = "OPEN_USER_POPUP";
const CLOSE_USER_POPUP = "CLOSE_USER_POPUP";
const OPEN_EXPORT_POPUP = "OPEN_EXPORT_POPUP";
const CLOSE_EXPORT_POPUP = "CLOSE_EXPORT_POPUP";
const OPEN_SESSION_EXPORT_POPUP = "OPEN_SESSION_EXPORT_POPUP";
const CLOSE_SESSION_EXPORT_POPUP = "CLOSE_SESSION_EXPORT_POPUP";

const EXPORT_CSV = "EXPORT_CSV";
const CLEAR_EXPORT_ACTIONS = "CLEAR_EXPORT_ACTIONS";

const routeChangeSuccess = (routeStart) => ({
  type: LOCATION_CHANGE,
  routeStart,
});

const openUserPopupSuccess = () => ({
  type: OPEN_USER_POPUP,
});

const closeUserPopupSuccess = () => ({
  type: CLOSE_USER_POPUP,
});

export const openUserPopup = () => (dispatch) => {
  dispatch(openUserPopupSuccess());
};

export const closeUserPopup = () => (dispatch) => {
  dispatch(closeUserPopupSuccess());
};

const openExportSuccess = () => ({
  type: OPEN_EXPORT_POPUP,
});

const closeExportSuccess = () => ({
  type: CLOSE_EXPORT_POPUP,
});

const openSessionExportSuccess = () => ({
  type: OPEN_SESSION_EXPORT_POPUP,
});

const closeSessionExportSuccess = () => ({
  type: CLOSE_SESSION_EXPORT_POPUP,
});

const downloadCSV = () => ({
  type: EXPORT_CSV,
});

const clearExportActionsSuccess = () => ({
  type: CLEAR_EXPORT_ACTIONS,
});

export const routeChange = () => (dispatch, getState) => {
  const state = getState();
  const module = window.location.pathname.split("/")[1];
  if (state?.screenActivitiesReducer?.routeStart === module) return;

  dispatch(routeChangeSuccess(module));
};

export const openExport = () => (dispatch) => {
  dispatch(openExportSuccess());
};

export const closeExport = () => (dispatch) => {
  dispatch(closeExportSuccess());
};

export const openSessionExport = () => (dispatch) => {
  dispatch(openSessionExportSuccess());
};

export const closeSessionExport = () => (dispatch) => {
  dispatch(closeSessionExportSuccess());
};

export const exportFiles = (config) => (dispatch) => {
  const { fileFormat } = config;
  if (fileFormat === "CSV") {
    dispatch(downloadCSV());
  }
};

export const clearExportActions = () => (dispatch) => {
  dispatch(clearExportActionsSuccess());
};
