import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SetGatewayModbus from "../../components/modbus/SetGatewayModbus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faServer,
  faHardDrive,
  faFolderGear,
} from "@fortawesome/pro-regular-svg-icons";
class SetGateway extends Component {
  render() {
    return (
      <div>
        <div>
          {/* <ModbusGatewaysSubmenu /> */}
          <div>
            <div style={{ padding: "60px" }}>
              {/* <SetGatewayModbus previousSelect={this.props.previousTab} nextModbusTab={this.props.nextTab} /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps, {})(SetGateway));
