const initialState = null;

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case "USER_AUTHENTICATED": {
      localStorage.setItem("userData", JSON.stringify(action.user.token));
      return action.user;
    }
    case "LOGOUT_USER": {
      localStorage.removeItem("userData");
      return null;
    }

    case "REGISTER_DATA_VIEW": {
      return {
        ...state,
        RegisterDataResult: action.data,
      };
    }

    case "REGISTER_FAILED_DATA_VIEW": {
      return {
        ...state,
        RegisterFailedDataResult: action.data,
      };
    }

    case "REFRESH_TOKEN": {
      const localUser = JSON.parse(localStorage.getItem("userData"));
      localStorage.setItem(
        "userData",
        JSON.stringify({
          ...localUser,
          expires: action.expires,
        })
      );
      return {
        ...state,
        token: {
          ...state.token,
          expires: action.expires,
        },
      };
    }
    default: {
      return state;
    }
  }
}
