import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import DownloadIcon from "../icons/DownloadIcon";
import ExportPopup from "../popups/ExportPopup";

import { openExport } from "../../actions/screen-activities";

class ExportComponent extends Component {
  render() {
    return (
      <Fragment>
        {this.props.firstTimestamp && this.props.lastTimestamp ? (
          <button className="d-block bace-link" onClick={this.props.openExport}>
            <DownloadIcon /> Export
          </button>
        ) : (
          <div></div>
        )}

        {this.props.exportPopup &&
        this.props.datatypes &&
        this.props.datatypes.length > 0 ? (
          <ExportPopup />
        ) : (
          <div></div>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    datatypes: state.chartViewReducer?.groupDatatypes,
    exportPopup: state.screenActivitiesReducer?.exportPopup,
    firstTimestamp: state.chartViewReducer?.groupData?.firstTimestamp,
    lastTimestamp: state.chartViewReducer?.groupData?.lastTimestamp,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    openExport,
  })(ExportComponent)
);
