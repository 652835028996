import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faChartLine,
  faHistory,
  faBolt,
} from "@fortawesome/free-solid-svg-icons";
import DownloadIcon from "../../../icons/DownloadIcon";
import { openSessionExport } from "../../../../actions/screen-activities";
import ExportSessionPopup from "../../../popups/ExportSessionPopup";
import { clearSessionData } from "../../../../actions/chart-view";

const initialState = {
  id_group: null,
  sessionId: null,
  sessionData: {},
};

class OneHumanMovementScienceSubmenu extends Component {
  state = initialState;
  componentDidMount = () => {
    let { id: id_group, sessionId } = this.props.match.params;
    if (!id_group) {
      this.props.history.push("/");
    }
    if (!sessionId) {
      sessionId = this.props.session_id;
    }
    let sessionData = {};
    if (this.props.groupSessionListViewData) {
      sessionData = this.props.groupSessionListViewData.items.filter(
        (data) => data.id_data_session === sessionId
      );
    }

    this.setState({
      id_group,
      sessionId,
      sessionData,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps !== this.props) {
      let { sessionId } = this.props.match.params;
      if (!sessionId) {
        sessionId = this.props.session_id;
      }

      let sessionData = {};
      if (this.props.groupSessionListViewData) {
        sessionData = this.props.groupSessionListViewData.items.find(
          (data) => data.id_data_session === sessionId
        );
      }

      this.setState({
        sessionId,
        sessionData,
      });
    }
  };

  handleChange = (value) => {
    this.props.clearSessionData();
    this.props.history.push(
      `/human-movement-science/${this.state.id_group}/measurement-data/${value}`
    );
  };

  render() {
    if (this.props.groupSessionListViewData) {
      return (
        <div className="row sticky-submenu">
          {this.props.exportSessionPopup ? (
            <ExportSessionPopup
              sessionId={this.state.sessionData.id_data_session}
              sessionLabel={this.state.sessionData.label}
              dataPoints={this.state.sessionData.datapoint_count}
              start={this.state.sessionData.start}
              end={this.state.sessionData.end}
            />
          ) : (
            ""
          )}
          <div className="col pl-4">
            <h1 className="page-title">
              <Link
                to="/human-movement-science/list-view/1"
                className="link-unstyled"
              >
                Human movement science{" "}
              </Link>
              {this.props.groupSessionListViewData ? (
                <span className="page-title-bold">
                  {" "}
                  | {this.props.groupSessionListViewData.items[0].group?.label}
                </span>
              ) : (
                <div></div>
              )}
            </h1>
            <div>
              <Link
                className={` ${
                  this.props.location.pathname.includes`/measurement-data`
                    ? "bacelink-active"
                    : "bacelink"
                }`}
                to={`/human-movement-science/${this.state.id_group}/measurement-data`}
              >
                <FontAwesomeIcon
                  style={{ cursor: "pointer", marginRight: "4px" }}
                  icon={faChartLine}
                />
                Measurement data
              </Link>
              <Link
                className={` ${
                  this.props.location.pathname.includes`/session-history`
                    ? "bacelink-active"
                    : "bacelink"
                }`}
                to={`/human-movement-science/${this.state.id_group}/session-history`}
              >
                <FontAwesomeIcon
                  style={{ cursor: "pointer", marginRight: "4px" }}
                  icon={faHistory}
                />
                Session history
              </Link>
              <Link
                className={` ${
                  this.props.location.pathname ===
                  `/human-movement-science/${this.state.id_group}/device-info`
                    ? "bacelink-active"
                    : "bacelink"
                }`}
                to={`/human-movement-science/${this.state.id_group}/device-info`}
              >
                <FontAwesomeIcon
                  style={{ cursor: "pointer", marginRight: "4px" }}
                  icon={faList}
                />
                Device info
              </Link>
              <div className="switch-btn-line"></div>
              {/* <div className="mt-4"> */}
              <div
                className={`form-group ${
                  this.props.location.pathname.includes`/measurement-data`
                    ? "row"
                    : "d-none"
                }`}
              >
                <div
                  className="row mx-2"
                  style={{ backgroundColor: "#DCDCDC" }}
                >
                  <div className="row pt-3 px-3">
                    <div className="col-auto">
                      <p className="submenu-specs-key">Session id</p>

                      <select
                        name="groupSessionList"
                        onChange={(event) =>
                          this.handleChange(event.target.value)
                        }
                      >
                        {this.props.groupSessionListViewData.items.map(
                          (data) => (
                            <option
                              key={data.id_data_session}
                              value={data.id_data_session}
                            >
                              {data.label}
                            </option>
                          )
                        )}
                      </select>
                    </div>

                    <div className="col-auto">
                      <p className="submenu-specs-key">Start</p>
                      <p className="submenu-specs-value">
                        {this.state.sessionData ? (
                          <Moment format="DD MMM YYYY hh:mm A">
                            {this.state.sessionData.start * 1000}
                          </Moment>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                    <div className="col-auto">
                      <p className="submenu-specs-key">End</p>
                      <div className="submenu-specs-value">
                        {this.state.sessionData ? (
                          this.state.sessionData.end ? (
                            <Moment format="DD MMM YYYY hh:mm A">
                              {this.state.sessionData.end * 1000}
                            </Moment>
                          ) : (
                            <div>
                              <FontAwesomeIcon
                                style={{ marginRight: "4px", color: "blue" }}
                                icon={faBolt}
                              />{" "}
                              active session
                            </div>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-auto">
                      <p className="submenu-specs-key">Data points</p>
                      <p className="submenu-specs-value">
                        {this.state.sessionData
                          ? this.state.sessionData.datapoint_count
                          : ""}
                      </p>
                    </div>

                    <div className="col-auto ms-auto align-items-center">
                      <button
                        className={true ? "d-block bacelink" : "d-none"}
                        onClick={this.props.openSessionExport}
                      >
                        <DownloadIcon /> Export
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      );
    } else return null;
  }
}

function mapStateToProps(state) {
  return {
    //latestData: state.groupReducer?.latestData,
    datatypes: state.chartViewReducer?.groupDatatypes,
    deviceViewData: state.listViewReducer.deviceViewData,
    exportSessionPopup: state.screenActivitiesReducer?.exportSessionPopup,
    groupSessionListViewData: state.sessionReducer.groupSessionListViewData,
  };
}

export default withRouter(
  connect(mapStateToProps, { openSessionExport, clearSessionData })(
    OneHumanMovementScienceSubmenu
  )
);
