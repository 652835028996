import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LoaderRoller from "../../components/loader/LoaderRoller";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNetworkWired,
  faChartLine,
  faPlusCircle,
  faSliders,
  faTimes,
  faEdit,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import "./style.css";
import {
  getAllWebhooksOverview,
  enableDisableWebhook,
  clearEnableDisableWebhook,
  DeleteWebhook,
  clearDeleteWebhook,
} from "../../actions/session-view";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import WebhookOverviewSubmenu from "../../components/menus/sub-menu/subtypes/WebhookOverviewSubmenu";

class WebhookOverview extends Component {
  state = {
    formInput: {
      projectId: "",
      enableDisableClick: false,
      diableValue: "",
      webhookId: "",
      disableEnableValue: "",
      enableDiableUpdate: false,
      webhookName: "",
      deleteWebhookId: "",
      deleteWebhook: false,
      deleteWebhookName: "",
      deleteWebhookUpdate: false,
    },
  };

  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;

    if (!currentPageNr) {
      return this.props.history.push(
        `/webhook/overview/1/${this.props.match.params?.projectId}`
      );
    }
    this.props.getAllWebhooksOverview(this.props.match.params?.projectId);
  };

  componentWillUnmount = () => {};

  handleToCreateWebhook = () => {
    return this.props.history.push(
      `/webhook/create/1/${this.props.match.params.projectId}`
    );
  };

  toggleEnableDiableChange = (event, webhookId, webhookName) => {
    const formInput = { ...this.state.formInput };
    formInput.enableDisableClick = true;
    formInput.diableValue = event === true ? false : true;
    formInput.webhookId = webhookId;
    formInput.webhookName = webhookName;
    this.setState({ formInput });
  };

  handleToConnectionClose = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.enableDisableClick = false;
    formInput.deleteWebhook = false;
    formInput.diableValue = "";
    this.setState({ formInput });
  };

  handleToChangeEnableDisable = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.disableEnableValue =
      formInput.diableValue === true ? null : Date.now();
    formInput.enableDiableUpdate = true;
    formInput.enableDisableClick = false;
    this.setState({ formInput });
    this.props.clearEnableDisableWebhook();
    this.props.enableDisableWebhook(
      formInput.webhookId,
      formInput.disableEnableValue
    );
  };

  handleToEnableDisableUpdate = () => {
    if (
      this.props.enableDisableUpdated?.status === 200 ||
      this.props.deletedWebhook === 200
    ) {
      this.props.getAllWebhooksOverview(this.props.match.params?.projectId);
    }
    const formInput = { ...this.state.formInput };
    formInput.enableDisableClick = false;
    formInput.diableValue = "";
    formInput.webhookId = "";
    formInput.disableEnableValue = "";
    formInput.enableDiableUpdate = false;
    formInput.deleteWebhookUpdate = false;
    this.setState({ formInput });
  };

  handleToDeleteWebhook = (webhookId, webhookName, index) => {
    const formInput = { ...this.state.formInput };
    formInput.deleteWebhook = true;
    formInput.deleteWebhookId = webhookId;
    formInput.deleteWebhookName = webhookName;
    this.setState({ formInput });
  };

  handleToConfirmDeleteWebhook = () => {
    const formInput = { ...this.state.formInput };
    formInput.deleteWebhookUpdate = true;
    formInput.deleteWebhook = false;
    this.setState({ formInput });
    this.props.clearDeleteWebhook();
    this.props.DeleteWebhook(formInput.deleteWebhookId);
  };

  handleToEdit = (webhookId) => {
    return this.props.history.push(
      `/webhook/edit/1/${this.props.match.params?.projectId}/${webhookId}`
    );
  };

  render() {
    const formInput = this.state.formInput;
    formInput.projectId = this.props.match.params?.projectId;

    return (
      <div>
        {formInput.deleteWebhookUpdate && (
          <Dialog
            open={formInput.deleteWebhookUpdate === true ? true : false}
            onClose={this.handleToEnableDisableUpdate}
          >
            <div onClick={this.handleToEnableDisableUpdate}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.handleToEnableDisableUpdate}
              />
            </div>
            <DialogTitle>
              {this.props.deletedWebhook === 200 ? (
                <p className="wififailedMessage" style={{ display: "flex" }}>
                  <div style={{ fontWeight: "600", marginRight: "9px" }}>
                    {" "}
                    {formInput.deleteWebhookName}
                  </div>{" "}
                  webhook is{"    "} deleted sucessfully !
                </p>
              ) : (
                <LoaderRoller />
              )}
            </DialogTitle>
          </Dialog>
        )}
        {formInput.enableDiableUpdate && (
          <Dialog
            open={formInput.enableDiableUpdate === true ? true : false}
            onClose={this.handleToEnableDisableUpdate}
          >
            <div onClick={this.handleToEnableDisableUpdate}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.handleToEnableDisableUpdate}
              />
            </div>
            <DialogTitle>
              {this.props.enableDisableUpdated?.status === 200 ? (
                <p className="wififailedMessage" style={{ display: "flex" }}>
                  <div style={{ fontWeight: "600", marginRight: "9px" }}>
                    {" "}
                    {formInput.webhookName}
                  </div>{" "}
                  webhook is{"    "}
                  {formInput.diableValue === true ? "enabled" : "disabled"}{" "}
                  sucessfully !
                </p>
              ) : (
                <LoaderRoller />
              )}
            </DialogTitle>
          </Dialog>
        )}

        {formInput.deleteWebhook && (
          <Dialog
            open={formInput.deleteWebhook === true ? true : false}
            onClose={this.handleToConnectionClose}
          >
            <div onClick={this.handleToConnectionClose}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.handleToConnectionClose}
              />
            </div>
            <DialogTitle>
              <p className="wififailedMessage" style={{ display: "flex" }}>
                Do you want to delete {"   "}
                <div
                  style={{
                    fontWeight: "600",
                    marginRight: "7px",
                    marginLeft: "7px",
                  }}
                >
                  {formInput.deleteWebhookName} {"   "}
                </div>
                webhook?
              </p>

              <div className="col pb-3">
                <div className="d-flex justify-content-end">
                  <button
                    className={
                      formInput.setApplyConfirm
                        ? "btn-cancel mx-1 cancelTemplate"
                        : "btn-cancel mx-1"
                    }
                    onClick={this.handleToConnectionClose}
                  >
                    Cancel
                  </button>{" "}
                  <button
                    className={
                      formInput.setApplyConfirm
                        ? "btn-cancel submitDisableButton"
                        : "btn-cancel submitButton"
                    }
                    onClick={() => this.handleToConfirmDeleteWebhook()}
                  >
                    <Fragment>Delete</Fragment>
                    {/* )} */}
                  </button>
                </div>
              </div>
            </DialogTitle>
          </Dialog>
        )}

        {formInput.enableDisableClick && (
          <Dialog
            open={formInput.enableDisableClick === true ? true : false}
            onClose={this.handleToConnectionClose}
          >
            <div onClick={this.handleToConnectionClose}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.handleToConnectionClose}
              />
            </div>
            <DialogTitle>
              <p className="wififailedMessage">
                Do you want to{" "}
                {formInput.diableValue === true ? "enable" : "disable"} this
                webhook?
              </p>

              <div className="col pb-3">
                <div className="d-flex justify-content-end">
                  <button
                    className={
                      formInput.setApplyConfirm
                        ? "btn-cancel mx-1 cancelTemplate"
                        : "btn-cancel mx-1"
                    }
                    onClick={this.handleToConnectionClose}
                  >
                    Cancel
                  </button>{" "}
                  <button
                    className={
                      formInput.setApplyConfirm
                        ? "btn-cancel submitDisableButton"
                        : "btn-cancel submitButton"
                    }
                    onClick={() => this.handleToChangeEnableDisable()}
                  >
                    <Fragment>Confirm</Fragment>
                    {/* )} */}
                  </button>
                </div>
              </div>
            </DialogTitle>
          </Dialog>
        )}
        <div>
          <div style={{ minHeight: "634px" }}>
            <WebhookOverviewSubmenu />
            {this.props.webhooksOverviewData?.items.length > 0 ? (
              <div className="webhookFlow">
                {" "}
                <table
                  class="table table-striped overview"
                  style={{
                    marginLeft: "40px",
                    textAlign: "center",
                    width: "94%",
                  }}
                >
                  <div>
                    {" "}
                    <tr>
                      <th style={{ textAlign: "center", width: "9%" }}>
                        Status
                      </th>
                      <th style={{ textAlign: "center", width: "12%" }}>
                        Label
                      </th>
                      <th style={{ textAlign: "center", width: "15%" }}>
                        Webhook URL
                      </th>
                      <th
                        width="12%"
                        style={{ textAlign: "center", width: "10%" }}
                      >
                        Forwarding Type
                      </th>

                      <th style={{ textAlign: "center", width: "10%" }}>
                        Created at
                      </th>
                      <th style={{ textAlign: "center", width: "10%" }}>
                        Updated at
                      </th>
                      <th style={{ textAlign: "center", width: "10%" }}>
                        Last start
                      </th>
                      <th style={{ textAlign: "center", width: "10%" }}>
                        Last success
                      </th>
                      <th style={{ textAlign: "center", width: "10%" }}>
                        Last finish
                      </th>
                      <th style={{ textAlign: "center", width: "10%" }}>
                        Action
                      </th>
                    </tr>
                  </div>
                  <div>
                    {this.props.webhooksOverviewData.items.map((m, index) => (
                      <tr key={index}>
                        <div style={{ display: "flex" }}>
                          {" "}
                          <Link
                            to={`/webhook/info/1/${this.props.match.params.projectId}/${m?.id_webhook}`}
                            className="col-md-auto mt-2"
                            key={index}
                            style={{
                              textDecoration: "none",
                              color: "black",
                              width: "95%",
                            }}
                          >
                            {" "}
                            <td>
                              {m.disabled_at === null ? (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={true}
                                      color="primary"
                                      onChange={(e) =>
                                        this.toggleEnableDiableChange(
                                          true,
                                          m?.id_webhook,
                                          m?.label
                                        )
                                      }
                                      value={true}
                                    />
                                  }
                                  label="Enabled"
                                />
                              ) : (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={false}
                                      onChange={(e) =>
                                        this.toggleEnableDiableChange(
                                          false,
                                          m?.id_webhook,
                                          m?.label
                                        )
                                      }
                                      value={false}
                                    />
                                  }
                                  label="Disabled"
                                />
                              )}
                            </td>
                            <td>{m.label}</td>
                            <td>
                              <div className="ellipses">{m.webhook_url}</div>
                            </td>
                            <td>
                              {m.forwarding_type === 1
                                ? "Measurements"
                                : "Events"}
                            </td>
                            <td style={{ textAlign: "center", width: "10%" }}>
                              {m.created_at === null ? (
                                "N/A"
                              ) : (
                                <Moment format="DD MMM YYYY HH:mm A">
                                  {m.created_at * 1000}
                                </Moment>
                              )}
                            </td>
                            <td style={{ textAlign: "center", width: "10%" }}>
                              {m.updated_at === null ? (
                                "N/A"
                              ) : (
                                <Moment format="DD MMM YYYY HH:mm A">
                                  {m.updated_at * 1000}
                                </Moment>
                              )}
                            </td>
                            <td style={{ textAlign: "center", width: "10%" }}>
                              {m.last_start === null ? (
                                "N/A"
                              ) : (
                                <Moment format="DD MMM YYYY HH:mm A">
                                  {m.last_start * 1000}
                                </Moment>
                              )}
                            </td>
                            <td style={{ textAlign: "center", width: "10%" }}>
                              {m.last_success === null ? (
                                "N/A"
                              ) : (
                                <Moment format="DD MMM YYYY HH:mm A">
                                  {m.last_success * 1000}
                                </Moment>
                              )}
                            </td>
                            <td style={{ textAlign: "center", width: "10%" }}>
                              {" "}
                              {m.last_finish === null ? (
                                "N/A"
                              ) : (
                                <Moment format="DD MMM YYYY HH:mm A">
                                  {m.last_finish * 1000}
                                </Moment>
                              )}
                            </td>
                          </Link>
                          <td style={{ textAlign: "center", width: "10%" }}>
                            <div style={{ display: "flex" }}>
                              {" "}
                              <button
                                className="btn-border-none webhookButton"
                                onClick={() => this.handleToEdit(m?.id_webhook)}
                              >
                                {" "}
                                <FontAwesomeIcon
                                  className="svg-icons-sm editSettings"
                                  icon={faEdit}
                                />
                              </button>
                              <button
                                className="btn-border-none webhookDeleteButton"
                                onClick={() =>
                                  this.handleToDeleteWebhook(
                                    m?.id_webhook,
                                    m?.label,
                                    index
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  className="svg-icons-sm"
                                  icon={faTrashCan}
                                  style={{ marginBottom: "5px" }}
                                />
                              </button>
                            </div>
                          </td>
                        </div>
                      </tr>
                    ))}
                  </div>
                </table>
              </div>
            ) : this.props.webhooksOverviewData?.items.length === 0 ? (
              <div className="WebhookNumber">
                No webhook is avaiable for now.
              </div>
            ) : (
              <LoaderRoller />
            )}
          </div>
          <div class="row">
            <div
              class="col-sm"
              style={{ textAlign: "center", maxWidth: "3%" }}
            ></div>
            <div class="col-sm" style={{ textAlign: "center" }}>
              <div
                className={
                  this.props.projectAllData?.items.length === 0
                    ? "noButtonProject"
                    : "buttonProject"
                }
              >
                <button
                  onClick={this.handleToCreateWebhook}
                  className="buttonCreateWebhook projectButton"
                >
                  <FontAwesomeIcon className="plusIcon" icon={faPlusCircle} />{" "}
                  <p className="plusCreate"> Create Webhook</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    webhooksOverviewData: state.sessionReducer.webhooksOverviewData,
    enableDisableUpdated: state.sessionReducer.enableDisableUpdated,
    deletedWebhook: state.sessionReducer.deletedWebhook,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAllWebhooksOverview,
    enableDisableWebhook,
    clearEnableDisableWebhook,
    DeleteWebhook,
    clearDeleteWebhook,
  })(WebhookOverview)
);
