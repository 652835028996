import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Moment from "react-moment";
import globalFunctions from "../../helpers/functions";
import { getListViewData } from "../../actions/list-view";
import "./sensor.css";
import Pagination from "@material-ui/lab/Pagination";
import DownIcon from "@material-ui/icons/ArrowDropDown";
import UpIcon from "@material-ui/icons/ArrowDropUp";
import ModbusTCP from "../../assets/Icons/Modbus_TCP.png";
import ModbusRTU from "../../assets/Icons/Modbus_RTU.png";
import BluetoothIcon from "../../assets/Icons/Bluetooth.png";
import ZwaveIcon from "../../assets/Icons/zwave.svg";
const { Td } = globalFunctions;

const removeLastNumber = (inputString) => {
  // Extract the last three digits
  const lastThreeDigitsMatch = inputString.match(/(\d{3})$/);

  // Check if a match is found
  if (lastThreeDigitsMatch) {
    // Replace the first digit with '1' if it is zero
    const modifiedDigits = lastThreeDigitsMatch[1].replace(/^0/, '1');
    return modifiedDigits;
  } else {
    return 'No valid match for the last three digits';
  }
};

class BaceEpListCard extends Component {
  state = {
    formInput: {
      name: "",
      id: "",
      status: "",
      protocol: "",
      connectivity: "",
      activeStatus: "",
    },
  };

  handlePageChange = (event, value) => {
    const { formInput } = this.state;
    const status =
      formInput.status === 1
        ? "Offline"
        : formInput.status === 0
        ? "Online"
        : "";
    const filters = this.getFilters(status);
    this.props.getListViewData(value, "start", "DESC", filters);
    this.props.history.push("/bace-ep/list-view/" + value);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  handleLabelChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.name = event.target.value.toLowerCase();
    this.setState({ formInput }, () => {
      this.handleFilterChange();
    });
  };
  handleProtocolChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.protocol = event.target.value.toLowerCase();
    this.setState({ formInput }, () => {
      this.handleFilterChange();
    });
  };
  handleIdChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.id = event.target.value.toLowerCase();
    this.setState({ formInput }, () => {
      this.handleFilterChange();
    });
  };
  handleConnectionChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.connectivity = event.target.value;
    this.setState({ formInput }, () => {
      this.handleFilterChange();
    });
  };
  handleActiveStatus = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.activeStatus = event.target.value;
    this.setState({ formInput }, () => {
      this.handleFilterChange();
    });
  };
  handleStatusChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.status =
      event.target.value === "Online"
        ? 0
        : event.target.value === "Offline"
        ? 1
        : null;

    this.setState({ formInput }, () => {
      this.handleFilterChange();
    });
  };

  handleFilterChange = () => {
    const { formInput } = this.state;
    const status =
      formInput.status === 1
        ? "Offline"
        : formInput.status === 0
        ? "Online"
        : "";
    const filters = this.getFilters(status);
    setTimeout(() => {
      this.props.getListViewData(1, "start", "DESC", filters);
    }, 1000);
  };

  getFilters = (status) => {
    const { formInput } = this.state;
    let filters = {
      "filter[label][like]": formInput.name ? formInput.name : undefined,
      "filter[id_device_type][like]": formInput.protocol
        ? formInput.protocol
        : undefined,
      "filter[iotDevice.iot_id][like]": formInput.id ? formInput.id : undefined,
    };

    if (status === "Online") {
      filters["filter[is_connected]"] = 1;
    } else if (status === "Offline") {
      filters["filter[is_connected]"] = "0";
    }
    if (formInput.connectivity === "Ethernet") {
      filters["filter[connection]"] = "ethernet";
    } else if (formInput.connectivity === "Cellular") {
      filters["filter[connection]"] = "cellular";
    } else if (formInput.connectivity === "Wifi") {
      filters["filter[connection]"] = "wifi";
    }

    if (formInput.activeStatus === "Active" || formInput.activeStatus === "") {
      filters["filter[inactive_at]"] = "NULL";
    } else if (formInput.activeStatus === "Inactive") {
      filters["filter[inactive_at][>]"] = 0;
    }
    const projectId = window.location.pathname.slice(21);
    filters["filter[id_project]"] = projectId;
    let params = Object.entries(filters)
      .filter((e) => e[1] !== undefined)
      .map((e) => {
        return e.join("=");
      })
      .join("&");
    return params;
  };
  render() {
    const { formInput } = this.state;
    if (this.props.listViewData) {
      const { pathname } = this.props.location;
      const { currentPageNr } = this.props.match.params;
      const baseRoute = currentPageNr
        ? pathname.substring(0, pathname.lastIndexOf("/"))
        : pathname;

      const sortColumn = new URLSearchParams(this.props.location.search).get(
        "sortColumn"
      );
      const sortDirection = new URLSearchParams(this.props.location.search).get(
        "sortDirection"
      );
      const newSortDirection = sortDirection
        ? sortDirection === "ASC"
          ? "DESC"
          : "ASC"
        : "DESC";
      const uniqueConnection = [];
      const uniqueStatus = [];
      this.props.listViewData.items.map((img) => {
        if (
          uniqueConnection.indexOf(img.connection) === -1 &&
          img.connection !== null
        ) {
          uniqueConnection.push(img.connection);
        }
      });

      this.props.listViewData.items.map((img) => {
        if (uniqueStatus.indexOf(img.is_connected) === -1) {
          uniqueStatus.push(img.is_connected);
        }
      });
      return (
        <div>
          <div className="card-p listMain">
            <div
              className="d-flex  mt-1"
              style={{ margin: "auto", width: "90%" }}
            >
              <select
                style={{ width: "10%", marginRight: "10px" }}
                className="form-select dropdownView"
                aria-label="Default select example"
                defaultValue={
                  formInput.status === "Disconnected"
                    ? 0
                    : formInput.status === "Connected"
                    ? 1
                    : ""
                }
                label="status"
                onChange={this.handleStatusChange}
              >
                <option value="" disabled selected>
                  Status
                </option>
                <option selected>All</option>
                <option selected>Offline</option>
                <option selected>Online</option>
              </select>

              <div >
                <input
                  className="dropdownView formInputSearchDeviceId"
                  defaultValue={formInput.name}
                  name="name"
                  type="text"
                  onChange={this.handleLabelChange}
                  placeholder="Label"
                  size="10"
                  
                />
              </div>
              <div>
                <input
                  className="dropdownView formInputSearchDeviceId"
                  defaultValue={formInput.id}
                  id="id"
                  // type="text"
                  onChange={this.handleIdChange}
                  placeholder="Device ID"
                  size="12"
                  type="text"
                  
                />
              </div>
              <div>
                <input
                  className="dropdownView formInputSearchDeviceId"
                  defaultValue={formInput.protocol}
                  name="protocol"
                  type="text"
                  onChange={this.handleProtocolChange}
                  placeholder="Protocol"
                  size="10"
                  
                />
              </div>
              <div className="columnSize">
                <select
                  className="form-select dropdownView"
                  aria-label="Default select example"
                  defaultValue={formInput.connectivity}
                  label="connectivity"
                  onChange={this.handleConnectionChange}
                  placeholder="status"
                >
                  <option value="" disabled selected>
                    Connectivity
                  </option>
                  <option selected>All</option>
                  <option selected>Ethernet</option>
                  <option selected>Cellular</option>
                  <option selected>Wifi</option>
                </select>
              </div>
              <th style={{ paddingLeft: "7px" }}>
                <select
                  className="form-select dropdownView"
                  aria-label="Default select example"
                  defaultValue={formInput.activeStatus}
                  label="status1"
                  onChange={this.handleActiveStatus}
                  placeholder="status"
                >
                  <option value="" selected>
                    Active
                  </option>
                  <option selected>All</option>
                  <option selected>Inactive</option>
                </select>
              </th>
            </div>
            <table
              className="table borderless mt-2"
              style={{ margin: "auto", width: "90%" }}
            >
              {this.props.listViewData?._meta?.pageCount > 0 && (
                <thead>
                  <tr>
                    <th className="card-list-key demoName" scope="col">
                      <Link
                        className="specs-key clickable"
                        scope="col"
                        to={`${baseRoute}/1?sortColumn=is_connected&sortDirection=${newSortDirection}`}
                      >
                        Status
                      </Link>
                      {sortColumn === "is_connected" ? (
                        sortDirection === "ASC" ? (
                          <DownIcon />
                        ) : (
                          <UpIcon />
                        )
                      ) : (
                        ""
                      )}
                    </th>

                    <th className="card-list-key" scope="col" style={{textAlign: "center"}}>
                      <Link
                        className="specs-key clickable"
                        scope="col"
                        to={`${baseRoute}/1?sortColumn=label&sortDirection=${newSortDirection}`}
                      >
                        Label
                      </Link>
                      {sortColumn === "label" ? (
                        sortDirection === "ASC" ? (
                          <DownIcon />
                        ) : (
                          <UpIcon />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                    <th className="card-list-key" scope="col" >
                      {/* <Link
                      className="specs-key clickable"
                      scope="col"
                      to={`${baseRoute}/1?sortColumn=iotDevice.iot_id&sortDirection=${newSortDirection}`}
                    >
                      Device ID
                    </Link> */}
                      <span className="" scope="col">
                        Device ID
                      </span>
                      {sortColumn === "iotDevice.iot_id" ? (
                        sortDirection === "ASC" ? (
                          <DownIcon />
                        ) : (
                          <UpIcon />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                    <th className="card-list-key" scope="col">
                      <Link
                        className="specs-key clickable"
                        scope="col"
                        to={`${baseRoute}/1?sortColumn=id_device_type&sortDirection=${newSortDirection}`}
                      >
                        Protocol
                      </Link>
                      {sortColumn === "id_device_type" ? (
                        sortDirection === "ASC" ? (
                          <DownIcon />
                        ) : (
                          <UpIcon />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                    <th className="card-list-key" scope="col">
                      <Link
                        className="specs-key clickable"
                        scope="col"
                        to={`${baseRoute}/1?sortColumn=connection&sortDirection=${newSortDirection}`}
                      >
                        Connectivity
                      </Link>
                      {sortColumn === "connection" ? (
                        sortDirection === "ASC" ? (
                          <DownIcon />
                        ) : (
                          <UpIcon />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                    <th className="card-list-key" scope="col">
                      <span className="" scope="col">
                        Connected Devices
                      </span>
                    </th>
                    <th className="card-list-key" scope="col" style={{textAlign:"center"}}>
                      <span className="" scope="col">
                        Loaction
                      </span>
                    </th>
                    <th className="card-list-key" scope="col" style={{textAlign:"center"}}>
                      <Link
                        className="specs-key clickable"
                        scope="col"
                        to={`${baseRoute}/1?sortColumn=last_data_at&sortDirection=${newSortDirection}`}
                      >
                        Last Seen
                        
                      </Link>
                      {sortColumn === "last_data_at" ? (
                        sortDirection === "ASC" ? (
                          <DownIcon />
                        ) : (
                          <UpIcon />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                  </tr>
                </thead>
              )}
              
              <div className="pt-4"></div>
              <tbody className="mh-50 p3 listViewStyle">
                {this.props.listViewData.items.length > 0 ? (
                  this.props.listViewData.items.map((i, index) => (
                    
                    <tr key={index} style={{
                      width:"1416px"}} className="clickbleTr"
                      >
                      <Td
                        className="card-list-key"
                        style={{ maxWidith: "25px" }}
                      >
                        <p
                          className={`${
                            i.is_connected === 1
                              ? "connected-list"
                              : "disconnected-list"
                          }`}
                        >
                          {i.is_connected === 0 ? "Offline" : "Online"}
                        </p>
                      </Td>
                    <Td
                        to={`/bace-ep/${i.id_project}/${i.id_physical_device}/device-info`}
                        className="card-list-value"
                      >
                        <p className="labelDesign" style={{textAlign:"center",marginTop:"15px"}}>
                          {i.label === "" ? "" : i.label}
                        </p>
                      </Td>
                      <Td className="card-list-value" >
                        {/* {i?.iotDevice?.iot_id} */}
                        
                        {removeLastNumber(i?.iotDevice?.iot_id)}
                      </Td>
                      <Td className="card-list-value">
                        {" "}
                        <div>
                          {" "}
                          <img
                            src={
                              i?.protocol_received !== null &&
                              i?.protocol_received.includes("modbus-rtu") &&
                              ModbusRTU
                            }
                            className={
                              i?.protocol_received !== null &&
                              i?.protocol_received.includes("modbus-rtu")
                                ? "imageWithoutValueBaseEP"
                                : "imageValue"
                            }
                            title={
                              i?.protocol_received !== null &&
                              i?.protocol_received.includes("modbus-rtu") &&
                              "Modbus RTU"
                            }
                          />
                          <img
                            src={
                              i?.protocol_received !== null &&
                              i?.protocol_received.includes("modbus-tcp") &&
                              ModbusTCP
                            }
                            className={
                              i?.protocol_received !== null &&
                              i?.protocol_received.includes("modbus-tcp")
                                ? "imageWithoutValueBaseEP"
                                : "imageValue"
                            }
                            title={
                              i?.protocol_received !== null &&
                              i?.protocol_received.includes("modbus-tcp") &&
                              "Modbus TCP"
                            }
                          />
                          <img
                            src={
                              i?.protocol_received !== null &&
                              i?.protocol_received.includes("ble") &&
                              BluetoothIcon
                            }
                            className={
                              i?.protocol_received !== null &&
                              i?.protocol_received.includes("ble")
                                ? "imageBluetoothValue"
                                : "imageValue"
                            }
                            title={
                              i?.protocol_received !== null &&
                              i?.protocol_received.includes("ble") &&
                              "Bluetooth"
                            }
                          />
                          <img
                            src={
                              i?.protocol_received !== null &&
                              i?.protocol_received.includes("zwave") &&
                              ZwaveIcon
                            }
                            className={
                              i?.protocol_received !== null &&
                              i?.protocol_received.includes("zwave")
                                ? "imageWithoutValueBaseEP"
                                : "imageValue"
                            }
                            title={
                              i?.protocol_received !== null &&
                              i?.protocol_received.includes("zwave") &&
                              "Zwave"
                            }
                          />
                        </div>
                      </Td>
                      <Td className="card-list-value">{i.connection}</Td>
                      <Td className="card-list-value noOfDevice">
                        {i.subDevices.length}
                      </Td>
                      
                      <Td className="card-list-value">{i?.location?.short_address==null?"NA":i?.location?.short_address}</Td>
                      <Td className="card-list-value">
                        {i?.last_data_at !== null ? (
                          <Moment format="DD MMM YYYY HH:mm">
                            {i?.last_data_at * 1000}
                          </Moment>
                        ) : (
                          <div></div>
                        )}
                      </Td>
                    </tr>
                    
                  ))
                ) : (
                  <tr>
                    <td colspan="7" className="text-center p-3">
                      <h5>No Data Found</h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {this.props.listViewData?._meta?.pageCount > 1 ? (
            <div className="pageStyle m-3">
              <Pagination
                count={this.props.listViewData?._meta?.pageCount}
                page={this.props.listViewData?._meta?.currentPage}
                onChange={this.handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

function mapStateToProps(state) {
  return {
    listViewData: state.listViewReducer.listViewData,
  };
}

export default withRouter(
  connect(mapStateToProps, { getListViewData })(BaceEpListCard)
);
