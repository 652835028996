import React, { Component, Fragment } from "react";
import CONFIG from "../../config";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import Login from "../login/Login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import BACELogo from "../../assets/logo/BACE-logo-blue.svg";
import { Link } from "react-router-dom";
import "../login/style.css";
import "./style.css";

class Failed extends Component {
  state = {
    formInput: {
      emailValue: "",
      emailValid: false,
    },
  };
  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;
    if (!currentPageNr) {
      this.props.history.push("/register/failed");
    }
  };
  render() {
    const { formInput } = this.state;

    return (
      <Fragment>
        <Fragment>
          <div className="loginImgDiv"></div>
          <img className="loginImg" src={BACELogo} alt="BACE logo" />
          <div>
            <p className="successBoard"> </p>
          </div>
          <div>
            <div className="d-flex flex-row justify-content-center">
              <div className="bg-login-container">
                <div className="col-12">
                  {" "}
                  <div className="justify-content-center bace-logo">
                    {" "}
                    <div className="failView">
                      {" "}
                      <FontAwesomeIcon
                        className="m-2 fa-3x"
                        icon={faTriangleExclamation}
                      />
                    </div>
                    <h3 className="loginView">
                      Please Try again !
                      <br />
                      Register is not completed successfully.
                    </h3>
                    <p>
                      Note:{"  "}Mail Id already registered or Mail Id is not valid.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer,
    RegisterFailedDataResult: state.userReducer?.RegisterFailedDataResult,

    RegisterDataResult: state.userReducer?.RegisterDataResult,
  };
}

export default withRouter(connect(mapStateToProps, {})(Failed));
