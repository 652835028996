import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import LoaderRoller from "../../components/loader/LoaderRoller";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNetworkWired,
  faChartLine,
  faPlusCircle,
  faSliders,
} from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import "./style.css";
import IntegrationSubmenu from "../../components/menus/sub-menu/subtypes/IntegrationSubmenu";

import IntegrationBigIcon from "../../assets/Icons/integration_big.png";

class Integration extends Component {
  state = {
    formInput: {
      projectId: "",
    },
  };

  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;

    if (!currentPageNr) {
      return this.props.history.push(
        `/integration/1/${this.props.match.params?.projectId}`
      );
    }
  };

  componentWillUnmount = () => {};

  render() {
    const formInput = this.state.formInput;
    formInput.projectId = this.props.match.params?.projectId;
    return (
      <div>
        <div>
          <div>
            <IntegrationSubmenu />
            <div className="card-grid">
              <Link
                to={`/webhook/overview/1/${this.props.match.params?.projectId}`}
                className="bg specs-key clickable divmodbus getwayCard integrationView"
              >
                <div className="card-data">
                  <p className="gateWayscardheading">Webhooks</p>

                  <img
                    alt="home card"
                    src={IntegrationBigIcon}
                    style={{
                      marginRight: "8px",
                      marginLeft: "-4px",
                      // color: "ccean",
                    }}
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps, {})(Integration));
