import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
//import globalFunctions from "../../helpers/functions";
import { clearExportActions } from "../../actions/screen-activities";
//import { getGroupData } from "../../actions/chart-view";

import { Line, Chart } from "react-chartjs-2";
import "chartjs-adapter-moment";
import zoomPlugin from "chartjs-plugin-zoom";
//import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus, faSearchMinus } from "@fortawesome/free-solid-svg-icons";
//import { faHandPaper } from "@fortawesome/free-regular-svg-icons";

import { getSessionData } from "../../actions/chart-view";
import "./sensor.css";
import moment from "moment";
import LoaderRoller from "../loader/LoaderRoller";
import CONFIG from "../../config";

const initialState = {
  id: null,
  sessionId: null,
  loader: false,
};

const { ACCELERATION_X, GYROSCOPE_X } = CONFIG.datatypesHumanMovementScience;

class MeasurementSessionDataMultiLineChart extends Component {
  state = initialState;

  componentDidMount = () => {
    let { id, sessionId } = this.props.match.params;
    if (!id) {
      return this.props.history.push("/");
    }

    if (!sessionId) {
      sessionId = this.props.session_id;
    }

    this.setState({
      id,
      sessionId,
    });

    this.props.getSessionData(sessionId, this.props.data_types[0].datatype);
    this.props.getSessionData(sessionId, this.props.data_types[1].datatype);
    this.props.getSessionData(sessionId, this.props.data_types[2].datatype);

    Chart.register(zoomPlugin);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps !== this.props) {
      this.setState({
        loader: false,
      });
    }
  };

  resetZoom = () => {
    this.props.getSessionData(
      this.state.sessionId,
      this.props.data_types[0].datatype
    );
    this.props.getSessionData(
      this.state.sessionId,
      this.props.data_types[1].datatype
    );
    this.props.getSessionData(
      this.state.sessionId,
      this.props.data_types[2].datatype
    );
  };

  // zoom in shows 50% of the currently visible data period
  zoomIn = (labels) => {
    const newPeriodInSeconds = (labels[labels.length - 1] - labels[0]) / 2;
    const maxLabel = labels[labels.length - 1] - newPeriodInSeconds / 2;
    const minLabel = labels[0] + newPeriodInSeconds / 2;
    //const { id } = this.props.match.params;

    this.props.getSessionData(
      this.state.sessionId,
      this.props.data_types[0].datatype,
      minLabel,
      maxLabel
    );
    this.props.getSessionData(
      this.state.sessionId,
      this.props.data_types[1].datatype,
      minLabel,
      maxLabel
    );
    this.props.getSessionData(
      this.state.sessionId,
      this.props.data_types[2].datatype,
      minLabel,
      maxLabel
    );
  };

  // zoom in shows 200% of the currently visible data period
  zoomOut = (labels) => {
    const newPeriodInSeconds = (labels[labels.length - 1] - labels[0]) * 2;
    const maxLabel = labels[labels.length - 1] + newPeriodInSeconds / 2;
    const minLabel = labels[0] - newPeriodInSeconds / 2;

    this.props.getSessionData(
      this.state.sessionId,
      this.props.data_types[0].datatype,
      minLabel,
      maxLabel
    );
    this.props.getSessionData(
      this.state.sessionId,
      this.props.data_types[1].datatype,
      minLabel,
      maxLabel
    );
    this.props.getSessionData(
      this.state.sessionId,
      this.props.data_types[2].datatype,
      minLabel,
      maxLabel
    );
  };

  getMoreData = (chart, labels, id) => {
    if (!this.props.data.moreDataAvailable) return;
    // const { min, max } = chart.scales.x;
    const { ticks } = chart.scales.x;

    this.setState({
      ...this.state,
      loader: true,
    });

    this.props.getSessionData(
      this.state.sessionId,
      this.props.data_types[0].datatype,
      (ticks[0]?.value / 1000).toFixed(0),
      (ticks[ticks.length - 1]?.value / 1000).toFixed(0)
    );
    this.props.getSessionData(
      this.state.sessionId,
      this.props.data_types[1].datatype,
      (ticks[0]?.value / 1000).toFixed(0),
      (ticks[ticks.length - 1]?.value / 1000).toFixed(0)
    );
    this.props.getSessionData(
      this.state.sessionId,
      this.props.data_types[2].datatype,
      (ticks[0]?.value / 1000).toFixed(0),
      (ticks[ticks.length - 1]?.value / 1000).toFixed(0)
    );
  };

  makeTicksUnique = function (next) {
    return function (value, index, values) {
      var nextValue = next(value);

      if (
        index &&
        nextValue.toString() ===
          moment(values[index - 1]?.value).format("MMM DD")
      ) {
        return null;
      }

      return nextValue;
    };
  };

  render() {
    if (this.props.dataX && this.props.dataY && this.props.dataZ) {
      const { id } = this.props.match.params;
      const { type, unit } = this.props;

      const dataToRenderX = [
        ...this.props.dataX.data.items.map((dataPoint) => dataPoint.avg_val),
      ];

      const dataToRenderY = [
        ...this.props.dataY.data.items.map((dataPoint) => dataPoint.avg_val),
      ];

      const dataToRenderZ = [
        ...this.props.dataZ.data.items.map((dataPoint) => dataPoint.avg_val),
      ];

      const labels = [
        ...this.props.dataX.data.items.map(
          (dataPoint) => dataPoint.timestamp_seconds
        ),
      ];

      const decimals =
        type === "Acceleration"
          ? ACCELERATION_X.decimals
          : GYROSCOPE_X.decimals;

      const pluginRegister = {
        // Vertical line
        afterDraw: function (chart, easing) {
          if (chart.tooltip._active && chart.tooltip._active.length) {
            const activePoint = chart.tooltip._active[0];
            const ctx = chart.ctx;
            const x = activePoint.element.x;
            const topY = chart.scales.y.top;
            const bottomY = chart.scales.y.bottom;

            ctx.save();
            ctx.beginPath();
            ctx.strokeStyle = "#000";
            ctx.lineWidth = 1;
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.stroke();
            ctx.restore();
          }
        },
      };

      const noDataPlugin = {
        afterDraw: function (chart) {
          let ctx = chart.ctx;
          let width = chart.width;
          let height = chart.height;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "34px Arial";
          ctx.fillText("No data to display", width / 2, height / 2);
          ctx.restore();
        },
      };

      const options = {
        animation: { duration: 500 },
        onHover: (e) => {
          // Change cursor
          const canvas = e.chart.canvas;
          var cursorStyle = "crosshair";
          canvas.style.cursor = cursorStyle;
        },
        responsive: true,
        scales: {
          // Remove horisontal grid
          x: {
            grid: {
              display: false,
            },
            ticks: {
              callback: this.makeTicksUnique(function (value) {
                return value;
              }),
            },
            type: "time",
            time: {
              tooltipFormat: "DD-MMM HH:mm:ss",
              displayFormats: {
                millisecond: "MMM DD",
                second: "MMM DD",
                minute: "MMM DD",
                hour: "MMM DD",
                day: "MMM DD",
                week: "MMM DD",
                month: "MMM DD",
                quarter: "MMM DD",
                year: "MMM DD",
              },
            },
          },
        },
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              fontColor: "white",
              boxHeight: 3,
              padding: 50,
            },
          },
          tooltip: {
            // Tooltip customization
            mode: "nearest",
            intersect: false,
            // Old code
            // displayColors: false,
            // cornerRadius: 0,
            // backgroundColor: "white",
            // titleColor: 'black',
            // // titleFontFamily: "Helvetica",
            // // titleFontSize: 40,
            // borderColor: "#00004D",
            // borderWidth: 1,
            // callbacks: {
            //   labelTextColor: function(tooltipItem, chart) {
            //     return '#000';
            //   },
            //   title: (tooltipItem) => {
            //     if (!tooltipItem || !tooltipItem[0]) return;
            //     return tooltipItem[0]?.label;
            //   },
            //   label: (tooltipItem, data) => {
            //     return `${tooltipItem.raw} ${datatypeUnit}`;
            //   },
            // },
            // End of old code
            // Disable the on-canvas tooltip
            enabled: false,
            external: function (context) {
              // Tooltip Element
              let tooltipEl = document.getElementById("chartjs-tooltip");
              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement("div");
                tooltipEl.id = "chartjs-tooltip";
                tooltipEl.innerHTML = "<table></table>";
                document.body.appendChild(tooltipEl);
              }

              var tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                if (
                  document.contains(document.getElementById("chartjs-tooltip"))
                ) {
                  document.body.removeChild(tooltipEl);
                }
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove("above", "below", "no-transform");
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add("no-transform");
              }

              // Style tooltip
              tooltipEl.style.backgroundColor = "white";
              tooltipEl.style.marginLeft = "5px";
              tooltipEl.style.marginTop = "5px";
              tooltipEl.style.opacity = 1;
              tooltipEl.style.boxShadow = "2px 4px 8px #0000004D";
              tooltipEl.style.padding = "1em";

              // Set Text
              if (tooltipModel.body) {
                const titleLines = tooltipModel.title || [];
                const bodyLines = tooltipModel.dataPoints.map(
                  (item) => item.formattedValue
                );

                let innerHtml = "<thead>";
                titleLines.forEach(function (title) {
                  let style =
                    "font: normal normal normal 14px/30px Montserrat;";
                  style += "letter-spacing: 0px;";
                  style += "color: #232323;";
                  innerHtml +=
                    '<tr><th style="' + style + '">' + title + "</th></tr>";
                });
                innerHtml += "</thead><tbody>";

                bodyLines.forEach(function (body, i) {
                  let style = "letter-spacing: 0px;";
                  style += "color: #00002A;";
                  style += "font: normal normal bold 48px/40px Montserrat;";
                  let span = `<span style="${style}">${body}</span>`;

                  let unitStyle =
                    "font: normal normal normal 48px/40px Montserrat;";
                  unitStyle += "letter-spacing: 0px;";
                  unitStyle += "color: #00002A;";
                  let unitSpan = `<span style="${unitStyle}">${
                    type === "Acceleration"
                      ? " " + ACCELERATION_X.unit
                      : " " + GYROSCOPE_X.unit
                  }</span>`;
                  innerHtml += "<tr><td>" + span + unitSpan + "</td></tr>";
                });
                innerHtml += "</tbody>";

                let tableRoot = tooltipEl.querySelector("table");
                tableRoot.innerHTML = innerHtml;
              }

              let position = context.chart.canvas.getBoundingClientRect();

              tooltipEl.style.position = "absolute";
              tooltipEl.style.left =
                position.left + window.pageXOffset + tooltipModel.caretX + "px";
              tooltipEl.style.top =
                position.top + window.pageYOffset + tooltipModel.caretY + "px";
              tooltipEl.style.padding =
                tooltipModel.padding + "px " + tooltipModel.padding + "px";
              tooltipEl.style.pointerEvents = "none";
            },
          },

          zoom: {
            // Zoom
            limits: {
              x: { min: "original", max: "original" },
            },
            zoom: {
              wheel: {
                enabled: true,
                modifierKey: "shift",
              },
              drag: {
                enabled: true,
              },
              // pinch: {
              //   enabled: true,
              //   modifierKey: 'ctrl',
              // },
              mode: "x",
              onZoomComplete: ({ chart }) => {
                let tooltipEl = document.getElementById("chartjs-tooltip");
                if (
                  document.contains(document.getElementById("chartjs-tooltip"))
                ) {
                  document.body.removeChild(tooltipEl);
                }
                this.getMoreData(chart, labels, id);
              },
            },
          },
        },
      };

      const data = (canvas) => {
        return {
          labels: labels.map((timestamp) => new Date(timestamp * 1000)),
          datasets: [
            {
              data: dataToRenderX.map((elem) => elem.toFixed(decimals)),
              type: "line",
              label: `X axis`,
              pointRadius: 0,
              fill: true,
              backgroundColor: "transparent",
              pointHoverBackgroundColor: "#003DFF",
              pointHoverBorderWidth: 1,
              pointHoverRadius: 5,
              lineThickness: 1,
              borderWidth: 3,
              borderColor: "#003DFF",
            },
            {
              data: dataToRenderY.map((elem) => elem.toFixed(decimals)),
              type: "line",
              label: `Y axis`,
              pointRadius: 0,
              fill: true,
              backgroundColor: "transparent",
              pointHoverBackgroundColor: "#003DFF",
              pointHoverBorderWidth: 1,
              pointHoverRadius: 5,
              lineThickness: 1,
              borderWidth: 3,
              borderColor: "#EDA900",
            },
            {
              data: dataToRenderZ.map((elem) => elem.toFixed(decimals)),
              type: "line",
              label: `Z axis`,
              pointRadius: 0,
              fill: true,
              backgroundColor: "transparent",
              pointHoverBackgroundColor: "#003DFF",
              pointHoverBorderWidth: 1,
              pointHoverRadius: 5,
              lineThickness: 1,
              borderWidth: 3,
              borderColor: "#E100E1",
            },
          ],
        };
      };

      return (
        <Fragment>
          <div className="bacecard-graph p-4">
            <div className="row pb-2">
              <div className="col-8 text-left">
                <h2>{type}</h2>
                <p>&nbsp; {unit}</p>
              </div>
              <div className="col-4 d-flex justify-content-end">
                <div>
                  <button
                    className="btn btn-sm shadow btn-default me-2"
                    onClick={this.zoomIn.bind(this, labels)}
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faSearchPlus}
                    />
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-sm shadow btn-default me-2"
                    onClick={this.zoomOut.bind(this, labels)}
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faSearchMinus}
                    />
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-sm shadow btn-default"
                    onClick={this.resetZoom}
                  >
                    Reset Zoom
                  </button>
                </div>
              </div>
            </div>
            {this.state.loader ? (
              <div
                className={`${
                  this.state.loader ? "d-flex" : "d-none"
                } justify-content-center align-items-center`}
                style={{
                  height: "100%",
                  width: "100%",
                  minHeight: 556,
                  minWidth: 480,
                  backgroundColor: "rgba(0,0,0,.1)",
                }}
              >
                <div style={{ height: "50px", color: "#fff" }}>
                  <LoaderRoller />
                </div>
              </div>
            ) : dataToRenderX.length > 0 &&
              dataToRenderY.length > 0 &&
              dataToRenderY.length > 0 ? (
              <Line data={data} options={options} plugins={[pluginRegister]} />
            ) : (
              <Line data={data} options={options} plugins={[noDataPlugin]} />
            )}
          </div>
        </Fragment>
      );
    } else {
      return (
        <div
          className="bacecard p-4"
          style={{ minHeight: "70vh", minWidth: "50vw" }}
        >
          <h4> </h4>
          <LoaderRoller />
        </div>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    dataX: state.chartViewReducer?.sessionDataPerDatatype.find(
      (data) => data.datatype === ownProps.data_types[0].datatype
    ),
    dataY: state.chartViewReducer?.sessionDataPerDatatype.find(
      (data) => data.datatype === ownProps.data_types[1].datatype
    ),
    dataZ: state.chartViewReducer?.sessionDataPerDatatype.find(
      (data) => data.datatype === ownProps.data_types[2].datatype
    ),
    datatypes: state.chartViewReducer?.groupDatatypes,
    moreDataAvailable: state.chartViewReducer?.moreDataAvailable,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    clearExportActions,
    getSessionData,
    //getGroupData,
  })(MeasurementSessionDataMultiLineChart)
);
