import axios from "axios";

import { newRedirect } from "./redirect";

import { showDanger, showWarning } from "./notification";

import CONFIG from "../config";
const {
  baseUrl,
  // API_v1_URL
} = CONFIG;

const USER_AUTHENTICATED = "USER_AUTHENTICATED";
const REFRESH_TOKEN = "REFRESH_TOKEN";
const LOGOUT_USER = "LOGOUT_USER";
const REGISTER_DATA_VIEW = "REGISTER_DATA_VIEW";
const REGISTER_FAILED_DATA_VIEW = "REGISTER_FAILED_DATA_VIEW";


const userAuthSuccess = (user) => ({
  type: USER_AUTHENTICATED,
  user,
});

const refreshTokenSuccess = (expires) => ({
  type: REFRESH_TOKEN,
  expires,
});

const logoutUserSuccess = () => ({
  type: LOGOUT_USER,
});

const getRegisterDataSuccess = (data) => ({
  type: REGISTER_DATA_VIEW,
  data,
});

const getRegisterDataFailed = (data) => ({
  type: REGISTER_FAILED_DATA_VIEW,
  data,
});



export const tryUserAuth = (token) => (dispatch) => {
  // Handle window refresh, and do nothing on window refresh
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(`${baseUrl}/user/self?include_permissions=1&include_roles=1`)
    .then((res) => {
      dispatch(
        userAuthSuccess({
          token,
          user: res.data?.user,
          roles: res.data?.roles,
          permissions: res.data?.permissions,
        })
      );
      // Check if token should not be refreshed'
      dispatch(refreshToken(token));
      if (performance?.navigation?.type !== 1) {
        dispatch(newRedirect("/"));
        // dispatch(showInfo({
        //   body: `Welcome back, ${res.data?.user?.username || 'User'}`
        // }))
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(clearUser());
      }
      dispatch(
        showDanger({
          body: err?.response?.data?.message || err.message,
        })
      );
    });
};

export const refreshToken = (token) => (dispatch) => {
  const expirationDate = new Date(token.expires).getTime();
  const currentDate = new Date().getTime();
  const hours24 = 86400000;
  const url = new URL(baseUrl);

  if (expirationDate < currentDate + hours24 * 7) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios
      .post(`${url.origin}/oauth/refresh-token`, {
        refresh_token: token.refresh,
      })
      .then((res) => {
        if (res.data?.refreshed) {
          dispatch(refreshTokenSuccess(res.data?.expires));
        } else {
          dispatch(showWarning("Unknown error, refreshing token."));
        }
      })
      .catch((err) => {
        dispatch(
          showDanger({
            body: err?.response?.data?.message || err.message,
          })
        );
      });
  }
};




export const logoutUser = () => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

  axios
    .get(`${baseUrl}/user/logout`)
    .then(() => {
      dispatch(clearUser());
    })
    .catch((err) => {
      console.log(err);
    });

  // dispatch(
  //   showInfo({
  //     body: "Thank you for visit. See you soon!",
  //   })
  // );
};

export const createRegister =
  (emailId, clientId, clientSecret) => (dispatch, getState) => {
    axios({
      method: "POST",
      url: `${baseUrl}/user/register`,
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        client_id: clientId,
        client_secret: clientSecret,
      },
      referrerPolicy: "no-referrer",
      data: {
        email: emailId.toString(),
      },
    })
      .then((res) => {
        dispatch(getRegisterDataSuccess(res));
      })
      .catch((err) => {
        // dispatch(showWarning(err.message));
        dispatch(getRegisterDataFailed("failed"));
      });
  };

export const clearUser = () => (dispatch) => {
  dispatch(logoutUserSuccess());
  dispatch(newRedirect("/"));
};
