import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import Moment from "react-moment";
import PaginationNew from "../../components/global/paginate/PaginationNew";

import globalFunctions from "../../helpers/functions";

import DownIcon from "@material-ui/icons/ArrowDropDown";
import UpIcon from "@material-ui/icons/ArrowDropUp";

import {
  getGroupSessionListViewData,
  clearGroupSessionListViewData,
} from "../../actions/session-view";

import "./sensor.css";

const { uniqueKey, Td, TdLink } = globalFunctions;

class DeviceSessionListCard extends Component {
  state = {
    formInput: {
      sessionId: "",
      // start: "",
      // end: "",
    },
  };

  handleSubmit = (e) => {
    e.preventDefault();

    let filter = this.getFilters();

    this.props.getGroupSessionListViewData(
      this.props.match.params.id,
      1,
      "start",
      "DESC",
      filter.key,
      filter.value
    );
  };

  handleChange = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput[input.name] = input.value;
    this.setState({ formInput });
  };

  getFilters = () => {
    let filterKey;
    let filterValue;
    const { formInput } = this.state;

    if (formInput.sessionId) {
      filterKey = "label";
      filterValue = formInput.sessionId;
    }

    return { key: filterKey, value: filterValue };
  };

  render() {
    const { formInput } = this.state;
    const { subtype } = this.props;
    if (
      this.props.groupSessionListViewData &&
      this.props.groupSessionListViewData?._meta?.totalCount > 0
    ) {
      const { pathname } = this.props.location;
      const { currentPageNr } = this.props.match.params;
      const baseRoute = currentPageNr
        ? pathname.substring(0, pathname.lastIndexOf("/"))
        : pathname;

      const sortColumn = new URLSearchParams(this.props.location.search).get(
        "sortColumn"
      );
      const sortDirection = new URLSearchParams(this.props.location.search).get(
        "sortDirection"
      );
      const newSortDirection = sortDirection
        ? sortDirection === "ASC"
          ? "DESC"
          : "ASC"
        : "DESC";
      return (
        <div className="bacecard-lg py-3 px-4">
          <form onSubmit={this.handleSubmit}>
            <button className="d-none">SUBMIT</button>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="no-border">
                    <Link
                      className="specs-key-low clickable"
                      scope="col"
                      to={`${baseRoute}/1?sortColumn=label&sortDirection=${newSortDirection}`}
                    >
                      Session id
                    </Link>
                    {sortColumn === "label" ? (
                      sortDirection === "ASC" ? (
                        <DownIcon />
                      ) : (
                        <UpIcon />
                      )
                    ) : (
                      ""
                    )}
                  </th>
                  <th className="specs-key-low no-border" scope="col">
                    <Link
                      className="specs-key-low clickable"
                      scope="col"
                      to={`${baseRoute}/1?sortColumn=start&sortDirection=${newSortDirection}`}
                    >
                      Start
                    </Link>
                    {sortColumn === "start" ? (
                      sortDirection === "ASC" ? (
                        <DownIcon />
                      ) : (
                        <UpIcon />
                      )
                    ) : (
                      ""
                    )}
                  </th>
                  <th className="specs-key-low no-border" scope="col">
                    <Link
                      className="specs-key-low clickable"
                      scope="col"
                      to={`${baseRoute}/1?sortColumn=end&sortDirection=${newSortDirection}`}
                    >
                      End
                    </Link>
                    {sortColumn === "end" ? (
                      sortDirection === "ASC" ? (
                        <DownIcon />
                      ) : (
                        <UpIcon />
                      )
                    ) : (
                      ""
                    )}
                  </th>
                  <th className="specs-key-low no-border" scope="col">
                    <Link
                      className="specs-key-low clickable"
                      scope="col"
                      to={`${baseRoute}/1?sortColumn=datapoint_count&sortDirection=${newSortDirection}`}
                    >
                      Data points
                    </Link>
                    {sortColumn === "datapoint_count" ? (
                      sortDirection === "ASC" ? (
                        <DownIcon />
                      ) : (
                        <UpIcon />
                      )
                    ) : (
                      ""
                    )}
                  </th>
                  <th className="specs-key-low no-border" scope="col"></th>
                </tr>
                <tr className="form-group">
                  <th className="no-border" scope="col">
                    <input
                      className="form-control"
                      value={formInput.sessionId}
                      name="sessionId"
                      type="text"
                      onChange={this.handleChange}
                    />
                  </th>
                  <th className="no-border" scope="col">
                    {/* <input
                      className="form-control"
                      value={formInput.start}
                      name="start"
                      type="date"
                      onChange={this.handleChange}
                    /> */}
                  </th>
                  <th className="no-border" scope="col">
                    {/* <input
                      className="form-control"
                      value={formInput.end}
                      name="end"
                      type="date"
                      onChange={this.handleChange}
                    /> */}
                  </th>
                  <th className="no-border" scope="col"></th>
                  <th className="no-border" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {this.props.groupSessionListViewData.items.map((data) => (
                  <tr key={uniqueKey()}>
                    {/* <Td>{data.id_data_session}</Td> */}
                    <Td>{data.label}</Td>
                    <Td>
                      {" "}
                      <Moment format="DD MMM YYYY hh:mm">
                        {data.start * 1000}
                      </Moment>
                    </Td>
                    <Td>
                      <Moment format="DD MMM YYYY hh:mm">
                        {data.end * 1000}
                      </Moment>
                    </Td>
                    <Td>{data.datapoint_count}</Td>
                    <TdLink
                      to={{
                        pathname: `/${subtype}/${data.id_group}/measurement-data/${data.id_data_session}`,
                      }}
                      className="specs-value"
                    >
                      Measurement data{" "}
                      <FontAwesomeIcon
                        style={{ marginLeft: "8px" }}
                        icon={faArrowRight}
                        size="sm"
                      />
                    </TdLink>
                  </tr>
                ))}
              </tbody>
            </table>
          </form>
          <PaginationNew />
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

function mapStateToProps(state) {
  return {
    groupSessionListViewData: state.sessionReducer.groupSessionListViewData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getGroupSessionListViewData,
    clearGroupSessionListViewData,
  })(DeviceSessionListCard)
);
