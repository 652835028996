import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModbusIconBlue from "./../../assets/Icons/modbussetting.png";
import {
  faHardDrive,
  faCircleInfo,
  faTrashCan,
  faServer,
  faPlusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import AddRegistersModbus from "./AddRegistersModbus";
import SetGatewayModbus from "./SetGatewayModbus";
import { logDOM } from "@testing-library/react";
import { getAllModbusDeviceForGateway } from "../../actions/modbus-view";

class SelectModbusDevices extends Component {
  state = {
    formInput: {
      previousTab: "",
      //  loadDataTemplate: [],
      previousSetTab: "",
      previousModbusTab: "",
      previousRegisterTab: "",
      nextSetTab: "",
      nextRegisterDevicesTab: "",
      nextModbusDevicesTab: "",
      nextResult: "",
      previousSet: false,
      previousLoadTemplate: false,
      addRow: [],
      modbusDeviceSetting: false,
      ModbusNewRow: [],
      ModbuserrorView: false,
      modbusTemplateName: "",
      interval: "",
      modeName: "",
      modeValue: "",
      physicalDeviceIdGateway: "",
      groupId: "",
      ModbusDataArray: [],
      loadTemplateModbus: [],
      labelId: false,
      firstipAddress: "",
      arrayipAddress: [],
      dataIpList: "",
      dataFirstIpName: "",
      ModbusFirstData: [
        {
          deviceName: "",
          slaveOrIpAddressID: "",
          byteOrder: "0",
          wordOrder: "0",
          id: "first",
          registers: [
            {
              label: "",
              unit: "",
              reg_start: "",
              scale: "",
              reg_type: "0",
              data_type: "1",
              access_mode: "2",
              byte_order: "0",
              word_order: "0",
              addr: "",
              value_id: "",
              is_single_bit_binary: 0,
              reg_Id: 0,
            },
          ],
        },
      ],
      modbusDeviceName: "",
      modbusDeviceMode: "",
      modbusErrorLabelMsg: false,
      modbusErrorModeMsg: false,
      modbusErrorIPAddressMsg: false,
      modbusNotValid: false,
      nextRegisterPage: false,
      id_iot_device_Value: "",
    },
  };

  handleToNextRegister = () => {
    const formInput = { ...this.state.formInput };
    const valueMissing = formInput.ModbusDataArray.map(
      (l) => l.slaveOrIpAddressID === ""
    );
    const ipList = formInput.arrayipAddress.filter(
      (x) => !Object.values(x).includes("")
    );
    if (this.props.setGatewayInfo.modeName === "modbus-tcp") {
      const listIpAddressVerify = formInput.ModbusDataArray.map((l) =>
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          l.slaveOrIpAddressID
        )
      );
      const firstIpAddressVerify =
        formInput.ModbusFirstData.length === 0
          ? ""
          : /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
              formInput.ModbusFirstData[0].slaveOrIpAddressID
            );

      if (valueMissing.includes(true)) {
        formInput.labelId = true;
        formInput.ModbuserrorView = false;
        formInput.modbusErrorIPAddressMsg = false;
        this.setState({ formInput });
        // }
      } else if (
        firstIpAddressVerify === false ||
        listIpAddressVerify.includes(false)
      ) {
        if (formInput.ModbusFirstData[0].slaveOrIpAddressID === "") {
          formInput.labelId = true;
          formInput.modbusErrorIPAddressMsg = false;
          this.setState({ formInput });
        } else {
          formInput.modbusErrorIPAddressMsg = true;
          formInput.labelId = false;
          this.setState({ formInput });
        }
      }
    }

    if (formInput.modbusErrorIPAddressMsg !== true) {
      if (
        formInput.ModbusFirstData.length === 1 &&
        formInput.ModbusFirstData[0].slaveOrIpAddressID === "" &&
        formInput.loadTemplateModbus.length === 0
      ) {
        if (formInput.firstipAddress === "") {
          formInput.labelId = true;
          formInput.ModbuserrorView = false;
          this.setState({ formInput });
        }
      } else if (valueMissing.includes(true)) {
        formInput.labelId = true;
        formInput.ModbuserrorView = false;
        this.setState({ formInput });
        // }
      } else {
        if (formInput.modbusErrorModeMsg === false) {
          if (formInput.ModbusFirstData.length >= 1) {
            formInput.ModbusFirstData.map((p) => {
              if (p.slaveOrIpAddressID !== "") {
                formInput.ModbusNewRow.push(p);
              }
            });
          }
          if (formInput.ModbusDataArray.length >= 1) {
            formInput.ModbusDataArray.map((m) => {
              formInput.ModbusNewRow.push(m);
            });
          }
          this.props.nextRegisterDevicesTab();
          formInput.nextRegisterPage = true;
          formInput.previousLoadTemplate = true;
          this.setState({ formInput });
        }
      }
    }
  };

  handleToDeleteRow = (rowId, index) => {
    const formInput = { ...this.state.formInput };
    if (
      formInput.ModbusFirstData.length === 0 &&
      formInput.ModbusDataArray.length === 1
    ) {
      formInput.modbusDeviceSetting = true;
    } else {
      formInput.ModbusDataArray = formInput.ModbusDataArray.filter(
        (_, i) => i !== index
      );
    }
    //})
    formInput.ModbuserrorView = false;
    this.setState({ formInput });
  };

  handleToDeleteRowFirst = () => {
    const formInput = { ...this.state.formInput };
    // formInput.ModbusDataArray.splice(index, 1);
    if (formInput.ModbusDataArray.length === 0) {
      formInput.modbusDeviceSetting = true;
    } else {
      formInput.ModbusFirstData = formInput.ModbusFirstData.filter((row) => {
        return row.id !== "first";
      });
    }
    formInput.ModbuserrorView = false;
    this.setState({ formInput });
  };

  handleToAddNewrow = (event) => {
    const formInput = { ...this.state.formInput };

    // formInput.ModbusDataArray[formInput.ModbusDataArray.length - 1].id =
    //   formInput.ModbusDataArray.length;
    if (
      this.props.setGatewayInfo.modeName === "modbus-tcp" &&
      formInput.ModbusDataArray.length > 3 &&
      formInput.ModbusFirstData.length === 1
    ) {
      formInput.ModbuserrorView = true;
      formInput.labelId = false;
      this.setState({ formInput });
    } else if (
      this.props.setGatewayInfo.modeName === "modbus-tcp" &&
      formInput.ModbusDataArray.length > 4 &&
      formInput.ModbusFirstData.length === 0
    ) {
      formInput.ModbuserrorView = true;
      formInput.labelId = false;
      this.setState({ formInput });
    } else if (
      formInput.ModbusDataArray.length > 30 &&
      formInput.ModbusFirstData.length === 1 &&
      this.props.setGatewayInfo.modeName === "modbus-rtu"
    ) {
      formInput.ModbuserrorView = true;
      formInput.labelId = false;
      this.setState({ formInput });
    } else if (
      formInput.ModbusDataArray.length > 31 &&
      this.props.setGatewayInfo.modeName === "modbus-rtu"
    ) {
      formInput.ModbuserrorView = true;
      formInput.labelId = false;
      this.setState({ formInput });
    } else {
      formInput.ModbusDataArray.push({
        deviceName: "",
        slaveOrIpAddressID: "",
        byteOrder: "0",
        wordOrder: "0",
        id: "",
        registers: [
          {
            label: "",
            unit: "",
            reg_start: "",
            scale: "",
            reg_type: "0",
            data_type: "1",
            access_mode: "2",
            byte_order: formInput.byteOrder,
            word_order: formInput.wordOrder,
            addr: "",
            value_id: "",
            reg_Id: 0,
            is_single_bit_binary: 0,
          },
        ],
      });
      if (formInput.ModbusDataArray.length === 1) {
        formInput.ModbusDataArray[0].id = formInput.ModbusDataArray.length;
      } else {
        let indexValue = formInput.ModbusDataArray.length - 1;
        // let previousvalue=indexValue-1;
        formInput.ModbusDataArray[indexValue].id =
          formInput.ModbusDataArray.length;
      }
      formInput.modbusDeviceSetting = false;
      formInput.modbusErrorIPAddressMsg = false;
      this.setState({ formInput });
    }
  };

  handleInputLabelChange = (e, m, index) => {
    const formInput = { ...this.state.formInput };
    const labelNameView = e.target.value;

    if (labelNameView.length > 5) {
      const labelview = e.target.className + " activeMsg";
      formInput.modbusErrorLabelMsg = true;
      this.setState({ formInput });
    } else {
      formInput.ModbusDataArray[index].deviceName = e.target.value;
      formInput.modbusErrorLabelMsg = false;
      this.setState({ formInput });
    }
  };
  handleInputModeChange = (e, m, index) => {
    const formInput = { ...this.state.formInput };
    const labelModeView = e.target.value;
    formInput.ModbuserrorView = false;
    //formInput.ModbusDataArray[index].id = index;
    if (this.props.setGatewayInfo.modeName === "modbus-tcp") {
      if (
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          labelModeView
        )
      ) {
        formInput.ModbusDataArray[index].slaveOrIpAddressID = e.target.value;
        formInput.ModbusDataArray[index].registers[0].addr = index + 1;
        formInput.modbusErrorIPAddressMsg = false;
        formInput.arrayipAddress[index] = e.target.value;
        this.setState({ formInput });
      } else {
        formInput.modbusErrorIPAddressMsg = true;
        formInput.labelId = false;
        formInput.modbusDeviceSetting = false;
        formInput.arrayipAddress[index] = e.target.value;
        formInput.ModbusDataArray[index].slaveOrIpAddressID = e.target.value;
        formInput.ModbusDataArray[index].registers[0].addr = index + 1;
        //  formInput.ipAddress = e.target.value;
        this.setState({ formInput });
      }
    } else {
      if (labelModeView > 32) {
        const labelview = e.target.className + " activeMsg";
        formInput.modbusErrorModeMsg = true;
        this.setState({ formInput });
      } else {
        formInput.ModbusDataArray[index].slaveOrIpAddressID = e.target.value;

        formInput.ModbusDataArray[index].registers[0].addr = e.target.value;
        formInput.modbusErrorModeMsg = false;
        this.setState({ formInput });
      }
    }
  };

  handleInputByteChange = (e, m, index) => {
    const formInput = { ...this.state.formInput };
    formInput.ModbusDataArray[index].byteOrder =
      e.target.value === "Big Endian" ? 1 : 0;
    formInput.ModbusDataArray[index].registers[0].byte_order =
      formInput.ModbusDataArray[index].byteOrder;

    this.setState({ formInput });
  };

  handleInputWordChange = (e, m, index) => {
    const formInput = { ...this.state.formInput };

    formInput.ModbusDataArray[index].wordOrder =
      e.target.value === "Big Endian" ? 1 : 0;
    formInput.ModbusDataArray[index].registers[0].word_order =
      formInput.ModbusDataArray[index].wordOrder;
    this.setState({ formInput });
  };

  handleLabelChange = (e) => {
    const formInput = { ...this.state.formInput };
    const labelNameView = e.target.value;
    if (labelNameView.length > 5) {
      const labelview = e.target.className + " activeMsg";
      formInput.modbusErrorLabelMsg = true;
      formInput.modbusDeviceSetting = false;
      this.setState({ formInput });
    } else {
      formInput.ModbusFirstData[0].deviceName = e.target.value;
      formInput.modbusErrorLabelMsg = false;
      formInput.modbusDeviceSetting = false;
      this.setState({ formInput });
    }
  };

  handleModeChange = (e) => {
    const formInput = { ...this.state.formInput };
    formInput.ModbuserrorView = false;
    const labelNameView = e.target.value;

    if (this.props.setGatewayInfo.modeName === "modbus-tcp") {
      if (
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          labelNameView
        )
      ) {
        formInput.ModbusFirstData[0].slaveOrIpAddressID = e.target.value;
        formInput.ModbusFirstData[0].registers[0].addr = e.target.value;
        formInput.modbusErrorIPAddressMsg = false;
        formInput.modbusDeviceSetting = false;
        formInput.firstipAddress = e.target.value;
        this.setState({ formInput });
      } else {
        formInput.modbusErrorIPAddressMsg = true;
        formInput.labelId = false;
        formInput.modbusDeviceSetting = false;

        formInput.firstipAddress = e.target.value;
        formInput.ModbusFirstData[0].slaveOrIpAddressID = e.target.value;
        formInput.ModbusFirstData[0].registers[0].addr = e.target.value;
        this.setState({ formInput });
      }
    } else {
      if (labelNameView > 32) {
        const labelview = e.target.className + " activeMsg";
        formInput.modbusErrorModeMsg = true;
        formInput.modbusDeviceSetting = false;
        this.setState({ formInput });
      } else {
        formInput.ModbusFirstData[0].slaveOrIpAddressID = e.target.value;
        formInput.ModbusFirstData[0].registers[0].addr = e.target.value;
        formInput.modbusErrorModeMsg = false;
        formInput.modbusDeviceSetting = false;
        this.setState({ formInput });
      }
    }
  };

  handleByteChange = (e) => {
    const formInput = { ...this.state.formInput };
    formInput.ModbusFirstData[0].byteOrder =
      e.target.value === "Big Endian" ? 1 : 0;
    formInput.ModbusFirstData[0].registers[0].byte_order =
      formInput.ModbusFirstData[0].byteOrder;
    this.setState({ formInput });
  };

  handleWordChange = (e) => {
    const formInput = { ...this.state.formInput };
    formInput.ModbusFirstData[0].wordOrder =
      e.target.value === "Big Endian" ? 1 : 0;
    formInput.ModbusFirstData[0].registers[0].word_order =
      formInput.ModbusFirstData[0].wordOrder;
    this.setState({ formInput });
  };

  handleToPrevious = (event) => {
    //  this.props.previousTab();
    const formInput = { ...this.state.formInput };
    formInput.previousSet = true;
    this.setState({ formInput });
    this.props.previousSetTab();
  };
  addRegisters = (m) => {
    m.modbusRegisterBinaries.map((p) => {
      p.push({
        label: p.label,
        unit: "",
        reg_start: "",
        scale: "",
        reg_type: "0",
        data_type: "1",
        access_mode: "2",
        byte_order: p.byte_order,
        word_order: p.word_order,
        addr: "",
        value_id: "",
        reg_Id: 0,
        is_single_bit_binary: 0,
      });
    });
  };
  componentDidMount = () => {
    this.props.getAllModbusDeviceForGateway();
    this.defaultSelectModbusGateway();
  };

  defaultSelectModbusGateway = (formId) => {
    const formInput = { ...this.state.formInput };
    formInput.previousTab = this.props.previousTab;
    formInput.previousSetTab = this.props.previousSetTab;
    formInput.previousModbusTab = this.props.previousModbusTab;
    formInput.previousRegisterTab = this.props.previousRegisterTab;
    formInput.previousLoadTemplate = this.props.previousLoadTemplate;
    formInput.nextSetTab = this.props.nextSetTab;
    formInput.nextRegisterDevicesTab = this.props.nextRegisterDevicesTab;
    formInput.nextModbusDevicesTab = this.props.nextModbusDevicesTab;
    formInput.nextResult = this.props.nextResult;
    formInput.ModbusFirstData =
      this.props.ModbusFirstData === undefined
        ? formInput.ModbusFirstData
        : this.props.ModbusFirstData;
    formInput.ModbusDataArray =
      this.props.ModbusDataArray === undefined
        ? formInput.ModbusDataArray
        : this.props.ModbusDataArray;
    const key = "addr";
    formInput.loadTemplateModbus =
      this.props.loadDataTemplate.length === 0
        ? formInput.loadTemplateModbus
        : [
            ...new Map(
              this.props.loadDataTemplate[0].registers.map((item) => [
                item[key],
                item,
              ])
            ).values(),
          ];
    if (
      formInput.loadTemplateModbus.length !== 0 &&
      formInput.previousLoadTemplate !== true
    ) {
      formInput.loadTemplateModbus.map((m) => {
        formInput.ModbusDataArray.push({
          deviceName: "",
          slaveOrIpAddressID: m.addr,
          byteOrder: m.byte_order,
          wordOrder: m.word_order,
          id: "",
          registers: [
            // {
            //   label: "",
            //   unit: "",
            //   reg_start: "",
            //   scale: "",
            //   reg_type: "0",
            //   data_type: "1",
            //   access_mode: "82",
            //   byte_order: m.byte_order,
            //   word_order: m.word_order,
            //   addr: "",
            //   value_id: "",
            //   reg_Id: 0,
            //   is_single_bit_binary: 0,
            //   type: 0,
            // },
          ],
        });
        // formInput.ModbusNewRow.push(m);
      });
    }
    this.setState({ formInput });
  };

  render() {
    const formInput = this.state.formInput;
    const setGatewayView = this.props.setGatewayInfo;
    formInput.modbusTemplateName = this.props.setGatewayInfo.modbusTemplateName;
    formInput.id_iot_device_Value = this.props.id_iot_device_Value;
    formInput.ModbusFirstData = this.state.formInput.ModbusFirstData;
    formInput.ModbusDataArray = this.state.formInput.ModbusDataArray;
    formInput.modeName = this.props.setGatewayInfo.modeName;
    formInput.modeValue = this.props.setGatewayInfo.modeValue;
    formInput.interval = this.props.setGatewayInfo.intervalValue;
    formInput.modNameValue = this.props.modNameValue;
    // formInput.modeName = this.props.setGatewayInfo.modeName;
    //  formInput.modeValue = this.props.setGatewayInfo.modeValue;
    formInput.physicalDeviceIdGateway =
      this.props.setGatewayInfo.physicalDeviceIdGateway;
    formInput.groupId = this.props.setGatewayInfo.groupId;
    formInput.previousTab = this.props.previousTab;
    formInput.previousSetTab = this.props.previousSetTab;
    formInput.previousModbusTab = this.props.previousModbusTab;
    formInput.previousRegisterTab = this.props.previousRegisterTab;

    formInput.nextSetTab = this.props.nextSetTab;
    formInput.nextRegisterDevicesTab = this.props.nextRegisterDevicesTab;
    formInput.nextModbusDevicesTab = this.props.nextModbusDevicesTab;
    formInput.nextResult = this.props.nextResult;
    return (
      <div>
        {formInput?.nextRegisterPage === true ? (
          <AddRegistersModbus
            setGatewayInfo={this.props.setGatewayInfo}
            modbusDetails={this.state.formInput}
            // nextModbusDevicesTab={this.props.nextModbusDevicesTab}
            // nextResult={this.props.nextResult}
            // previousRegisterTab={this.props.previousRegisterTab}
            // nextRegisterDevicesTab={this.props.nextRegisterDevicesTab}
            modbusTemplateName={this.props.modbusTemplateName}
            modeName={this.props.modeName}
            modeValue={this.props.modeValue}
            intervalValue={this.props.intervalValue}
            modNameList={this.props.modNameList}
            modNameValue={this.props.modNameValue}
            groupId={this.props.groupId}
            defaultValueGateway={this.props.defaultValueGateway}
            loadDataTemplate={this.props.loadDataTemplate}
            formId={this.props.formId}
            physicalDeviceIdGateway={this.props.physicalDeviceIdGateway}
            previousTab={formInput.previousTab}
            previousSetTab={formInput.previousSetTab}
            previousModbusTab={formInput.previousModbusTab}
            previousRegisterTab={formInput.previousRegisterTab}
            //  nextSetTab={this.props.nextSetTab}
            previousLoadTemplate={formInput.previousLoadTemplate}
            ModbusFirstData={formInput.ModbusFirstData}
            ModbusDataArray={formInput.ModbusDataArray}
            nextSetTab={formInput.nextSetTab}
            nextRegisterDevicesTab={formInput.nextRegisterDevicesTab}
            nextModbusDevicesTab={formInput.nextModbusDevicesTab}
            nextResult={formInput.nextResult}
            id_iot_device_Value={this.props.id_iot_device_Value}
            projectId={this.props.projectId}
          />
        ) : formInput.previousSet === true ? (
          <SetGatewayModbus
            formInput={this.props.formInput}
            //  nextModbusDevicesTab={this.props.nextModbusDevicesTab}
            //  nextRegisterDevicesTab={this.props.nextRegisterDevicesTab}
            modbusTemplateName={this.props.modbusTemplateName}
            modeName={this.props.modeName}
            modeValue={this.props.modeValue}
            intervalValue={this.props.intervalValue}
            modNameList={this.props.modNameList}
            modNameValue={this.props.modNameValue}
            groupId={this.props.groupId}
            //  nextResult={this.props.nextResult}
            defaultValueGateway={this.props.defaultValueGateway}
            formId={this.props.formId}
            physicalDeviceIdGateway={this.props.physicalDeviceIdGateway}
            // nextSetTab={this.props.nextSetTab}
            ModbusFirstData={formInput.ModbusFirstData}
            ModbusDataArray={formInput.ModbusDataArray}
            previousTab={formInput.previousTab}
            previousSetTab={formInput.previousSetTab}
            previousModbusTab={formInput.previousModbusTab}
            previousRegisterTab={formInput.previousRegisterTab}
            nextSetTab={formInput.nextSetTab}
            nextRegisterDevicesTab={formInput.nextRegisterDevicesTab}
            nextModbusDevicesTab={formInput.nextModbusDevicesTab}
            nextResult={formInput.nextResult}
            id_iot_device_Value={this.props.id_iot_device_Value}
            projectId={this.props.projectId}
            //nextResult={this.props.nextResult}
          />
        ) : (
          <div>
            <div
              style={{
                height: "400px",
              }}
            >
              <img
                className="settingsIcon"
                src={ModbusIconBlue}
                title="modBus device"
              />
              <span className="modbusTitle modbusNewDevice">
                {" "}
                Select a Modbus device by manufacturer or model
              </span>

              <div style={{ width: "100%" }}>
                <div
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    display: "flex",
                    padding: "50px",
                  }}
                >
                  {" "}
                  <table
                    style={{
                      width: "80%",
                    }}
                  >
                    <tr key={0}>
                      <th
                        style={{
                          width: "29%",
                        }}
                      >
                        {" "}
                        {setGatewayView.modeName === "modbus-rtu"
                          ? "Slave ID"
                          : "IP address"}
                      </th>
                      <th>Byte order</th>{" "}
                      <th
                        style={{
                          width: "5%",
                        }}
                      ></th>
                      <th>Word order</th>
                      <th></th>
                    </tr>

                    {/* {formInput.loadTemplateModbus.length !== 0 &&
                      formInput.loadTemplateModbus.map((m, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              className="form-control-search formInputModbusSearch"
                              defaultValue={m.addr}
                              name="name"
                              type="text"
                              onChange={(e) =>
                                this.handleInputModeChange(e, m, index)
                              }
                              placeholder={
                                this.props.setGatewayInfo.modeName ===
                                "modbus-rtu"
                                  ? "Slave ID"
                                  : "IP address"
                              }
                            />
                          </td>
                          <td>
                            <select
                              className="form-select dropdownView"
                              aria-label="Default select example"
                              defaultValue={m.word_order}
                              label="status"
                              onChange={(e) =>
                                this.handleInputByteChange(e, m, index)
                              }
                            >
                              <option>Little Endian</option>
                              <option>Big Endian</option>
                            </select>
                          </td>
                          <td></td>
                          <td>
                            <select
                              className="form-select dropdownView"
                              aria-label="Default select example"
                              defaultValue={m.byte_order}
                              label="status"
                              onChange={(e) =>
                                this.handleInputWordChange(e, m, index)
                              }
                            >
                              <option>Little Endian</option>
                              <option>Big Endian</option>
                            </select>
                          </td>
                          <td>
                            <button
                              className="btn-border-none"
                              onClick={() =>
                                this.handleToDeleteRow(m.id, index)
                              }
                            >
                              <FontAwesomeIcon
                                className="svg-icons-sm"
                                icon={faTrashCan}
                              />
                            </button>
                          </td>
                        </tr>
                      ))} */}

                    {formInput.ModbusFirstData.length !== 0 &&
                      formInput.loadTemplateModbus.length === 0 && (
                        <tr key={"first"}>
                          {" "}
                          <td>
                            <input
                              className="form-control-search formInputModbusSearch"
                              value={
                                formInput.ModbusFirstData[0].slaveOrIpAddressID
                              }
                              name="name"
                              type="text"
                              onChange={(e) => this.handleModeChange(e)}
                              onInputChange={(e) => this.handleModeChange(e)}
                              placeholder={
                                setGatewayView.modeName === "modbus-rtu"
                                  ? "Slave ID"
                                  : "IP address"
                              }
                            />
                          </td>
                          <td>
                            <select
                              className="form-select dropdownView"
                              aria-label="Default select example"
                              defaultValue={
                                formInput.ModbusFirstData[0].byteOrder
                              }
                              label="status"
                              onChange={(e) => this.handleByteChange(e)}
                            >
                              <option>Little Endian</option>
                              <option>Big Endian</option>
                            </select>
                          </td>
                          <td></td>
                          <td>
                            <select
                              className="form-select dropdownView"
                              aria-label="Default select example"
                              defaultValue={
                                formInput.ModbusFirstData[0].wordOrder
                              }
                              label="status"
                              onChange={(e) => this.handleWordChange(e)}
                            >
                              <option>Little Endian</option>
                              <option>Big Endian</option>
                            </select>
                          </td>
                          <td>
                            <button
                              className="btn-border-none"
                              onClick={() => this.handleToDeleteRowFirst()}
                            >
                              <FontAwesomeIcon
                                className="svg-icons-sm"
                                icon={faTrashCan}
                              />
                            </button>
                          </td>
                        </tr>
                      )}
                    {formInput.ModbusDataArray.map((m, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            className="form-control-search formInputModbusSearch"
                            defaultValue={m.slaveOrIpAddressID}
                            name="name"
                            type="text"
                            onChange={(e) =>
                              this.handleInputModeChange(e, m, index)
                            }
                            placeholder={
                              this.props.setGatewayInfo.modeName ===
                              "modbus-rtu"
                                ? "Slave ID"
                                : "IP address"
                            }
                          />
                        </td>
                        <td>
                          <select
                            className="form-select dropdownView"
                            aria-label="Default select example"
                            defaultValue={m.wordOrder}
                            label="status"
                            onChange={(e) =>
                              this.handleInputByteChange(e, m, index)
                            }
                          >
                            <option>Little Endian</option>
                            <option>Big Endian</option>
                          </select>
                        </td>
                        <td></td>
                        <td>
                          <select
                            className="form-select dropdownView"
                            aria-label="Default select example"
                            defaultValue={m.byteOrder}
                            label="status"
                            onChange={(e) =>
                              this.handleInputWordChange(e, m, index)
                            }
                          >
                            <option>Little Endian</option>
                            <option>Big Endian</option>
                          </select>
                        </td>
                        <td>
                          <button
                            className="btn-border-none"
                            onClick={() => this.handleToDeleteRow(m.id, index)}
                          >
                            <FontAwesomeIcon
                              className="svg-icons-sm"
                              icon={faTrashCan}
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "2%" }}></div>
                <div>
                  <button
                    className="btn-border-none"
                    onClick={this.handleToAddNewrow.bind(this)}
                  >
                    <FontAwesomeIcon
                      //  className="svg-icon-sm fa-lg plusModbus"
                      className="svg-icons-sm fa-lg plusModbus"
                      icon={faPlusCircle}
                    />
                  </button>
                </div>
                <div
                  className={
                    formInput.ModbuserrorView ? "showMsgModbusMode" : "hideMode"
                  }
                >
                  {this.props.setGatewayInfo.modeName === "modbus-tcp"
                    ? "Reached max numbers of devices, allow maximum of 5 devices in TCP"
                    : "Reached max numbers of devices, allow maximum of 32 devices in RTU"}
                </div>
                <div
                  className={
                    (formInput.modbusErrorLabelMsg &&
                      formInput.ModbuserrorView) ||
                    (formInput.ModbuserrorView && formInput.modbusErrorModeMsg)
                      ? "showErrorMsgMode"
                      : "hideMode"
                  }
                >
                  &nbsp; , &nbsp;
                </div>
                <div
                  className={
                    formInput.modbusErrorLabelMsg
                      ? "showErrorMsgMode"
                      : "hideMode"
                  }
                >
                  Label can not be more than 5
                </div>{" "}
                <div
                  className={
                    formInput.modbusErrorLabelMsg &&
                    formInput.modbusErrorModeMsg
                      ? "showErrorMsgMode"
                      : "hideMode"
                  }
                >
                  &nbsp; , &nbsp;
                </div>{" "}
                <div
                  className={
                    formInput.labelId ? "showErrorMsgMode" : "hideMode"
                  }
                >
                  {setGatewayView.modeName === "modbus-rtu"
                    ? "Slave Id can not be empty."
                    : "IP address can not be empty."}
                </div>{" "}
                <div
                  className={
                    formInput.modbusErrorModeMsg
                      ? "showErrorMsgMode"
                      : "hideMode"
                  }
                >
                  Exceed slave Id range 1-32
                </div>{" "}
                <div
                  className={
                    formInput.modbusErrorLabelMsg &&
                    formInput.modbusErrorModeMsg
                      ? "showErrorMsgMode"
                      : "hideMode"
                  }
                >
                  &nbsp; , &nbsp;
                </div>
                <div
                  className={
                    formInput.modbusDeviceSetting
                      ? "showErrorMsgMode"
                      : "hideMode"
                  }
                >
                  Atleast one modbus device is required
                </div>{" "}
                <div
                  className={
                    formInput.modbusErrorIPAddressMsg &&
                    formInput.ModbuserrorView
                      ? "showErrorMsgMode"
                      : "hideMode"
                  }
                >
                  &nbsp; , &nbsp;
                </div>
                <div
                  className={
                    formInput.modbusErrorIPAddressMsg
                      ? "showErrorMsgMode"
                      : "hideMode"
                  }
                >
                  IP address is not valid
                </div>
              </div>
            </div>
            <div className="belowModbusButton belowModbusDeviceButton">
              <button
                onClick={this.handleToNextRegister}
                className="btn btn-primary buttonView modbusButton"
              >
                Next {">"} Add Registers
              </button>{" "}
              <button
                onClick={this.handleToPrevious}
                className="btn btn-secondary previousbutton"
              >
                Previous
              </button>{" "}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allModbusDevicesForGateway:
      state.modbusViewReducer.allModbusDevicesForGateway,
  };
}

export default withRouter(
  connect(mapStateToProps, { getAllModbusDeviceForGateway })(
    SelectModbusDevices
  )
);
