import React, { Component } from "react";
//import CardBody from "./CardBody";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModbusIconBlue from "./../../assets/Icons/modbussetting.png";
import {
  faHardDrive,
  faCircleInfo,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import SelectModbusDevices from "./SelectModbusDevices";
import SelectGatewayModbus from "./SelectGatewayModbus";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getLoadTemplate } from "../../actions/modbus-view";
import { logDOM } from "@testing-library/react";
import { DialogContent } from "@material-ui/core";
import SelectModbusDeviceType from "./SelectModbusDeviceType";

class SetGatewayModbus extends Component {
  state = {
    formInput: {
      loadTemplate: false,
      loadErrorMeassage: false,
      nextSet: false,
      nextModbusPage: false,
      previousSet: false,
      modeName: "modbus-rtu",
      modeValue: "1800",
      modeValueNew: "1800",
      modbusTemplateName: "",
      setLoadTemplateId: "",
      loadDataTemplate: [],
      loadTemplateBaudRate: "",
      ModbusFirstData: [
        {
          deviceName: "",
          slaveOrIpAddressID: "",
          byteOrder: "0",
          wordOrder: "0",
          id: "first",
          registers: [
            {
              label: "",
              unit: "",
              reg_start: "",
              reg_type: "0",
              data_type: "1",
              access_mode: "2",
              byte_order: "0",
              word_order: "0",
              addr: "",
              value_id: "",
              is_single_bit_binary: 0,
              reg_Id: 0,
            },
          ],
        },
      ],
      ModbusDataArray: [],
      templateLabelMsg: false,
      physicalDeviceIdGateway: "",
      id_iot_device_Value: "",
      groupId: "",
      intervalValue: "60000",
      modNameList: [],
      modNameValue: [],
      previousTab: "",
      previousSetTab: "",
      previousModbusTab: "",
      previousRegisterTab: "",
      nextSetTab: "",
      nextRegisterDevicesTab: "",
      nextModbusDevicesTab: "",
      nextResult: "",
      modeNameChange: false,
    },
  };
  handleToNext = (event) => {
    //  this.props.nextModbusTab();
    const formInput = { ...this.state.formInput };

    if (
      formInput.modbusTemplateName === undefined ||
      formInput.modbusTemplateName === null ||
      formInput.modbusTemplateName.match(/^ *$/) !== null
    ) {
      formInput.templateLabelMsg = true;
      this.setState({ formInput });
    } else if (formInput.modbusTemplateName.length > 50) {
      formInput.templateLabelMsg = true;
      this.setState({ formInput });
    } else {
      formInput.nextModbusPage = true;
      this.setState({ formInput });
      this.props.nextModbusDevicesTab();
    }
  };
  handleModeChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.modeName = event === "modbus-rtu" ? "modbus-rtu" : "modbus-tcp";
    formInput.modeNameChange = true;
    formInput.ModbusDataArray = formInput.loadTemplate
      ? [
          {
            deviceName: "",
            slaveOrIpAddressID: "",
            byteOrder: "0",
            wordOrder: "0",
            id: "first",
          },
        ]
      : [
          {
            deviceName: "",
            slaveOrIpAddressID: "",
            byteOrder: "0",
            wordOrder: "0",
            id: "first",
            registers: [
              {
                label: "",
                unit: "",
                reg_start: "",
                reg_type: "0",
                data_type: "1",
                access_mode: "2",
                byte_order: "0",
                word_order: "0",
                addr: "",
                value_id: "",
                is_single_bit_binary: 0,
                reg_Id: 0,
              },
            ],
          },
        ];
    formInput.ModbusFirstData = [];
    this.setState({ formInput });
  };
  handleStatusChange = (event) => {
    const formInput = { ...this.state.formInput };

    formInput.modeValue = event.target.value.slice(0, 4);
    formInput.modeValueNew = event.target.value;
    this.setState({ formInput });
  };
  handleIntervalChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.intervalValue = event.target.value;
    this.setState({ formInput });
  };

  handleLabelChange = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput.modbusTemplateName = input.value;
    formInput.templateLabelMsg = false;
    //  formInput.name = input.value;
    this.setState({ formInput });
  };

  handleToPrevious = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.previousSet = true;
    this.setState({ formInput });
    this.props.previousTab();
  };

  handleToLoadTemplate = () => {
    const formInput = { ...this.state.formInput };
    formInput.loadTemplate = true;
    this.setState({ formInput });
  };

  handleToConnectionClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.loadTemplate = false;
    this.setState({ formInput });
  };

  handleChangeLoad = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.setLoadTemplateId = event.target.id;
    formInput.loadErrorMeassage = false;
    this.setState({ formInput });
  };

  handleToLoadTemplateConfirm = (event) => {
    const formInput = { ...this.state.formInput };
    //formInput.setLoadTemplateId = event.target.id;
    formInput.loadDataTemplate = this.props.getLoadTemplateData?.items.filter(
      (o) => o.id_modbus_template === formInput.setLoadTemplateId
    );
    if (formInput.loadDataTemplate.length === 0) {
      formInput.loadErrorMeassage = true;
      this.setState({ formInput });
    } else {
      formInput.loadTemplate = false;
      formInput.loadErrorMeassage = false;
      this.setState({ formInput });
    }
  };

  componentDidMount = () => {
    const formInput = { ...this.state.formInput };
    this.defaultSetGateway();
    this.props.getLoadTemplate(this.props.projectId);
  };

  defaultSetGateway = (formId) => {
    const formInput = { ...this.state.formInput };

    formInput.modbusTemplateName = this.props.modbusTemplateName;
    formInput.modeName =
      this.props.modeName === undefined ? "modbus-rtu" : this.props.modeName;
    formInput.modeValue =
      this.props.modeValue === undefined ? "1800" : this.props.modeValue;
    formInput.intervalValue =
      this.props.intervalValue === undefined
        ? "60000"
        : this.props.intervalValue;
    formInput.previousTab = this.props.previousTab;
    formInput.previousSetTab = this.props.previousSetTab;
    formInput.previousModbusTab = this.props.previousModbusTab;
    formInput.previousRegisterTab = this.props.previousRegisterTab;

    formInput.nextSetTab = this.props.nextSetTab;
    formInput.nextRegisterDevicesTab = this.props.nextRegisterDevicesTab;
    formInput.nextModbusDevicesTab = this.props.nextModbusDevicesTab;
    formInput.nextResult = this.props.nextResult;

    formInput.modNameValue =
      this.props.modNameValue === undefined
        ? this.props.gateWayInfo?.find(
            (m) => m.id_iot_device === this.props.formId
          )?.protocol_enabled
        : this.props.modNameValue;
    formInput.groupId = this.props.groupId;
    formInput.ModbusFirstData = this.props.ModbusFirstData;
    formInput.ModbusDataArray = this.props.ModbusDataArray;

    this.setState({ formInput });
  };
  render() {
    const formInput = this.state.formInput;
    formInput.physicalDeviceIdGateway = this.props.physicalDeviceIdGateway;
    formInput.id_iot_device_Value = this.props.id_iot_device_Value;
    formInput.formId = this.props.formId;
    formInput.groupId = this.props.groupId;
    formInput.ModbusFirstData = formInput.modeNameChange
      ? formInput.ModbusFirstData
      : this.props.ModbusFirstData;
    formInput.ModbusDataArray = formInput.modeNameChange
      ? formInput.ModbusDataArray
      : this.props.ModbusDataArray;

    formInput.nextSetTab = this.props.nextSetTab;
    formInput.nextRegisterDevicesTab = this.props.nextRegisterDevicesTab;
    formInput.nextModbusDevicesTab = this.props.nextModbusDevicesTab;
    formInput.nextResult = this.props.nextResult;

    formInput.previousTab = this.props.previousTab;
    formInput.previousSetTab = this.props.previousSetTab;
    formInput.previousModbusTab = this.props.previousModbusTab;
    formInput.previousRegisterTab = this.props.previousRegisterTab;

    formInput.modNameList = this.props.gateWayInfo?.find(
      (m) => m.id_iot_device === this.props.formId
    )?.protocol_enabled;

    if (formInput.loadDataTemplate.length > 0) {
      formInput.loadTemplateBaudRate = formInput.loadDataTemplate[0].baud_rate;
      formInput.modbusTemplateName = formInput.loadDataTemplate[0].label;
    }

    return (
      <div>
        {formInput.nextModbusPage === true ? (
          <SelectModbusDeviceType
            setGatewayInfo={this.state.formInput}
            formInputData={this.state.formInput}
            modbusTemplateName={formInput.modbusTemplateName}
            modeName={formInput.modeName}
            modeValue={formInput.modeValue}
            intervalValue={formInput.intervalValue}
            modNameList={formInput.modNameList}
            modNameValue={formInput.modNameValue}
            groupId={this.props.groupId}
            ModbusFirstData={formInput.ModbusFirstData}
            ModbusDataArray={formInput.ModbusDataArray}
            defaultValueGateway={this.props.defaultValueGateway}
            formId={this.props.formId}
            physicalDeviceIdGateway={this.props.physicalDeviceIdGateway}
            id_iot_device_Value={this.props.id_iot_device_Value}
            previousTab={formInput.previousTab}
            previousSetTab={formInput.previousSetTab}
            previousModbusTab={formInput.previousModbusTab}
            previousRegisterTab={formInput.previousRegisterTab}
            previousLoadTemplate={formInput.previousLoadTemplate}
            nextSetTab={formInput.nextSetTab}
            nextRegisterDevicesTab={formInput.nextRegisterDevicesTab}
            nextModbusDevicesTab={formInput.nextModbusDevicesTab}
            nextResult={formInput.nextResult}
            loadDataTemplate={formInput.loadDataTemplate}
            projectId={this.props.projectId}
          />
        ) : // <SelectModbusDevices
        //   setGatewayInfo={this.state.formInput}
        //   formInput={this.state.formInput}
        //   modbusTemplateName={formInput.modbusTemplateName}
        //   modeName={formInput.modeName}
        //   modeValue={formInput.modeValue}
        //   intervalValue={formInput.intervalValue}
        //   modNameList={formInput.modNameList}
        //   modNameValue={formInput.modNameValue}
        //   groupId={this.props.groupId}
        //   ModbusFirstData={formInput.ModbusFirstData}
        //   ModbusDataArray={formInput.ModbusDataArray}
        //   defaultValueGateway={this.props.defaultValueGateway}
        //   formId={this.props.formId}
        //   physicalDeviceIdGateway={this.props.physicalDeviceIdGateway}
        //   id_iot_device_Value={this.props.id_iot_device_Value}
        //   previousTab={formInput.previousTab}
        //   previousSetTab={formInput.previousSetTab}
        //   previousModbusTab={formInput.previousModbusTab}
        //   previousRegisterTab={formInput.previousRegisterTab}
        //   nextSetTab={formInput.nextSetTab}
        //   nextRegisterDevicesTab={formInput.nextRegisterDevicesTab}
        //   nextModbusDevicesTab={formInput.nextModbusDevicesTab}
        //   nextResult={formInput.nextResult}
        //   loadDataTemplate={formInput.loadDataTemplate}
        //   projectId={this.props.projectId}
        // />
        formInput.previousSet === true ? (
          <SelectGatewayModbus
            defaultValueGateway={this.props.defaultValueGateway}
            formId={this.props.formId}
            physicalDeviceIdGateway={this.props.physicalDeviceIdGateway}
            id_iot_device_Value={this.props.id_iot_device_Value}
            nextSetTab={formInput.nextSetTab}
            nextRegisterDevicesTab={formInput.nextRegisterDevicesTab}
            nextModbusDevicesTab={formInput.nextModbusDevicesTab}
            nextResult={formInput.nextResult}
            modNameValue={this.props.modNameValue}
            modbusTemplateName={formInput.modbusTemplateName}
            modeName={formInput.modeName}
            modeValue={formInput.modeValue}
            intervalValue={formInput.intervalValue}
            // nextModbusDevicesTab={this.props.nextModbusDevicesTab}
            ModbusFirstData={this.props.ModbusFirstData}
            ModbusDataArray={this.props.ModbusDataArray}
            previousTab={formInput.previousTab}
            previousSetTab={formInput.previousSetTab}
            previousModbusTab={formInput.previousModbusTab}
            previousRegisterTab={formInput.previousRegisterTab}
            projectId={this.props.projectId}
          />
        ) : (
          <div>
            {formInput.loadTemplate && (
              <Dialog
                open={formInput.loadTemplate === true ? true : false}
                onClose={this.handleToConnectionClose}
                maxWidth="md"
                style={{ minWidth: "1200px" }}
              >
                <DialogTitle>
                  <h4 style={{ textAlign: "center", marginTop: "revert" }}>
                    Load Modbus Template
                  </h4>
                </DialogTitle>
                <div
                  style={{
                    overflow: "auto",
                    height: "450px",
                    marginLeft: "40px",
                    marginRight: "40px",
                    marginBottom: "40px",
                  }}
                >
                  {this.props.getLoadTemplateData.items.length > 0 ? (
                    <table>
                      <tr>
                        <th></th>
                        <th>Template label</th>
                        <th>Mode</th>
                        <th>Baud Rate</th>
                        <th style={{ minWidth: "200px" }}>
                          No. of Modbus devices
                        </th>
                        <th style={{ minWidth: "135px" }}>No. of registers</th>
                      </tr>

                      {this.props.getLoadTemplateData.items.map((j, index) => (
                        <tr key={index}>
                          <td style={{ width: "15%" }}>
                            {" "}
                            <input
                              type="radio"
                              id={j.id_modbus_template}
                              name="headerNameDevice"
                              defaultValue="0"
                              className="radioOption"
                              onChange={this.handleChangeLoad.bind(this)}
                              //  defaultChecked={index === 0 ?? true}
                              style={{
                                marginLeft: "15px",
                                accentColor: "rgb(0, 0, 0)",
                              }}
                            />
                          </td>

                          <td style={{ width: "30%" }}> {j.label}</td>
                          <td style={{ width: "20%" }}>
                            {" "}
                            {j.mode === 0 ? "RTU" : "TCP"}
                          </td>
                          <td style={{ width: "20%" }}>
                            {" "}
                            {j.mode === 0 ? j.baud_rate : "-"} bits/s
                          </td>
                          <td style={{ width: "20%", textAlign: "center" }}>
                            {" "}
                            {
                              [...new Set(j.registers.map((item) => item.addr))]
                                .length
                            }
                          </td>
                          <td style={{ width: "30%", textAlign: "center" }}>
                            {" "}
                            {j.registers.length}
                          </td>
                        </tr>
                      ))}
                    </table>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      Sorry, Modbus Template is not avaiable for this Gateway.
                    </div>
                  )}
                </div>
                <div
                  className={
                    formInput.loadErrorMeassage
                      ? "showErrorMsgMode"
                      : "hideMode"
                  }
                >
                  Please select one modbus template
                </div>
                <div className="col pb-3 loadButtoAlign">
                  <button
                    stye={{ textTransform: "capitalize" }}
                    className={
                      formInput.setApplyConfirm
                        ? "btn-cancel cancelTemplate"
                        : "btn-cancel"
                    }
                    onClick={this.handleToConnectionClose}
                    disabled={formInput.setApplyConfirm ? true : false}
                  >
                    Cancel
                  </button>{" "}
                  <button
                    stye={{ textTransform: "capitalize" }}
                    className={
                      formInput.setApplyConfirm
                        ? "btn-cancel submitDisableButton"
                        : "btn-cancel submitButton"
                    }
                    onClick={() => this.handleToLoadTemplateConfirm()}
                    disabled={formInput.setApplyConfirm ? true : false}
                  >
                    Load
                    {/* )} */}
                  </button>
                </div>{" "}
              </Dialog>
            )}
            <button
              type="button"
              className="btn shadow previousbutton border-primary"
              onClick={() => this.handleToLoadTemplate()}
            >
              Load Modbus template
            </button>
            <img
              className="settingsIcon"
              src={ModbusIconBlue}
              title="modBus device"
            />
            <span className="modbusTitle">
              {" "}
              Enter Gateway settings for Modbus communication
            </span>
            <div style={{ height: "346px", overflowY: "auto", width: "100%" }}>
              <div style={{ display: "flex", padding: "50px" }}>
                <table
                  style={
                    formInput.modeName === "modbus-rtu"
                      ? { width: "60%" }
                      : { width: "47%" }
                  }
                >
                  <tr>
                    <th>Template label</th>
                    <th>
                      Mode{" "}
                      <div className="tooltipSetting">
                        <FontAwesomeIcon
                          style={{
                            marginRight: "4px",
                            marginLeft: "10px",
                            width: "40%",
                          }}
                          className="fa-lg"
                          icon={faCircleInfo}
                        />
                        <span className="tooltipsSettingText tooltipsTemplate">
                          <div>
                            Select Modbus RTU if using RS485 or Modbus TCP if
                            using IP based Modbus device.
                          </div>
                        </span>
                      </div>
                    </th>
                    <th
                      style={{
                        width: "3%",
                      }}
                    ></th>
                    <th
                      className={
                        formInput.modeName === "modbus-rtu"
                          ? "showMode"
                          : "hideMode"
                      }
                    >
                      Baud rate
                    </th>
                    <th
                      style={{
                        width: "3%",
                      }}
                    ></th>
                    <th>Interval</th>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <input
                        className="form-control-search formInputModbusSearch"
                        defaultValue={formInput.modbusTemplateName}
                        name="name"
                        type="text"
                        // maxLength="50"
                        onChange={this.handleLabelChange}
                        placeholder="Name"
                      />
                    </td>
                    <td>
                      <select
                        className="form-select dropdownView"
                        aria-label="Default select example"
                        label="status"
                        onChange={(event) =>
                          this.handleModeChange(event.target.value)
                        }
                        value={
                          formInput.loadDataTemplate.length > 0
                            ? formInput.loadDataTemplate[0].mode === 0
                              ? "modbus-rtu"
                              : "modbus-tcp"
                            : formInput.modeName
                        }
                      >
                        {formInput.modNameValue !== undefined &&
                          formInput.modNameValue.map((x, y) => (
                            <option key={y}> {x}</option>
                          ))}
                      </select>
                    </td>
                    <td></td>
                    <td
                      className={
                        formInput.modeName === "modbus-rtu"
                          ? "showMode"
                          : "hideMode"
                      }
                    >
                      <select
                        className="form-select dropdownView"
                        aria-label="Default select example"
                        value={
                          formInput.loadDataTemplate.length > 0
                            ? formInput.loadTemplateBaudRate === 1800
                              ? "1800 bits/s"
                              : formInput.loadTemplateBaudRate === 2400
                              ? "2400 bits/s"
                              : formInput.loadTemplateBaudRate === 4800
                              ? "4800 bits/s"
                              : formInput.loadTemplateBaudRate === 9600
                              ? "9600 bits/s"
                              : formInput.loadTemplateBaudRate === 19200
                              ? "19200 bits/s"
                              : formInput.loadTemplateBaudRate === 28800
                              ? "28800 bits/s"
                              : formInput.loadTemplateBaudRate === 57600
                              ? "57600 bits/s"
                              : formInput.loadTemplateBaudRate === 115200
                              ? "115200 bits/s"
                              : formInput.loadTemplateBaudRate === 230400
                              ? "230400 bits/s"
                              : formInput.loadTemplateBaudRate === 460800
                              ? "460800 bits/s"
                              : formInput.loadTemplateBaudRate === 576000
                              ? "576000 bits/s"
                              : formInput.loadTemplateBaudRate === 921600
                              ? "921600 bits/s"
                              : ""
                            : formInput.modeValueNew
                        }
                        label="status"
                        onChange={this.handleStatusChange}
                      >
                        <option>1800 bits/s</option>
                        <option>2400 bits/s</option>
                        <option>4800 bits/s</option>
                        <option>9600 bits/s</option>
                        <option>19200 bits/s</option>
                        <option>28800 bits/s</option>
                        <option>57600 bits/s</option>
                        <option>115200 bits/s</option>
                        <option>230400 bits/s</option>
                        <option>460800 bits/s</option>
                        <option>576000 bits/s</option>
                        <option>921600 bits/s</option>
                      </select>
                    </td>{" "}
                    <td></td>
                    <td>
                      <select
                        className="form-select dropdownView"
                        aria-label="Default select example"
                        value={
                          formInput.loadDataTemplate.length > 0
                            ? Math.floor(
                                formInput.loadDataTemplate[0]
                                  .interval_milliseconds / 1000
                              )
                            : formInput.intervalValue === "60000"
                            ? 60000
                            : formInput.intervalValue
                        }
                        label="status"
                        onChange={this.handleIntervalChange}
                      >
                        <option value="30000">30s</option>
                        <option value="45000">45s</option>
                        <option value="60000">1m</option>
                        <option value="120000">2m</option>
                        <option value="180000">3m</option>
                        <option value="300000">5m</option>
                        <option value="600000">10m</option>
                        <option value="900000">15m</option>
                        <option value="1200000">20m</option>
                        <option value="1800000">30m</option>
                        <option value="2700000">45m</option>
                        <option value="3600000">1h</option>
                        <option value="7200000">2h</option>
                        <option value="10800000">3h</option>
                        <option value="18000000">4h</option>
                        <option value="36000000">6h</option>
                        <option value="54000000">8h</option>
                        <option value="72000000">12h</option>
                        <option value="86400000">1d</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="setViewModbus">Parity</div>
                    </td>
                    <td>
                      <div className="setViewModbus">Data bits</div>
                    </td>
                    <td></td>
                    <td
                      className={
                        formInput.modeName === "modbus-rtu"
                          ? "showMode"
                          : "hideMode"
                      }
                    >
                      <div className="setViewModbus">Stop bits</div>
                    </td>
                  </tr>
                  <tr>
                    <td>None</td>
                    <td>8 bits</td>
                    <td></td>
                    <td
                      className={
                        formInput.modeName === "modbus-rtu"
                          ? "showMode"
                          : "hideMode"
                      }
                    >
                      1 bit
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div
              className={
                formInput.templateLabelMsg ? "showMsgMode" : "hideMode"
              }
            >
              Template label cannot be empty,null and can't be more than 50
              Characters
            </div>
            <div className="belowModbusDeviceButton">
              {" "}
              <button
                onClick={this.handleToNext}
                className="btn btn-primary buttonView modbusButton"
              >
                Next {">"} Set Modbus Devices
              </button>{" "}
              <button
                onClick={this.handleToPrevious}
                className="btn btn-secondary previousbutton"
              >
                Previous
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    getLoadTemplateData: state.modbusViewReducer.getLoadTemplateData,
  };
}

export default connect(mapStateToProps, { getLoadTemplate })(SetGatewayModbus);
