import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Switch from "@material-ui/core/Switch";
import LoaderRoller from "../../components/loader/LoaderRoller";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNetworkWired,
  faChartLine,
  faPlusCircle,
  faSliders,
  faTimesCircle,
  faTimes,
  faCheckCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import "./style.css";
import {
  createNewWebhook,
  getAllWebhooksOverview,
  clearNewWebhook,
} from "../../actions/session-view";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import WebhookCreateSubmenu from "../../components/menus/sub-menu/subtypes/WebhookCreateSubmenu";

class WebhookCreate extends Component {
  state = {
    formInput: {
      projectId: "",
      status: true,
      labelName: "",
      forwardingType: "1",
      formatterType: "v1",
      urlValue: "",
      authorization: "",
      labelNameError: false,
      URLNameError: false,
      dialogBox: false,
    },
  };

  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;

    if (!currentPageNr) {
      return this.props.history.push(
        `/webhook/create/1/${this.props.match.params?.projectId}`
      );
    }
    this.props.clearNewWebhook();
  };

  componentWillUnmount = () => {};

  toggleWebhookChecked = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.status = formInput.status === true ? false : true;
    this.setState({ formInput });
  };

  handleLabelChange = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput[input.name] = input.value;
    formInput.labelName = input.value;
    this.setState({ formInput });
  };

  handleURLChange = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput[input.name] = input.value;
    formInput.urlValue = input.value;
    this.setState({ formInput });
  };

  handleAuthorizationChange = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput[input.name] = input.value;
    formInput.authorization = input.value;
    this.setState({ formInput });
  };

  handleForwardingTypeChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.forwardingType = event.target.value === "Measurements" ? 1 : 2;
    this.setState({ formInput });
  };

  handleFormatterTypeChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.formatterType = event.target.value;
    this.setState({ formInput });
  };

  handleToCreateWebhook = (event) => {
    const formInput = { ...this.state.formInput };

    if (formInput.labelName === "") {
      formInput.labelNameError = true;
      formInput.URLNameError = false;
      this.setState({ formInput });
    } else if (formInput.urlValue === "") {
      formInput.URLNameError = true;
      formInput.labelNameError = false;
      this.setState({ formInput });
    } else {
      this.props.createNewWebhook(
        formInput,
        this.props.match.params?.projectId
      );
    }
  };

  handleToConnectionClose = () => {
    if (this.props.createdwebhookStatus === "pass") {
      const projectId = this.props.match.params?.projectId;
      this.props.getAllWebhooksOverview(projectId);
      return this.props.history.push(
        `/webhook/overview/1/${this.props.match.params?.projectId}`
      );
      // this.props.getAllProject();
    }
    const formInput = { ...this.state.formInput };
    formInput.dialogBox = false;
    this.setState({ formInput });
  };

  handleToConnectionCancel = () => {
    return this.props.history.push(
      `/webhook/overview/1/${this.props.match.params?.projectId}`
    );
  };

  render() {
    const formInput = this.state.formInput;
    formInput.projectId = this.props.match.params?.projectId;

    return (
      <div>
        {this.props.createdFailedWebhookStatus === "failed" && (
          <div>
            <Dialog
              open={formInput.dialogBox ? true : false}
              onClose={this.handleToConnectionClose}
            >
              <div onClick={this.handleToConnectionClose}>
                <button className="cancelViewIcon cancelErrorWifi">
                  <FontAwesomeIcon
                    className="svg-icons-sm wifiView"
                    data-toggle="tooltip"
                    data-placement="top"
                    icon={faTimes}
                  />
                </button>
              </div>
              <DialogTitle>
                <div>
                  {" "}
                  <FontAwesomeIcon
                    className="fa-lg"
                    style={{
                      marginLeft: "10px",
                      width: "100%",
                      fontSize: "45px",
                      color: "#ec0000",
                    }}
                    icon={faTimesCircle}
                  />
                </div>
                <p className="infoTemplate">
                  Webhook is n't created sucessfully.
                </p>
              </DialogTitle>
            </Dialog>
          </div>
        )}
        {this.props.createdwebhookStatus === "pass" && (
          <div>
            <Dialog open={true} onClose={this.handleToConnectionClose}>
              <div onClick={this.handleToConnectionClose}>
                <button className="cancelViewIcon cancelErrorWifi">
                  <FontAwesomeIcon
                    className="svg-icons-sm wifiView"
                    data-toggle="tooltip"
                    data-placement="top"
                    icon={faTimes}
                  />
                </button>
              </div>
              <DialogTitle>
                <div>
                  {" "}
                  <FontAwesomeIcon
                    className="fa-lg"
                    style={{
                      marginLeft: "10px",
                      width: "100%",
                      fontSize: "45px",
                      color: "green",
                    }}
                    icon={faCheckCircle}
                  />
                </div>
                <p className="infoTemplate">Webhook is created sucessfully.</p>
              </DialogTitle>
            </Dialog>
          </div>
        )}

        <div>
          <div style={{ height: "700px" }}>
            <WebhookCreateSubmenu />
            <div className="createDiv">
              <div style={{ marginTop: "24px" }}>Webhook Label</div>
              <input
                className="form-control"
                value={formInput.labelName}
                name="labelName"
                type="text"
                onChange={this.handleLabelChange}
                placeholder="Enter a label for the webhook(i.e. webhook to Azure Event Hub)"
              />

              <div style={{ marginTop: "24px" }}>Forwarding Type</div>
              <select
                style={{ marginRight: "10px" }}
                className="form-select dropdownView"
                aria-label="Default select example"
                label="status"
                defaultValue={
                  formInput.forwardingType === "1" ? "Measurements" : "Events"
                }
                onChange={this.handleForwardingTypeChange}
              >
                <option selected>Measurements</option>
                <option>Events</option>
              </select>

              <div style={{ marginTop: "24px" }}>Webhook Formatter Type</div>
              <select
                style={{ marginRight: "10px" }}
                className="form-select dropdownView"
                aria-label="Default select example"
                label="status"
                onChange={this.handleFormatterTypeChange}
                defaultValue={formInput.formatterType}
              >
                <option selected>v1</option>
                <option>v2a</option>
                <option>v2b</option>
                <option>v3a</option>
                <option> v3b</option>
              </select>

              <div style={{ marginTop: "24px" }}>Webhook URL</div>
              <input
                className="form-control"
                value={formInput.urlValue}
                name="sessionId"
                type="text"
                onChange={this.handleURLChange}
                placeholder="URL of the webhookto post it to"
              />

              <div style={{ marginTop: "24px" }}>Authorization</div>

              <textarea
                className="form-control"
                value={formInput.authorization}
                name="sessionId"
                onChange={this.handleAuthorizationChange}
                placeholder="If an Authorization header needs to be sent, add it here. If it is a Bearer token, include the Bearer text."
              ></textarea>
            </div>
          </div>
        </div>

        <div style={{ marginLeft: "25px" }}>
          <div
            className={
              formInput.labelNameError ? "showErrorMsgMode" : "hideMode"
            }
          >
            Webhook Label can not be empty.
          </div>
          <div
            className={formInput.URLNameError ? "showErrorMsgMode" : "hideMode"}
          >
            Webhook Url can not be empty.
          </div>
        </div>
        <div className="col pb-3">
          <div className="d-flex divButton">
            <button
              className={"btn-cancel mx-1"}
              onClick={this.handleToConnectionCancel}
            >
              Cancel
            </button>{" "}
            <button
              className={"btn-cancel submitButton"}
              onClick={() => this.handleToCreateWebhook()}
            >
              <Fragment>Create</Fragment>
              {/* )} */}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    createdwebhookStatus: state.sessionReducer.createdwebhookStatus,
    webhooksOverviewData: state.sessionReducer.webhooksOverviewData,
    createdFailedWebhookStatus: state.sessionReducer.createdFailedWebhookStatus,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    createNewWebhook,
    getAllWebhooksOverview,
    clearNewWebhook,
  })(WebhookCreate)
);
