import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faCircleInfo,
  faCircleUser,
} from "@fortawesome/pro-regular-svg-icons";
import TopMenu from "../../../../components/menus/top-menu/TopMenu";
import { deleteWholeProject } from "../../../../actions/list-view";
import { settingsEpViewChange } from "../../../../actions/view-history";
class SettingsMovingSubmenu extends Component {
  state = {
    formInput: {
      projectId: "",
    },
  };
  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;
    // const projectId = window.location.pathname.slice(29);
    if (this.props.lastSettingsEpViewSelected === "LIST_VIEW") {
      return this.props.history.push(
        `/settings/moving/1/${this.props.match.params?.projectId}`
      );
    }
  };

  render() {
    const formInput = this.state.formInput;
    formInput.projectId = this.props.projectId;
    return (
      <div className="col pl-4">
        <h1 style={{marginTop:"15px"}}>
      <div className="row">
      <div className="col-md-9">
      <h6 className="gatewayColor" >
      <Link to={`/settings/info-view/1/${this.props.match.params?.projectId}`} className="link-unstyled" >  
                {" "}
            Settings {" "}
            </Link>{" "}
            {">"}
            </h6>
            <h5 className=" basepannelheading color-ocean-blue" style={{marginBottom:"10px"}}> Move Gateways</h5>
          </div>
          <div className="col-md-3">
          <TopMenu />
        
        
        </div>
      </div>
      <div className="mb-3 switch-btn-line"> </div>
      </h1>{" "}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lastSettingsEpViewSelected: state.viewHistoryReducer.lastBaceEpViewSelected,
    allProjectData: state.listViewReducer?.allProjectData,
    projectNameDeleteNotPossible:
      state.listViewReducer?.projectNameDeleteNotPossible,
    projectNameDeletePossible: state.listViewReducer?.projectNameDeletePossible,
  };
}

export default withRouter(
  connect(mapStateToProps, { settingsEpViewChange, deleteWholeProject })(
    SettingsMovingSubmenu
  )
);
