const BACE_EP_VIEW_CHANGED = "BACE_EP_VIEW_CHANGED";
const MODBUS_EP_VIEW_CHANGED = "MODBUS_EP_VIEW_CHANGED";
const SETTINGS_EP_VIEW_CHANGED = "SETTINGS_EP_VIEW_CHANGED";
const ACTIVITY_MONITORS_VIEW_CHANGED = "ACTIVITY_MONITORS_VIEW_CHANGED";

const baceEpViewChangeSuccess = (viewOption) => ({
  type: BACE_EP_VIEW_CHANGED,
  viewOption,
});

const modbusEpViewChangeSuccess = (viewOption) => ({
  type: MODBUS_EP_VIEW_CHANGED,
  viewOption,
});

const settingsEpViewChangeSuccess = (viewOption) => ({
  type: SETTINGS_EP_VIEW_CHANGED,
  viewOption,
});

const activityMonitorsViewChangeSuccess = (viewOption) => ({
  type: ACTIVITY_MONITORS_VIEW_CHANGED,
  viewOption,
});

export const baceEpViewChange = (viewOption) => (dispatch) => {
  dispatch(baceEpViewChangeSuccess(viewOption));
};

export const modbusEpViewChange = (viewOption) => (dispatch) => {
  dispatch(modbusEpViewChangeSuccess(viewOption));
};

export const settingsEpViewChange = (viewOption) => (dispatch) => {
  dispatch(settingsEpViewChangeSuccess(viewOption));
};

export const activityMonitorsViewChange = (viewOption) => (dispatch) => {
  dispatch(activityMonitorsViewChangeSuccess(viewOption));
};
