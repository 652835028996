import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ModbusGatewaysSubmenu from "../../components/menus/sub-menu/subtypes/ModbusGatewaysSubmenu";
import DeviceListCard from "../../components/device-cards/DeviceListCard";
import { getListViewData, clearListViewData } from "../../actions/list-view";
import LoaderRoller from "../../components/loader/LoaderRoller";
import TopMenu from "../../components/menus/top-menu/TopMenu";
import { Link } from "react-router-dom";
import ModbusGatewayEdit from "./ModbusGatewayEdit";
const initialState = {
  formInput: {
    gatewayModbus: false,
    projectId: "",
  },
};
class ModbusMovePage extends Component {
  state = initialState;
  componentDidMount = () => {
    const formInput = { ...this.state.formInput };
    formInput.projectId = this.props.match.params?.projectId;
    this.setState({ formInput });
    const { currentPageNr } = this.props.match.params;
  };
  handleCancelView = () => {
    const formInput = { ...this.state.formInput };
    formInput.selectCancelGateway = false;
    this.setState({ formInput });
  };
  render() {
    const formInput = this.state.formInput;
    return (
      <div>
        <ModbusGatewayEdit />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps, {})(ModbusMovePage));
