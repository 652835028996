import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import DownloadIcon from "../../../icons/DownloadIcon";

const initialState = {
  id_group: null,
};

class OneBleGatewaySubmenu extends Component {
  state = initialState;
  componentDidMount = () => {
    const { id: id_group } = this.props.match.params;
    if (!id_group) {
      this.props.history.push("/");
    }

    this.setState({
      id_group,
    });
  };

  render() {
    const routeArr = window.location.pathname.split("/");

    return (
      <div className="row">
        <div className="col pl-4">
          <h1 className="page-title">
            <Link to="/ble-gateways/list-view/1" className="link-unstyled">
              BLE gateways
            </Link>
            {this.props.deviceViewData ? (
              <span className="page-title-bold">
                {" "}
                | {this.props.deviceViewData.label}
              </span>
            ) : (
              <div></div>
            )}
          </h1>
          <div>
            <Link
              className={` ${
                this.props.location.pathname ===
                `/ble-gateways/${this.state.id_group}/device-info`
                  ? "bacelink-active"
                  : "bacelink"
              }`}
              to={`/ble-gateways/${this.state.id_group}/device-info`}
            >
              <FontAwesomeIcon
                style={{ cursor: "pointer", marginRight: "4px" }}
                icon={faList}
              />
              Device info
            </Link>
            <div className="switch-btn-line"></div>
            <div className="mt-4">
              <div
                className={`form-group ${
                  routeArr[routeArr.length - 1] === "measurement-data"
                    ? "row"
                    : "d-none"
                }`}
              >
                <div className="col-auto">
                  <input
                    className="form-control"
                    type="date"
                    value={moment(this.state.fromTime).format("YYYY-MM-DD")}
                    onChange={(e) =>
                      this.changeDate({
                        date: "dateFrom",
                        value: e.target.valueAsDate,
                      })
                    }
                  />
                </div>
                <div className="col-auto">to</div>
                <div className="col-auto">
                  <input
                    className="form-control"
                    type="date"
                    value={moment(this.state.toTime).format("YYYY-MM-DD")}
                    onChange={(e) =>
                      this.changeDate({
                        date: "dateTo",
                        value: e.target.valueAsDate,
                      })
                    }
                  />
                </div>

                <div className="col-auto ms-auto">
                  <button
                    className={true ? "d-block bacelink" : "d-none"}
                    onClick={this.props.openExport}
                  >
                    <DownloadIcon /> Export
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    deviceViewData: state.listViewReducer.deviceViewData,
    //latestData: state.groupReducer?.latestData,
  };
}

export default withRouter(connect(mapStateToProps)(OneBleGatewaySubmenu));
