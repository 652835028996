/* eslint-disable no-unused-expressions */
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHardDrive,
  faCircleInfo,
  faTrashCan,
  faPlusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { logDOM } from "@testing-library/react";
import EditModbusDevicesRegisters from "./EditModbusDevicesRegisters";
import ModbusCardView from "../../../views/modbus-gateways/ModbusCardView";
import ModbusListView from "../../../views/modbus-gateways/ModbusListView";

class EditModbusDevices extends Component {
  state = {
    formInput: {
      nextPageRegister: false,
      ModbuserrorView: false,
      dataList: [],
      addRow: false,
      labelId: false,
      uniqueRow: [],
      oldData: [],
      modbusErrorModeMsg: false,
      registerList: [],
      dataNewList: [],
      modbusHome: false,
      routeURLPath: "",
      modbusDeviceSetting: false,
      newDataList: [],
      uniqueModbusList: [],
      oldRowDeviceData: "0",
      ipValue: [],
      IpDataList: [],
      oldTCPData: [],
      NewTCPData: [],
      modeName: "",
      addrList: [],
      modbusErrorIPAddressMsg: false,
    },
  };

  handleToNextRegister = () => {
    const formInput = { ...this.state.formInput };
    if (formInput.modeName === "TCP") {
      const valueMissing = formInput.oldTCPData.map((l) => l.IPadress === "");
      const newValueMissing = formInput.NewTCPData.map(
        (l) => l.IPadress === ""
      );

      if (valueMissing.includes(true) || newValueMissing.includes(true)) {
        formInput.labelId = true;
        formInput.ModbuserrorView = false;
        formInput.modbusDeviceSetting = false;
        this.setState({ formInput });
      } else {
        formInput.registerList = this.props.modbusGatewayInfo?.registers;
        formInput.NewTCPData.map((n) => {
          formInput.dataNewList.push(n);
        });
        formInput.oldTCPData.map((np) => {
          formInput.dataNewList.push(np);
        });
        formInput.nextPageRegister = true;
        this.setState({ formInput });
      }
    } else {
      formInput.dataList =
        formInput.dataList.length === 0 && formInput.oldRowDeviceData === "0"
          ? this.props.modbusGatewayInfo?.registers
          : formInput.dataList;
      const valueMissing = formInput.oldData.map((l) => l.addr === "");
      const newValueMissing = formInput.uniqueRow.map((l) => l.addr === "");
      if (valueMissing.includes(true) || newValueMissing.includes(true)) {
        formInput.labelId = true;
        formInput.ModbuserrorView = false;
        formInput.modbusDeviceSetting = false;
        this.setState({ formInput });
      } else {
        formInput.dataList.map((n) => {
          formInput.dataNewList.push(n);
        });
        formInput.uniqueRow.map((np) => {
          formInput.dataNewList.push(np);
        });
        formInput.registerList = this.props.modbusGatewayInfo?.registers;
        formInput.ipValue.map((m) => {
          this.props.modbusGatewayInfo.ips.push(m);
        });
        formInput.nextPageRegister = true;
        this.setState({ formInput });
      }
    }
  };

  handleToAddNewrow = () => {
    const formInput = { ...this.state.formInput };
    formInput.addRow = true;
    if (
      formInput.modeName === "RTU" &&
      formInput.oldData.length + formInput.uniqueRow.length > 31
    ) {
      formInput.ModbuserrorView = true;
      this.setState({ formInput });
    }
    if (
      formInput.modeName === "TCP" &&
      formInput.oldTCPData.length + formInput.NewTCPData.length > 4
    ) {
      formInput.ModbuserrorView = true;
      this.setState({ formInput });
    } else {
      if (formInput.modeName === "TCP") {
        formInput.NewTCPData.push({
          IPadress: "",
          data_type: 1,
          id: "",
          id_modbus_register: "",
          id_modbus_template: "",
          modbusRegisterBinaries: [],
          opts_max: null,
          opts_min: null,
          opts_step: null,
          scale: 100,
          registers: {
            unit: "C",
            value_id: 2101,
            word_order: 0,
            reg_start: 26,
            reg_type: 0,
            access_mode: 2,
            addr: "",
            byte_order: 0,
            is_single_bit_binary: 0,
            label: "Temperaure",
          },
        });
      } else {
        formInput.uniqueRow.push({
          access_mode: 2,
          addr: "",
          byte_order: 0,
          data_type: 1,
          id: "",
          id_modbus_register: "",
          id_modbus_template: "",
          is_single_bit_binary: 0,
          label: "Temperaure",
          modbusRegisterBinaries: [],
          opts_max: null,
          opts_min: null,
          opts_step: null,
          reg_start: 26,
          reg_type: 0,
          scale: 100,
          unit: "C",
          value_id: 2101,
          word_order: 0,
        });
      }
      formInput.modbusDeviceSetting = false;
      this.setState({ formInput });
    }
  };

  handleModbusPage = () => {
    this.props.handleModbusPage();
  };

  handleInputByteChange = (e, m, index) => {
    const formInput = { ...this.state.formInput };
    formInput.oldData[index].byte_order =
      e.target.value === "Big Endian" ? 1 : 0;
    const byteOrderNumber = e.target.value === "Big Endian" ? 0 : 1;
    this.props.modbusGatewayInfo?.registers
      .filter((p) => p.addr === m.modbusDevice?.server_addr)
      .map((l) => (l.byte_order = byteOrderNumber));
    formInput.oldData[index].byte_order =
      e.target.value === "Big Endian" ? 1 : 0;
    this.setState({ formInput });
  };

  handleInputWordChange = (e, m, index) => {
    const formInput = { ...this.state.formInput };
    formInput.oldData[index].word_order =
      e.target.value === "Big Endian" ? 1 : 0;
    this.setState({ formInput });
  };

  handleRowByteChange = (e, m, index) => {
    const formInput = { ...this.state.formInput };
    formInput.uniqueRow[index].byte_order =
      e.target.value === "Big Endian" ? 1 : 0;
    this.setState({ formInput });
  };

  handleRowWordChange = (e, m, index) => {
    const formInput = { ...this.state.formInput };
    formInput.uniqueRow[index].word_order =
      e.target.value === "Big Endian" ? 1 : 0;
    this.setState({ formInput });
  };

  handleInputModeChange = (e, m, index) => {
    const formInput = { ...this.state.formInput };
    const labelModeView = e.target.value;
    if (labelModeView > 32) {
      const labelview = e.target.className + " activeMsg";
      formInput.modbusErrorModeMsg = true;
      this.setState({ formInput });
    } else {
      if (this.props.modbusGatewayInfo.mode === 1) {
        formInput.ipValue[index] = e.target.value;
        formInput.uniqueRow[index].addr =
          this.props.modbusGatewayInfo.ips.length;
        formInput.modbusErrorModeMsg = false;
        this.setState({ formInput });
      } else {
        //    formInput.ModbusDataArray[index].slaveOrIpAddressID = e.target.value;
        formInput.uniqueRow[index].addr = e.target.value;
        formInput.modbusErrorModeMsg = false;
        this.setState({ formInput });
      }
    }
  };

  handleInputTCPOldModeChange = (e, m, index) => {
    const formInput = { ...this.state.formInput };
    const labelModeView = e.target.value;
    formInput.oldTCPData[index].IPadress = labelModeView;
    if (
      !(e.target.value,
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        e.target.value
      ))
    ) {
      formInput.modbusErrorIPAddressMsg = true;
      this.setState({ formInput });
    } else {
      this.setState({ formInput });
    }
  };

  handleInputTCPOldByteOrderModeChange = (e, m, index) => {
    const formInput = { ...this.state.formInput };
    formInput.oldTCPData[index].byte_order =
      e.target.value === "Big Endian" ? 1 : 0;
    this.setState({ formInput });
  };

  handleInputTCPOldWordOrderModeChange = (e, m, index) => {
    const formInput = { ...this.state.formInput };
    formInput.oldTCPData[index].word_order =
      e.target.value === "Big Endian" ? 1 : 0;
    this.setState({ formInput });
  };

  handleInputTCPIPAddressModeChange = (e, m, index) => {
    const formInput = { ...this.state.formInput };
    const labelModeView = e.target.value;
    formInput.NewTCPData[index].IPadress = labelModeView;
    if (
      !(e.target.value,
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        e.target.value
      ))
    ) {
      formInput.modbusErrorIPAddressMsg = true;
      this.setState({ formInput });
    } else {
      formInput.NewTCPData[index].addr =
        Math.max(formInput.addrList) + index + 1;

      this.setState({ formInput });
    }
  };

  handleInputTCPByteOrderModeChange = (e, m, index) => {
    const formInput = { ...this.state.formInput };
    formInput.NewTCPData[index].byte_order =
      e.target.value === "Big Endian" ? 1 : 0;
    this.setState({ formInput });
  };

  handleInputTCPWordOrderModeChange = (e, m, index) => {
    const formInput = { ...this.state.formInput };
    formInput.NewTCPData[index].word_order =
      e.target.value === "Big Endian" ? 1 : 0;
    this.setState({ formInput });
  };

  handleInputOldModeChange = (e, m, index) => {
    const formInput = { ...this.state.formInput };
    const labelModeView = e.target.value;

    if (labelModeView > 32) {
      const labelview = e.target.className + " activeMsg";
      formInput.modbusErrorModeMsg = true;
      this.setState({ formInput });
    } else {
      this.props.modbusGatewayInfo.ips[index] = e.target.value;
      //formInput.oldData[index].addr = index + 2;
      formInput.oldData[index].addr = e.target.value;
      formInput.modbusErrorModeMsg = false;
      this.setState({ formInput });
    }
  };

  handleToDeleteTCPRow = (rowId, addr, index) => {
    const formInput = { ...this.state.formInput };
    if (
      formInput.oldTCPData.length === 1 &&
      formInput.NewTCPData.length === 0
    ) {
      formInput.modbusDeviceSetting = true;
    } else {
      formInput.oldTCPData = formInput.oldTCPData.filter((row) => {
        return row.addr !== addr;
      });
    }
    this.setState({ formInput });
  };

  handleToDeleteUniqueTCPRow = (rowId, addr, index) => {
    const formInput = { ...this.state.formInput };
    if (
      formInput.oldTCPData.length === 0 &&
      formInput.NewTCPData.length === 1
    ) {
      formInput.modbusDeviceSetting = true;
    } else {
      formInput.NewTCPData = formInput.NewTCPData.filter((v, i) => i !== index);
    }
    this.setState({ formInput });
  };

  handleToDeleteRow = (rowId, addr, index) => {
    const formInput = { ...this.state.formInput };
    formInput.dataList =
      formInput.dataList.length === 0 && formInput.oldRowDeviceData === "0"
        ? this.props.modbusGatewayInfo?.registers
        : formInput.dataList;
    const key = "addr";
    const modbusList = [
      ...new Map(formInput.dataList.map((item) => [item[key], item])).values(),
    ];
    if (modbusList.length === 1 && formInput.uniqueRow.length === 0) {
      formInput.modbusDeviceSetting = true;
    } else {
      formInput.dataList = formInput.dataList.filter((row) => {
        return row.addr !== addr;
      });
      if (formInput.dataList.length === 0) {
        formInput.oldRowDeviceData = "1";
      }
    }
    this.setState({ formInput });
  };

  handleToDeleteUniqueRow = (rowId, addr, index) => {
    const formInput = { ...this.state.formInput };
    const key = "addr";
    const modbusList = [
      ...new Map(formInput.dataList.map((item) => [item[key], item])).values(),
    ];
    formInput.dataList =
      formInput.dataList.length === 0 && formInput.oldRowDeviceData === "0"
        ? this.props.modbusGatewayInfo?.registers
        : formInput.dataList;
    if (modbusList.length === 0 && formInput.uniqueRow.length === 1) {
      formInput.modbusDeviceSetting = true;
    } else {
      formInput.uniqueRow = formInput.uniqueRow.filter((_, i) => i !== index);
    }
    if (formInput.dataList.length === 0) {
      formInput.oldRowDeviceData = "1";
    }
    this.setState({ formInput });
  };

  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;
    if (!currentPageNr) {
      return this.props.history.push(
        `/modbus-gateways/config-view/1/${this.props.match.params}`
      );
    }
    const formInput = { ...this.state.formInput };
    formInput.addrList = this.props.modbusSubDevicesInfo.map(
      (o) => o.modbusDevice?.server_addr
    );
    formInput.modeName =
      this.props.modbusGatewayInfo.mode === 1 ? "TCP" : "RTU";
    if (this.props.modbusGatewayInfo.mode === 1) {
      this.props.modbusSubDevicesInfo.map((p, index) => {
        const modbusGatewayRegiterData =
          this.props.modbusGatewayInfo?.registers.filter(
            (ll) => ll.addr === p.modbusDevice?.server_addr
          )[0];

        if (p.modbusDevice !== null) {
          formInput.oldTCPData.push({
            IPadress: p.modbusDevice?.server_ip,
            byte_order: this.props.modbusGatewayInfo?.registers.filter(
              (ll) => ll.addr === p.modbusDevice?.server_addr
            )[0].byte_order,
            word_order: this.props.modbusGatewayInfo?.registers.filter(
              (ll) => ll.addr === p.modbusDevice?.server_addr
            )[0].word_order,
            addr: p.modbusDevice?.server_addr,
            access_mode: modbusGatewayRegiterData.access_mode,
            data_type: modbusGatewayRegiterData.data_type,
            id: modbusGatewayRegiterData.id,
            is_single_bit_binary: 0,
            label: modbusGatewayRegiterData.label,
            reg_start: modbusGatewayRegiterData.reg_start,
            reg_type: modbusGatewayRegiterData.reg_type,
            scale: modbusGatewayRegiterData.scale,
            unit: modbusGatewayRegiterData.unit,
            value_id: modbusGatewayRegiterData.value_id,
          });
        }
      });
    }
    this.setState({ formInput });
  };

  render() {
    const formInput = { ...this.state.formInput };
    formInput.dataList =
      formInput.dataList.length === 0 && formInput.oldRowDeviceData === "0"
        ? this.props.modbusGatewayInfo?.registers
        : formInput.dataList;
    const key = "addr";

    formInput.uniqueModbusList = [
      ...new Map(formInput.dataList.map((item) => [item[key], item])).values(),
    ];
    formInput.IpDataList = this.props.modbusGatewayInfo;
    if (formInput.oldData.length === 0) {
      formInput.oldData.push(
        ...new Map(formInput.dataList.map((item) => [item[key], item])).values()
      );
    }

    return (
      <div>
        <div>
          {formInput.nextPageRegister ? (
            <EditModbusDevicesRegisters
              modbusGatewayInfo={this.props.modbusGatewayInfo}
              modbusSubDevicesInfo={this.props.modbusSubDevicesInfo}
              inputRecordData={formInput}
              modbusGroupId={this.props.modbusGroupId}
              modbusPhysicalDeviceId={this.props.modbusPhysicalDeviceId}
              handleModbusPage={this.props.handleModbusPage}
            />
          ) : (
            <div>
              <div
                style={{
                  height: "400px",
                }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "8px" }}
                  className="fa-lg driveModbus"
                  icon={faHardDrive}
                />
                <span className="modbusTitle">
                  {" "}
                  Enter Modbus Device settings
                </span>

                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      height: "300px",
                      overflowY: "auto",
                      padding: "50px",
                    }}
                  >
                    <table
                      style={{
                        width: "80%",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            width: "29%",
                          }}
                        >
                          {this.props.modbusGatewayInfo.mode === 1
                            ? "IP Address"
                            : "Slave Id"}
                        </th>
                        <th>Byte order</th>
                        <th
                          style={{
                            width: "5%",
                          }}
                        ></th>
                        <th>Word order</th>
                        <th></th>
                      </tr>
                      <tbody>
                        {this.props.modbusGatewayInfo.mode === 1
                          ? formInput.oldTCPData.map((p, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    className="form-control-search formInputModbusSearch"
                                    value={p.IPadress}
                                    name="name"
                                    type="text"
                                    onChange={(e) =>
                                      this.handleInputTCPOldModeChange(
                                        e,
                                        p,
                                        index
                                      )
                                    }
                                    placeholder={
                                      this.props.modbusGatewayInfo.mode === 1
                                        ? "IP address"
                                        : "Slave ID"
                                    }
                                  />
                                </td>
                                <td>
                                  <select
                                    className="form-select dropdownView"
                                    aria-label="Default select example"
                                    value={
                                      p.byte_order == 0
                                        ? "Little Endian"
                                        : "Big Endian"
                                    }
                                    label="status"
                                    onChange={(e) =>
                                      this.handleInputTCPOldByteOrderModeChange(
                                        e,
                                        p,
                                        index
                                      )
                                    }
                                  >
                                    <option>Little Endian</option>
                                    <option>Big Endian</option>
                                  </select>
                                </td>
                                <td></td>
                                <td>
                                  <select
                                    className="form-select dropdownView"
                                    aria-label="Default select example"
                                    value={
                                      p.word_order == 0
                                        ? "Little Endian"
                                        : "Big Endian"
                                    }
                                    label="status"
                                    onChange={(e) =>
                                      this.handleInputTCPOldWordOrderModeChange(
                                        e,
                                        p,
                                        index
                                      )
                                    }
                                  >
                                    <option>Little Endian</option>
                                    <option>Big Endian</option>
                                  </select>
                                </td>
                                <td>
                                  <button
                                    className="btn-border-none"
                                    onClick={() =>
                                      this.handleToDeleteTCPRow(
                                        p,
                                        p.addr,
                                        index
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="svg-icons-sm"
                                      icon={faTrashCan}
                                    />
                                  </button>
                                </td>
                              </tr>
                            ))
                          : formInput.uniqueModbusList.map((m, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    className="form-control-search formInputModbusSearch"
                                    value={
                                      this.props.modbusGatewayInfo.mode === 1
                                        ? this.props.modbusGatewayInfo.ips[
                                            formInput.uniqueModbusList[index]
                                              .addr
                                          ]
                                        : m.addr
                                    }
                                    name="name"
                                    type="text"
                                    onChange={(e) =>
                                      this.handleInputOldModeChange(e, m, index)
                                    }
                                    placeholder={
                                      this.props.modbusGatewayInfo.mode === 1
                                        ? "IP address"
                                        : "Slave ID"
                                    }
                                  />
                                </td>
                                <td>
                                  <select
                                    className="form-select dropdownView"
                                    aria-label="Default select example"
                                    value={
                                      m.word_order === 0
                                        ? "Little Endian"
                                        : "Big Endian"
                                    }
                                    label="status"
                                    onChange={(e) =>
                                      this.handleInputByteChange(e, m, index)
                                    }
                                  >
                                    <option>Little Endian</option>
                                    <option>Big Endian</option>
                                  </select>
                                </td>
                                <td></td>
                                <td>
                                  <select
                                    className="form-select dropdownView"
                                    aria-label="Default select example"
                                    value={
                                      m.byte_order === 0
                                        ? "Little Endian"
                                        : "Big Endian"
                                    }
                                    label="status"
                                    onChange={(e) =>
                                      this.handleInputWordChange(e, m, index)
                                    }
                                  >
                                    <option>Little Endian</option>
                                    <option>Big Endian</option>
                                  </select>
                                </td>
                                <td>
                                  <button
                                    className="btn-border-none"
                                    onClick={() =>
                                      this.handleToDeleteRow(
                                        m.id,
                                        m.addr,
                                        index
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="svg-icons-sm"
                                      icon={faTrashCan}
                                    />
                                  </button>
                                </td>
                              </tr>
                            ))}
                        {formInput.modeName === "RTU"
                          ? formInput.uniqueRow.map((m, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    className="form-control-search formInputModbusSearch"
                                    defaultValue={m.addr}
                                    name="name"
                                    type="text"
                                    onChange={(e) =>
                                      this.handleInputModeChange(e, m, index)
                                    }
                                    placeholder={
                                      this.props.modbusGatewayInfo.mode === 1
                                        ? "IP address"
                                        : "Slave ID"
                                    }
                                  />
                                </td>
                                <td>
                                  <select
                                    className="form-select dropdownView"
                                    aria-label="Default select example"
                                    defaultValue={
                                      m.word_order === 0
                                        ? "Little Endian"
                                        : "Big Endian"
                                    }
                                    label="status"
                                    onChange={(e) =>
                                      this.handleRowByteChange(e, m, index)
                                    }
                                  >
                                    <option>Little Endian</option>
                                    <option>Big Endian</option>
                                  </select>
                                </td>
                                <td></td>
                                <td>
                                  <select
                                    className="form-select dropdownView"
                                    aria-label="Default select example"
                                    defaultValue={
                                      m.byte_order === 0
                                        ? "Little Endian"
                                        : "Big Endian"
                                    }
                                    label="status"
                                    onChange={(e) =>
                                      this.handleRowWordChange(e, m, index)
                                    }
                                  >
                                    <option>Little Endian</option>
                                    <option>Big Endian</option>
                                  </select>
                                </td>
                                <td>
                                  <button
                                    className="btn-border-none"
                                    onClick={() =>
                                      this.handleToDeleteUniqueRow(
                                        m.id,
                                        m.addr,
                                        index
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="svg-icons-sm"
                                      icon={faTrashCan}
                                    />
                                  </button>
                                </td>
                              </tr>
                            ))
                          : formInput.NewTCPData.map((m, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    className="form-control-search formInputModbusSearch"
                                    defaultValue={m.addr}
                                    name="name"
                                    type="text"
                                    onChange={(e) =>
                                      this.handleInputTCPIPAddressModeChange(
                                        e,
                                        m,
                                        index
                                      )
                                    }
                                    placeholder={
                                      this.props.modbusGatewayInfo.mode === 1
                                        ? "IP address"
                                        : "Slave ID"
                                    }
                                  />
                                </td>
                                <td>
                                  <select
                                    className="form-select dropdownView"
                                    aria-label="Default select example"
                                    defaultValue={
                                      m.word_order === 0
                                        ? "Big Endian"
                                        : "Little Endian"
                                    }
                                    label="status"
                                    onChange={(e) =>
                                      this.handleInputTCPByteOrderModeChange(
                                        e,
                                        m,
                                        index
                                      )
                                    }
                                  >
                                    <option>Little Endian</option>
                                    <option>Big Endian</option>
                                  </select>
                                </td>
                                <td></td>
                                <td>
                                  <select
                                    className="form-select dropdownView"
                                    aria-label="Default select example"
                                    defaultValue={
                                      m.byte_order === 0
                                        ? "Big Endian"
                                        : "Little Endian"
                                    }
                                    label="status"
                                    onChange={(e) =>
                                      this.handleInputTCPWordOrderModeChange(
                                        e,
                                        m,
                                        index
                                      )
                                    }
                                  >
                                    <option>Little Endian</option>
                                    <option>Big Endian</option>
                                  </select>
                                </td>
                                <td>
                                  <button
                                    className="btn-border-none"
                                    onClick={() =>
                                      this.handleToDeleteUniqueTCPRow(
                                        m.id,
                                        m.addr,
                                        index
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="svg-icons-sm"
                                      icon={faTrashCan}
                                    />
                                  </button>
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>
                      <button
                        className="btn-border-none"
                        onClick={this.handleToAddNewrow.bind(this)}
                      >
                        <FontAwesomeIcon
                          className="svg-icon-sm fa-lg plusEditModbus"
                          icon={faPlusCircle}
                        />
                      </button>
                    </div>
                    <div
                      style={{
                        display: "inline-flex",
                        marginTop: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      {" "}
                      <div
                        className={
                          formInput.modbusErrorModeMsg
                            ? "showErrorMsgMode"
                            : "hideMode"
                        }
                      >
                        Exceed slave Id range 1-32
                      </div>{" "}
                      <div
                        className={
                          formInput.modbusDeviceSetting
                            ? "showErrorMsgMode"
                            : "hideMode"
                        }
                      >
                        Atleast one modbus device is required
                      </div>{" "}
                      <div
                        className={
                          formInput.ModbuserrorView
                            ? "showMsgModbusMode"
                            : "hideMode"
                        }
                      >
                        {formInput.modeName === "TCP"
                          ? "Reached max numbers of devices, allow maximum of 5 devices in TCP"
                          : "Reached max numbers of devices, allow maximum of 32 devices in RTU"}
                      </div>{" "}
                      <div
                        className={
                          formInput.labelId ? "showErrorMsgMode" : "hideMode"
                        }
                      >
                        {formInput.modeName === "RTU"
                          ? "Slave Id can not be empty."
                          : "IP address can not be empty."}
                      </div>{" "}
                      <div
                        className={
                          formInput.modbusErrorIPAddressMsg
                            ? "showErrorMsgMode"
                            : "hideMode"
                        }
                      >
                        IP address is not valid
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="belowModbusButton">
                <button
                  onClick={this.handleToNextRegister}
                  className="btn btn-primary buttonView modbusButton"
                >
                  Next {">"} Add Registers
                </button>
                <button
                  onClick={this.handleModbusPage}
                  className="btn btn-secondary previousbutton"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps, {})(EditModbusDevices));
