import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import OneActivityMonitorSubmenu from "../../components/menus/sub-menu/subtypes/OneActivityMonitorSubmenu";
import MeasurementSessionDataChart from "../../components/device-cards/MeasurementSessionDataChart";
import ExportComponent from "../../components/global/ExportComponent";

import {
  clearGroupData,
  getGroupDatatypes,
  clearSessionData,
} from "../../actions/chart-view";
import {
  getGroupSessionListViewData,
  clearGroupSessionListViewData,
} from "../../actions/session-view";
import LoaderRoller from "../../components/loader/LoaderRoller";
import NoDataCard from "../../components/device-cards/NoDataCard";

import "./style.css";

class DeviceChartView extends Component {
  componentDidMount = () => {
    this.fetchData();
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.match.params?.sessionId !== prevProps.match.params?.sessionId
    ) {
      this.fetchData();
    }
  };

  fetchData = () => {
    const { id } = this.props.match.params;
    if (!id) {
      return this.props.history.push("/");
    }

    this.props.getGroupDatatypes(id); // also contains latestSession data
    this.props.getGroupSessionListViewData(id);
  };

  componentWillUnmount = () => {
    this.props.clearSessionData();
    this.props.clearGroupData();
    this.props.clearGroupSessionListViewData();
  };

  render() {
    if (
      this.props.datatypes &&
      this.props.datatypes.length === 0 &&
      this.props.latestSession &&
      this.props.groupSessionListViewData
    ) {
      return (
        <Fragment>
          <OneActivityMonitorSubmenu
            session_id={this.props.latestSession.id_data_session}
          />
          <NoDataCard />
        </Fragment>
      );
    } else if (
      this.props.datatypes &&
      this.props.datatypes.length > 0 &&
      this.props.latestSession &&
      this.props.groupSessionListViewData
    ) {
      return (
        <Fragment>
          <OneActivityMonitorSubmenu
            session_id={this.props.latestSession.id_data_session}
          />
          <div className="bacecontainer">
            <div className="d-flex justify-content-end">
              {this.props.datatypes && this.props.datatypes.length ? (
                <ExportComponent />
              ) : (
                <div></div>
              )}
            </div>
            <div className="row mt-3">
              <div className="col-lg-auto">
                {this.props.datatypes.map((datatype) => {
                  return (
                    <Fragment key={datatype.datatype}>
                      <MeasurementSessionDataChart
                        session_id={this.props.latestSession.id_data_session}
                        datatype={datatype}
                      />
                      <br />
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <OneActivityMonitorSubmenu />
          <div className="bacecontainer">
            <div className="row mt-3">
              <LoaderRoller />
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    datatypes: state.chartViewReducer?.groupDatatypes,
    latestSession: state.chartViewReducer?.groupLatestSession,
    groupSessionListViewData: state.sessionReducer.groupSessionListViewData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    clearSessionData,
    clearGroupData,
    getGroupDatatypes,
    getGroupSessionListViewData,
    clearGroupSessionListViewData,
  })(DeviceChartView)
);
