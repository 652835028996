import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
// import moment from "moment";
import { openExport } from "../../../../actions/screen-activities";

// import DownloadIcon from "../../../icons/DownloadIcon";
import ExportPopup from "../../../popups/ExportPopup";
import TopMenu from "../../top-menu/TopMenu";

const initialState = {
  id_group: null,
};

class OneModbusGatewaySubmenu extends Component {
  state = initialState;
  componentDidMount = () => {
    const { id: id_group } = this.props.match.params;

    if (!id_group) {
      this.props.history.push("/");
    }

    this.setState({
      id_group,
    });
  };

  render() {
    const routeArr = window.location.pathname.split("/");

    return (
      <div className="row">
        {this.props.exportPopup ? (
          <ExportPopup
            fromTime={this.state.fromTime}
            toTime={this.state.toTime}
          />
        ) : (
          ""
        )}
        <div className="col pl-4 ">
          <div style={{ display: "flex" }}>
            <TopMenu />
          </div>
          <h5 className="page-title">
            <h6
            // className="p-3 modbusDevice"
            // style={{ color: "#2c42b8", marginBottom: "0px" }}
            >
              <Link
                to={`/modbus-gateways/card-view/1/${this.props.match.params.projectId}`}
                className="link-unstyled-view"
              >
                Modbus Devices
              </Link>
              &nbsp;&nbsp; &gt;
            </h6>
            {this.props.allModbusDeviceInfo ? (
              <span>
                {" "}
                <h3 style={{ color: "#2c42b8", marginLeft: "15px" }}>
                  {this.props.allModbusDeviceInfo?.parentDevice?.label}
                </h3>
              </span>
            ) : (
              <div></div>
            )}
          </h5>

          <div class="mb-3 switch-btn-line"></div>
          <div>
            <Link
              className={` ${
                this.props.location.pathname ===
                `/modbus-gateways/${this.props.match.params.projectId}/${this.state.id_group}/device-info`
                  ? "bacelink-active"
                  : "bacelink"
              }`}
              to={`/modbus-gateways/${this.props.match.params.projectId}/${this.state.id_group}/device-info`}
            >
              <FontAwesomeIcon
                style={{ cursor: "pointer", marginRight: "4px" }}
                className="fa-lg"
                icon={faCircleInfo}
              />
              Device info
            </Link>
            <Link
              className={` ${
                this.props.location.pathname ===
                `/modbus-gateways/${this.props.match.params.projectId}/${this.state.id_group}/measurement-data`
                  ? "bacelink-active"
                  : "bacelink"
              }`}
              to={`/modbus-gateways/${this.props.match.params.projectId}/${this.state.id_group}/measurement-data`}
            >
              <FontAwesomeIcon
                style={{ cursor: "pointer", marginRight: "4px" }}
                icon={faChartLine}
              />
              Measurement data
            </Link>
            <div className="mt-4">
              <div
                className={`form-group ${
                  routeArr[routeArr.length - 1] === "measurement-data"
                    ? "row"
                    : "d-none"
                }`}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    //latestData: state.groupReducer?.latestData,
    deviceViewData: state.listViewReducer.deviceViewData,
    exportPopup: state.screenActivitiesReducer?.exportPopup,
  };
}

export default withRouter(
  connect(mapStateToProps, { openExport })(OneModbusGatewaySubmenu)
);
