import axios from "axios";
//import { newRedirect } from "./redirect";
import { showWarning } from "./notification";
import CONFIG from "../config";

const { baseUrl } = CONFIG;

const ALL_SESSIONS_RECEIVED = "ALL_SESSIONS_RECEIVED";
const SESSION_LIST_VIEW_DATA_RECEIVED = "SESSION_LIST_VIEW_DATA_RECEIVED";
const CLEAR_SESSION_LIST_VIEW_DATA = "CLEAR_SESSION_LIST_VIEW_DATA";
const GROUP_SESSION_LIST_VIEW_DATA_RECEIVED =
  "GROUP_SESSION_LIST_VIEW_DATA_RECEIVED";
const CLEAR_GROUP_SESSION_LIST_VIEW_DATA = "CLEAR_GROUP_SESSION_LIST_VIEW_DATA";
const SESSION_DEVICE_DATA_RECEIVED = "SESSION_DEVICE_DATA_RECEIVED";
const CLEAR_SESSION_DEVICE_DATA = "CLEAR_SESSION_DEVICE_DATA";
const ALL_WEBHOOK_OVERVIEW_DATA_RECEIVED = "ALL_WEBHOOK_OVERVIEW_DATA_RECEIVED";
const CREATED_WEBHOOK_STATUS = "CREATED_WEBHOOK_STATUS";
const CLEAR_WEBHOOK_STATUS = "CLEAR_WEBHOOK_STATUS";
const CREATED_FAILED_WEBHOOK_STATUS = "CREATED_FAILED_WEBHOOK_STATUS";
const ENABLE_DISABLE_WEBHOOK_RECEIVED = "ENABLE_DISABLE_WEBHOOK_RECEIVED";
const CLEAR_ENABLE_DISABLE_WEBHOOK_STATUS =
  "CLEAR_ENABLE_DISABLE_WEBHOOK_STATUS";
const DELETE_WEBHOOK_RECEIVED = "DELETE_WEBHOOK_RECEIVED";
const CLEAR_DELETE_WEBHOOK_RECEIVED = "CLEAR_DELETE_WEBHOOK_RECEIVED";
const UPDATE_WEBHOOK_STATUS = "UPDATE_WEBHOOK_STATUS";
const CLEAR_EDIT_WEBHOOK_STATUS = "CLEAR_EDIT_WEBHOOK_STATUS";
const UPDATED_FAILED_WEBHOOK_STATUS = "UPDATED_FAILED_WEBHOOK_STATUS";
const WEBHOOK_EP_VIEW_CHANGED = "WEBHOOK_EP_VIEW_CHANGED";
const WEBHOOK_INFO_DATA_RECEIVED = "WEBHOOK_INFO_DATA_RECEIVED";
const WEBHOOK_ATTEMPTS_DATA_RECEIVED = "WEBHOOK_ATTEMPTS_DATA_RECEIVED";
const getAllSessionSuccess = (data) => ({
  type: ALL_SESSIONS_RECEIVED,
  data,
});

const getCreateWebhookDetail = (data) => ({
  type: CREATED_WEBHOOK_STATUS,
  data,
});

const getUpdateWebhookDetail = (data) => ({
  type: UPDATE_WEBHOOK_STATUS,
  data,
});

const getCreateWebhookFailedDetail = (data) => ({
  type: CREATED_FAILED_WEBHOOK_STATUS,
  data,
});

const getUpdateWebhookFailedDetail = (data) => ({
  type: UPDATED_FAILED_WEBHOOK_STATUS,
  data,
});

const clearNewWebhookSuccess = (data) => ({
  type: CLEAR_WEBHOOK_STATUS,
  data,
});

const clearEnableDisableWebhookSuccess = (data) => ({
  type: CLEAR_ENABLE_DISABLE_WEBHOOK_STATUS,
  data,
});

const clearEditWebhookSuccess = (data) => ({
  type: CLEAR_EDIT_WEBHOOK_STATUS,
  data,
});

const getSessionListViewDataSuccess = (data) => ({
  type: SESSION_LIST_VIEW_DATA_RECEIVED,
  data,
});

const getAllWebhooksOverviewSuccess = (data) => ({
  type: ALL_WEBHOOK_OVERVIEW_DATA_RECEIVED,
  data,
});

const getAllWebhooksInfoSuccess = (data) => ({
  type: WEBHOOK_INFO_DATA_RECEIVED,
  data,
});

const getAllWebhooksAttemptsSuccess = (data) => ({
  type: WEBHOOK_ATTEMPTS_DATA_RECEIVED,
  data,
});

const getDeleteWebhookSuccess = (data) => ({
  type: DELETE_WEBHOOK_RECEIVED,
  data,
});

const clearDeleteWebhookSuccess = (data) => ({
  type: CLEAR_DELETE_WEBHOOK_RECEIVED,
  data,
});

const enableDisableWebhookSuccess = (data) => ({
  type: ENABLE_DISABLE_WEBHOOK_RECEIVED,
  data,
});

const clearSessionListViewDataSuccess = () => ({
  type: CLEAR_SESSION_LIST_VIEW_DATA,
});

const getGroupSessionListViewDataSuccess = (data) => ({
  type: GROUP_SESSION_LIST_VIEW_DATA_RECEIVED,
  data,
});

const clearGroupSessionListViewDataSuccess = () => ({
  type: CLEAR_GROUP_SESSION_LIST_VIEW_DATA,
});

const getSessionDeviceDataSuccess = (data) => ({
  type: SESSION_DEVICE_DATA_RECEIVED,
  data,
});

const clearSessionDeviceDataSuccess = () => ({
  type: CLEAR_SESSION_DEVICE_DATA,
});

export const getAllSessions = () => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

  axios
    .get(`${baseUrl}/data-session`)
    .then((res) => {
      dispatch(getAllSessionSuccess(res.data));
    })
    .catch((err) => {
      dispatch(showWarning(err.message));
    });
};

const webhookEpViewChangeSuccess = (viewOption) => ({
  type: WEBHOOK_EP_VIEW_CHANGED,
  viewOption,
});

export const webhookEpViewChange = (viewOption) => (dispatch) => {
  dispatch(webhookEpViewChangeSuccess(viewOption));
};

// retrieve all data necessary for the list views
export const getSessionListViewData =
  (pageNr, sortColumn, sortDirection, filterKey, filterValue) =>
  (dispatch, getState, props) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios
      .get(
        `${baseUrl}/data-session?expand=fromDevice,group&per-page=20${
          sortColumn && sortDirection
            ? `&sort=${sortDirection === "DESC" ? "-" : ""}${sortColumn}`
            : ""
        }${pageNr ? `&page=${pageNr}` : ""}${
          filterKey && filterValue
            ? `&filter[${filterKey}][like]=${filterValue}`
            : ""
        }`
      )
      .then((res) => {
        dispatch(getSessionListViewDataSuccess(res.data));
      })
      .catch((err) => {
        dispatch(showWarning(err.message));
      });
  };

export const clearSessionListViewData = () => (dispatch) => {
  dispatch(clearSessionListViewDataSuccess());
};

// retrieve all data necessary for the group list views
export const getGroupSessionListViewData =
  (groupId, pageNr, sortColumn, sortDirection, filterKey, filterValue) =>
  (dispatch, getState, props) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios
      .get(
        `${baseUrl}/data-session?expand=fromDevice,group&filter[group.id_group]=${groupId}&per-page=20${
          sortColumn && sortDirection
            ? `&sort=${sortDirection === "DESC" ? "-" : ""}${sortColumn}`
            : ""
        }${pageNr ? `&page=${pageNr}` : ""}${
          filterKey && filterValue
            ? `&filter[${filterKey}][like]=${filterValue}`
            : ""
        }`
      )
      .then((res) => {
        dispatch(getGroupSessionListViewDataSuccess(res.data));
      })
      .catch((err) => {
        dispatch(showWarning(err.message));
      });
  };

export const clearGroupSessionListViewData = () => (dispatch) => {
  dispatch(clearGroupSessionListViewDataSuccess());
};

export const getSessionDeviceData = (id) => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

  axios
    .get(`${baseUrl}/data-session/${id}?expand=fromDevice,group`)
    .then((res) => {
      dispatch(getSessionDeviceDataSuccess(res.data));
    })
    .catch((err) => {
      dispatch(showWarning(err.message));
    });
};

export const clearSessionDeviceData = () => (dispatch) => {
  dispatch(clearSessionDeviceDataSuccess());
};

export const clearNewWebhook = () => (dispatch) => {
  dispatch(clearNewWebhookSuccess());
};

export const clearEnableDisableWebhook = () => (dispatch) => {
  dispatch(clearEnableDisableWebhookSuccess());
};

export const clearEditWebhook = () => (dispatch) => {
  dispatch(clearEditWebhookSuccess());
};

export const clearDeleteWebhook = () => (dispatch) => {
  dispatch(clearDeleteWebhookSuccess());
};
export const getAllWebhooksOverview = (projectId) => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

  axios
    .get(`${baseUrl}/webhook?filter[id_project]=${projectId}`)
    .then((res) => {
      dispatch(getAllWebhooksOverviewSuccess(res.data));
    })
    .catch((err) => {
      dispatch(
        showWarning({ body: err?.response?.data?.message || err.message })
      );
    });
};

export const getWebhooksInfo = (webhookId) => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

  axios
    .get(`${baseUrl}/webhook/${webhookId}`)
    .then((res) => {
      dispatch(getAllWebhooksInfoSuccess(res.data));
    })
    .catch((err) => {
      dispatch(
        showWarning({ body: err?.response?.data?.message || err.message })
      );
    });
};

export const getWebhooksAttempts = (webhookId) => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

  axios
    .get(`${baseUrl}/webhook-queue/${webhookId}/show-history`)
    .then((res) => {
      dispatch(getAllWebhooksAttemptsSuccess(res.data));
    })
    .catch((err) => {
      dispatch(
        showWarning({ body: err?.response?.data?.message || err.message })
      );
    });
};

export const enableDisableWebhook =
  (webhookId, disableValue) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios({
      method: "PUT",
      url: `${baseUrl}/webhook/${webhookId}`,
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
      referrerPolicy: "no-referrer",
      data: {
        disabled_at: disableValue,
      },
    })
      .then((res) => {
        dispatch(enableDisableWebhookSuccess(res));
      })
      .catch((err) => {
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const createNewWebhook =
  (webhookData, projectId) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios({
      method: "POST",
      url: `${baseUrl}/webhook`,
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
      referrerPolicy: "no-referrer",
      data: {
        id_project: projectId,
        webhook_url: webhookData.urlValue,
        label: webhookData.labelName,
        forwarding_type: webhookData.forwardingType,
        formatter: webhookData.formatterType,
        authorization: webhookData.authorization,
      },
    })
      .then((res) => {
        dispatch(getCreateWebhookDetail("pass"));
      })
      .catch((err) => {
        //  dispatch(showWarning(err.message));
        dispatch(getCreateWebhookFailedDetail("failed"));
      });
  };

export const updateWebhook =
  (webhookData, projectId) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios({
      method: "PUT",
      url: `${baseUrl}/webhook/${webhookData.webhookId}`,
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
      referrerPolicy: "no-referrer",
      data: {
        //  id_project: projectId,
        webhook_url: webhookData.urlValue,
        label: webhookData.labelName,
        forwarding_type: webhookData.forwardingType,
        formatter: webhookData.formatterType,
        authorization: webhookData.authorization,
        disabled_at: webhookData.status,
      },
    })
      .then((res) => {
        dispatch(getUpdateWebhookDetail("pass"));
      })
      .catch((err) => {
        //  dispatch(showWarning(err.message));
        dispatch(getUpdateWebhookFailedDetail("failed"));
      });
  };

export const DeleteWebhook = (webhookId) => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

  axios
    .delete(`${baseUrl}/webhook/${webhookId}`)
    .then((res) => {
      dispatch(getDeleteWebhookSuccess(res.status));
    })
    .catch((err) => {
      dispatch(
        showWarning({ body: err?.response?.data?.message || err.message })
      );
    });
};
