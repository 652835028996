import React, { Component } from "react";
//import CardBody from "./CardBody";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { faServer, faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import AddRegistersModbus from "./AddRegistersModbus";
import { clearApplyModbus } from "../../actions/modbus-view";
import { Link } from "react-router-dom";

class Result extends Component {
  state = {
    formInput: {
      nextSet: false,
      modeNumber: "0",
      modeId: "0",
      modbusEdit: false,
      previousModbusTab: "",
      previousTab: "",
      previousSetTab: "",
      previousRegisterTab: "",
      nextSetTab: "",
      nextRegisterDevicesTab: "",
      nextModbusDevicesTab: "",
      nextResult: "",
      id_iot_device_Value: "",
    },
  };
  componentDidMount = () => {
    this.defaultResult();
    // this.props.nextResult();
  };

  defaultResult = (formId) => {
    const formInput = { ...this.state.formInput };
    formInput.previousTab = this.props.previousTab;
    formInput.previousSetTab = this.props.previousSetTab;
    formInput.previousModbusTab = this.props.previousModbusTab;
    formInput.previousRegisterTab = this.props.previousRegisterTab;
    formInput.nextSetTab = this.props.nextSetTab;
    formInput.nextRegisterDevicesTab = this.props.nextRegisterDevicesTab;
    formInput.nextModbusDevicesTab = this.props.nextModbusDevicesTab;
    formInput.nextResult = this.props.nextResult;
    this.setState({ formInput });
  };

  handleToShowRegister = (event, lo, index) => {
    const formInput = { ...this.state.formInput };

    // formInput.regisiterList = lo.registers;
    formInput.modeNumber = index;
    formInput.modeId = lo;
    this.setState({ formInput });
  };

  handleToEdit = () => {
    const formInput = { ...this.state.formInput };
    formInput.modbusEdit = true;
    this.setState({ formInput });
    this.props.clearApplyModbus();
    this.props.previousRegisterTab();
  };
  handleToModbusdevice = () => {
    this.props.clearApplyModbus();

    return this.props.history.push("/modbus-gateways/card-view/1");
  };

  render() {
    const formInput = this.state.formInput;

    formInput.previousTab = this.props.previousTab;
    formInput.previousSetTab = this.props.previousSetTab;
    formInput.previousModbusTab = this.props.previousModbusTab;
    formInput.previousRegisterTab = this.props.previousRegisterTab;
    formInput.nextSetTab = this.props.nextSetTab;
    formInput.nextRegisterDevicesTab = this.props.nextRegisterDevicesTab;
    formInput.nextModbusDevicesTab = this.props.nextModbusDevicesTab;
    formInput.nextResult = this.props.nextResult;

    formInput.id_iot_device_Value = this.props.id_iot_device_Value;

    const dataModbusResult = this.props.modbusResultInfo;
    let dataSlaveId = [];

    let data = _.groupBy(this.props.redisterData, "addr");
    var resArr = [];

    let dataSlaveValueId = _.keys(data);

    formInput.modeId =
      formInput.modeId === "0" ? dataSlaveValueId[0] : formInput.modeId;
    dataSlaveValueId =
      this.props.modbusDetails.arrayipAddress.length === 0
        ? dataSlaveValueId
        : this.props.modbusDetails.arrayipAddress;
    return (
      <div>
        {formInput.modbusEdit === true ? (
          <AddRegistersModbus
            modbusDetails={this.props.modbusDetails}
            //   nextResult={this.props.nextResult}
            previousTab={formInput.previousTab}
            previousSetTab={formInput.previousSetTab}
            previousModbusTab={formInput.previousModbusTab}
            previousRegisterTab={formInput.previousRegisterTab}
            nextSetTab={formInput.nextSetTab}
            nextRegisterDevicesTab={formInput.nextRegisterDevicesTab}
            nextModbusDevicesTab={formInput.nextModbusDevicesTab}
            nextResult={formInput.nextResult}
            id_iot_device_Value={this.props.id_iot_device_Value}
          />
        ) : (
          <div>
            <div>
              <FontAwesomeIcon
                style={{ marginRight: "8px" }}
                className="fa-lg driveModbus"
                icon={faServer}
              />
            </div>
            <div style={{ display: "flex", padding: "27px" }}>
              <div style={{ display: "flex" }} className="container">
                <ul
                  style={{ display: "block", width: "15%" }}
                  className="nav nav-pills nav-stacked"
                >
                  <div style={{ fontWeight: "700" }}>
                    {this.props.modbusDetails.arrayipAddress.length !== 0
                      ? "IP Address"
                      : "Slave Id"}

                    {/* {modbusDetails.modeName === "modbus-rtu"
                  ? "Slave Id"
                  : "IP Address"} */}
                  </div>
                  {dataSlaveValueId.map((m, index) => (
                    <li>
                      <button
                        onClick={(p) => this.handleToShowRegister(p, m, index)}
                        className={
                          formInput.modeNumber === index ||
                          (formInput.modeNumber === "0" && index === 0)
                            ? "deviceList activeSetting"
                            : "deviceList"
                        }
                      >
                        {m}
                      </button>
                    </li>
                  ))}
                </ul>
                <div style={{ width: "85%" }}>
                  <table>
                    <tr style={{ textAlign: "center" }}>
                      <th>
                        <div>Label</div>
                      </th>
                      <th>
                        <div>Unit</div>
                      </th>
                      <th>
                        <div>
                          <span>Reg start </span>{" "}
                          <FontAwesomeIcon
                            className="fa-1x questionIcon buttonPerform"
                            icon={faCircleQuestion}
                            style={{ marginTop: "5px" }}
                          />{" "}
                        </div>
                      </th>
                      <th>
                        <div>
                          <span>scale </span>{" "}
                          <FontAwesomeIcon
                            className="fa-1x questionIcon buttonPerform"
                            icon={faCircleQuestion}
                            style={{ marginTop: "5px" }}
                          />{" "}
                        </div>
                      </th>
                      <th style={{ width: "17%" }}>
                        <div>
                          Reg type
                          <FontAwesomeIcon
                            className="fa-1x questionIcon buttonPerform"
                            icon={faCircleQuestion}
                            style={{ marginTop: "5px" }}
                          />
                        </div>
                      </th>
                      <th style={{ width: "2%" }}>
                        <div></div>
                      </th>
                      <th>
                        <div>
                          data type{" "}
                          <FontAwesomeIcon
                            className="fa-1x questionIcon buttonPerform"
                            icon={faCircleQuestion}
                            style={{ marginTop: "5px" }}
                          />
                        </div>
                      </th>
                      <th style={{ width: "2%" }}>
                        <div></div>
                      </th>
                      <th>
                        <div>
                          Access mode{" "}
                          <FontAwesomeIcon
                            className="fa-1x questionIcon buttonPerform"
                            icon={faCircleQuestion}
                            style={{ marginTop: "5px" }}
                          />
                        </div>
                      </th>{" "}
                      <th style={{ width: "2%" }}>
                        <div></div>
                      </th>
                      <th>
                        <div>Value</div>
                      </th>
                    </tr>{" "}
                    <tr style={{ height: "10px", textAlign: "center" }}></tr>
                    {this.props.redisterData !== undefined
                      ? this.props.redisterData
                          ?.filter(
                            (m) =>
                              m.addr ===
                              ((
                                this.props.modbusDetails?.modeName ===
                                "modbus-tcp"
                                  ? formInput.modeNumber === "1"
                                  : formInput.modeId === "0"
                              )
                                ? dataSlaveValueId[0]
                                : this.props.modbusDetails?.modeName ===
                                  "modbus-tcp"
                                ? formInput.modeNumber + 1
                                : formInput.modeId)
                          )
                          // .filter((m) => m.addr === formInput.modeId)
                          .map((p, index) => (
                            <tr style={{ textAlign: "center" }}>
                              {" "}
                              <td>{p.label}</td>
                              <td>{p.unit}</td>
                              <td>{p.reg_start}</td>
                              <td>{p.scale}</td>
                              <td>{p.reg_type}</td>
                              <td></td>
                              <td>{p.data_type}</td>
                              <td></td>
                              <td>{p.access_mode}</td>
                              <td></td>
                              <td>
                                {
                                  this.props.modbusResultInfo?.data?.latestData.filter(
                                    (o) => o.datatype === p.value_id
                                  )[0]?.value
                                }
                              </td>
                            </tr>
                          ))
                      : ""}
                  </table>
                </div>
              </div>
            </div>
            <div className="belowResultButton">
              <span>
                <div style={{ width: "70%" }}>
                  Modbus device settings applied,reading register values may
                  take some time <a href="">click here</a> to go to overview
                </div>
              </span>{" "}
              <button
                onClick={this.handleToEdit}
                className="btn btn-primary previousEditbutton"
              >
                Edit
              </button>
              <button
                //  onClick={this.handleToModbusdevice}
                className="btn btn-primary buttonView modbusResultButton"
              >
                <Link to="/modbus-gateways/card-view/1" className="gotoModbus">
                  Go to Modbus Devices
                </Link>
              </button>{" "}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, { clearApplyModbus })(Result);
