import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ModbusGatewaysSubmenu from "../../components/menus/sub-menu/subtypes/ModbusGatewaysSubmenu";
import DeviceListCard from "../../components/device-cards/DeviceListCard";
import { getListViewData, clearListViewData } from "../../actions/list-view";
import LoaderRoller from "../../components/loader/LoaderRoller";
import ModbusEpSubMenu from "./ModbusEpSubMenu";
import ModbusEpBottomMenu from "./ModbusEpBottomMenu";
import { getAllModbusDevices } from "../../actions/modbus-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNetworkWired,
  faAngleDown,
  faAngleUp,
  faChartLine,
  faPlusCircle,
  faSliders,
} from "@fortawesome/pro-regular-svg-icons";

import { Link } from "react-router-dom";
import DownIcon from "@material-ui/icons/ArrowDropDown";
import UpIcon from "@material-ui/icons/ArrowDropUp";
import globalFunctions from "../../helpers/functions";
import Moment from "react-moment";
import { logDOM } from "@testing-library/react";
import ModbusGatewayEdit from "./ModbusGatewayEdit";
const { Td } = globalFunctions;

class ModbusListView extends Component {
  state = {
    expandedRows: [],
    formInput: {
      modbusIndex: 0,
      selectModel: "false",
      expandRegister: false,
      lengthLatestData: "",
      selectModbusGateway: false,
      addrValue: "",
    },
  };

  componentDidMount = () => {
    const { currentPageNr, projectId } = this.props.match.params;
    const projectsId = window.location.pathname.slice(29);
    if (!currentPageNr) {
      return this.props.history.push(
        `/modbus-gateways/list-view/1/${projectsId}`
      );
    }
    // return this.props.getAllModbusDevices();
  };

  componentDidUpdate = (prevProps) => {};

  changeModbusDevice = (modbusNumber) => {
    const formInput = { ...this.state.formInput };
    formInput.modbusIndex = modbusNumber;
    formInput.selectModel = true;
    this.setState({ formInput });
  };
  componentWillUnmount = () => {
    if (!window.location.pathname.startsWith("/modbus-gateways")) {
    }
  };
  handleNewGateway = () => {
    this.props.history.push("/modbus-gateways/dashboard-view/1");
    //window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  handleConfigureOption = () => {
    const modbusGatewayInfo = this.props.modbusGatewayInfo;
    const formInput = { ...this.state.formInput };
    formInput.selectModbusGateway = true;
    this.setState({ formInput });

    //  this.props.history.push("/modbus-gateways/edit-view/1");
  };
  handleExpand = (template) => {
    const formInput = { ...this.state.formInput };
    formInput.addrValue = template.modbusDevice.server_addr;
    const templateData =
      this.props.allModbusDevices?.items[formInput.modbusIndex]
        ?.currentModbusTemplate?.registers;

    const newExpandedRows = [...this.state.expandedRows];
    const idxFound = newExpandedRows.findIndex((id) => {
      return id === template.modbusDevice.server_addr;
    });
    if (idxFound > -1) {
      newExpandedRows.splice(idxFound, 1);
    } else {
      newExpandedRows.push(template.modbusDevice.server_addr);
    }

    this.setState({ expandedRows: [...newExpandedRows] });
    this.setState({ formInput });
  };

  isExpanded = (template) => {
    const formInput = { ...this.state.formInput };
    formInput.lengthLatestData = template.latestData.length;

    const templateData =
      this.props.allModbusDevices?.items[formInput.modbusIndex]
        ?.currentModbusTemplate?.registers;
    const idx = this.state.expandedRows.find((id) => {
      return id === template.modbusDevice?.server_addr;
    });

    return idx > -1;
    this.setState({ formInput });
  };

  getRows = (i, index) => {
    const formInput = { ...this.state.formInput };
    const templateData =
      this.props.allModbusDevices?.items[formInput.modbusIndex]
        ?.currentModbusTemplate?.registers;
    const rows = [];
    const combinedArr = [];
    //  const configPresets = template.deviceConfigPresets || [];

    const firstRow = (
      <>
        {i.modbusDevice !== null && (
          <tr key={index} className="listViewBorderStyle">
            <Td className="card-list-key" style={{ maxWidith: "25px" }}>
              <p
                className={`${
                  i.is_connected === 1 ? "connected-list" : "disconnected-list"
                }`}
              >
                {i.is_connected === 0 ? "Offline" : "Online"}
              </p>
            </Td>

            <Td
              // to={`/bace-ep/${i.id_physical_device}/device-info`}
              className="card-list-value"
            >
              <p className="labelDesign">{i.label === "" ? "" : i.label}</p>
            </Td>
            <Td className="card-list-value">
              {this.props.allModbusDevices?.items[formInput.modbusIndex]
                ?.currentModbusTemplate?.mode === 1
                ? i.modbusDevice?.server_ip
                : i.modbusDevice?.server_addr}
            </Td>
            <Td className="card-list-value"> {i.inactive_at}</Td>

            <Td className="card-list-value">
              {i?.last_data_at !== null ? (
                <Moment format="DD MMM YYYY HH:mm">
                  {i?.last_data_at * 1000}
                </Moment>
              ) : (
                <div></div>
              )}
            </Td>
            <Td>
              <div onClick={() => this.handleExpand(i)}>
                {this.isExpanded(i) ? (
                  <FontAwesomeIcon className="m-2" icon={faAngleUp} />
                ) : (
                  <FontAwesomeIcon className="m-2" icon={faAngleDown} />
                )}
              </div>
            </Td>
          </tr>
        )}
      </>
    );
    rows.push(firstRow);
    if (this.isExpanded(i)) {
      const data = (
        <tbody className="mt-2 code-block">
          <tr>
            {" "}
            <th></th>
            <th></th>
            <th>Register name</th>
            <th>Value</th>
            <th>Time</th>
            <th>Graph</th>
          </tr>
          {i.latestData.map(
            (k) =>
              templateData.find(
                (elem) => elem.value_id === k.datatype
                // &&
                //   elem.addr === formInput.addrValue
              ) !== undefined && (
                <tr>
                  {" "}
                  <td></td>
                  <td></td>
                  <td>
                    {" "}
                    <div>
                      {" "}
                      <div title={k.label}> {k.label}</div>{" "}
                    </div>
                  </td>
                  <td>
                    <div>
                      {" "}
                      {this.props.allModbusDevices?.items[formInput.modbusIndex]
                        ?.currentModbusTemplate?.mode === 0
                        ? k.value.includes(".")
                          ? parseFloat(k.value).toFixed(2)
                          : k.value
                        : k.value}
                      {k.unit}
                    </div>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY HH:mm">{k.timestamp}</Moment>
                  </td>
                  <td>
                    {" "}
                    <FontAwesomeIcon
                      className="fa-lg"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Chart"
                      icon={faChartLine}
                    />
                  </td>
                </tr>
              )
          )}
        </tbody>
      );
      rows.push(data);
    }
    return rows;
  };

  render() {
    const formInput = { ...this.state.formInput };
    const templateData =
      this.props.allModbusDevices?.items[formInput.modbusIndex]
        ?.currentModbusTemplate?.registers;

    const templateRows = this.props.allModbusDevices?.items[
      formInput.modbusIndex
    ].subDevices.map((i, index) => {
      //  .map((template) => {
      return this.getRows(i, index);
    });
    if (this.props.allModbusDevices) {
      const { pathname } = this.props.location;
      const { currentPageNr } = this.props.match.params;
      const baseRoute = currentPageNr
        ? pathname.substring(0, pathname.lastIndexOf("/"))
        : pathname;
      const sortColumn = new URLSearchParams(this.props.location.search).get(
        "sortColumn"
      );
      const sortDirection = new URLSearchParams(this.props.location.search).get(
        "sortDirection"
      );
      const newSortDirection = sortDirection
        ? sortDirection === "ASC"
          ? "DESC"
          : "ASC"
        : "DESC";
      return (
        <div>
          {" "}
          {formInput.selectModbusGateway ? (
            <div>
              <ModbusGatewayEdit
                modbusGatewayInfo={
                  this.props.allModbusDevices?.items[formInput.modbusIndex]
                    ?.currentModbusTemplate
                }
                modbusSubDevicesInfo={
                  this.props.allModbusDevices?.items[formInput.modbusIndex]
                    ?.subDevices
                }
              />
            </div>
          ) : (
            <Fragment>
              {" "}
              <div>
                <ModbusGatewaysSubmenu
                  nrResults={9}
                  // nrResults={this.props.listViewData?._meta?.totalCount}
                />
                <ModbusEpSubMenu />
                <div style={{ display: "flex" }}>
                  <div className="nav-item  arrowModbus" role="presentation">
                    {"<"}
                  </div>

                  <ul
                    className="nav nav-tabs tabModbus tabModbusLayout code-block"
                    id="ex1"
                    role="tablist"
                  >
                    {this.props.allModbusDevices?.items.map((m, index) => (
                      <li className="nav-item" role="presentation">
                        <button
                          className={
                            index === formInput.modbusIndex
                              ? "nav-link viewModbus"
                              : "nav-link hideModbus"
                          }
                          // id="ex1-tab-1"
                          data-mdb-toggle="tab"
                          onClick={() => this.changeModbusDevice(index)}
                          role="tab"
                          aria-controls="ex1-tabs-1"
                          aria-selected="true"
                        >
                          <span style={{ fontWeight: 600 }}>
                            {m.label === "" ? "No label name" : m.label}
                            <FontAwesomeIcon
                              className="svg-icons-sm fa-sm"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Strong"
                              icon={faNetworkWired}
                            />
                          </span>
                          <span>
                            <br />
                          </span>
                          <span className="idDevicetype">
                            van-wijnen {m.id_device_type}
                          </span>
                        </button>
                      </li>
                    ))}
                  </ul>
                  <div className="nav-item  arrowModbus" role="presentation">
                    {">"}
                  </div>
                </div>
                <div className="card-p listMainModbus">
                  <table className="table">
                    <thead>
                      <tr className="tableRow">
                        <th className="card-list-key" scope="col">
                          <Link
                            className="specs-key clickable"
                            scope="col"
                            to={`${baseRoute}/1?sortColumn=is_connected&sortDirection=${newSortDirection}`}
                          >
                            Status
                          </Link>
                          {sortColumn === "is_connected" ? (
                            sortDirection === "ASC" ? (
                              <DownIcon />
                            ) : (
                              <UpIcon />
                            )
                          ) : (
                            ""
                          )}
                        </th>
                        <th className="card-list-key" scope="col">
                          <Link
                            className="specs-key clickable"
                            scope="col"
                            to={`${baseRoute}/1?sortColumn=label&sortDirection=${newSortDirection}`}
                          >
                            Name
                          </Link>
                          {sortColumn === "label" ? (
                            sortDirection === "ASC" ? (
                              <DownIcon />
                            ) : (
                              <UpIcon />
                            )
                          ) : (
                            ""
                          )}
                        </th>
                        <th className="card-list-key" scope="col">
                          <Link
                            className="specs-key clickable"
                            scope="col"
                            to={`${baseRoute}/1?sortColumn=label&sortDirection=${newSortDirection}`}
                          >
                            {this.props.allModbusDevices?.items[
                              formInput.modbusIndex
                            ]?.currentModbusTemplate?.mode === 1
                              ? "IP Address"
                              : "Slave Id"}
                          </Link>
                          {sortColumn === "label" ? (
                            sortDirection === "ASC" ? (
                              <DownIcon />
                            ) : (
                              <UpIcon />
                            )
                          ) : (
                            ""
                          )}
                        </th>
                        <th className="card-list-key" scope="col">
                          <Link
                            className="specs-key clickable"
                            scope="col"
                            to={`${baseRoute}/1?sortColumn=id_device_type&sortDirection=${newSortDirection}`}
                          >
                            Device alerts
                          </Link>
                          {sortColumn === "id_device_type" ? (
                            sortDirection === "ASC" ? (
                              <DownIcon />
                            ) : (
                              <UpIcon />
                            )
                          ) : (
                            ""
                          )}
                        </th>
                        <th className="card-list-key" scope="col">
                          <Link
                            className="specs-key clickable"
                            scope="col"
                            to={`${baseRoute}/1?sortColumn=connection&sortDirection=${newSortDirection}`}
                          >
                            Last seen
                          </Link>
                          {sortColumn === "connection" ? (
                            sortDirection === "ASC" ? (
                              <DownIcon />
                            ) : (
                              <UpIcon />
                            )
                          ) : (
                            ""
                          )}
                        </th>
                      </tr>
                    </thead>
                    {/* <tbody className="mh-50 listViewStyle"> */}
                    {/* {this.props.allModbusDevices?.items?.length > 0 ? (
                  this.props.allModbusDevices?.items[
                    formInput.modbusIndex
                  ].subDevices.map((i, index) => (
                    // this.props.listViewData.items.map((i, index) => (
                    <tr key={index} className="listViewBorderStyle">
                      <Td
                        className="card-list-key"
                        style={{ maxWidith: "25px" }}
                      >
                        <p
                          className={`${
                            i.is_connected === 1
                              ? "connected-list"
                              : "disconnected-list"
                          }`}
                        >
                          {i.is_connected === 0 ? "Disconnected" : "Connected"}
                        </p>
                      </Td>

                      <Td
                        // to={`/bace-ep/${i.id_physical_device}/device-info`}
                        className="card-list-value"
                      >
                        <p className="labelDesign">
                          {i.label === "" ? "" : i.label}
                        </p>
                      </Td>
                      <Td className="card-list-value">
                        {i?.modbusDevice?.server_addr}
                      </Td>
                      <Td className="card-list-value"> {i.inactive_at}</Td>

                      <Td className="card-list-value">
                        {i?.last_data_at !== null ? (
                          <Moment format="DD MMM YYYY HH:mm A">
                            {i?.last_data_at * 1000}
                          </Moment>
                        ) : (
                          <div></div>
                        )}
                      </Td>
                      <Td>
                        <div onClick={() => this.handleExpand(i, templateData)}>
                          {formInput.expandRegister ? (
                            <FontAwesomeIcon className="m-2" icon={faAngleUp} />
                          ) : (
                            <FontAwesomeIcon
                              className="m-2"
                              icon={faAngleDown}
                            />
                          )}
                        </div>
                      </Td>
                    </tr>
                  ))
                ) : (
                  <tr></tr>
                )} */}
                    {templateRows}
                    {/* </tbody> */}
                  </table>
                </div>
                <div className="divModbusModal">
                  <div className="settingsViewArea">
                    <button
                      className="settingsViewAreaCheck buttonSettings"
                      onClick={this.handleConfigureOption}
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        className="fa-lg"
                        icon={faSliders}
                      />
                      Configure
                    </button>
                  </div>
                  <div className="settingsViewAreaGateway">
                    <button
                      className="settingsViewAreaCheck buttonSettings"
                      onClick={this.handleNewGateway}
                    >
                      <FontAwesomeIcon
                        className="svg-icons-sm fa-lg"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Strong"
                        icon={faPlusCircle}
                      />{" "}
                      Add new gateway
                    </button>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      );
      // } else {
      //   return (
      //     <Fragment>
      //       <ModbusGatewaysSubmenu nrResults={0} />
      //       <div className="bacecontainer pl-2">
      //         <LoaderRoller />
      //       </div>
      //     </Fragment>
      //   );
      // }
    } else {
      return <div></div>;
    }
  }
}

function mapStateToProps(state) {
  return {
    allModbusDevices: state.modbusViewReducer.allModbusDevices,
    modbusEpView: state.viewHistoryReducer.lastModbusEpViewSelected,
  };
}

export default withRouter(
  connect(mapStateToProps, { getAllModbusDevices, clearListViewData })(
    ModbusListView
  )
);
