import axios from "axios";
//import { newRedirect } from "./redirect";
import { showWarning } from "./notification";
import CONFIG from "../config";

const { baseUrl } = CONFIG;

const ALL_SELTECT_GATEWAY_RECEIVED = "ALL_SELTECT_GATEWAY_RECEIVED";
const ALL_SELTECT_MODBUS_RECEIVED = "ALL_SELTECT_MODBUS_RECEIVED";
const APPLY_MODBUS_DATA = "APPLY_MODBUS_DATA";
const CLEAR_MODBUS_APPLY_DEVICE_DATA = "CLEAR_MODBUS_APPLY_DEVICE_DATA";
const ALL_MODBUS_DEVICES_RECEIVED = "ALL_MODBUS_DEVICES_RECEIVED";
const ALL_MODBUS_DEVICES_INFO_RECEIVED = "ALL_MODBUS_DEVICES_INFO_RECEIVED";
const CLEAR_MODBUS_DEVICE_DATA = "CLEAR_MODBUS_DEVICE_DATA";
const ALL_SAVE_MODBUS_RECEIVED = "ALL_SAVE_MODBUS_RECEIVED";
const ALL_LOAD_MODBUS_RECEIVED = "ALL_LOAD_MODBUS_RECEIVED";
const CLEAR_GATEWAY_DATA = "CLEAR_GATEWAY_DATA";
const ALL_MODBUS_DEVICE_FOR_GATEWAY = "ALL_MODBUS_DEVICE_FOR_GATEWAY";
const CLEAR_SELECT_GATEWAY_DATA = "CLEAR_SELECT_GATEWAY_DATA";
const CLEAR_FAIL_SELECT_GATEWAY_DATA = "CLEAR_FAIL_SELECT_GATEWAY_DATA";

const getAllSelectGatewaySuccess = (data) => ({
  type: ALL_SELTECT_GATEWAY_RECEIVED,
  data,
});

const getAllModbusDeviceForGatewaySuccess = (data) => ({
  type: ALL_MODBUS_DEVICE_FOR_GATEWAY,
  data,
});

const getModbusDeviceInfoSuccess = (data) => ({
  type: ALL_MODBUS_DEVICES_INFO_RECEIVED,
  data,
});

const getAllModbusDevicesSuccess = (data) => ({
  type: ALL_MODBUS_DEVICES_RECEIVED,
  data,
});

const getAllModbusSuccess = (data, dataPreload) => ({
  type: ALL_SELTECT_MODBUS_RECEIVED,
  data,
  dataPreload,
});

const getSaveModbusSuccess = (data, dataPreload) => ({
  type: ALL_SAVE_MODBUS_RECEIVED,
  data,
});

const getAllLoadModbusSuccess = (data, dataPreload) => ({
  type: ALL_LOAD_MODBUS_RECEIVED,
  data,
});

const getApplyModbusSuccess = (data) => ({
  type: APPLY_MODBUS_DATA,
  data,
});

const clearApplyModbusDataSuccess = () => ({
  type: CLEAR_MODBUS_APPLY_DEVICE_DATA,
});

const clearModbusDeviceDataSuccess = () => ({
  type: CLEAR_MODBUS_DEVICE_DATA,
});

const clearGateWayDataSuccess = () => ({
  type: CLEAR_GATEWAY_DATA,
});

const getClearSelectGatewaySuccess = (data) => ({
  type: CLEAR_SELECT_GATEWAY_DATA,
  data,
});

const getClearFailSelectGatewaySuccess = (data) => ({
  type: CLEAR_FAIL_SELECT_GATEWAY_DATA,
  data,
});

export const getClearSelectGateway =
  (id_physical_device, pageNr) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .delete(
        `${baseUrl}/physical-device/${id_physical_device}/clear-modbus-configuration`
      )
      .then((res) => {
        dispatch(getClearSelectGatewaySuccess(res));
      })
      .catch((err) => {
        dispatch(getClearFailSelectGatewaySuccess("failed"));
      });
  };

export const getAllSelectGateway =
  (projectId, pageNr) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .get(
        `${baseUrl}/physical-device?type=gateway&expand=subDevices,subDevices.modbusDevice&modbusDeviceFilter=without&filter[id_project]=${projectId}${
          pageNr ? `&page=${pageNr}` : ""
        }`
      )
      .then((res) => {
        dispatch(getAllSelectGatewaySuccess(res.data));
      })
      .catch((err) => {
        dispatch(showWarning(err.message));
      });
  };

export const getAllModbusDeviceForGateway =
  (modeName) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .get(`${baseUrl}/device-profile?sort=model`)
      .then((res) => {
        if (modeName === "modbus-tcp") {
          const dataViewTCP = res.data.items.filter(
            (p) => p.modbusTemplate?.ips.length !== 0
          );
          dispatch(getAllModbusDeviceForGatewaySuccess(dataViewTCP));
        } else {
          const dataViewRTU = res.data.items.filter(
            (p) => p.modbusTemplate?.ips.length === 0
          );
          dispatch(getAllModbusDeviceForGatewaySuccess(dataViewRTU));
        }
      })
      .catch((err) => {
        dispatch(showWarning(err.message));
      });
  };

export const getAllModbusDevices =
  (projectId, pageNr, sortColumn, sortDirection) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .get(
        `${baseUrl}/physical-device?type=gateway&expand=subDevices,subDevices.modbusDevice,subDevices.latestData,currentModbusTemplate&filter[id_project]=${projectId}&modbusDeviceFilter=only`
      )
      .then((res) => {
        dispatch(getAllModbusDevicesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(showWarning(err.message));
      });
  };

export const getLoadTemplate = (projectId) => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(
      `${baseUrl}/modbus-template?filter[id_project]=${projectId}&deprecated_at=null`
    )
    .then((res) => {
      dispatch(getAllLoadModbusSuccess(res.data));
    })
    .catch((err) => {
      dispatch(showWarning(err.message));
    });
};

export const getSaveModbus =
  (ModbusData, modbusRegistersData, modeValue, ipsData, modeName, projectId) =>
  (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    let modbusTemplateId = "";
    if (modeName === "modbus-rtu") {
      axios({
        method: "POST",
        url: `${baseUrl}/modbus-template`,
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.token}`,
        },
        referrerPolicy: "no-referrer",
        data: {
          label: ModbusData.label,
          hidden_at: null,
          deprecated_at: null,
          mode: ModbusData.mode,
          baud_rate: modeValue,
          interval: ModbusData.interval,
          id_physical_device: null,
          as_sub_devices: ModbusData.as_sub_devices,
          id_project: projectId,
          registers: modbusRegistersData,
        },
      })
        .then((ress) => {
          dispatch(getSaveModbusSuccess(ress));
        })
        .catch((err) => {
          dispatch(showWarning(err.message));
        });
    } else {
      axios({
        method: "POST",
        url: `${baseUrl}/modbus-template`,
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.token}`,
        },
        referrerPolicy: "no-referrer",
        data: {
          label: ModbusData.label,
          hidden_at: null,
          deprecated_at: null,
          mode: ModbusData.mode,
          interval: ModbusData.interval,
          ips: ipsData,
          id_physical_device: ModbusData.id_physical_device,
          as_sub_devices: ModbusData.as_sub_devices,
          id_project: projectId,
          registers: modbusRegistersData,
        },
      })
        .then((res) => {
          dispatch(getSaveModbusSuccess(res));
        })
        .catch((err) => {
          dispatch(showWarning(err.message));
        });
    }
  };

export const getApplyModbus =
  (ModbusData, modbusRegistersData, modeValue, ipsData, modeName, projectId) =>
  (dispatch, getState) => {
    const { token } = getState().userReducer;
    const todayTimestamp = new Date().getTime();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    let modbusTemplateId = "";
    if (modeName === "modbus-rtu") {
      axios({
        method: "POST",
        url: `${baseUrl}/modbus-template`,
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.token}`,
        },
        referrerPolicy: "no-referrer",
        data: {
          label: ModbusData.label,
          hidden_at: null,
          deprecated_at: todayTimestamp,
          mode: ModbusData.mode,
          baud_rate: modeValue,
          interval: ModbusData.interval,
          // ips: ipsData,
          id_physical_device: ModbusData.id_physical_device,
          as_sub_devices: ModbusData.as_sub_devices,
          id_project: projectId,
          registers: modbusRegistersData,
        },
      })
        .then((res) => {
          axios({
            method: "POST",
            url: `${baseUrl}/modbus-template/${res.data.id_modbus_template}/apply-to-devices`,
            cache: "no-cache",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.token}`,
            },
            referrerPolicy: "no-referrer",
            data: {
              deviceIdsArr: [ModbusData.id_physical_device],
              id_project: projectId,
            },
          });
          modbusTemplateId = res.data.id_modbus_template;
        })
        .then((resData) => {
          axios
            .get(`${baseUrl}/group/${ModbusData.id_group}?expand=latestData`)
            .then((ress) => {
              dispatch(getAllModbusSuccess(ress, modbusRegistersData));
            });
        })
        .catch((err) => {
          dispatch(showWarning(err.message));
        });
    } else {
      axios({
        method: "POST",
        url: `${baseUrl}/modbus-template`,
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.token}`,
        },
        referrerPolicy: "no-referrer",
        data: {
          label: ModbusData.label,
          hidden_at: null,
          deprecated_at: todayTimestamp,
          mode: ModbusData.mode,
          //  baud_rate: modeValue,
          interval: ModbusData.interval,
          ips: ipsData,
          id_physical_device: ModbusData.id_physical_device,
          as_sub_devices: ModbusData.as_sub_devices,
          registers: modbusRegistersData,
        },
      })
        .then((res) => {
          axios({
            method: "POST",
            url: `${baseUrl}/modbus-template/${res.data.id_modbus_template}/apply-to-devices`,
            cache: "no-cache",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.token}`,
            },
            referrerPolicy: "no-referrer",
            data: {
              deviceIdsArr: [ModbusData.id_physical_device],
            },
          });
          modbusTemplateId = res.data.id_modbus_template;
        })
        .then((resData) => {
          axios
            .get(`${baseUrl}/group/${ModbusData.id_group}?expand=latestData`)
            .then((ress) => {
              dispatch(getAllModbusSuccess(ress, modbusRegistersData));
            });
        })
        .catch((err) => {
          dispatch(showWarning(err.message));
        });
    }
  };

export const getAllModbusDetails =
  (modbusTemplateId) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .get(
        `${baseUrl}/modbus-template/${modbusTemplateId}?expand=devicesApplied,registers`
      )
      .then((res) => {
        dispatch(getAllModbusSuccess(res.data));
      })
      .catch((err) => {
        // dispatch(showWarning(err.message));
      });
  };

export const getModbusDeviceInfo =
  (physicalModbusDeviceId) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .get(
        `${baseUrl}/physical-device/${physicalModbusDeviceId.id}?expand=latestData,modbusDevice,parentDevice`
      )
      .then((res) => {
        dispatch(getModbusDeviceInfoSuccess(res.data));
      })
      .catch((err) => {
        // dispatch(showWarning(err.message));
      });
  };

export const clearApplyModbus = () => (dispatch) => {
  dispatch(clearApplyModbusDataSuccess());
};

export const clearModbusDeviceData = () => (dispatch) => {
  dispatch(clearModbusDeviceDataSuccess());
};

export const clearGateWayData = () => (dispatch) => {
  dispatch(clearGateWayDataSuccess());
};
