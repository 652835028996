import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import globalFunctions from "../../helpers/functions";
import BaceEpSubmenu from "../../components/menus/sub-menu/subtypes/BaceEpSubmenu";
import DeviceCard from "../../components/device-cards/DeviceCard";
import { getListViewData, clearListViewData } from "../../actions/list-view";
import LoaderRoller from "../../components/loader/LoaderRoller";
import Pagination from "@material-ui/lab/Pagination";
const { uniqueKey } = globalFunctions;

class DeviceCardView extends Component {
  state = {
    formInput: {
      name: "",
      status: "",
      protocol: "",
      connectivity: "",
      activeStatus: "",
      projectId: "",
    },
  };
  componentDidMount = () => {
    const projectId = window.location.pathname.slice(21);
    const formInput = this.state.formInput;
    formInput.projectId = projectId;
    this.setState({ formInput });

    if (this.props.baceEpView === "LIST_VIEW") {
      return this.props.history.push(`/bace-ep/list-view/1/${projectId}`);
    }

    const { currentPageNr } = this.props.match.params;
    if (!currentPageNr) {
      return this.props.history.push(`/bace-ep/card-view/1/${projectId}`);
    }
    this.props.clearListViewData();
    const filterParams = `filter[id_project]=${projectId}&filter[inactive_at]=NULL`;
    return this.props.getListViewData("", currentPageNr, "", filterParams);
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { currentPageNr } = this.props.match.params;
    const projectId = window.location.pathname.slice(21);

    if (!currentPageNr) {
      return this.props.history.push(`/bace-ep/card-view/1/${projectId}`);
    }
    const filterParams = `filter[id_project]=${projectId}&filter[inactive_at]=NULL`;
    if (this.props.listViewData === null) {
      return this.props.getListViewData(
        "bace-ep",
        currentPageNr,
        "",
        filterParams
      );
    }
  };

  handlePageChange = (event, value) => {
    const { formInput } = this.state;

    const status =
      formInput.status === 1
        ? "Offline"
        : formInput.status === 0
        ? "Online"
        : "";
    const filters = this.getFilters(status);

    this.props.getListViewData(value, "start", "DESC", filters);
    this.props.history.push("/bace-ep/card-view/" + value);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  getFilters = (status) => {
    const { formInput } = this.state;
    const projectId = window.location.pathname.slice(21);
    let filters = {
      "filter[label][like]": formInput.name ? formInput.name : undefined,
      "filter[id_device_type][like]": formInput.protocol
        ? formInput.protocol
        : undefined,
      "filter[iotDevice.iot_id][like]": formInput.id ? formInput.id : undefined,
    };

    if (status === "Online") {
      filters["filter[is_connected]"] = 1;
    } else if (status === "Offline") {
      filters["filter[is_connected]"] = "0";
    }
    if (formInput.connectivity === "Ethernet") {
      filters["filter[connection]"] = "ethernet";
    } else if (formInput.connectivity === "Cellular") {
      filters["filter[connection]"] = "cellular";
    } else if (formInput.connectivity === "Wifi") {
      filters["filter[connection]"] = "wifi";
    }

    if (formInput.activeStatus === "Active" || formInput.activeStatus === "") {
      filters["filter[inactive_at]"] = "NULL";
    } else if (formInput.activeStatus === "Inactive") {
      filters["filter[inactive_at][>]"] = 0;
    }
    filters["filter[id_project]"] = projectId;
    let params = Object.entries(filters)
      .filter((e) => e[1] !== undefined)
      .map((e) => {
        return e.join("=");
      })
      .join("&");
    return params;
  };

  handleFilterChange = () => {
    const { currentPageNr } = this.props.match.params;
    const { formInput } = this.state;
    const status =
      formInput.status === 1
        ? "Offline"
        : formInput.status === 0
        ? "Online"
        : "";
    const filters = this.getFilters(status);
    setTimeout(() => {
      this.props.getListViewData(currentPageNr, "start", "DESC", filters);
    }, 1000);
  };

  handleLabelChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.name = event.target.value.toLowerCase();
    this.setState({ formInput }, () => {
      this.handleFilterChange();
    });
  };
  handleProtocolChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.protocol = event.target.value.toLowerCase();
    this.setState({ formInput }, () => {
      this.handleFilterChange();
    });
  };
  handleIdChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.id = event.target.value.toLowerCase();
    this.setState({ formInput }, () => {
      this.handleFilterChange();
    });
  };
  handleConnectionChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.connectivity = event.target.value;
    this.setState({ formInput }, () => {
      this.handleFilterChange();
    });
  };

  handleActiveStatus = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.activeStatus = event.target.value;
    this.setState({ formInput }, () => {
      this.handleFilterChange();
    });
  };
  handleStatusChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.status =
      event.target.value === "Online"
        ? 0
        : event.target.value === "Offline"
        ? 1
        : null;

    this.setState({ formInput }, () => {
      this.handleFilterChange();
    });
  };
  render() {
    const formInput = this.state.formInput;
    const projectId = window.location.pathname.slice(21);

    if (this.props.listViewData) {
      return (
        <Fragment>
          <BaceEpSubmenu
            nrResults={this.props.listViewData?._meta?.totalCount}
            projectId={window.location.pathname.slice(21)}
          />
          <div>
            <div
              className="d-flex "
              style={{marginLeft: "5%" }}
            >
              <select
                style={{ width: "10.5%", marginRight: "10px" }}
                className="form-select dropdownView"
                aria-label="Default select example"
                defaultValue={
                  formInput.status === "Disconnected"
                    ? 0
                    : formInput.status === "Connected"
                    ? 1
                    : ""
                }
                label="status"
                onChange={this.handleStatusChange}
              >
                <option value="" disabled selected>
                  Status
                </option>
                <option selected>All</option>
                <option selected>Offline</option>
                <option selected>Online</option>
              </select>

              <div>
                <input
                  className="dropdownView formInputSearchDeviceView"
                  defaultValue={formInput.name}
                  name="name"
                  type="text"
                  onChange={this.handleLabelChange}
                  placeholder="Label"
                  size="8"
                  
                />
              </div>
              <div>
                <input
                  className="dropdownView formInputSearchDeviceId"
                  defaultValue={formInput.id}
                  id="id"
                  // type="text"
                  onChange={this.handleIdChange}
                  placeholder="Device ID"
                  size="12"
                  type="text"
                  
                />
              </div>
              <div>
                {/* <input
                  className="dropdownView formInputSearch"
                  defaultValue={formInput.protocol}
                  name="protocol"
                  type="text"
                  onChange={this.handleProtocolChange}
                  placeholder="Protocol"
                  size="10"
                /> */}
              </div>
              <div className="">
                <select
                  className="form-select dropdownView"
                  aria-label="Default select example"
                  defaultValue={formInput.connectivity}
                  label="connectivity"
                  onChange={this.handleConnectionChange}
                  placeholder="status"
                >
                  <option value="" disabled selected>
                    Connectivity
                  </option>
                  <option selected>All</option>
                  <option selected>Ethernet</option>
                  <option selected>Cellular</option>
                  <option selected>Wifi</option>
                </select>
              </div>
              <th style={{ paddingLeft: "7px" }}>
                <select
                  className="form-select dropdownView"
                  aria-label="Default select example"
                  defaultValue={formInput.activeStatus}
                  label="Active"
                  onChange={this.handleActiveStatus}
                  placeholder="status"
                >
                  <option value="" selected>
                    Active
                  </option>
                  <option selected>All</option>
                  {/* <option selected>Active</option> */}
                  <option selected>Inactive</option>
                </select>
              </th>
            </div>
            <div className="listMainCardView" >
            <div className="card-wrap " style={{marginLeft:"5%",justifyContent: "normal"}}>
              {this.props.listViewData.items.length > 0 ? (
                this.props.listViewData.items.map((group, index) => (
                  <Link
                    to={`/bace-ep/${projectId}/${group.id_physical_device}/device-info`}
                    className="col-md-auto"
                    key={index}
                    style={{ textDecoration: "none", marginTop: "12px" }}
                  >
                    {" "}
                    <div className="bacecard-width">
                      
                      <DeviceCard
                        type={"bace-ep"}
                        subpage={"device-info"}
                        deviceInfo={group}
                      />
                    </div>
                  </Link>
                ))
              ) : (
                <h5 className="p-4">No Data Found</h5>
              )}
            </div>
            </div>
          </div>
          {/* <div className="card-wrap">
            {this.props.listViewData.items.length > 0 ? (
              this.props.listViewData.items.map((group, index) => (
                <Link
                  to={`/bace-ep/${group.id_physical_device}/device-info`}
                  className="col-md-auto mt-4"
                  key={index}
                  style={{ textDecoration: "none" }}
                >
                  <div className="bacecard-width">
                    <DeviceCard
                      type={"bace-ep"}
                      subpage={"device-info"}
                      deviceInfo={group}
                    />
                  </div>
                </Link>
              ))
            ) : (
              <div></div>
            )}
          </div> */}

          {this.props.listViewData?._meta?.pageCount > 1 ? (
            <div className="pageStyle m-3">
              <Pagination
                count={this.props.listViewData?._meta?.pageCount}
                page={this.props.listViewData?._meta?.currentPage}
                onChange={this.handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <BaceEpSubmenu
            nrResults={0}
            projectId={window.location.pathname.slice(21)}
          />
          <div className="bacecontainer-lg">
            <LoaderRoller />
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    listViewData: state.listViewReducer.listViewData,
    baceEpView: state.viewHistoryReducer.lastBaceEpViewSelected,
    configData: state.viewHistoryReducer.listConfigViewData,
  };
}

export default withRouter(
  connect(mapStateToProps, { getListViewData, clearListViewData })(
    DeviceCardView
  )
);
