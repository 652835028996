import React, { Component, Fragment } from "react";
import CONFIG from "../../config";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import Login from "../login/Login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import BACELogo from "../../assets/logo/BACE-logo-blue.svg";
import { Link } from "react-router-dom";
import "../login/style.css";
import "./style.css";

import { createRegister } from "../../actions/user";
import Success from "./Success";
const { loginUrl, passResetUrl, clientId, clientSecret } = CONFIG;

class Register extends Component {
  state = {
    formInput: {
      emailValue: "",
      emailValid: false,
    },
  };
  componentDidMount = () => {};

  handleLabelChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.emailValue = event.target.value.toLowerCase();
    this.setState({ formInput });
  };

  handleRegister = () => {
    const formInput = { ...this.state.formInput };
    const emailValid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailValid.test(formInput.emailValue)) {
      formInput.emailValid = true;
    } else {
      formInput.emailValid = false;
      const queryParams = new URLSearchParams(this.props.location.search);
      const token = queryParams.get("token");
      this.props.createRegister(formInput.emailValue, clientId, clientSecret);
    }
    this.setState({ formInput });
  };

  render() {
    const { formInput } = this.state;
    return (
      <Fragment>
        {this.props.user?.RegisterDataResult?.status === 200 && (
          <div>
            <Route path="/register/success" exact component={Success} />
          </div>
        )}
        <div className="loginImgDiv"></div>
        <img className="loginImg" src={BACELogo} alt="BACE logo" />
        <div>
          <p className="welcomeBoard"> Create your Bace account </p>
        </div>
        <div>
          <div className="d-flex flex-row justify-content-center">
            <div className="bg-login-container">
              <div className="col-12">
                {" "}
                <div className="justify-content-center bace-logo">
                  <h3 className="loginView">Fill your email</h3>
                </div>
                <div className="emailBox">
                  <input
                    className="formInputEmail"
                    defaultValue={formInput.name}
                    name="name"
                    type="text"
                    onChange={this.handleLabelChange}
                    placeholder="Email address"
                    size="4"
                  />
                </div>
                <div className="text-center mt-4">
                  <button
                    rel="noopener noreferrer"
                    className="login-btn"
                    onClick={this.handleRegister}
                  >
                    &nbsp; Create
                  </button>
                  <Link to="/" className="link-register">
                    <button
                      rel="noopener noreferrer"
                      className="login-register cancel-register"
                    >
                      &nbsp; Cancel
                    </button>
                  </Link>
                </div>
                <div
                  className={
                    formInput.emailValid ? "showErrorMsgMode" : "hideMode"
                  }
                >
                  <p className="emailValidation">
                    {" "}
                    Email address is not valid.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer,
    RegisterDataResult: state.userReducer?.RegisterDataResult,
    RegisterFailedDataResult: state.userReducer?.RegisterFailedDataResult,
  };
}

export default withRouter(
  connect(mapStateToProps, { createRegister })(Register)
);
