import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./main.css";
import { faCircleCheck, faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
class StatusPopupDelete extends Component {
  render() {
    return (
      <div className="main-popup-edit">
        <div className="popup-style popup-container " style={{border: "2px solid var(--Ocean, #006)"}} >
          <div
            className="justify-content-end d-flex"
            onClick={this.props.closePopup}
          >
            <div style={{justifyItems: "right",display:"grid"}}>
            <FontAwesomeIcon icon={faTimes} className="wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top" style={{fontSize:"25px",marginTop:"10px",marginRight:"16px"}}></FontAwesomeIcon>
          </div>
          </div>
          <div className="justify-content-center d-flex">
            <FontAwesomeIcon
              icon={this.props.icon}
              className={this.props.className}
              style={{ width: "35px", height: "35px" }}
            ></FontAwesomeIcon>
          </div>
          <div className="font-weight-bold mb-3 mt-3 mr-5 ml-5">
            {this.props.message}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps, {})(StatusPopupDelete));
