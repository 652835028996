import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./main.css";
import { deleteConfigTemplate } from "../../actions/list-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

class DeletePopup extends Component {
  state = {
    formInput: {
      setDeleteButton: false,
    },
  };
  handleDelete = (id) => {
    const formInput = { ...this.state.formInput };
    formInput.setDeleteButton = true;
    this.props.deleteConfigTemplate(id, this.props.projectId);
    this.props.popupDeleteScreen();
    this.setState({ formInput });
  };

  render() {
    const formInput = { ...this.state.formInput };
    return (
      <div className="main-popup-delete">
        {/* {this.props.deletedConfigById === "Pass" ? (
          <div>
            <div onClick={this.handleToConnectionClose}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.props.popupScreen()}
              />
            </div>
            <div className="popup-container">Deleted Sucessfully</div>
          </div>
        ) : ( */}
        <div className="popup-container" style={{padding:"0px"}}>
        <div style={{justifyItems: "right",display:"grid"}}>
        <FontAwesomeIcon
                style={{fontSize:"30px",marginTop:"10px",marginRight:"16px"}}
                className="wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.props.handleToClose}
              />
              </div>
          <div className="mx-3 my-3" style={{ color: "black" }}>
            <div className="d-flex" style={{ color: "black" }}>
              <h6 className="deleteTitle" style={{ fontWeight: "700",marginTop:"2px" }}> Do you want to remove</h6> &nbsp;{" "}
              <span >
                {this.props.label}
              </span>{" "}
              &nbsp; <h6 className="deleteTitle" style={{ fontWeight: "700",marginTop:"2px" }}> Template?</h6>
            </div>
          </div>
          <div className="col pb-3">
            <div className="row">
              <div class="col-md-5">
              <button
                className="cancelbutton mx-1"
                onClick={this.props.handleToClose}
              >
                Cancel
              </button>
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-1"></div>
              <div class="col-md-4">
              <button
                // className="btn-export me-3 ms-1"
                className={
                  formInput.setDeleteButton
                    ? "cancelbutton submitDisableButton"
                    : "cancelbutton submitButton"
                }
                onClick={() => this.handleDelete(this.props.templateId)}
                disabled={formInput.setDeleteButton ? true : false}
              >
                Delete
              </button>
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    deletedConfigById: state.listViewReducer?.deletedConfigById,
  };
}

export default withRouter(
  connect(mapStateToProps, { deleteConfigTemplate })(DeletePopup)
);
