const CLEAR_ALERTS = "CLEAR_ALERTS";

const ADD_WARNING = "ADD_WARNING";
const ADD_DANGER = "ADD_DANGER";
const ADD_INFO = "ADD_INFO";
const CLEAR_ONE_NOTIFICATION = "CLEAR_ONE_NOTIFICATION";

const alertsCleared = () => ({
  type: CLEAR_ALERTS
});

const alertWarning = (data) => ({
  type: ADD_WARNING,
  data,
});

const alertInfo = (data) => ({
  type: ADD_INFO,
  data,
});

const alertDanger = (data) => ({
  type: ADD_DANGER,
  data,
});

const clearOneNotificationSuccess = (id) => ({
  type: CLEAR_ONE_NOTIFICATION,
  id,
});

export const clearAlerts = () => (dispatch) => {
  dispatch(alertsCleared());
};

export const showWarning = (data) => (dispatch) => {
  dispatch(alertWarning(data));
};

export const showInfo = (data) => (dispatch) => {
  dispatch(alertInfo(data));
};

export const showDanger = (data) => (dispatch) => {
  dispatch(alertDanger(data));
};

export const clearOneNotification = (id) => (dispatch) => {
  dispatch(clearOneNotificationSuccess(id));
};