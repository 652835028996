import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ModbusGatewaysSubmenu from "../../components/menus/sub-menu/subtypes/ModbusGatewaysSubmenu";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBrowser,
  faListUl,
  faSliders,
} from "@fortawesome/pro-regular-svg-icons";
import { modbusEpViewChange } from "../../actions/view-history";

class ModbusEpSubMenu extends Component {
  state = {
    formInput: {
      projectId: "",
    },
  };

  render() {
    const formInput = this.state.formInput;
    formInput.projectId = this.props.match.params?.projectId;
    return (
      <div>
        <div>
          <div className="divModal">
            <Link
              onClick={() => {
                this.props.modbusEpViewChange("CARD_VIEW");
              }}
              to={`/modbus-gateways/card-view/1/${formInput.projectId}`}
              className={` ${
                this.props.location.pathname.includes("card-view")
                  ? "bacelink-active"
                  : "bacelink-inactive"
              }`}
            >
              <FontAwesomeIcon className="fa-lg" icon={faBrowser} />
            </Link>

            <Link
              onClick={() => {
                this.props.modbusEpViewChange("LIST_VIEW");
              }}
              to={`/modbus-gateways/list-view/1/${formInput.projectId}`}
              className={` ${
                this.props.location.pathname.includes("list-view")
                  ? "bacelink-active"
                  : "bacelink-inactive"
              }`}
            >
              <FontAwesomeIcon className="fa-lg" icon={faListUl} />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, { modbusEpViewChange })(ModbusEpSubMenu)
);
