import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ModbusGatewaysSubmenu from "../../components/menus/sub-menu/subtypes/ModbusGatewaysSubmenu";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSliders } from "@fortawesome/pro-regular-svg-icons";
import { modbusEpViewChange } from "../../actions/view-history";
import ModbusGatewayEdit from "./ModbusGatewayEdit";

class ModbusEpBottomMenu extends Component {
  state = {
    formInput: {
      selectModbusGateway: false,
      projectId: "",
    },
  };

  handleNewGateway = () => {
    this.props.history.push("/modbus-gateways/dashboard-view/1");
    //window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  handleConfigureOption = () => {
    const modbusGatewayInfo = this.props.modbusGatewayInfo;
    const formInput = { ...this.state.formInput };
    formInput.selectModbusGateway = true;
    this.setState({ formInput });

    //  this.props.history.push("/modbus-gateways/edit-view/1");
  };

  render() {
    const formInput = { ...this.state.formInput };
    return (
      <div>
        {formInput.selectModbusGateway ? (
          <div>
            <ModbusGatewayEdit
              modbusGatewayInfo={this.props.modbusGatewayInfo}
            />
          </div>
        ) : (
          <div>
            <div>
              <div className="divModbusModal">
                <div className="settingsViewArea">
                  <button
                    className="settingsViewAreaCheck buttonSettings"
                    onClick={this.handleConfigureOption}
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: "10px" }}
                      className="fa-lg"
                      icon={faSliders}
                    />
                    Configure
                  </button>
                </div>
                <div className="settingsViewAreaGateway">
                  <button
                    className="settingsViewAreaCheck buttonSettings"
                    onClick={this.handleNewGateway}
                  >
                    <FontAwesomeIcon
                      className="svg-icons-sm fa-lg"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Strong"
                      icon={faPlusCircle}
                    />{" "}
                    Add new gateway
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, { modbusEpViewChange })(ModbusEpBottomMenu)
);
